import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  :focus {
    outline: 0;
  }
`

export const Dropdown = styled.div`
  margin-top: 0.5rem;
  border: 2px solid #5697f9;
  border-radius: 2px;
`
