import styled, { keyframes } from 'styled-components'

import Icon from 'Icon'
import mixins from 'css/mixins'

export const loaderAnimation = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`

export const Loader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: ${loaderAnimation} 1.8s infinite ease-in-out;
  &:before,
  &:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: ${loaderAnimation} 1.8s infinite ease-in-out;
  }
  color: #dfcca9;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }
  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 3.5em;
  }
`

export const FullImage = styled.div`
  margin-bottom: 60px;
  height: 80%;

  img {
    height: 100%;
  }
`

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  margin-top: -3px;
  padding: 12px;
`

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`

export const LabelName = styled.div`
  font-weight: bold;
`

export const Stars = styled.div`
  display: flex;
  padding-right: 10px;
`

export const Star = styled(Icon)`
  ${mixins.noselect()};
  ${mixins.icon('30px', '30px')};
  margin-left: 10px;
  cursor: pointer;
  path {
    fill: #343434;
  }

  &:hover {
    path {
      fill: #acacac;
    }
  }

  ${(props) =>
    props.active &&
    `

    path {
      fill: #FFCF3E;
    }

    &:hover {
      path {
        fill: #98791B;
      }
    }
  `}
`
