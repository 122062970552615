import React, { Component } from 'react'
import InViewMonitor from 'react-inview-monitor'
import Item from './Item'

import { AppContent, Grid } from './style.js'

export default class App extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { list, byId } = this.props

    return (
      <AppContent>
        <Grid>
          {list.map((Id, i) => (
            <InViewMonitor key={i} childPropsInView={{ render: true }}>
              <Item Id={Id} nr={i} byId={byId} />
            </InViewMonitor>
          ))}
        </Grid>
      </AppContent>
    )
  }
}
