function isDocumentElement(el) {
  return [document.documentElement, document.body, window].indexOf(el) > -1
}

function scrollTo(el, top) {
  if (isDocumentElement(el)) {
    window.scrollTo(0, top)
    return
  }

  el.scrollTop = top
}

export function scrollIntoView(listEl, focusedEl) {
  const listRect = listEl.getBoundingClientRect()
  const focusedRect = focusedEl.getBoundingClientRect()
  const overScroll = focusedEl.offsetHeight

  if (focusedRect.bottom + overScroll > listRect.bottom) {
    scrollTo(
      listEl,
      Math.min(
        focusedEl.offsetTop +
          focusedEl.clientHeight -
          listEl.offsetHeight +
          overScroll,
        listEl.scrollHeight
      )
    )
  } else if (focusedRect.top - overScroll < listRect.top) {
    scrollTo(listEl, Math.max(focusedEl.offsetTop - overScroll, 0))
  }
}
