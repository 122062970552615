import React, { Component } from 'react'
import checkDomTargetPath from 'checkDomTargetPath'
import gw_data from 'format_gw_data.js'

import FullImage from './FullImage'

import { Modal, PrevLink, NextLink } from './style.js'
export default class Item extends Component {
  constructor(props) {
    super(props)

    this.state = {
      usedEvent: false,
    }

    this.modal = React.createRef()

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleClickModal = this.handleClickModal.bind(this)
  }

  componentDidMount() {
    document.addEventListener(
      'keydown',
      (event) => this.handleKeyDown(event),
      false
    )
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleClickModal(evt) {
    const clickedBackground = checkDomTargetPath(evt, this.modal.current)

    if (clickedBackground) this.props.history.push('/')
  }

  handleKeyDown(event) {
    const { Id, history } = this.props
    const entry = gw_data.byId[Id]
    const keyName = event.key
    if (this.state.usedEvent) return

    if (keyName === 'Escape') {
      this.setState(
        {
          usedEvent: true,
        },
        () => history.push('/')
      )
      return
    }

    if (keyName === 'ArrowLeft') {
      this.setState(
        {
          usedEvent: true,
        },
        () => history.push(`/${entry._prevId}`)
      )
      return
    }

    if (keyName === 'ArrowRight') {
      this.setState(
        {
          usedEvent: true,
        },
        () => history.push(`/${entry._nextId}`)
      )
      return
    }
  }

  render() {
    const { Id, nr, onUpdate, byId } = this.props
    const entry = byId[Id]

    return (
      <Modal ref={this.modal} onClick={this.handleClickModal}>
        <PrevLink to={`/${entry._prevId}`}>prev</PrevLink>
        <FullImage Id={Id} nr={nr} byId={byId} onUpdate={onUpdate} />
        <NextLink to={`/${entry._nextId}`}>next</NextLink>
      </Modal>
    )
  }
}
