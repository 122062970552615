import React from 'react'
import logo from 'img/neverchosen.png'
import { AppHeader, AppLogoWrapper, AppLogo } from './style.js'

export default (props) => (
  <AppHeader className="App-header">
    <AppLogoWrapper>
      <AppLogo className="App-logo" src={logo} />
    </AppLogoWrapper>
  </AppHeader>
)
