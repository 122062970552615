import React from 'react'
import Select from 'Select'
import Selector from './Selector'

import { Root, SelectBox, Stars, Star } from './style.js'

export default ({ selectedCountry, options, onUpdate, selectedStars }) => (
  <Root>
    <Stars>
      {[1, 2, 3, 4, 5].map((n) => (
        <Star
          key={n}
          id="star"
          active={n <= selectedStars}
          onClick={() => onUpdate('stars', selectedStars === n ? 0 : n)}
        />
      ))}
    </Stars>
    <SelectBox>
      <Select
        searchKey="Title"
        defaultValue={selectedCountry}
        options={options}
        onSelect={(item) => onUpdate('countryId', item.Id)}
        Selector={Selector}
      />
    </SelectBox>
  </Root>
)
