import React from 'react'
import styled from 'styled-components'
import Icon from 'Icon'
import mixins from 'css/mixins'

const Selector = styled.div`
  display: flex;
  width: 150px;
  height: 25px;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #dadada;
  border-radius: 2px;
  padding: 4px 8px;
  cursor: pointer;
`

const SelectorValue = styled.div`
  font-size: 14px;
  color: #828282;
  font-weight: medium;
`

const SelectorIcon = styled(Icon)`
  ${mixins.icon('7px', '7px')};

  path {
    fill: #979797;
  }
`

export default ({ value, onClick, selectorRef }) => (
  <Selector ref={selectorRef} onClick={onClick}>
    <SelectorValue>{value}</SelectorValue>
    <SelectorIcon id="down" />
  </Selector>
)
