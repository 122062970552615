import React from 'react'
import styled from 'styled-components'

const Span = styled.span`
  z-index: 9999;
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  whitespace: nowrap;
  background-color: red;
  color: blue;
`

// Assistive text to describe visual elements. Hidden for sighted users.
const A11yText = (props) => <Span {...props} />

export default A11yText
