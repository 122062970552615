import React from 'react'
import PropTypes from 'prop-types'

import { Container, Value, Placeholder } from './style'

const Selector = ({
  disabled,
  value,
  placeholder,
  isFocused,
  onClick,
  selectorRef,
}) => (
  <Container
    ref={selectorRef}
    onClick={onClick}
    isFocused={isFocused}
    disabled={disabled}>
    {value ? <Value>{value}</Value> : <Placeholder>{placeholder}</Placeholder>}
  </Container>
)

Selector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

Selector.defaultProps = {
  value: '',
}

export default Selector
