import iconList from './iconList'
import React from 'react'

import { Icon } from './style.js'

export default require('create-react-class')({
  render() {
    return (
      <Icon
        {...this.props}
        iconId={this.props.id}
        styled={this.props.styled}
        className={this.props.className}
        dangerouslySetInnerHTML={{ __html: iconList[this.props.id].default }}
      />
    )
  },
})
