import getScrollbarWidth from 'getScrollbarWidth'
import { removeBodyScroll, restoreBodyScroll } from 'bodyScroll'

var counter = 0
var list = []

export function openPortal() {
  const newId = counter + 1
  counter++

  if (list.length === 0) {
    removeBodyScroll()
  }

  list.push(newId)
  return newId
}

export function closePortal(portalId) {
  list = list.filter((id) => id !== portalId)
  if (list.length === 0) {
    restoreBodyScroll()
  }
}

export function getPopupCoords(elem) {
  // crossbrowser version
  if (!elem) return {}

  var box = elem.getBoundingClientRect()

  var body = document.body
  var docEl = document.documentElement

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  var clientTop = docEl.clientTop || body.clientTop || 0
  var clientLeft = docEl.clientLeft || body.clientLeft || 0

  var top = box.top + scrollTop - clientTop
  var left = box.left + scrollLeft - clientLeft
  var bottom = box.bottom + scrollTop - clientTop
  var right = box.right + scrollLeft - clientLeft

  return {
    top: Math.round(top),
    left: Math.round(left),
    bottom: Math.round(bottom),
    right: Math.round(right),
    xCenter: Math.round(left) + elem.offsetWidth / 2,
    yCenter: Math.round(top) + elem.offsetHeight / 2,
  }
}
