// will need to inject svg dynamically once I go npm with this module

export default {
  close: require('svg/close.svg'),
  up: require('svg/up.svg'),
  down: require('svg/down.svg'),
  left: require('svg/left.svg'),
  right: require('svg/right.svg'),
  plus: require('svg/plus.svg'),
  minus: require('svg/minus.svg'),
  star: require('svg/star.svg'),
}
