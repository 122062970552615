import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Modal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: auto;
  text-decoration: none;
`

export const CloseLink = styled(Link)`
  cursor: pointer;
  color: white;
  padding: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
`

export const Links = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PrevLink = styled(Link)`
  cursor: pointer;
  color: white;
  padding: 20px;
`

export const NextLink = styled(Link)`
  cursor: pointer;
  color: white;
  padding: 20px;
`
