import getScrollbarWidth from 'getScrollbarWidth'

function createFakeScrollbar() {
  const element = document.createElement('div')

  element.classList.add('xch-mootools-portal-fake-scrollbar')
  element.style.cssText = `
    display: none;
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    width: ${getScrollbarWidth()}px;
    overflow-y: scroll;
    overflow-x: hidden;
  `
  return element
}

export function removeBodyScroll(node) {
  const element = node || document.getElementsByTagName('body')[0]
  const scrollTop = node
    ? element.scrollTop
    : window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0
  const scrollHeight = element.scrollHeight

  element.style.paddingRight = `${getScrollbarWidth()}px`
  element.style.overflowY = 'hidden'

  let fakeScrollbar = element.getElementsByClassName(
    'xch-mootools-portal-fake-scrollbar'
  )[0]

  if (!fakeScrollbar) {
    fakeScrollbar = createFakeScrollbar()
    element.insertBefore(fakeScrollbar, element.firstChild)
  }

  fakeScrollbar.style.display = 'block'
  fakeScrollbar.innerHTML = `<div style='height: ${scrollHeight}px'></div>`
  fakeScrollbar.scrollTop = scrollTop
}

export function restoreBodyScroll(node) {
  const element = node || document.getElementsByTagName('body')[0]

  element.style.paddingRight = 0
  element.style.overflowY = !node ? '' : 'scroll'

  const fakeScrollbar = element.getElementsByClassName(
    'xch-mootools-portal-fake-scrollbar'
  )[0]

  if (fakeScrollbar) {
    fakeScrollbar.style.display = 'none'
  }
}
