
import color from 'css-color-function'

export default {

  shade(c, p) {
    return color.convert(`color(${c} shade(${p}%))`);
  },

  tint(c, p) {
    return color.convert(`color(${c} tint(${p}%))`);
  },

  opacity(c, p) {
    return color.convert(`color(${c} a(${p}%))`);
  },

  appWidth() {
    return `
      max-width: 1000px;
      margin: 0 auto;
    `
  },

  absolut() {
    return `
      position: absolute;
      z-index: 1;
      top:0;
      bottom:0;
      right:0;
      left:0;
    `
  },

  absolutBox(unit) {
    return `
      position: absolute;
      width: ${unit}px;
      height: ${unit}px;
      top: 50%;
      left: 50%;
      margin-top: -${unit / 2}px;
      margin-left: -${unit / 2}px;
    `
  },

  absoluticon(unit) {
    return `
      position: absolute;
      z-index: 1;
      display: block;
      width: ${unit}px;
      height: ${unit}px;
      top: 50%;
      left: 50%;
      margin-top: -${unit / 2}px;
      margin-left: -${unit / 2}px;

      svg {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: ${unit}px;
        height: ${unit}px;
      }
    `
  },

  clearfixfunction() {
    return `
      zoom 1;
      &:before, &:after {
          content: "";
          display: table;
      }
      &:after {
          clear: both;
      }
    `
  },

  ellipsis() {
    return `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `
  },

  icon(width = '20px', height = '20px', display = 'block') {
    return `
      position: relative;
      z-index: 1;
      display: ${display};
      width: ${width};
      height: ${height};

      svg {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: ${width};
        height: ${height};
      }
    `
  },

  mediaObj() {
    return `
      overflow: hidden;
      _overflow: visible;
      zoom: 1;
    `
  },

  minContainer() {
    return `
      min-width: 300px;
      padding-left: 15px;
      padding-right: 15px;
    `
  },

  noselect() {
    return `
      user-select: none;
    `
  },

  noZ() {
    return `
      transform: translateZ(0px);
    `
  },

  scrollbox() {
    return `
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    `
  },

  wrapdat() {
    return `
      white-space: pre;
      white-space: pre-wrap;
      white-space: pre-line;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -hp-pre-wrap;
      word-wrap: break-word;
    `
  },

}


