import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border: 2px solid rgba(86, 151, 249, 0.36);
  border-radius: 2px;
  font-size: 1rem;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: border-color 0.25s ease;

  ${(props) => !props.disabled && `:hover { border-color: #5697f9; }`}
  ${(props) => props.isFocused && `border-color: #5697f9;`}
`

export const Value = styled.span`
  font-weight: 600;
`

export const Placeholder = styled.span`
  color: grey;
  user-select: none;
`
