import React, { Suspense } from 'react'
import { unstable_createResource } from 'react-cache'
import InViewMonitor from 'react-inview-monitor'

import gw_data from 'format_gw_data.js'

import {
  Loader,
  FullImage,
  Box,
  Label,
  LabelName,
  Stars,
  Star,
} from './style.js'

const ImageResource = unstable_createResource(
  (source) =>
    new Promise((resolve) => {
      const img = new Image()
      img.src = source
      img.onload = resolve
    })
)

const Img = ({ src, alt, ...props }) => {
  ImageResource.read(src)
  return <img src={src} alt={alt} {...props} />
}

export default ({ Id, nr, onUpdate, byId }) => {
  const entry = byId[Id]

  return (
    <Suspense
      fallback={
        <FullImage>
          <Loader>{'Loading...'}</Loader>
        </FullImage>
      }>
      <FullImage>
        <Img src={entry.Url} alt={`img_large_${nr}`} />
        <Box>
          <Label>
            <LabelName>{entry.Name}</LabelName>
            <div>
              {entry.Country}
              {entry.State.length > 0 && <span>, {entry.State}</span>}
            </div>
            <div>{entry.StoreName}</div>
          </Label>

          <Stars>
            {[1, 2, 3, 4, 5].map((n) => (
              <Star
                key={n}
                id="star"
                active={entry._stars >= n}
                onClick={() => onUpdate(entry.Id, n)}
              />
            ))}
          </Stars>
        </Box>
      </FullImage>
    </Suspense>
  )
}
