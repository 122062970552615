
var scrollDiv = document.createElement("div");
scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;');
document.body.appendChild(scrollDiv);

// Get the scrollbar width
var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

// Delete the DIV
document.body.removeChild(scrollDiv);

export default function() {
  return scrollbarWidth;
}
