import { indexOf } from 'lodash'

export default function(evt, node) {
  const { target, path } = evt

  if (target === node) return true

  const targetIndex = indexOf(path, target)
  const nodeIndex = indexOf(path, node)

  if (targetIndex === -1 || nodeIndex === -1) return false

  if (targetIndex <= nodeIndex) return true

  return false
}
