import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import mixins from 'css/mixins'
import Icon from 'Icon'

export const loaderAnimation = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`

export const logoSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
export const ImageContainer = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  width: 150px;
  height: 150px;
  display: block;
  overflow: hidden;
  position: relative;
  img {
    width: 230px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .blurry {
    filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`

export const Loader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: ${loaderAnimation} 1.8s infinite ease-in-out;
  &:before,
  &:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: ${loaderAnimation} 1.8s infinite ease-in-out;
  }
  color: #dfcca9;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }
  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 3.5em;
  }
`

export const ThumbnailLink = styled(Link)`
  display: block;
`

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

export const FullImage = styled.div`
  padding-top: 20px;
  height: 80%;

  img {
    height: 100%;
  }
`

export const CloseLink = styled(Link)`
  cursor: pointer;
  color: white;
  padding: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
`

export const Links = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PrevLink = styled(Link)`
  cursor: pointer;
  color: white;
  padding: 20px;
`

export const NextLink = styled(Link)`
  cursor: pointer;
  color: white;
  padding: 20px;
`

export const Stars = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0px;
  padding-top: 20px;
  padding-bottom: 12px;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 100%);
  pointer-events: none;
`

export const Star = styled(Icon)`
  ${mixins.noselect()};
  ${mixins.icon('18px', '18px')};
  margin-left: 2px;
  margin-right: 2px;
  path {
    fill: #ffd554;
  }
`
