import React, { Component } from 'react'

import { Route } from 'react-router-dom'
import Portal from 'Portal'

import gw_data from 'format_gw_data.js'

import Item from './Item'

import { AppContent, Grid } from './style.js'

export default class App extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { list, onUpdate, byId } = this.props

    return (
      <React.Fragment>
        {list.map((Id, i) => {
          const entry = byId[Id]
          return (
            <Route
              key={Id}
              path={`/${entry.Id}`}
              render={(props) => (
                <Portal
                  fullscreen
                  isOpen
                  background="rgba(53,53,53,0.8)"
                  Children={({ onToggle, onClose }) => (
                    <Item
                      {...props}
                      Id={Id}
                      nr={i}
                      onUpdate={onUpdate}
                      byId={byId}
                    />
                  )}
                />
              )}
            />
          )
        })}
      </React.Fragment>
    )
  }
}
