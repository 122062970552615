import styled from 'styled-components'
import mixins from 'css/mixins'
import Icon from 'Icon'

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SelectBox = styled.div`
  width: 200px;
`

export const Stars = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`

export const Star = styled(Icon)`
  ${mixins.noselect()};
  ${mixins.icon('24px', '24px')};
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
  path {
    fill: #acacac;
  }

  &:hover {
    path {
      fill: #343434;
    }
  }

  ${(props) =>
    props.active &&
    `

    path {
      fill: #FFCF3E;
    }

    &:hover {
      path {
        fill: #98791B;
      }
    }
  `}
`
