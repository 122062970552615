import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  min-width: 140px;
  max-height: ${(props) => props.maxHeight}px;
  margin: 0;
  padding: 0;

  background-color: #fff;
  overflow-y: auto;

  :focus {
    outline: none;
  }
`

export const Option = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 7px 10px;
  border: 0;
  background-color: ${(props) => {
    if (props.isFocused) return `rgba(86, 151, 249, 0.36)`
    else if (props.isSelected) return `rgba(86, 151, 249, 0.1)`
  }};
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
`

export const ResultFoundContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0;
`

export const Hr = styled.hr`
  margin: 6px;
  border-top: 1px solid #e5e5e5;
`

export const ResultFoundText = styled.p`
  padding: 5px 10px;
  color: #c4c4c4;
  text-align: left;
`

export const ShowMoreText = styled.button`
  margin-bottom: 6px;
  border: 0;
  padding: 0;
  margin: 0 10px 5px;
  font-size: 1rem;
  color: #5697f9;
  cursor: pointer;
  opacity: 0.36;
  transition: 0.2s opacity ease;

  background: none;

  :hover,
  :focus {
    opacity: 1;
  }

  :focus {
    outline: auto;
  }
`
