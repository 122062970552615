export const valueEventAriaMessage = (event, context) => {
  const { value } = context

  if (!value) return

  switch (event) {
    case 'deselect-option':
      return `option ${value}, deselected.`
    case 'select-option':
      return `option ${value}, selected.`
  }
}

export const optionFocusAriaMessage = ({
  shouldShowAllOptions,
  focusedOption,
  titleKey,
  options,
}) =>
  `${shouldShowAllOptions ? `All options show, ` : ''}option ${
    focusedOption[titleKey]
  } focused, ${focusedOption.focusedId + 1} of ${options.length}.`

export const resultsAriaMessage = ({ searchValue, screenReaderMessage }) =>
  `${screenReaderMessage}${
    searchValue ? ' for search term ' + searchValue : ''
  }.`
