import styled from 'styled-components'
import mixins from 'css/mixins'

import Icon from 'Icon'

export const Input = styled.input`
  box-sizing: border-box !important;
  width: 100%;
  padding: 0.5rem;
  border: 0;
  border-bottom: 1px solid #5697f9;
  font-size: 1rem;

  :focus {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`

export const ClearIcon = styled(Icon)`
  ${mixins.icon('14px', '14px')};
  path {
    fill: #c2c2c2;
  }
`

export const ClearButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 0;
  transform: translateY(100%);
  border: 0;
  background: none;

  &:hover {
    ${ClearIcon} {
      path {
        fill: #8a8a8a;
      }
    }
  }
`
