import React, { useContext } from 'react'
import ReactDOM from 'react-dom'

import { scrollContext } from '_contexts'
import { getPopupCoords, openPortal, closePortal } from './portal-utils.js'

class Portal extends React.Component {
  constructor(props) {
    super(props)

    this.buttonRef = null

    this.state = {
      coordsSet: false,
    }

    this.node = document.createElement('div')

    this.node.style.cssText = `
      position: relative;
      z-index: 1;
    `

    if (props.fullscreen) {
      this.node.style.cssText = `
        position: fixed;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: scroll;
        background: ${props.background || 'rgba(255,255,255,0.9)'};
      `
    }

    this.element = props.element || document.body
  }

  setCoords = () => {
    const { popup, buttonRef, position } = this.props

    const { coordsSet } = this.state

    if (popup && this.buttonRef === null) {
      this.buttonRef = buttonRef

      const coords = getPopupCoords(buttonRef)

      const _pArr = position || 'top left'
      const positions = _pArr.split(' ')
      const top = positions[0]
      const left = positions[1]

      this.node.style.cssText = `
        position: absolute;
        z-index: 1;
        top: ${coords[top === 'center' ? 'yCenter' : top]}px;
        left: ${coords[left === 'center' ? 'xCenter' : left]}px;
      `
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   console.log(props)
  // }

  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   console.log(prevProps)
  // }

  componentWillMount() {
    this.element.appendChild(this.node)
    // add portalId to manager
    if (this.props.fullscreen) this.portalId = openPortal()
  }
  componentWillUnmount() {
    // remove portal id from manager
    this.element.removeChild(this.node)
    if (this.props.fullscreen) {
      closePortal(this.portalId)
    }
  }

  render() {
    const {
      children,
      fullscreen,
      Children,
      onToggle,
      onClose,
      onOpen,
      popup,
      align,
    } = this.props

    const portalFns = {
      onToggle,
      onClose,
      onOpen,
    }

    this.setCoords()

    const ChildComponent = Children ? <Children {...portalFns} /> : children

    let PortalComponent = ChildComponent

    if (fullscreen) {
      PortalComponent = (
        <scrollContext.Provider value={{ scrollNode: this.node }}>
          {ChildComponent}
        </scrollContext.Provider>
      )
    }

    if (popup && align) {
      const alignArr = align.split(' ')
      const y = alignArr[0]
      const x = alignArr[1]

      const alignment = {
        top: y === 'top' || y === 'center' ? 0 : undefined,
        bottom: y === 'bottom' ? 0 : undefined,
        left: x === 'left' || x === 'center' ? 0 : undefined,
        right: x === 'right' ? 0 : undefined,
        transform: `
          ${x === 'center' ? 'translateX(-50%)' : ''}
          ${y === 'center' ? 'translateY(-50%)' : ''}
        `,
      }

      PortalComponent = (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            ...alignment,
          }}>
          {ChildComponent}
        </div>
      )
    }

    return ReactDOM.createPortal(PortalComponent, this.node)
  }
}

export default class Door extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.isOpen,
      buttonRef: null,
    }

    this.buttonRef = null
  }

  setButtonRef = (element) => {
    this.buttonRef = element

    this.setState((state, props) => ({
      buttonRef: element,
    }))
  }

  handleToggle = () => {
    this.setState((state, props) => ({
      isOpen: !state.isOpen,
    }))
  }

  handleOpen = () => {
    this.setState((state, props) => ({
      isOpen: true,
    }))
  }

  handleClose = () => {
    this.setState((state, props) => ({
      isOpen: false,
    }))
  }

  render() {
    const { Button } = this.props
    const { isOpen } = this.state

    const portalFns = {
      onToggle: this.handleToggle,
      onClose: this.handleClose,
      onOpen: this.handleOpen,
    }

    const _button = !Button ? null : (
      <div ref={this.setButtonRef}>
        <Button {...portalFns} />
      </div>
    )

    const _portal = !isOpen ? null : (
      <Portal {...this.props} {...portalFns} buttonRef={this.state.buttonRef} />
    )

    return (
      <React.Fragment>
        {_button}
        {_portal}
      </React.Fragment>
    )
  }
}
