import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Container as DefaultContainer,
  Option as DefaultOption,
  ResultFoundContainer,
  Hr,
  ResultFoundText,
  ShowMoreText,
} from './style'

class List extends Component {
  renderOptions = (options) => {
    const {
      Option,
      titleKey,
      focusedOption,
      selectedOption,
      focusedOptionRef,
      onSelectOption,
      onHoverOption,
    } = this.props

    return options.map((option) => {
      const isFocused = option.focusedId === focusedOption.focusedId
      const isSelected = option.Id === selectedOption.Id
      const optionProps = {
        option,
        titleKey,
        key: `${option[titleKey]}_${option.Id}`,
        onClick: () => onSelectOption(option),
        isFocused,
        isSelected,
        ref: isFocused ? focusedOptionRef : undefined,
        onMouseEnter: () => onHoverOption(option),
      }

      if (Option) {
        return <Option {...optionProps} />
      }

      return <DefaultOption {...optionProps}>{option[titleKey]}</DefaultOption>
    })
  }

  render() {
    const {
      listRef,
      ListContainer,
      options,
      originalOptions,
      maxHeight,
      isSearching,
      showAllOptionsRef,
      isShowAllOptionsOpen,
      handleShowAllOptionsFocused,
      showAllOptions,
      className,
    } = this.props

    const ContainerComponent = ListContainer || DefaultContainer

    return (
      <ContainerComponent
        ref={listRef}
        maxHeight={maxHeight}
        className={className}>
        {this.renderOptions(options)}

        {isSearching && (
          <ResultFoundContainer>
            {options.length !== 0 && <Hr />}

            <ResultFoundText>{`${options.length} results found of ${
              originalOptions.length
            } options`}</ResultFoundText>

            {!isShowAllOptionsOpen && (
              <ShowMoreText
                ref={showAllOptionsRef}
                onFocus={() => handleShowAllOptionsFocused(true)}
                onBlur={() => handleShowAllOptionsFocused(false)}
                onClick={showAllOptions}
                tabIndex="3">
                show more options
              </ShowMoreText>
            )}

            {isShowAllOptionsOpen && this.renderOptions(originalOptions)}
          </ResultFoundContainer>
        )}
      </ContainerComponent>
    )
  }
}

List.propTypes = {
  listRef: PropTypes.func.isRequired,
  selectedOption: PropTypes.object,
  focusedOption: PropTypes.object,
  focusedOptionRef: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  originalOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectOption: PropTypes.func.isRequired,
  onHoverOption: PropTypes.func.isRequired,
  maxHeight: PropTypes.number.isRequired,
  isSearching: PropTypes.bool.isRequired,
  showAllOptionsRef: PropTypes.func.isRequired,
  isShowAllOptionsOpen: PropTypes.bool.isRequired,
  handleShowAllOptionsFocused: PropTypes.func.isRequired,
  showAllOptions: PropTypes.func.isRequired,
  className: PropTypes.string,
}

List.defaultProps = {
  selectedOption: {},
  focusedOption: {},
  className: '',
}

export default List
