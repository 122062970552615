import React from 'react'

import { Root } from './style.js'

const options = {}

export default require('create-react-class')({
  getInitialState() {
    const { elem } = this.props
    console.log(elem)

    // crossbrowser version
    var box = elem.getBoundingClientRect()

    var body = document.body
    var docEl = document.documentElement

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

    var clientTop = docEl.clientTop || body.clientTop || 0
    var clientLeft = docEl.clientLeft || body.clientLeft || 0

    var top = box.top + scrollTop - clientTop
    var left = box.left + scrollLeft - clientLeft
    var bottom = box.bottom + scrollTop - clientTop
    var right = box.right + scrollLeft - clientLeft

    return {
      top: Math.round(top),
      left: Math.round(left),
      bottom: Math.round(bottom),
      right: Math.round(right),
      xCenter: Math.round(left) + elem.offsetWidth / 2,
      yCenter: Math.round(top) + elem.offsetHeight / 2,
    }
  },

  render() {
    const { onMouseEnter, onMouseLeave, popupRef, children } = this.props
    const { bottom, left } = this.state

    return (
      <Root
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={popupRef}
        top={bottom}
        left={left}>
        {children}
      </Root>
    )
  },
})
