import gw from './gw.js'
import { uniqueId } from 'lodash'
import { getEntries } from 'localEntries'

const initialData = {
  // todo: randomise order on session?
  Ids: [],
  byId: {},
  byCountryCode: {},
  _prevId: undefined,
}

const starObj = getEntries()

export default gw.reduce((__obj, r, i) => {
  const { firstName, miniature, store } = r

  const { name, country, countryCode, state } = store

  // https://everchosen-images.warhammer-community.com/2aa75d4c-7668-4114-bed7-5fd6d4e2cd42.jpg",
  const urlArr = miniature.split('/')
  const imgArr = urlArr[urlArr.length - 1].split('.')
  let Id = imgArr[0]

  if (__obj.byId[Id]) {
    Id = `${Id}-${uniqueId()}`
  }

  const Ids = [...__obj.Ids, Id]

  const Record = {
    Id,
    OrderId: i, // i guess?
    Url: miniature,
    Name: firstName,
    StoreName: name,
    Country: country,
    CountryCode: countryCode,
    State: state,
    _prevId: __obj._prevId,
    _stars: starObj[Id] || undefined,
  }

  const prevRecord = __obj.byId[__obj._prevId]

  const byId = {
    ...__obj.byId,
    [__obj._prevId]: {
      ...prevRecord,
      _nextId: Id,
    },
    [Id]: Record,
  }

  const countryArr = __obj.byCountryCode[countryCode] || []

  const byCountryCode = {
    ...__obj.byCountryCode,
    [countryCode]: [...countryArr, Id],
  }

  return {
    Ids,
    byId,
    byCountryCode,
    _prevId: Id,
  }
}, initialData)
