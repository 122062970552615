import React, { Component, Suspense } from 'react'
import { unstable_createResource } from 'react-cache'

import gw_data from 'format_gw_data.js'

import { ImageContainer, Loader, ThumbnailLink, Stars, Star } from './style.js'

const ImageResource = unstable_createResource(
  (source) =>
    new Promise((resolve) => {
      const img = new Image()
      img.src = source
      img.onload = resolve
    })
)

const Img = ({ src, alt, ...props }) => {
  ImageResource.read(src)
  return <img src={src} alt={alt} {...props} />
}

export default ({ Id, nr, render, byId }) => {
  const entry = byId[Id]
  return (
    <Suspense
      fallback={
        <ImageContainer>
          {
            // This gets shown while the full res image is preloading
            // <img className="blurry" src={image.miniature} alt={`img_small_${nr}`}/>
            // This gets shown below while the low res image is preloading
          }
          <Loader>{'Loading...'}</Loader>
        </ImageContainer>
      }>
      <ImageContainer>
        <ThumbnailLink to={`/${entry.Id}`}>
          <Img src={entry.Url} alt={`img_large_${nr}`} />
        </ThumbnailLink>
        {typeof entry._stars !== 'undefined' && (
          <Stars>
            {[1, 2, 3, 4, 5].map(
              (n) => entry._stars >= n && <Star key={n} id="star" />
            )}
          </Stars>
        )}
      </ImageContainer>
    </Suspense>
  )
}
