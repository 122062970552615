export function getEntries() {
  let localString = window.localStorage.getItem('neverchosen_entries')

  console.log(localString)
  if (localString === null) {
    localString = '{}'
  }
  return JSON.parse(localString)
}

export function updateEntries(Id, n) {
  const existingEntries = getEntries()
  const updatedEntries = JSON.stringify({
    ...existingEntries,
    [Id]: n,
  })
  const votedEntries = window.localStorage.setItem(
    'neverchosen_entries',
    updatedEntries
  )
}
