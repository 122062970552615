export default [
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8869aafc-7112-46b3-9c91-e5e758c84729.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Sutton Coldfield",
      "countryCode":"GB"
    },
    "firstName":"Adam",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTdiOGI2YjgtZTE0NS00NmFlLWEyOWItM2IyZmI0N2JhZDFiIiwidm90ZXJJZCI6IjNmOTY2NzRjLTUxMzEtNDY4Yi1iNGYzLTBiYWIzZmU1NzkwNCIsImlhdCI6MTU2NTk1NTM4OSwiZXhwIjoxNTY1OTU1Njg5fQ._UJQ7d6m-dF1mzDjVIio3n1j5tACryf1Vx_eITLieUc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8ab39b32-620a-4f24-89eb-775869a15e62.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Paris Rue Lecourbe",
      "countryCode":"FR"
    },
    "firstName":"Maxime",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDg5N2Y1MTctZTlhZS00NmI2LTljNTYtMTU4YWI4ZGI5ZDQzIiwidm90ZXJJZCI6IjNjOTkyNGJiLTRkMzYtNDRkMC05ZDIzLWRlM2Q3OTkzMDE3MCIsImlhdCI6MTU2NTk1NTM5MywiZXhwIjoxNTY1OTU1NjkzfQ.Ra6HI_Gdhpd1NIHPpYRgNIdQHBMnuUm85Sa0TJJMZ7Q"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a1a81728-e99f-4eb8-9b1b-1af8bdbb1e50.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"OR",
      "name":"Warhammer - Hillsboro",
      "countryCode":"US"
    },
    "firstName":"Alyssa",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDZhOThhY2EtNWVlZC00NjQ2LTkzNWEtMTljYzA4MDAxNjMzIiwidm90ZXJJZCI6ImIxNDBkNTRhLWNjOWMtNDRlYS05YmI0LTM4N2YwOThlZjBiNyIsImlhdCI6MTU2NTk1NTM5NiwiZXhwIjoxNTY1OTU1Njk2fQ.HaT05luV8yLUN32-Ve6kcFjR1cW-3Wt5rkRUzmWk4Js"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6920603e-b420-4126-825d-a925fddea1b2.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NY",
      "name":"COOPER'S CAVE GAMES & PAINTBALL",
      "countryCode":"US"
    },
    "firstName":"Matthew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGM5NGMzOGQtZmUzMi00ZGJiLWI4MjItYTkwZDVlMzc5MTI4Iiwidm90ZXJJZCI6ImU2NGE2MmQ0LTVjMmMtNDk3Ni05YjQ5LWRiY2IxNmI2MGU5YyIsImlhdCI6MTU2NTk1NTQwMCwiZXhwIjoxNTY1OTU1NzAwfQ.OjVFpfl7nnG0-jt7X-AFPznMvB4YhDI2eH63gs9jmrQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/00438ae1-648a-4664-a5d6-851a4d2ef823.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Bournemouth",
      "countryCode":"GB"
    },
    "firstName":"andy",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGNhYjljMTctM2EzNC00OTEzLWEwZTYtMWE5MTQ0MWM4ZmQ0Iiwidm90ZXJJZCI6IjQ3OGFlZjVkLTM5ZTMtNDI2MC05OTZiLWRhM2VjOGQ4MDIwMiIsImlhdCI6MTU2NTk1NTQwMywiZXhwIjoxNTY1OTU1NzAzfQ.opHjBdNaHggrZsE4vfgbkA8v-k00YZxnlU1nHMp7NoY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/41633f1c-852a-4004-875f-1330e40cba37.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"COMIC QUEST",
      "countryCode":"US"
    },
    "firstName":"Brant",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDgxNmNjYjktZjM3Mi00N2ExLWE4MjQtNjJkYzZmMTY4MzM2Iiwidm90ZXJJZCI6ImMwNDBjYjBmLTVkNzAtNGJkNC1hMjQzLThlNTg4MzIxYjE2MiIsImlhdCI6MTU2NTk1NTQwNywiZXhwIjoxNTY1OTU1NzA3fQ.gKf_y2dVPZCNzEtDbzpOGfnipc1SjEmTQ5iSbiFI-Vc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0e5e1d2a-8993-4edc-b458-22277e38ba6d.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Cardiff",
      "countryCode":"GB"
    },
    "firstName":"Catherine",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDY4MjU4OTMtYmQxOC00YTFiLTkwMjItMTRlMzhhYjQ4YTBmIiwidm90ZXJJZCI6ImY5OTcwYTY3LTg3MmItNGRmYy1hYjY1LTY4NWFiZjI4YmEwZSIsImlhdCI6MTU2NTk1NTQxMSwiZXhwIjoxNTY1OTU1NzExfQ.uj1MyA9sWJ1hF_32hbtKCs8RjtqhwT1ldxunn2WDzGE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0eb75458-52c3-44d9-87f2-406bb04728ec.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"LA",
      "name":"Games Workshop - Riverbend",
      "countryCode":"US"
    },
    "firstName":"Katherine",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmVhYzk3MTItMWE2MS00MDA2LTllNDYtYWQ4NGNkZTVmNjE0Iiwidm90ZXJJZCI6IjcwYjM2YzM5LTU0NjQtNDRjMi05OGQ2LTg3MGQwZmJhMjU5MiIsImlhdCI6MTU2NTk1NTQxNCwiZXhwIjoxNTY1OTU1NzE0fQ.lCI-O0PyGFom4NiheIoO9N872JYurcbQOT19j5ypyi4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d26b2781-8fb7-45e9-bd53-4942790b8c86.jpg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Ars Manufactorea",
      "countryCode":"IT"
    },
    "firstName":"Giulia",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzdhMWMwYTAtY2Q0MS00M2NjLTg5MTYtYzhiMzA5ZjgxZDdjIiwidm90ZXJJZCI6IjA1YjcxNDkzLTUzNDMtNDJlZi05ZDdmLTU2OTc0MDQzZTc2MCIsImlhdCI6MTU2NTk1NTQxNywiZXhwIjoxNTY1OTU1NzE3fQ.GRrSUkCkawmO0HGDO2SArzODoYbMj0ypMyWUMEPhibg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/06eb07b2-b1ab-43e1-87db-636ef83ecb1e.jpg",
    "rotation":0,
    "store":{
      "country":"Belgium",
      "state":"",
      "name":"VERBRUGGHE NEVERLAND BRUGGE",
      "countryCode":"BE"
    },
    "firstName":"Christopher",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzFiMzFhYjktNDE2MC00OGNhLTg0YjctZDMzMTI1NjI1MzcxIiwidm90ZXJJZCI6ImFkMDUzZmYwLTZkZmMtNGE4NC05OTdlLThlZmQxYzkzMWYyYiIsImlhdCI6MTU2NTk1NTQyMSwiZXhwIjoxNTY1OTU1NzIxfQ.IXccsgMQBHaHAP-P7f6pvkkgRXeibYnGmRsuA7MXY3E"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/136d3472-c510-4007-a90e-c0deb675f115.jpg",
    "rotation":0,
    "store":{
      "country":"Serbia",
      "state":"",
      "name":"Zmaj",
      "countryCode":"RS"
    },
    "firstName":"Nikola",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDQzYTNjMDgtZmQyYy00ZjdmLTk2NDItNjdkMWM4NDgwMzUzIiwidm90ZXJJZCI6IjJmY2Y0YTg0LTMxYzQtNGM4NC04MzUxLWQwYThmZTNlM2NkMyIsImlhdCI6MTU2NTk1NTQyNCwiZXhwIjoxNTY1OTU1NzI0fQ.lLmS9aeNIQ6mvkIslxLiWYPlggll40n-j1r_omVjwDo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4bbce870-f92a-44c1-a301-9b5f1333a73f.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"DE",
      "name":"Warhammer - Wilmington",
      "countryCode":"US"
    },
    "firstName":"Ethan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2JjYTZhZGYtMWZkNC00ZTgxLWIyY2QtOTM3NzE1ZmY0NjdjIiwidm90ZXJJZCI6ImRjNDk4ZmRhLWMyNjUtNGE1ZS1iZWIzLTFkOTQxNTRjMzFmYiIsImlhdCI6MTU2NTk1NTQyOCwiZXhwIjoxNTY1OTU1NzI4fQ.d1r_VUMo88lnOHUjQ9xLzAvtTsPtVe4QjSFvCRktED4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/85af060e-d609-40a1-b19c-c42aa85766c8.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Edinorog Kolomenskaya",
      "countryCode":"RU"
    },
    "firstName":"Nikita",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmE2OWFkMjktZGZlOS00YjQwLWE2YTMtMjhjMmYxOThiMDkyIiwidm90ZXJJZCI6ImFkNjIyMjE5LTJhYWUtNGY4My04NjIzLTdhZjYyYjY2ZjkwNCIsImlhdCI6MTU2NTk1NTQzMSwiZXhwIjoxNTY1OTU1NzMxfQ.VCORjcHOJX8o6gsjTa0utTcGQ0_DKy3RSslxW5ocP5I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bab45376-ea11-4ee1-9a5f-1005ce758a7d.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Hobby Games Moscow Myasnitskaya st",
      "countryCode":"RU"
    },
    "firstName":"Aleksey",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGEyOTVhYjktZGVhYy00NDkzLTg4NDMtZGMzNjJlYTgzNDg3Iiwidm90ZXJJZCI6IjJkMzUxOGViLTk1ODQtNGI3Yy05MDhmLTBiNjgxYTlmNjM0ZiIsImlhdCI6MTU2NTk1NTQzNSwiZXhwIjoxNTY1OTU1NzM1fQ.WyAW9OnnVlrX1aESIylwDIGV8KDl9rRhKlgPxCM4k7w"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ef20e623-ac03-4f97-bc32-f6c76641c998.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MD",
      "name":"TITAN GAMES AND HOBBIES",
      "countryCode":"US"
    },
    "firstName":"Drew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTU0NDFlOWQtYTVjMy00ZGNmLTkwZmQtZjE5ODEyM2VhZWM0Iiwidm90ZXJJZCI6IjdmY2IxNzdmLWI0NjQtNDZjYy05MGEwLTgxZWUyY2I1MjkxYSIsImlhdCI6MTU2NTk1NTQzOCwiZXhwIjoxNTY1OTU1NzM4fQ.D7Kuk6xNc3cbvBG7p-L5X7jtegmAkMILqHSUvAKAUtQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/26cc3db9-13e4-4a6c-815d-054d9f101447.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Warhammer - Toulouse",
      "countryCode":"FR"
    },
    "firstName":"Benjamin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2E5MTRlZjctYzZjMy00NjAxLTgwYTctMmUxZTYwZmM4NTk4Iiwidm90ZXJJZCI6IjIyYjllMGZlLTBmYzAtNDk0Mi1hMjRhLTA0ZTNmZDBkMjljOCIsImlhdCI6MTU2NTk1NTQ0MiwiZXhwIjoxNTY1OTU1NzQyfQ.ZRFwrpw-m9a_yW66Yz9MxILMjzTv3tE2NdeV-cgHGfk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6c971c07-7711-4959-b70d-94b67f98fcd4.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Atomic Hobby Shop",
      "countryCode":"US"
    },
    "firstName":"Christopher",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDk0MWZmYTAtNjRlMy00ZDQyLWIwM2ItYjM3NDJjYzFlM2M2Iiwidm90ZXJJZCI6ImVjZWMwMjZkLTQ5NjctNDA4ZC05YjJjLTRiNGU3NjIxNDU2ZCIsImlhdCI6MTU2NTk1NTQ0NSwiZXhwIjoxNTY1OTU1NzQ1fQ.hbB66UkUYn5q2E_IrPJA_uOWJHnrcLkZUklmmvpUY2Y"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/74cab4f6-2f66-4a9e-86c7-5dc73e5e08fb.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"GOBLINTRADER PALMA",
      "countryCode":"ES"
    },
    "firstName":"Bernardo",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2QyMTY3MTgtMDMzZi00YjZkLWI4N2QtZjhmYzFmMjRhMTVmIiwidm90ZXJJZCI6IjY5NWE4ZDM5LTJhMDYtNDRmMi04ZDMzLTE0MDE4YTA2ZDM3OCIsImlhdCI6MTU2NTk1NTQ0OSwiZXhwIjoxNTY1OTU1NzQ5fQ.S2menEKuvMlUCs8xny8Vt3HH1nB9zDl6tCbyxVhXQjw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/30b81c6b-6c90-48dc-959a-407acd92148a.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Nice",
      "countryCode":"FR"
    },
    "firstName":"Pyer",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWIzMWE2YTEtMGQ4OS00MDdiLWFlNmItZDg1ZTc4NTk2ZjkxIiwidm90ZXJJZCI6IjAxYjUzN2RmLTAzNTUtNDJjZS1hM2E1LWIwMjAyOGZkMTA5YSIsImlhdCI6MTU2NTk1NTQ1MiwiZXhwIjoxNTY1OTU1NzUyfQ.jvzlWx_c2GHKdTf_kZO94lMgKnaAwgKyo5FnQXycUjQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8020d41d-f8d1-46a6-884d-8d289523126e.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Dresden",
      "countryCode":"DE"
    },
    "firstName":"Hermann",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTViNGFlMWYtNDQ3NC00NzMzLTg0NGQtNDRjMTVjZTRjYzQxIiwidm90ZXJJZCI6IjlmNWMwZGQ0LTM2NDctNDMwMy05YTc4LTFiN2M4MzllZWRmNyIsImlhdCI6MTU2NTk1NTQ1NiwiZXhwIjoxNTY1OTU1NzU2fQ.WMDC0Vlm-NueuQQ_0lucdrCKt2ehpzmMI8yLinU-O8Q"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7f2dd7f0-da09-41e4-b152-c8570d7e077c.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NV",
      "name":"Warhammer - The Summit",
      "countryCode":"US"
    },
    "firstName":"William",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDk0YTZmNTctMTY0YS00MmM3LThlNjAtZjNmYWI1NmNmMDRjIiwidm90ZXJJZCI6IjYwZTg5MzBlLTEyMmQtNDZjMS1hOTI4LTA2Njg1YTJlZGRiMiIsImlhdCI6MTU2NTk1NTQ1OSwiZXhwIjoxNTY1OTU1NzU5fQ.-la5Cacu0VNTMU5Tlstf1r57sLykwNH8C_yDIRm844s"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ee1a2d18-a6f8-4235-85eb-7108f0058054.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"PA",
      "name":"Warhammer - Jenkintown",
      "countryCode":"US"
    },
    "firstName":"Shawn",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYThhZmI4ZTItMmE2MC00YTFmLTkzNjItNDEzMTc2MzIwNGJjIiwidm90ZXJJZCI6IjdiY2IzOTk2LTQ5OTktNDJkYi04Yjk2LWFlNGE2ZDQ0MThjMyIsImlhdCI6MTU2NTk1NTQ2MywiZXhwIjoxNTY1OTU1NzYzfQ.ot4c_DTiiOeEQV4AKUzBuM5jqQGuNmc1CkvfrhxeoAI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/80bee927-ca62-4b02-bbbd-7ecf40bbc760.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Chester",
      "countryCode":"GB"
    },
    "firstName":"Ross",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmUxMGI2MmEtNGY2Ny00MjAyLTliZTMtMWM0MDg2ZGY2ZDJlIiwidm90ZXJJZCI6IjcyNzgxNmRkLTA1ZDktNGRkMS05MzJlLWU1ZjUzOWZkZjRiMCIsImlhdCI6MTU2NTk1NTQ2NywiZXhwIjoxNTY1OTU1NzY3fQ.LFBrwrjfI9krVEkxLIpOAFP51lzHTt14jCLZ6neba48"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bc013d5c-4c7a-493f-938f-707f181eee5c.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"OH",
      "name":"Games Workshop - Eastgate Shopping Center",
      "countryCode":"US"
    },
    "firstName":"William",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGQ2ODgyZmMtM2NlNC00ZmIyLTkxZWEtMGI2OGI5YzlkN2ExIiwidm90ZXJJZCI6ImVjNWRjNjJhLTU5ZWQtNGFhMC04ZjNlLWJiZmYzOTNjZjUxOCIsImlhdCI6MTU2NTk1NTQ3MCwiZXhwIjoxNTY1OTU1NzcwfQ.jGZj00LWj32G30Ux3G2Dl_r6KP-5ckBLy4eDjsINnEQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2cb2854a-83c9-44c3-8a64-ef9257803c69.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NY",
      "name":"Warhammer - High Point",
      "countryCode":"US"
    },
    "firstName":"Erik",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODAxNjczYTctMDQwMi00NmFlLWJhYjMtMjA2OWMxYmYwY2RkIiwidm90ZXJJZCI6IjZlODc3MTE5LTEzY2ItNDA1My1iMTlmLWI4ZjQwZWY4MjUwMCIsImlhdCI6MTU2NTk1NTQ3NCwiZXhwIjoxNTY1OTU1Nzc0fQ.ZjDAeypwpVxZg-jUDbvcRvltGra3lCPppbgMyrtM-hY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/defdc7c8-2175-47b0-bf75-d08316720dd2.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Clermont-Ferrand",
      "countryCode":"FR"
    },
    "firstName":"Yvan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGIwOTI0YjAtOTAyNS00YWYyLTg3OTYtMGUyZjE0YWQ4MGZhIiwidm90ZXJJZCI6IjhjNTAwYjQ4LTg4MzktNGZlYS1iYThlLTM3Yjg5ODI5MTE1MiIsImlhdCI6MTU2NTk1NTQ4MCwiZXhwIjoxNTY1OTU1NzgwfQ.Y5sXkV-4xW4l_jOGoW2tR69rluui_ix-T_yvRZMExEk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9e797869-8675-41ea-a692-7f8d1130aaac.jpeg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Orl\u00e9ans",
      "countryCode":"FR"
    },
    "firstName":"Antoine",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWEwYWJiODItZjk2Yi00MGJmLThjYWYtMGZiODIwMjVkOWQ2Iiwidm90ZXJJZCI6ImEyN2NiZWI5LTZmNGItNDFhNS04ZDhhLTExNGRjYzY4NzhlNiIsImlhdCI6MTU2NTk1NTQ4NCwiZXhwIjoxNTY1OTU1Nzg0fQ.oE3MGdCyDtgLQ0PqL-c3rVvZGGZJyfWheJs2kIReygg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5fd75b08-8fd6-4761-99b6-0a5c84d3753d.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"UT",
      "name":"Warhammer - Layton",
      "countryCode":"US"
    },
    "firstName":"Darcy",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmY5MzViNjMtNGEwNi00ZGJmLTlmY2EtYzNkYjFiOTQwMTk0Iiwidm90ZXJJZCI6IjEzNGRiNTEwLTgzYzAtNDA1YS04NzRhLTVkMDU1MmE0OTRhZSIsImlhdCI6MTU2NTk1NTQ4NywiZXhwIjoxNTY1OTU1Nzg3fQ.DJvOgd9oOcrSIca4GW5seEDc5bHC8lqhmMVIozPS_zY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fe73ae4e-1201-471b-9697-cbb2c84e2136.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Winchester",
      "countryCode":"GB"
    },
    "firstName":"Anthony",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGVjODk3MmQtMjBlMS00ZDRkLWFjZTgtYTgwMjYwNWE0NTk2Iiwidm90ZXJJZCI6IjgyYjBkNTUxLWE4MzgtNGM2ZC1hN2MxLWRkZWYyMDgwNWNiNyIsImlhdCI6MTU2NTk1NTQ5MSwiZXhwIjoxNTY1OTU1NzkxfQ.OXg-XEVNMbTnPqEwwkUYTrRs9ac2D9k8pkkMEcgZ7DE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8b5b8e65-400e-4055-8cd9-86672da8f744.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MT",
      "name":"MUSE COMICS - P-3",
      "countryCode":"US"
    },
    "firstName":"Connor",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzNlYjhhMjMtZDZjNy00YzA1LWJmNTUtZTIyZDNiZGQ5OWY2Iiwidm90ZXJJZCI6ImQwNjlhY2ZjLWMyYmMtNDA4NC04MmI0LWNmZDk0NTlkNTBkMiIsImlhdCI6MTU2NTk1NTQ5NCwiZXhwIjoxNTY1OTU1Nzk0fQ.hPMON00ak4Sf4G2Ne5j0Xkc2ZR7mcQFiHcEsLYlyae0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cf38c7f5-465c-412e-b0a1-dca0519157c3.JPG",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"ON",
      "name":"Games Workshop - White Oaks Mall",
      "countryCode":"CA"
    },
    "firstName":"Josh",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjkyMGI0OGMtNWJhOC00NDJlLWI5ZDItZmI5YTQ1YmE1MTZhIiwidm90ZXJJZCI6IjM0NzZhNDJiLTY2ODAtNDBmZi1hODhlLWZhYjdmNDk3NDI5YSIsImlhdCI6MTU2NTk1NTUwMSwiZXhwIjoxNTY1OTU1ODAxfQ.pp_LkkT_XuL57zvwcPiKZ1uyfDiVP7EmZDuVMLUIGWk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bbcbe1c7-b8e0-4562-a879-52523ce7234d.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"",
      "name":"Tezza's Games Shop",
      "countryCode":"AU"
    },
    "firstName":"Mark",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjJmMTFkZWQtMTViMy00M2RiLWIxNmMtMWNjMDNkNzEwYzBjIiwidm90ZXJJZCI6IjU4YmUwODVkLTQ4MjMtNDhlMy1iMDM0LTFiMThlZTI3ZmE5MSIsImlhdCI6MTU2NTk1NTUwNSwiZXhwIjoxNTY1OTU1ODA1fQ.B2UW3OI1F4KftNplVXdDs804GDMXl35MT92a-FMFZ2M"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/23574a67-1707-4997-942c-fbd2ed4786cd.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NH",
      "name":"BLACKMOON GAMES",
      "countryCode":"US"
    },
    "firstName":"Joshua",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2UxYTA1MzYtMzQ2YS00ZjM0LWE5YjUtYTFlMWRjMzE0ODAxIiwidm90ZXJJZCI6ImY3MTI4YWE3LWNhNzQtNDhmMi05MDE5LWY0MGQ0YzU2ZGJiOSIsImlhdCI6MTU2NTk1NTUwOCwiZXhwIjoxNTY1OTU1ODA4fQ.mZGhzWSFDprXsVEGksKSnhNS1LuIEGOc6euTgt3Wz3M"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6545b929-71cb-49fc-a19e-ba4c7ce20dda.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NC",
      "name":"Games Workshop - Mallard Point",
      "countryCode":"US"
    },
    "firstName":"Dana",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGM5ZDk0MGMtMjIyZC00YTljLThhOGMtMGY4OTIyYjE2NDg2Iiwidm90ZXJJZCI6ImM1MTA2OTRiLWUwNzUtNGMzNS1hYjBjLTQ1ZjFlMDFkODU4YSIsImlhdCI6MTU2NTk1NTUxMiwiZXhwIjoxNTY1OTU1ODEyfQ._G-_tOLBUGjoEq2WlTmthJV52AuuONym65OxC6GhtaU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/134dd52c-d064-40f2-80f2-7c22eee2a588.jpg",
    "rotation":0,
    "store":{
      "country":"Malta",
      "state":"",
      "name":"ICON LTD",
      "countryCode":"MT"
    },
    "firstName":"Waylon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzE3YjczNTAtNzQxMC00ODdhLWE4MTktNGU4NTY3M2QxMzc5Iiwidm90ZXJJZCI6IjE5MjQzNDU0LTM1ODYtNDdmMS1iNDA2LTBhYTA5OGFkMTJjNSIsImlhdCI6MTU2NTk1NTUxNSwiZXhwIjoxNTY1OTU1ODE1fQ.FlRNmXcSJ2PLDMVdvoZ_15HJsRAmG5D62iZlL94dtXw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f3f96be2-d5ba-4be2-9716-2278998ed8f9.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Games Workshop - Milton Keynes",
      "countryCode":"GB"
    },
    "firstName":"Ricky",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTE0ODkxYTAtOGQzMi00ZGEyLWJmYWUtZGZmZmJmOWQwZDZmIiwidm90ZXJJZCI6IjNhM2ZmZDNhLWE2YmMtNGE5My1hOGFkLTEyZTU1MjZkYzlhZCIsImlhdCI6MTU2NTk1NTUxOSwiZXhwIjoxNTY1OTU1ODE5fQ.NNp_a5iLT5HjRM1CQAqzBBKaVXmJCdpQexa5FPCdicw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d35d836e-b2e1-4373-9213-9e14147bb3e3.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Games Workshop - Royal Oaks",
      "countryCode":"US"
    },
    "firstName":"Kythera",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTlhYTkyYmQtZTkwNS00MDJkLThjNzEtNmUyYWUyMGE2YTRiIiwidm90ZXJJZCI6ImQ1MWMwNjMyLTYwNWUtNDc2Ni04N2FiLTIwNzlhNzAwNzFlZiIsImlhdCI6MTU2NTk1NTUyMiwiZXhwIjoxNTY1OTU1ODIyfQ.k_Ift8q1SU1mi1flcZTJx6sfqIY4qRw_TNZq60GWNvI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/97157733-457b-425c-a65d-9eb31044cf37.jpg",
    "rotation":0,
    "store":{
      "country":"Finland",
      "state":"",
      "name":"FANTASIAPELIT HELSINKI",
      "countryCode":"FI"
    },
    "firstName":"Jussi",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDRmNmU4MTEtNWNkNS00MDc1LTg0NzItNTkyOTJmMmYxYmIxIiwidm90ZXJJZCI6IjIxODA3MWI5LWQzZTktNDU1Mi1iNjUwLTlkOTY0ODc2MmI0NSIsImlhdCI6MTU2NTk1NTUyNiwiZXhwIjoxNTY1OTU1ODI2fQ.BJvA9E1r3knd2A2yAGcHvyjFpsPLhvEpuFRd6v2wE8s"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a16e175c-ec14-4baa-966a-8e73513abfa2.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Warhammer - Marseille",
      "countryCode":"FR"
    },
    "firstName":"Mathieu",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGQzNGMwZDItMmUxNS00YjdkLWFiNDYtZjAwY2Q2OWZhNWU1Iiwidm90ZXJJZCI6IjYwOTY4ODQ1LWM3OTctNDU5My05ZjA4LWI2Y2Q2OWI4Y2Y1YSIsImlhdCI6MTU2NTk1NTUyOSwiZXhwIjoxNTY1OTU1ODI5fQ.lwaVcfKIVP2IItrWSbhu0REMhg9k9wh6IMUjvPIBRiM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ebb83d10-9822-47c9-9be3-7c23b9d1b6ea.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Carlisle",
      "countryCode":"GB"
    },
    "firstName":"Alexander",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWM0NWJmNDYtMWNiNC00NjlhLWE4N2ItYzQ0M2RiOGIxOTA1Iiwidm90ZXJJZCI6ImJmZTg3OTBmLTJkZGEtNGRiMy1iYjAyLTkyYmMyYzViMGQ0MCIsImlhdCI6MTU2NTk1NTUzNiwiZXhwIjoxNTY1OTU1ODM2fQ.TbigdR0gBtqRguCDQ12LCmaVQ96B4SGaODsqniU90qA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/018a372a-9d58-4566-9a81-818b20aa09d7.JPEG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Games Workshop - Denton Town Crossing",
      "countryCode":"US"
    },
    "firstName":"james",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzYzYmIxZmItYmEzNS00MGE1LWI5OTQtZDAxZDI2NWRhNWE1Iiwidm90ZXJJZCI6IjNhZGYwY2M0LTI5MGEtNDk3Ni1iY2JiLWYzODBjMTZjNzNkZiIsImlhdCI6MTU2NTk1NTU0MCwiZXhwIjoxNTY1OTU1ODQwfQ.KPuxyacXW_D6lKBWL8ejqq9qTGVnbxSGa97970rs77g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/16d8b616-ab0d-4c4d-8f08-f0c04b401d13.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"GA",
      "name":"Games Workshop - City Walk",
      "countryCode":"US"
    },
    "firstName":"Darryl",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmI0ZDQyNTItYmU1OC00YWMxLTkyYzQtMjU3ZDQxYTZlZGRlIiwidm90ZXJJZCI6IjE1ODc2YTVhLTViMWYtNGRhNC04MWNjLWQwOTA0ZWM5OWQ4YyIsImlhdCI6MTU2NTk1NTU0MywiZXhwIjoxNTY1OTU1ODQzfQ.iHCEbUPkhH3RoaPxTx4fO8bn_8a4zOyTDWSjYaUlxdE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/860fce22-422b-4605-a3ca-8736ea7e05f1.jpeg",
    "rotation":0,
    "store":{
      "country":"Philippines",
      "state":"",
      "name":"Neutral Grounds Glorietta",
      "countryCode":"PH"
    },
    "firstName":"Emil",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWI4NjczYjctMjdlNi00M2RjLWJiYWItNDFmOTEwMDQ5NmQ5Iiwidm90ZXJJZCI6IjM3NWQ3MzJhLWRmODAtNDMzYy1iYzM4LTkxMTU3NTc5MDI5ZSIsImlhdCI6MTU2NTk1NTU0NywiZXhwIjoxNTY1OTU1ODQ3fQ.NEHpvJDRovRiqyrfaeVl8-z8dW81CPRFSSGdV-rBGcM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2586fc8f-90aa-4929-95f3-11173bf12808.jpg",
    "rotation":0,
    "store":{
      "country":"Argentina",
      "state":"",
      "name":"Imaginario Games",
      "countryCode":"AR"
    },
    "firstName":"Mariano",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjFhZDMxMmYtYmRjMS00MzQ2LTgyYWUtZWNiMmQ0MzA4Njc1Iiwidm90ZXJJZCI6ImY0ODQ4YmU4LTIyNmUtNGU3NC04ZmRmLWQ0Y2UxNDNmM2I3MCIsImlhdCI6MTU2NTk1NTU1MCwiZXhwIjoxNTY1OTU1ODUwfQ.jLi4Jc1ek1xjzi8f7hvBMvwysAb-18c_25NINYVEpkE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4a61466b-a82a-4b71-9ba4-b6cb6fdc66da.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"OR",
      "name":"Games Workshop - Eastport Plaza",
      "countryCode":"US"
    },
    "firstName":"Brendan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGZhODYwMGMtOWRmYy00MmRmLWE2NWUtNGY1YWYzY2VjZjM3Iiwidm90ZXJJZCI6IjBmMDg4OTJjLWYzZGEtNDUzZi04MjhiLTExZjU0MTYxYzExMyIsImlhdCI6MTU2NTk1NTU1NCwiZXhwIjoxNTY1OTU1ODU0fQ.E-EVH-L-NFt9ZRhUnVxxaqoJN6fHG7U_qte-8j1Lh14"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/460db385-3d39-4a84-8af0-c3d758206f22.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Hemel Hempstead",
      "countryCode":"GB"
    },
    "firstName":"Ellis",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNWUxMjU2N2UtYjY2MS00N2EwLWIxMzAtY2FjYmQ0MDU2ZmJkIiwidm90ZXJJZCI6ImUxNTgyODhjLTgxZTEtNDdjOS05OGE2LTlmZjJmOGM4YzY4MCIsImlhdCI6MTU2NTk1NTU1NywiZXhwIjoxNTY1OTU1ODU3fQ.Tr_IJ9mq4d74S3Uhw6oFp7xHsJONKY5IS-Y7pG3TzHE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/418a51dc-b5e1-4692-a90b-09a38a2c55ab.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Hamburg Colonnaden",
      "countryCode":"DE"
    },
    "firstName":"Christopher",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzk0MjVhYTAtOTAyOS00ZDNhLTk0YjUtMjFiYjZkZDg4Mjg0Iiwidm90ZXJJZCI6Ijc3YTRlN2ZlLTViMDQtNDBhMi1iMDc2LTRkMjE0NmI3OGRhZCIsImlhdCI6MTU2NTk1NTU2MSwiZXhwIjoxNTY1OTU1ODYxfQ.jdHNlNfpVcAuYnFArZYOHUAgPWMqHdB66RkpDI1mwYA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4e03361e-97dd-42d2-aff7-d53e3f0bf1a2.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"",
      "name":"Out of the BOx",
      "countryCode":"CA"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzZlOTI0ODUtZTgyNS00YTk3LTk5Y2YtODA0NDRkZGEyYjY4Iiwidm90ZXJJZCI6ImYxZWUzOGIwLTczZTYtNGZiMC1iMzBhLThmMTdmOGU4YjkwNiIsImlhdCI6MTU2NTk1NTU2NCwiZXhwIjoxNTY1OTU1ODY0fQ.b-H9r9Ez-WVeAoW2mdygDy-RuMHVeW-pShBurpVr4uU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e1929970-1254-47a6-87d4-2c5a58b0d299.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Tours",
      "countryCode":"FR"
    },
    "firstName":"Nicolas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDRhYjQyY2QtMDQ1OC00NzE0LWJjNjUtYzM1YzJiMDY1MGM1Iiwidm90ZXJJZCI6IjZiN2Y1NjdmLTE1ODItNDM4ZS05NjE3LTU5ZWVjYjg5N2JiNiIsImlhdCI6MTU2NTk1NTU2OCwiZXhwIjoxNTY1OTU1ODY4fQ.upoSk8LgJD01ltLNs9v_N4zswfJq4CoQ77P7beIeeiw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/23ba213f-f8a1-4346-a58f-5f4a5d17f74c.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Nottingham (Friar Lane)",
      "countryCode":"GB"
    },
    "firstName":"Niall",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDZkOWNhYTQtNDNhNy00YWZhLTlhN2YtZGU4MDY4NWYwMmU3Iiwidm90ZXJJZCI6ImIyNzNjZDhlLTE2ZTctNDRjNy1hNDM0LTFiNzMzZjY4YjBmMCIsImlhdCI6MTU2NTk1NTU3MSwiZXhwIjoxNTY1OTU1ODcxfQ.xfQ0aSciHWks6aRyCOG5Zjdmk6YMZk_-Lj6s2gXPS_M"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9345fa31-35ed-40de-a08e-714b88d1e131.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"UT",
      "name":"BLAKFYRE GAMES",
      "countryCode":"US"
    },
    "firstName":"Taylor",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODg4M2NjMGUtNjUwMS00MmJkLWEwZTQtZTdiM2MzOGUwMDcyIiwidm90ZXJJZCI6IjQ1NzlhYzY3LTUzODgtNGQ2Ny1iMDcxLWYzMWE2YWRiZTE2NiIsImlhdCI6MTU2NTk1NTU3NSwiZXhwIjoxNTY1OTU1ODc1fQ.SbZ6ni9tqos8LoMWJVkNmLKfPulI70I4jK766X3tOdQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7daa122d-03df-4902-bb87-90c34d1afcf5.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Warhammer - Paris Rue Poirier",
      "countryCode":"FR"
    },
    "firstName":"Antoine",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDUyYzZlODMtMTQ4Ni00ZDdiLTkwMDQtZjZiNDdlYjE1ZDk0Iiwidm90ZXJJZCI6IjRlMzQyZjI0LTcwZDAtNGQzMi1iNWRiLWRkMTNhYjA1ZTllYSIsImlhdCI6MTU2NTk1NTU3OCwiZXhwIjoxNTY1OTU1ODc4fQ.UEaxUooYQ_cy38YsWhY5UdvOi8KqTqdjwS7Ex8hJpL8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bbdf873d-749c-4ab8-a4f2-85ede70361db.JPG",
    "rotation":0,
    "store":{
      "country":"China",
      "state":"",
      "name":"\u94f8\u9020\u4e16\u754c",
      "countryCode":"CN"
    },
    "firstName":"\u51ef",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjIxZDg5NTMtZjkxYy00YmJjLWJlMzAtZjQxMGJkNzY2NDYxIiwidm90ZXJJZCI6IjA0NWEzM2I0LWIyMmItNGI3Zi1hYjc4LTgwMTViOTZiYjg5ZCIsImlhdCI6MTU2NTk1NTU4MiwiZXhwIjoxNTY1OTU1ODgyfQ.BfV1yX0ypGhk8FVcfFntGKdBEUKdW0REqwRGbbfFt7M"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7d7f8db0-5643-46a6-ac82-409a949029cd.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"AZ",
      "name":"Warhammer - Goodyear",
      "countryCode":"US"
    },
    "firstName":"Ryan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGRmYjhlYzgtMDY2MC00YTIyLWI0N2UtYjUwMzQzZGM2YjU4Iiwidm90ZXJJZCI6Ijk3NzhjNDUzLTNjNDQtNDRjOS05YTYzLWJiMmY3MTkzYTlmYSIsImlhdCI6MTU2NTk1NTU4NSwiZXhwIjoxNTY1OTU1ODg1fQ.HzXaoNb_nC0gWMmPzUJnEIKs3pWEFsGGRg10Q8CJHU0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/94e4e714-f020-4418-903f-ace9a15f9208.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Doncaster",
      "countryCode":"GB"
    },
    "firstName":"Dean",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODY2Y2FkY2UtYzllNS00NzM0LTkwOTItZjFiYTQxN2E5ZjUxIiwidm90ZXJJZCI6ImUxODVlYmM5LTkzMjgtNGY1OS1iNzgxLTdkMjdjYWUyNTI1ZiIsImlhdCI6MTU2NTk1NTU5MiwiZXhwIjoxNTY1OTU1ODkyfQ.K6y_LD94KFfuFdEBE0l9Uf_n9Vu3WVIeBE4qKlPcW4o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/903de82f-9e2b-44cf-b37c-cbcf12393dbd.jpeg",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"",
      "name":"boiler",
      "countryCode":"JP"
    },
    "firstName":"Takanao",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWQxMDU0MzktMTIwZi00MDA5LTk1MGYtMzRjNzI3NDQ4ZTI3Iiwidm90ZXJJZCI6IjUyOGYzOTA3LTJjZWQtNDIwZi1iZDZjLWI3YmU3MDAwNmQ1NyIsImlhdCI6MTU2NTk1NTU5NiwiZXhwIjoxNTY1OTU1ODk2fQ.O-GcSovb3MsA3bM-cN6Jy8loaTKwzeuQhAxybaNtlGc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/07029c25-3033-4992-97ab-be3943cac617.jpeg",
    "rotation":0,
    "store":{
      "country":"Thailand",
      "state":"",
      "name":"Battlefield Bangkok",
      "countryCode":"TH"
    },
    "firstName":"Sahaphan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTNmMWIwOWQtOTYzOC00ZmUyLWFjMTctYjgxN2RkMjg0NTVhIiwidm90ZXJJZCI6ImFkODM4YTI2LWNlNGEtNDBiZS04ODU3LTU1Njg2ZWM2NzkzMyIsImlhdCI6MTU2NTk1NTU5OSwiZXhwIjoxNTY1OTU1ODk5fQ.svxaPDwc8ycuF7BWNn99QjXqeLvN781JvO8zZxyiB9Y"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f98f5d3d-0b85-4f7e-970c-3659ddc7984b.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"VIC",
      "name":"Warhammer - Ringwood",
      "countryCode":"AU"
    },
    "firstName":"Richard",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTVhMTgwOTctZDMwNi00MjcyLTkyMzgtNzVhNWNkYjYwZDEzIiwidm90ZXJJZCI6IjY4MDY0ODM5LWUzMDYtNDFhZi05NzBlLWJkOWRjNTJmNjRiMiIsImlhdCI6MTU2NTk1NTYwMywiZXhwIjoxNTY1OTU1OTAzfQ.tyRwIRekFj2I3Z0KwIIZ2hJBRHNO53LdGL21vYCiDzI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4a8f9624-3a46-4c8d-a209-dc25d5be5336.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MI",
      "name":"GAMERS SANCTUARY, INC",
      "countryCode":"US"
    },
    "firstName":"Miriah",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjYxZDUxNzQtMDFmMS00MzkwLWIzODItNDcwMmM2NTE2NmMxIiwidm90ZXJJZCI6ImJlNzg4ZTJmLTRlNTUtNDQyOC1iMzgzLTBhYjYzZmE5ZjExYSIsImlhdCI6MTU2NTk1NTYwNiwiZXhwIjoxNTY1OTU1OTA2fQ.osZLRXHjqwcHsj0gq9IGUZ3HaU_IhquX3z6MFxLKiZM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ec8d190d-558a-40e9-badf-7576a4043db7.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"AK",
      "name":"Tier 1 Cards & Games",
      "countryCode":"US"
    },
    "firstName":"Trevor ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmY2OTNmMjItOGUxOC00Mzg3LTg3MmItNjk4NDEwY2VlNDg3Iiwidm90ZXJJZCI6IjIzZDAyZDU2LTA4OTgtNGQzNy04Yzg1LWYwMWJlZTU4ODc2NSIsImlhdCI6MTU2NTk1NTYxMCwiZXhwIjoxNTY1OTU1OTEwfQ.4W9oVjYfn3BvmHIlomq-uiTTqG36TQFOkhTPd6_-qWI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/06c97e80-c5c4-4606-89fb-b80f2fa559b7.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"VA",
      "name":"Games Workshop - Hilltop East Shopping Ctr",
      "countryCode":"US"
    },
    "firstName":"Quinton",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjNkYmRmMTEtYzMzMC00OWEyLTk1YjYtODI5NDY3N2E4ZThhIiwidm90ZXJJZCI6IjViYTI5ZTJlLTcxMmYtNDMyYS1hNDFiLWIzZDU5NWExNjhlMCIsImlhdCI6MTU2NTk1NTYxMywiZXhwIjoxNTY1OTU1OTEzfQ.9V5kiv9s7LsH98kJ8t51l6QW7zvWFjHb5LLYlcgVn5o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/148e9291-a2bc-4aff-ae95-38e16a9f15bb.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"TAS",
      "name":"Games Workshop Hobart",
      "countryCode":"AU"
    },
    "firstName":"Michael",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWIwYTdjMTYtMDc0ZS00ZDI5LWE4YmEtNTljZjNjYjAzNmZkIiwidm90ZXJJZCI6ImU1MzVkMjc2LWE0OTgtNGRmMi04M2Q5LTg2MDlhYTg3ODhjOCIsImlhdCI6MTU2NTk1NTYxNiwiZXhwIjoxNTY1OTU1OTE2fQ.MradxKkUmTiaSVKC80O_twcrIuxnSF6P1zp4EX0G-GY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e4c07841-0fd0-4c2f-8b7f-ffc680ae028c.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"QLD",
      "name":"Warhammer - Mt Gravatt",
      "countryCode":"AU"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzU3YmYwNWItOTQwYy00OTE1LWFlMmQtYWFlZWU4MWFjZGJjIiwidm90ZXJJZCI6ImI1MDgwMTJiLTk0YWYtNDlmMy1iZTBiLWFjOGUyNzk3MWI4NiIsImlhdCI6MTU2NTk1NTYyNCwiZXhwIjoxNTY1OTU1OTI0fQ.WaokCypO7rGSAq5OjQiBjup7iFv0OmZ6JImCAjNaNAI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5097467c-c676-496e-9df9-e838adc20f52.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"ATOMIC COMICS & GAMES",
      "countryCode":"GB"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODQ3ZmQxZTQtZjZjNy00Mzg5LTg3NmYtNTRjYzgwMTA1ODYxIiwidm90ZXJJZCI6ImIzODRkMzg2LThhZjItNDBlOS04YjAyLWM2ZmU2YmY4YmNkNSIsImlhdCI6MTU2NTk1NTYyNywiZXhwIjoxNTY1OTU1OTI3fQ.7aqmwBY9AJQJN5UZ26eU8D1_q-kNbBhSMgrVjpZsdok"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/caf9fe83-e1cf-4f7a-89a2-242caa94d49b.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NY",
      "name":"Warhammer - Greenwich Village",
      "countryCode":"US"
    },
    "firstName":"Amanda",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjhjNzkxMGMtNTI3Ny00MmVkLTljMTUtZTcwODViYjMzOWRiIiwidm90ZXJJZCI6IjhlYWY4MTk3LTNkYzItNDVlZi1hNzU0LTEzYWEyZDBlZmM2ZSIsImlhdCI6MTU2NTk1NTYzMCwiZXhwIjoxNTY1OTU1OTMwfQ._LOskv9AcO5Tcmii53K98krD6_5oZW1rSeFO2g5vpt4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/47d19beb-a965-48a9-baff-4a55db96d66f.jpeg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"MiniaturesFan",
      "countryCode":"RU"
    },
    "firstName":"Mamikon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2U0ZDA0YmQtOTNlMC00MDA2LWIwM2EtMDA5MjgxODNiOWJlIiwidm90ZXJJZCI6IjNkYjQ5ZDlhLTA0NGQtNGQyMi04OGY0LTNhNWFkMGE0MjcwOCIsImlhdCI6MTU2NTk1NTYzNywiZXhwIjoxNTY1OTU1OTM3fQ._l8Y9PxxxvOwzBmtG4oaLXyFbFQOQBatskmaUU9L8_c"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d8080908-73a1-4035-9f48-daf4b12bca86.jpg",
    "rotation":0,
    "store":{
      "country":"Malaysia",
      "state":"",
      "name":"WIRA GAMES AND HOBBY",
      "countryCode":"MY"
    },
    "firstName":"Mew Yen",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWQ5ZDNhZWQtZTFhNy00ZDIyLTk2Y2ItMWEyYjJmNDQwODg2Iiwidm90ZXJJZCI6ImIxY2U0MGVhLTAyOTctNDMyMS1hYzllLWJkN2EzZDkzNmQwZiIsImlhdCI6MTU2NTk1NTY0MSwiZXhwIjoxNTY1OTU1OTQxfQ.nr-m8EdSzijrMmonWYZqsx1Q66W49PIJEZi6PjSaSpY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9fba2b34-39f8-42b9-a638-992e7a1fdba2.JPG",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"AB",
      "name":"Games Workshop - Chinook Centre",
      "countryCode":"CA"
    },
    "firstName":"Brant",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjQ3MGRmMmEtYTcyMC00OTFhLTk2YzItZjg4Y2RlZjVhZWJhIiwidm90ZXJJZCI6IjQyNTNkNzY2LWU0ZGMtNGQ0NS1iZTY1LTg2Mzc1ZGRkZWQ3MiIsImlhdCI6MTU2NTk1NTY0NCwiZXhwIjoxNTY1OTU1OTQ0fQ.pBrhm9r2_lRhR-1t2-h42mWTyAqVahgToP5tD1fppCw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0cf1b820-3327-4dc7-84bc-4446c9716e83.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WI",
      "name":"Games Workshop - Layton Plaza",
      "countryCode":"US"
    },
    "firstName":"Nick",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTA5YTAxZTQtYzQxOS00NTEwLWJiYTktZmQxMmY3ODQ3ZGI2Iiwidm90ZXJJZCI6ImFjMjNhZWY2LTc0YWMtNDRlMy05M2EzLTI5ZDdkY2MzMzU3YiIsImlhdCI6MTU2NTk1NTY0OCwiZXhwIjoxNTY1OTU1OTQ4fQ.XhkJ2eaLOMQQ8za6_mn8AVs7GF5-JIaQiHRfOqTW8tg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4c1eb1a1-5982-4e62-92db-c2430da9ac2c.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Stirling",
      "countryCode":"GB"
    },
    "firstName":"Owen",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTk3YzhmODktOTZmNi00NTBkLTgyNTktNjJlMDQzNjcxOGZjIiwidm90ZXJJZCI6ImZjNGUxMjJjLWY5ZDQtNGY1Zi1hMWNhLWVhNWU5ZmE3N2IwMCIsImlhdCI6MTU2NTk1NTY1MywiZXhwIjoxNTY1OTU1OTUzfQ.cRkyHWfG7aaz6Y0Shnhkbq6BAFQ2ieWSH7OvCEt2yQY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2483f54c-bc2a-4fcb-98b8-56ab564fff69.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Norwich",
      "countryCode":"GB"
    },
    "firstName":"Conrad",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGZmOGVhMzAtMTg0OS00OWY1LWI2NTMtNjMzZTBkNzhiYTc3Iiwidm90ZXJJZCI6ImRjMmVmNjk5LTM2MWUtNDVkZC1hY2VjLTdhZWUwM2U4ZjhhZCIsImlhdCI6MTU2NTk1NTY1NiwiZXhwIjoxNTY1OTU1OTU2fQ.Nu-ngyVxQorPc6WfpEMRiglFtalndNh7N3CHh_NhUcc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b0691471-714b-4f48-bc0a-fc71fd6ee679.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Warhammer - Colleyville",
      "countryCode":"US"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmQxMTEyNTAtNGMxYy00ZmU5LWFkODctMjczYTM3NTcxYTAyIiwidm90ZXJJZCI6IjA0Yjg5MzkxLWE4MDEtNDk3Ny05MzEwLTk5YmJkN2YzZWM2NSIsImlhdCI6MTU2NTk1NTY2MCwiZXhwIjoxNTY1OTU1OTYwfQ.ko_UHe-dWMhKpJ9K-Scn7rXu7o1egf3gvLzB12NqPg0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7d8da94f-f85a-45b1-bb07-86f38740fb26.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"",
      "name":"GAMES NOOK II",
      "countryCode":"CA"
    },
    "firstName":"Andrew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDkzZDBmODYtZmFjYy00M2M1LTgxZWMtZDE1ODM0NTUwYjJjIiwidm90ZXJJZCI6ImI3ZGRjODE1LTg4ZTUtNDRiYi1hZWQ2LTFkZmRlYzI1MWUwMSIsImlhdCI6MTU2NTk1NTY2MywiZXhwIjoxNTY1OTU1OTYzfQ.r3gNww5dDPz9kbWik9-wK31PtdoHZ0M10JZkVf5BIMs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/008cf311-88a8-4a30-9bdc-1c38a0590c71.jpeg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Games Workshop - Modena",
      "countryCode":"IT"
    },
    "firstName":"Francesco",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWNmODk2MjctYWFmYy00ZTdiLTk2YWYtMTMxOGI5ZmJkMmE0Iiwidm90ZXJJZCI6IjdiZTcwNDNlLTdmZTQtNGNmYi05ODNhLWM1Nzc0OTUyMzQ5NSIsImlhdCI6MTU2NTk1NTY2NiwiZXhwIjoxNTY1OTU1OTY2fQ.iLFwylXhWflbwE6HYCwCPyMZLHnS8lfXlz8TURnS07I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ecec04a7-c842-4dd2-91d3-8da459fdd232.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Salisbury",
      "countryCode":"GB"
    },
    "firstName":"Matt",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGE3Y2Y3YWEtZTcwZC00M2VmLTlmOTgtMjFmN2Y4YzZhMTY2Iiwidm90ZXJJZCI6IjIxM2Q0OGJmLWJkMGItNDE3ZC04Mzk5LWFmNjQzZDkzNzdiMiIsImlhdCI6MTU2NTk1NTY3MCwiZXhwIjoxNTY1OTU1OTcwfQ.HTxh0dit75caRjiJ7Pv-Zz5UaVoPKPAW4gNTBGFDMM0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a381ab17-6980-4ff0-95f8-6ab80c440868.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Bury",
      "countryCode":"GB"
    },
    "firstName":"Adam",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2RmOTViN2MtMDE5Ny00MjVkLThmNzgtMmVjMjgxN2ZlNTg0Iiwidm90ZXJJZCI6ImJiYmYyNjMxLTJiNGMtNDU3OC04YWNkLTNhZDQxMDJlMWY4MyIsImlhdCI6MTU2NTk1NTY3MywiZXhwIjoxNTY1OTU1OTczfQ.Q0X0hXBOEzr2IwTlWPh5ZopziILRGEum9xBgCddhlBI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/adabc131-f8cd-463a-977e-55683bed6067.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CT",
      "name":"Warhammer - Stamford",
      "countryCode":"US"
    },
    "firstName":"Pieter",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzY1MGVhZTUtZmY0Yi00MWMxLTliNTAtMzc5MzQwNTViZGU1Iiwidm90ZXJJZCI6IjJmZWE0NDIwLWU5YzQtNDNiNy1iNzM2LWQzMGNjZDM0MmIzOSIsImlhdCI6MTU2NTk1NTY3NywiZXhwIjoxNTY1OTU1OTc3fQ.6u8QYAuEJ_5aRYhRZT18BIg65zw4KHS8EKeioeY1jJk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/19952538-75e4-4845-9c68-0b2689b800df.jpg",
    "rotation":0,
    "store":{
      "country":"Thailand",
      "state":"",
      "name":"Legendary Wargame",
      "countryCode":"TH"
    },
    "firstName":"Chanon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGFjMDVhN2UtYTk0Yi00MDAzLTkxNDEtOGI3OWUwNmNjNmQ3Iiwidm90ZXJJZCI6ImI5OTJkZTIzLTMxZTMtNDJhMS1hMTZiLTYxNmUyYmRlN2Y2NiIsImlhdCI6MTU2NTk1NTY4MCwiZXhwIjoxNTY1OTU1OTgwfQ.4wNToaRLmflYfvKzq4153AxLGpsnt0x_KwdaBVCAblI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5a0469d7-3663-45ae-a183-0e0deb307e98.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"PA",
      "name":"TACTICAL ADVANTAGE",
      "countryCode":"US"
    },
    "firstName":"Robert",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzU1YzIzYmItOWIwNy00OTQ2LTllZTYtYmRiN2I2ZTkzZTZiIiwidm90ZXJJZCI6IjhjZGNmN2IxLTljNGUtNDRiZC05MDYzLTUyZDg3ZWU1ODlmNSIsImlhdCI6MTU2NTk1NTY4NywiZXhwIjoxNTY1OTU1OTg3fQ.QknUMF_1lF9nZuId0HlMdB3byFFh2NY-ZJYmAotcHHk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4d9b7b62-16ad-4988-93ae-cf22e33fd45a.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"GA",
      "name":"GIGA - BITES CAFE LLC",
      "countryCode":"US"
    },
    "firstName":"Curtis",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGQ4MmEyOWQtYmIwNy00NzNkLWFjNDMtNTk2ODUyNThjNGJjIiwidm90ZXJJZCI6IjgzZDQzNTA3LTE2Y2ItNDlkNC1iOGI4LWVkZDFjY2U5OTE3ZiIsImlhdCI6MTU2NTk1NTY5MSwiZXhwIjoxNTY1OTU1OTkxfQ.AsBL2z6GWgWcTYLfX0r9oTv2WxvUJjyWaZPlfDQlryM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c00d095b-14a0-4bdd-93ad-3030d3c28f12.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Games Workshop - Ventura Village",
      "countryCode":"US"
    },
    "firstName":"Devin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzhlZWIyZTctZjI2Mi00NjBiLThkNDItNjlkODkwODBlYzBhIiwidm90ZXJJZCI6ImQxYzI2MjQzLWNmOGQtNGU5My04MjI3LTEyOWQ2YWJmNjdlMCIsImlhdCI6MTU2NTk1NTY5NCwiZXhwIjoxNTY1OTU1OTk0fQ.iAHv3ConIIdc1NHHeEsfaMWQ2_UMgBUHpW0XLQ7AQp8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0ab76ba3-7b5c-4796-a15c-2a64fd152b22.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - D\u00fcsseldorf",
      "countryCode":"DE"
    },
    "firstName":"Nicolai",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzIwYWQ3ZDQtOGIxZS00MDkzLTg3NTAtNDBkZTllMmQxZDIyIiwidm90ZXJJZCI6IjVjNGMzOGUwLTE5MDctNDliYS1iMGFkLTc1NTA4OTdjYjQ3YSIsImlhdCI6MTU2NTk1NTY5OCwiZXhwIjoxNTY1OTU1OTk4fQ.B3uXv92ZoJUJZ-417iyx-b8dQsxA_baDWwPjoVIQ3ZM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e70f80c3-0caa-4bcd-9e85-3e4c57511ed6.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IN",
      "name":"FANTASY GAMES AND CARDS-SB",
      "countryCode":"US"
    },
    "firstName":"Andrew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjcxNjEwYjMtYzhiYy00OWQyLWIyZjgtODUwOWE3N2UxZDgzIiwidm90ZXJJZCI6IjQ5NzVmNTI2LTQ1YTUtNGM5OC05OTY3LTQ0MzQyZmNlNTZlYSIsImlhdCI6MTU2NTk1NTcwMSwiZXhwIjoxNTY1OTU2MDAxfQ.SxQJuiy0AXaA004gWalEH2srAgib9cg7QMfpSmX_6qY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5d6809ce-9294-4344-9683-3047faf4a67c.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Warhammer - Petaluma",
      "countryCode":"US"
    },
    "firstName":"Shawn",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDJhYjU5OTAtMzk2NS00ODBjLWJhYzktODliZDgzNjkwMjBiIiwidm90ZXJJZCI6ImZmYjY0ZDY0LWFkYzgtNDIzYi04ODA1LWE1NmQwMTZmMjUyMCIsImlhdCI6MTU2NTk1NTcwNSwiZXhwIjoxNTY1OTU2MDA1fQ.OtjjteVMN_ykUFY7HzcH1EpR22xNh-7vNsV8mCQFI9s"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/09ea9c5c-febb-4cf5-bb20-87def2ef33ce.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"SA",
      "name":"Games Workshop Warradale",
      "countryCode":"AU"
    },
    "firstName":"Adam",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWJjOTMzZjctMjFmZi00OWRmLThhZWQtMDA5MDZiOWQ5MjAwIiwidm90ZXJJZCI6ImYxMjBlNmVmLTBhOTItNDhjYi04NjhlLTdiMDdkYjdmMjM5MiIsImlhdCI6MTU2NTk1NTcwOCwiZXhwIjoxNTY1OTU2MDA4fQ.odd8MjvPLuqmya-vDlgxTWJjlo-GBQfgw79QJRLEoc0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c7bbae57-a2bf-401c-9d39-3e389c8f01d9.png",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"ON",
      "name":"Games Workshop - Appleby Village",
      "countryCode":"CA"
    },
    "firstName":"Brent",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGJkZDNjNmMtMWY0ZS00YjBlLWJmNTUtMDc4ZDc3NWZhNjlkIiwidm90ZXJJZCI6IjgzNDBmODQzLThiNDEtNGJjNi1iNmZjLWU5ZjgyMjU4NmRmNSIsImlhdCI6MTU2NTk1NTcxMiwiZXhwIjoxNTY1OTU2MDEyfQ.jFj-EbpSsua_MvxZraqAncuowBGtudvv0wTdavBngys"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5e2c455c-8709-4e11-9734-c28278a85cd4.jpg",
    "rotation":0,
    "store":{
      "country":"Netherlands",
      "state":"",
      "name":"Games Workshop - Alkmaar",
      "countryCode":"NL"
    },
    "firstName":"Nigel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTU2NTI0YjQtZmI2NS00MThlLWI1NzQtZDBiYzQyMDVmYjNkIiwidm90ZXJJZCI6IjFkMDk4NjMyLTNjMzgtNDllMC04ZTUwLWI4MjZhMWViZWI0MyIsImlhdCI6MTU2NTk1NTcxNSwiZXhwIjoxNTY1OTU2MDE1fQ.OOpEzGLdVXfMe-lqcqaNFTHKhQvTFwvNa0uNc-v3aWo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2fd428e5-d986-472a-8271-dbeb2ca6bcb9.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"MARQUEE MODELS",
      "countryCode":"GB"
    },
    "firstName":"William",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWRjMzFiNDgtZGRlZi00MjE3LTg4NWEtYTg1MzkyYTNiYWEyIiwidm90ZXJJZCI6IjJmNzBmY2M1LTVjODktNDI1MS05YzBjLWQzY2I3YThhMTBjYSIsImlhdCI6MTU2NTk1NTcxOSwiZXhwIjoxNTY1OTU2MDE5fQ.mtj-T5rc5TeRDxIaEelV5sTamB7PTS1AessmBIAJX3E"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e5d23dae-f630-467d-bd43-a257ae55a843.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"BATTLE BUNKER LTD",
      "countryCode":"GB"
    },
    "firstName":"Luke",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjc2MDBjNjQtMDc5YS00NTFhLWIyNWQtMDJlZTYwOGE5OGUxIiwidm90ZXJJZCI6IjhiMTNiZGRiLTE3ZjEtNDQyZS1hZjcyLTMyMDVjZWVlYjA1MSIsImlhdCI6MTU2NTk1NTcyMiwiZXhwIjoxNTY1OTU2MDIyfQ.OaCj_RUKGZoWKJYlb6N5rC3dsTOCCkRBKqwOHPMeteg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fef3fb5f-7759-43f1-92fe-eda81ee86f3d.jpeg",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"Osaka-Fu",
      "name":"Warhammer - Namba",
      "countryCode":"JP"
    },
    "firstName":"TOMOKATSU",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWM1ZDc3YWMtZTQ3MS00ZmE3LWE2MDEtM2I1YjZlNTRhMzRmIiwidm90ZXJJZCI6IjBlMjFjOWJkLTc2NTItNDYyYi1iOGIzLWZlNmRkZjgyOWZiMyIsImlhdCI6MTU2NTk1NTcyNiwiZXhwIjoxNTY1OTU2MDI2fQ.8MZtNWmOoAsCZXnmW6QTp96a13VaJmAypl4SYUDIH_U"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c703c120-1974-42ea-a269-a99df1cdaef7.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Chelmsford",
      "countryCode":"GB"
    },
    "firstName":"Thomas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmIzODczNDYtM2I4NS00MjljLTg5ZjktYmRkNDZiNjE2Y2JhIiwidm90ZXJJZCI6IjQzM2U2ZjEwLTI5OTUtNGU3MC1hMDRlLWY4YmY5MDkxMjcwZiIsImlhdCI6MTU2NTk1NTczMywiZXhwIjoxNTY1OTU2MDMzfQ.1sAMUkK3BNGmGG0yXxl0LJPYqMQEgsx1C3aZuYcwy0k"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1f52ed88-ee8a-4062-97c5-9e346e0fd443.jpeg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Frankfurt",
      "countryCode":"DE"
    },
    "firstName":"Alexander ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzEzMGQ4MmItYjE0Mi00ZjM0LThlZTEtYjY5YWQ1MjhhYTExIiwidm90ZXJJZCI6IjM4Y2Y5ODhlLWU0YzctNDM2OS1iZDIwLWFkMTYzYWU2YzFhOCIsImlhdCI6MTU2NTk1NTc0MCwiZXhwIjoxNTY1OTU2MDQwfQ.WGqoe9rhtMRYyTvsHjbYfVOHCpQkIolEmU_LM7FHcRU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e12ae5cf-3492-4c18-a911-3ef543ee8a88.png",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Warhammer - Chatswood",
      "countryCode":"AU"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDBiMzA5ODktZTYzNC00ZDlkLWI4MmMtZWQ2YWY4YTE5YmQwIiwidm90ZXJJZCI6IjQ4MWFhMzBlLTdlMGEtNGRkOS05ZDY5LWVkZmFmMmU2NzhkOCIsImlhdCI6MTU2NTk1NTc0MywiZXhwIjoxNTY1OTU2MDQzfQ._GUAFVbI-6xs-5tcEJ6YLrjCgPL0w2Ha20Bc5USbmfQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c810c9f3-b7a8-490e-92ab-bce327916c18.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"KY",
      "name":"Heroes Comics and Gaming",
      "countryCode":"US"
    },
    "firstName":"Chris",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzE5MWU1YzgtYjA5NS00YzJhLWFlNTItMDVmNmJmYWZkMmMwIiwidm90ZXJJZCI6IjkwNGI3NTAyLWZhNGMtNDMyYi1iMmEzLWI0MWYyNDM4ZmNmMyIsImlhdCI6MTU2NTk1NTc1MCwiZXhwIjoxNTY1OTU2MDUwfQ.DvEfuMAp7HrbOvepeXSiOBc5tnJEMF2xdP2mdgv6WMo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1f381fd1-a9bd-4316-b8db-953138e035bb.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"MAD FOR MINIATURES",
      "countryCode":"GB"
    },
    "firstName":"Ryan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2E2NTU5NDEtMjdhYy00NjMzLWI1OTktNmU4MjQwOGRmYmZiIiwidm90ZXJJZCI6IjRlMmVkMjZjLTE4ZTktNGFhNC04OTVjLWJkYTgyMzU3NDAxZSIsImlhdCI6MTU2NTk1NTc1NiwiZXhwIjoxNTY1OTU2MDU2fQ.RyoASsyvlwtk-1KEYOrFn3bnj3cyK8zU3OJjPgzMSqY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/734ca129-34e3-4188-b2de-06d1a0b3abcb.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Kingston",
      "countryCode":"GB"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2Q3ZjA4ZjctYzEyZS00NDI3LWEwMWYtMmU0YjY0NzIyNzJkIiwidm90ZXJJZCI6IjZmZTUwZTM3LThhMzMtNDkwNi05NTFiLTMxNGQ0MjQ2OTY5ZCIsImlhdCI6MTU2NTk1NTc2MywiZXhwIjoxNTY1OTU2MDYzfQ.MPz__WhMm9DidaSMXo48XtiqIOQ74NTAFXgx_CL7SNk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9bb95ac6-c694-4273-b13e-7425870706de.jpg",
    "rotation":0,
    "store":{
      "country":"Ireland",
      "state":"",
      "name":"DUNGEONS AND DONUTS",
      "countryCode":"IE"
    },
    "firstName":"Andrew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGRiZmZjMmMtOWQ1Yi00MTYzLWExYmMtMDk4MDZmYjA1YzJlIiwidm90ZXJJZCI6ImVjMmI4NTE1LTcyMDktNDYyNy04YzZiLWNiNGY1ZTRkN2YwZCIsImlhdCI6MTU2NTk1NTc3MCwiZXhwIjoxNTY1OTU2MDcwfQ.6kZm6y2Gdz-vaMpceZbO4OF3owXR0CO3FPIgGdC_CTQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/93ab57ff-6c5b-40ed-bc4d-b6cb6357f62b.jpeg",
    "rotation":0,
    "store":{
      "country":"Singapore",
      "state":"",
      "name":"Warhammer - Red House",
      "countryCode":"SG"
    },
    "firstName":"Richard",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWQ0MzZlOTQtZDJlMy00NjA0LTlmZjctYWI3NzJjODFjNDJjIiwidm90ZXJJZCI6Ijc0MDA1YWJiLTkzNzctNDJmNC1iNTUwLTgwNGM4YTRmOTk5NyIsImlhdCI6MTU2NTk1NTc3MywiZXhwIjoxNTY1OTU2MDczfQ.X_Unu98xkctfj1oRgM-DVzzqsZHxa5Zt8fdlVTHBoYM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6737bb3e-b892-4f87-a484-0ed104dcec24.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Wigan",
      "countryCode":"GB"
    },
    "firstName":"Lee",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjkwYTZmZTAtNjdjZi00ZmIzLWJjNmYtOTAzNjg4YjI3NjBmIiwidm90ZXJJZCI6IjdiZTA1MjBkLWE2MDMtNDc3NC1iYzBlLTI5ZWQ2YzQyNGY1NCIsImlhdCI6MTU2NTk1NTc3NywiZXhwIjoxNTY1OTU2MDc3fQ.ftPGsvVcSAKqeGsUrrVBDVIq_wpRdmZWnf-8q4x_ufg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9ab938cd-23c4-45fa-a233-ff0b5770760c.JPG",
    "rotation":0,
    "store":{
      "country":"China",
      "state":"",
      "name":"Games Workshop - Huangpu",
      "countryCode":"CN"
    },
    "firstName":"Qing",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWE3YTQ4ZDktMDU0YS00Y2Q3LThmZTAtZjA4NTVkZGRlZGI3Iiwidm90ZXJJZCI6IjQzYzU1Njg3LWJhMTEtNDViZi04OGRkLTAzMTMyMDE2YjE4MyIsImlhdCI6MTU2NTk1NTc4MCwiZXhwIjoxNTY1OTU2MDgwfQ.OTwj2kt5TtCZXcUfqR24dbm-9gBF1-Open5jL7uauFg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/868c231b-f9c8-430b-be2a-96ed6702527a.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"VA",
      "name":"Battlegrounds",
      "countryCode":"US"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWY0NWMxMzEtZDNkMi00MWQzLWJkNDctOWUxODc2N2YyYjZkIiwidm90ZXJJZCI6Ijg4NmRiM2ZkLWI4OTQtNGE4Yy1iZmM0LWI5YjhjZTg4ZDE5OSIsImlhdCI6MTU2NTk1NTc4NCwiZXhwIjoxNTY1OTU2MDg0fQ.fE4lAUbPYy4TWGhvf7DZPkhn0-4WLbzlzBzQ2fLnfso"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ed5997b5-dc5a-4401-9d72-8f5fd6009bc5.jpg",
    "rotation":0,
    "store":{
      "country":"Poland",
      "state":"",
      "name":"CENTRUM HOBBYSTYCZNE STREFA S.C.",
      "countryCode":"PL"
    },
    "firstName":"Kamil",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDkyMjRlNjYtMmFmZS00MjA3LTk1NGEtNzZiNzRhMDBhYjk3Iiwidm90ZXJJZCI6Ijc1NGIwNGEwLTcyNTctNGYwZi04MjhhLWQ2NTZjZWRmNmZlMCIsImlhdCI6MTU2NTk1NTc4NywiZXhwIjoxNTY1OTU2MDg3fQ._jOc2dWjCkVpGKGWkp3cFQp6RXMjia4YppxrdCcHg70"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/aaf9573f-33c2-4564-9c76-746aa85799ce.jpeg",
    "rotation":0,
    "store":{
      "country":"Netherlands",
      "state":"",
      "name":"Warhammer - Eindhoven",
      "countryCode":"NL"
    },
    "firstName":"Johan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmE2ZWU2MDctOWM5MS00YjZlLTg2MWMtM2UwY2YwNzNmMmUwIiwidm90ZXJJZCI6ImI2NmRkMmVmLThhNDQtNGM1Yy1hZjYwLWI0NGU4Y2YyZmJiZCIsImlhdCI6MTU2NTk1NTc5MSwiZXhwIjoxNTY1OTU2MDkxfQ.aFGQpyZb-XPqI8gHqhU4X3VT-kfaV20ByyO_MoNYEwU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/47a93d53-a258-4898-bb38-86e2007015ad.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer World",
      "countryCode":"GB"
    },
    "firstName":"Lance",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTkyZmRjMzQtODliZi00MDUxLWE0ZjEtODEwNDU4ZjNjZmFjIiwidm90ZXJJZCI6IjRhODZmZTRjLTEzNGUtNDJlMi1iY2ViLWIyM2M0MjAyZjZhNCIsImlhdCI6MTU2NTk1NTc5NywiZXhwIjoxNTY1OTU2MDk3fQ.Aqsbi3DgpbP8zbcjEmhIU4Uy9xzMxbyor0GjtnEadGw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/28dbfd90-9f13-4424-8a4e-0ad0c3f1ec7a.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Games Workshop - Union Landing",
      "countryCode":"US"
    },
    "firstName":"Alexander",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGZjMWZlN2UtYzNkZi00ZmEyLTgyYjctZDBjYmY4NmI0ZWY4Iiwidm90ZXJJZCI6IjczNGNmMzFkLTRkYjItNGQ4Ny1iZTFhLTE1OTc4MGI1ZmRjMiIsImlhdCI6MTU2NTk1NTgwMSwiZXhwIjoxNTY1OTU2MTAxfQ.BJUkLUGITsvivDD650mRm0LLewsAxIp9b5Cvq_9c2tg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ed983aa3-01e6-4f3e-b20b-9940b222f92d.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Crewe",
      "countryCode":"GB"
    },
    "firstName":"Kevin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjQ4Y2VmNzctOGRjYS00NDM5LWJiN2MtOGQ2NzYzZTZkNDJmIiwidm90ZXJJZCI6IjU5OTY0MmRkLWE2M2YtNDA0OS1iN2Q4LTcwZmM0MjVlOTIwOCIsImlhdCI6MTU2NTk1NTgwNCwiZXhwIjoxNTY1OTU2MTA0fQ.sN-LhEDRmdtneyRe2ML3DXFrQDfipGGg72OQIdZ1zEo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/91126631-4151-4f39-a230-fa54ce18fb0b.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Games Workshop - Watford",
      "countryCode":"GB"
    },
    "firstName":"Helen",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTYxYWJlNGEtNjg2OS00ZDE0LWE3ZjQtZDQwMGJkOWIxMzEwIiwidm90ZXJJZCI6IjE0ZjZiOGRkLTZlMzMtNGYwYS05MGY1LTZjYmJmOGZiYzkzZiIsImlhdCI6MTU2NTk1NTgwOCwiZXhwIjoxNTY1OTU2MTA4fQ.6BB4gzVJvBBVhON9fE37M9nrpt7IoB9ij5IcRIHtcH8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ae58a077-1c1a-4552-b37f-48f0c49475a3.jpg",
    "rotation":0,
    "store":{
      "country":"Panama",
      "state":"",
      "name":"PlisPlis",
      "countryCode":"PA"
    },
    "firstName":"Luis ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTc4ZDM4NjMtOGMwZi00OGE1LWJkZDktNTNiMDMwMjYxYjUyIiwidm90ZXJJZCI6ImNmZWI4OTljLTQxMTEtNGE0MS1hM2UzLTBmNDA1Y2M0MjRiNSIsImlhdCI6MTU2NTk1NTgxMSwiZXhwIjoxNTY1OTU2MTExfQ.xlbTW95PY4s9vZLinlvXxKLPMMJP789PZwpmno0-DYk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7f9fd9c7-9573-48e0-82a4-552e28fe479d.jpg",
    "rotation":0,
    "store":{
      "country":"New Zealand",
      "state":"",
      "name":"Warhammer - Wellington",
      "countryCode":"NZ"
    },
    "firstName":"Sloane",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjQ4NjEyM2QtOGYzZS00NTc4LWE0NDUtOWEzNDhmOWUyYTMwIiwidm90ZXJJZCI6IjVjYmFhYjg0LTE0MmYtNGI0Zi1hNmYzLWY5NzM4MDFjNjdiYiIsImlhdCI6MTU2NTk1NTgxOCwiZXhwIjoxNTY1OTU2MTE4fQ.2VFBN8S2xygqMym0HbwOc0aFMk6yTYqeyV4mswvku5g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d9d99a04-a5a8-401e-a520-7993fe59e9ae.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NJ",
      "name":"Mythicos Studios",
      "countryCode":"US"
    },
    "firstName":"John",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmUxMDg0ZjctMWQ3NS00ZDNlLTg0MzctZWNjOTZmZWRmMDY3Iiwidm90ZXJJZCI6IjA3M2IzYzAxLTAyNGUtNDUwOS1hNzMzLTllMzA1ODdlYjBkMSIsImlhdCI6MTU2NTk1NTgyMiwiZXhwIjoxNTY1OTU2MTIyfQ.IEKdaouAXQYGmW33jr2xiAao_LF78CIQoWTnxBOiuzs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/65ab4813-ceaa-49c4-83dd-b3c523c77abd.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"BC",
      "name":"Games Workshop - Victoria",
      "countryCode":"CA"
    },
    "firstName":"Gerald",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzg1NjQzZGMtOTAyNC00YWE1LWFhNDItZGEyZTc0M2U0MjI5Iiwidm90ZXJJZCI6ImRkNWFiN2VjLWVjNjEtNDE1Ny1hODE1LTBmMzRlMDVhOGIwYSIsImlhdCI6MTU2NTk1NTgyNSwiZXhwIjoxNTY1OTU2MTI1fQ.IQB5f8LnTyjZl9HHZRsov1Z_sOoqgMLW5arKlVLzUac"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/27e85ac0-9a5e-470e-bd10-96f38101abac.JPG",
    "rotation":0,
    "store":{
      "country":"Russia",
      "state":"",
      "name":"GeekOn",
      "countryCode":"RU"
    },
    "firstName":"Sergey",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjQxMTRiNmUtNjcwYS00OTNjLWEyZjAtNGJiZDc1YTE4MzY5Iiwidm90ZXJJZCI6IjBhMmFkM2M1LTJhNjEtNDQyNC04MmU0LTI4ZmQ5MGY1MDUzNCIsImlhdCI6MTU2NTk1NTgyOSwiZXhwIjoxNTY1OTU2MTI5fQ.R-m7NyuIBbNRyenADd7XUXHlHMdK5xjmDlomhpnGAdc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e33df0d3-43c4-4456-80a7-49545f1c2506.jpg",
    "rotation":0,
    "store":{
      "country":"Denmark",
      "state":"",
      "name":"FARAOS CIGARER LYNGBY",
      "countryCode":"DK"
    },
    "firstName":"Kenn",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWEyYmExZDUtYzFhMi00MjFiLWJlYTItNzYxYmMwNTM2ZmNmIiwidm90ZXJJZCI6IjcyNTg0OGY1LWUyNmMtNGM4NC05N2I4LTA1NjIxMzIyMDk3MSIsImlhdCI6MTU2NTk1NTgzMiwiZXhwIjoxNTY1OTU2MTMyfQ.gqz7yEveMDKZ9KdyPs8aIq9u2FxIkYD9e8HRvwasu5o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/47910af1-b016-4b30-9668-63b616b93cfb.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - South Shields",
      "countryCode":"GB"
    },
    "firstName":"David",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjc2YmU1NDctMDA5MC00NDA4LWJmZTAtMGQyNWNkNWEyMjM2Iiwidm90ZXJJZCI6Ijc4ZGNkNTI3LTllMTAtNGVlOC04ZWVhLWE1YmQyNTcxZjc1YSIsImlhdCI6MTU2NTk1NTgzNSwiZXhwIjoxNTY1OTU2MTM1fQ.8l5XCIIl8MDJLjX52ZToFN8kQzfu9t7i2Wf0ZI7O92g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e96f545f-df76-48db-9f8d-d02b68c0edcd.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Bath",
      "countryCode":"GB"
    },
    "firstName":"Jason",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTQxYTE0OWYtYTc4NS00MTdmLWJjZGUtZjZkNDIzNzkyZGQ4Iiwidm90ZXJJZCI6IjBhNjU2OTI1LThhMjEtNDA4ZS04ZmFlLWFmYTAwYjUxNzMzYyIsImlhdCI6MTU2NTk1NTgzOSwiZXhwIjoxNTY1OTU2MTM5fQ.hwMZv-kb-TUU6rXCSHzcxzcV-sYmfWOnx9sui6AOHM4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/23188ca2-f511-42dd-be44-70e7009b8417.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"OR",
      "name":"CASTLE OF GAMES",
      "countryCode":"US"
    },
    "firstName":"Geordie",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjcxZTc1YTItY2I4OS00ZmU2LWFmYjUtMjBjZmZmMGU1MmEzIiwidm90ZXJJZCI6IjAxMTBmNTU1LTgxYTQtNDk4Ni05M2M0LWVhNmIwMzBkYzQ5MiIsImlhdCI6MTU2NTk1NTg1MywiZXhwIjoxNTY1OTU2MTUzfQ.hm0cciyqd3v85RX8cM1H0Zl4yMOackasvzX656QYjUs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/564f400e-44bd-40dc-a210-db0e51f164b7.JPG",
    "rotation":0,
    "store":{
      "country":"Netherlands",
      "state":"",
      "name":"Warhammer - Enschede",
      "countryCode":"NL"
    },
    "firstName":"Twan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODVlNmZjOGQtYTcwNy00NjIxLTg1ZGMtZWVkMDk4YWYwZDVhIiwidm90ZXJJZCI6IjUyNjI5NjFlLWUwNDAtNDNmNi05MTRlLWRiMDYyN2NiZmYzNyIsImlhdCI6MTU2NTk1NTg2MCwiZXhwIjoxNTY1OTU2MTYwfQ.qGLOONUoNhxwE1f4P8T5BC8VXuqCAb_0YQk1Am0WUI4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/375f2318-acbd-4374-90a8-606fb74f77cc.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Southampton",
      "countryCode":"GB"
    },
    "firstName":"Christian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGE5MGMyMjktN2M2Yy00OTdlLWJlZWQtMjI1MTAxNTYwZDBiIiwidm90ZXJJZCI6Ijg0ZTZlN2FkLWU3OGQtNDlkZi1iMzMzLWI2MDA5MTUxY2Y5NSIsImlhdCI6MTU2NTk1NTg2MywiZXhwIjoxNTY1OTU2MTYzfQ.tkOjV-64H57uV_RNlvW3WeaohfiPTuZ88Kij7WpbtnM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7b11f5da-c4af-4c65-8ce1-3114ee7ca809.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"",
      "name":"DRGAMING PTY LTD",
      "countryCode":"AU"
    },
    "firstName":"Eric",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzIxYjg3NTgtNDg3MC00ZTliLTlkOTEtNDllN2RiMmI5ZmJlIiwidm90ZXJJZCI6ImQ4Mzg0NjNjLWE4NjYtNGQxNi04YmM4LWY3YTNhMTZlOTk4YSIsImlhdCI6MTU2NTk1NTg2NiwiZXhwIjoxNTY1OTU2MTY2fQ.8T_TFHeHyw20l1_AFIcv1U_2w9wcTC1Pc3gk2_SDkMA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8afba54d-71b3-48bd-9fda-b16bed69edf2.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Darmstadt",
      "countryCode":"DE"
    },
    "firstName":"Jonathan ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDZiM2Y1OTEtMjgxYS00Y2Y5LTgwZjctYTY5MGFkNTgwZmE4Iiwidm90ZXJJZCI6IjU2Yjk2M2Y0LWVjODgtNDhjOC05MWQ3LTk1M2NlZjEzYWJlNSIsImlhdCI6MTU2NTk1NTg3MCwiZXhwIjoxNTY1OTU2MTcwfQ.qYi-x5XUnIVD2PMwsc5-Ni-_eIyRRWVzU1K1TWmRnLw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/310595eb-ce04-4fe9-a375-5a629d2dfca5.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CO",
      "name":"GAMERS HAVEN",
      "countryCode":"US"
    },
    "firstName":"Colin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTRlN2Q3NjktNjcxOS00Yzc2LWExMjAtZGZiOTRhYTM0ODUyIiwidm90ZXJJZCI6IjNiMzZlNzY1LWM1MWMtNDU3Ni05ZWJmLTM2M2QzNDM3NjQxNSIsImlhdCI6MTU2NTk1NTg3NCwiZXhwIjoxNTY1OTU2MTc0fQ.oLy3aDPUsiqaA74dZrW0Fd6pRR45drjDsIObltwQ5NU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/61cb55fe-3f2c-402a-aeac-5a66cf4dc82b.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Solingen",
      "countryCode":"DE"
    },
    "firstName":"Kevin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTUwOTg5ZTAtNjcxZC00MzA5LTk5MTUtNDUyYjVlMmY2MGY4Iiwidm90ZXJJZCI6ImY5ZGUyN2I0LWUxMjAtNDQyMC05NGNjLTlkOTJjN2I0Yzk4MSIsImlhdCI6MTU2NTk1NTg4MSwiZXhwIjoxNTY1OTU2MTgxfQ.ABo57FN7xpPUpc0CprsvT1PBXeW5ClXAkVyRWb5A7zo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/578584e0-f473-4211-bcb7-0f60ff211c16.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"VA",
      "name":"Mishap Games",
      "countryCode":"US"
    },
    "firstName":"Brian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDU3ZGM4ZmMtNGVhZC00NDE5LWE1OTItYTI3YjFmYTIwYjZkIiwidm90ZXJJZCI6IjgzMjYxNTc1LWFlYTAtNGMxMi1iMzgzLThlOWQwZjg0Mjc1NSIsImlhdCI6MTU2NTk1NTg4NCwiZXhwIjoxNTY1OTU2MTg0fQ.Ay72DhOx71Ioh1GBH7ahS1fO2t8AprQYyYrEfNoF6sQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e74e7b72-91cd-4a7e-9ebf-e1d100cb3c2c.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Caen",
      "countryCode":"FR"
    },
    "firstName":"Pierre-Alain",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmFmOWI4MjUtNzg4YS00MGZiLTk5MjgtYmRiMjJkYmVhYjhmIiwidm90ZXJJZCI6IjBhNzdhYzg4LWQxZDItNDI1Ni1hODM4LWUyNjVkYTY4OGM1YyIsImlhdCI6MTU2NTk1NTg4OCwiZXhwIjoxNTY1OTU2MTg4fQ.Ihj84_GsfPMJ7UCMUoGtbdzgZP1rv4myhlIJstz80AE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/31b8bd52-1f19-4eca-ba30-8b395b817a16.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IA",
      "name":"Warhammer - Des Moines",
      "countryCode":"US"
    },
    "firstName":"Ross",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDZjYzA0ODItNzIxMi00NWI0LTgxMzYtNjEwNDk4Yzc0N2EwIiwidm90ZXJJZCI6IjgwOGEzNGZjLTk1OGYtNDhkMS05Njc5LTVjZjI2NGIyYzE2NSIsImlhdCI6MTU2NTk1NTg5MSwiZXhwIjoxNTY1OTU2MTkxfQ.4n2n_uoRo6UH5ywEsI4glKhEGOEGKqUm2yJSz0_kbqA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/26eb591d-a871-4880-990a-c40480d78354.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"PA",
      "name":"Warhammer - Allentown",
      "countryCode":"US"
    },
    "firstName":"John",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTVlMWRhZmEtZDE3NC00NzVhLTgyNDctNmI5ZDllM2RhMjdkIiwidm90ZXJJZCI6IjgxYzhmYTkzLTI2ZjQtNGU3MC04YTY1LTZhMzg1MGM5MzI3YSIsImlhdCI6MTU2NTk1NTg5NSwiZXhwIjoxNTY1OTU2MTk1fQ.cWT5acgDjkTov7-jLMmo_HGBfJf-BjV2grbyOOgiOrE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6c0b91ac-db01-4e55-b54a-fe811bd1ccf7.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"SPIELZEIT",
      "countryCode":"DE"
    },
    "firstName":"Guido",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTk1YjA0OWYtMzNjMi00OTlkLWFmYTctNGQxZDRlYzBlNWI1Iiwidm90ZXJJZCI6ImQ3OTlkMDdjLTQxMDItNGI5Yy04ZGVmLWViNWNhODMzOTM1OCIsImlhdCI6MTU2NTk1NTg5OCwiZXhwIjoxNTY1OTU2MTk4fQ.4isgy1jllw0DO9E3uiAajjjqIq0oXMjO9dAZxnOUe38"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4a61e66f-9ca5-45f7-b239-6a6c93ffa0ab.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Coventry",
      "countryCode":"GB"
    },
    "firstName":"Kieran",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWIyYWFiODQtNWJmYi00ZTE5LThmODUtNDk2MGMyOGEwZjE1Iiwidm90ZXJJZCI6Ijg0OTliOTkxLTA0MzAtNDAwZC1hN2Y4LWZiZTUxMGI2NTIxMyIsImlhdCI6MTU2NTk1NTkwMiwiZXhwIjoxNTY1OTU2MjAyfQ.41ujVahfoECmNmp-rS48UCqbEtD31hVRqm4uCfGtgE4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/14e52aa9-d633-496b-9922-0d5f65b31601.jpg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Games Workshop - Roma Via Eturia",
      "countryCode":"IT"
    },
    "firstName":"stefano",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGRiMGRlN2QtZDllNC00YjQ1LWI2NDctMWJhYThiZDEzZDY0Iiwidm90ZXJJZCI6ImY1ZTg5N2YzLTE3ZDMtNDEwZS05ZDZiLTVkMGRlMGRlNTdjNyIsImlhdCI6MTU2NTk1NTkwNSwiZXhwIjoxNTY1OTU2MjA1fQ.6U-sxV6nTn3mJkZSnLiOe5o0wMRdZFSvht6DfVaoY4M"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1ba25149-bacb-400d-9903-0ec01c4b18ef.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IL",
      "name":"Games Workshop - Geneva Commons",
      "countryCode":"US"
    },
    "firstName":"David",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjJiY2YwZGYtYjA3Mi00MDJmLTgwNmItZWYwMzkwOTE0MWExIiwidm90ZXJJZCI6IjhiZjI1Mjc3LWY5ODgtNDg2OC04YmM3LTllYjk0NzJiYzQzOSIsImlhdCI6MTU2NTk1NTkxMiwiZXhwIjoxNTY1OTU2MjEyfQ.K-NLPSEa8G24SnUjTF7m5e_BkQUtc0KqIoDXUlBYZAE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c51c6635-62cd-4e16-bd09-b16861c6e574.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Hobby Games Kaliningrad Krasnooktyabrskaya",
      "countryCode":"RU"
    },
    "firstName":"Maxim",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTI5Y2U1NmMtMzczMi00OGZiLWI4NGUtYzQ4MmJlNTc4MjU2Iiwidm90ZXJJZCI6ImY3NmU4NmZhLWE0NWQtNDVhNi1iZmVmLWUxZjVmMGFlODMwOCIsImlhdCI6MTU2NTk1NTkxOSwiZXhwIjoxNTY1OTU2MjE5fQ.5DcyFLfAx1c63_MS2j8difebslUvXqhzsm0_1vlPdgY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9a8663fa-0fe9-4b20-ba74-30825e2e4e6b.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Val\u00e8ncia",
      "countryCode":"ES"
    },
    "firstName":"Jose Miguel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWVhNDdlNDMtODg3OC00NGJhLTk5NzEtNDAwY2VmZjMyZWJhIiwidm90ZXJJZCI6IjVjMjJhYzYwLWZmNWYtNGU3Mi05YjJmLTZhZjRkNTI5MDM3NSIsImlhdCI6MTU2NTk1NTkyNiwiZXhwIjoxNTY1OTU2MjI2fQ.dFH1bDMfpoeCySNZbAdmi530KHdJsHYR6v-RiC5uuOE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/adc068c4-47ad-47b4-b6f9-934d41995a0f.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NV",
      "name":"Power 9 Games",
      "countryCode":"US"
    },
    "firstName":"Ryan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDU3ZWQ2MWUtM2Q3MC00NDI1LTk2NmItNWUxN2VkMmY1NjI2Iiwidm90ZXJJZCI6ImEzOGUzYzQwLTcwNDctNDRkMi04ODI4LWFkZDUyMDM1OGM2NCIsImlhdCI6MTU2NTk1NTkyOSwiZXhwIjoxNTY1OTU2MjI5fQ.Kzxzfq0tvhWd6tL5j_L2bTnLMDEn1AJIUldLnQGBB3g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/42fd627c-62c2-4681-bbbb-83d131a449eb.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Dijon",
      "countryCode":"FR"
    },
    "firstName":"BENOIT",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGEwMTAyODQtMDI1Yi00YjU5LTkzODQtNWJiNjcyMzM0ZjcwIiwidm90ZXJJZCI6ImMwMzBkNWQyLTQyZjEtNDRiZS1hZWM3LTM5OTVjMDViNDQyNiIsImlhdCI6MTU2NTk1NTkzMywiZXhwIjoxNTY1OTU2MjMzfQ.DBsWWB148RB9O83w36x8EjBh7DtM0sSzu8wiRVYGwKA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1caa437b-ed85-4fa0-8c86-bf2f42c953fb.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Lavka Orka - Krasnodar",
      "countryCode":"RU"
    },
    "firstName":"Oleg",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2VhMjgwMzQtMDc4ZS00MGE3LWE5NGItODI5MTQ5NDBhNjczIiwidm90ZXJJZCI6ImUzZDEyMDUyLTNmYTktNDBmOC1iZDM2LTc0YWFlNzA0NTJjMCIsImlhdCI6MTU2NTk1NTkzNiwiZXhwIjoxNTY1OTU2MjM2fQ.b5mxYUrNGCS04aOVA0195TAaOcdUpwDTdwtd4CcMmMY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/66782007-20b1-4c7b-a7e3-7788056300b9.jpeg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"WA",
      "name":"Warhammer - Baldivis",
      "countryCode":"AU"
    },
    "firstName":"Megan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmE5MzI0N2MtZGYyNi00MDU1LWE5MjctZGMyZmU2ZWI3NjE5Iiwidm90ZXJJZCI6IjlkM2U1ZTNjLTVjZjYtNDZkZS1hMTFhLTA5ZjhmZjViYjliYiIsImlhdCI6MTU2NTk1NTk0MCwiZXhwIjoxNTY1OTU2MjQwfQ.PoKqfHE7HahyXjeB60JZTPhplyS7NKKRJ0gM5YlK4ms"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bbb3e0b8-7ceb-47fc-80f7-24644ffb9c9f.JPG",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Berlin Frankfurter Allee",
      "countryCode":"DE"
    },
    "firstName":"Marcus",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDU5MWFlYzAtMzgwMC00OTRjLTg2MDYtYzQzMDNhYWI0OTI3Iiwidm90ZXJJZCI6IjViNjk3ZjNlLWE3NzQtNDdlNC04ZTljLWRjNTllOTVlZGQzYyIsImlhdCI6MTU2NTk1NTk0MywiZXhwIjoxNTY1OTU2MjQzfQ.IREN-eIxculhUJDnvpwwBoZVecHlcZIYVG6dRJFqN0A"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/63e1b3ff-90a0-482a-bd44-4b3ad0670078.jpeg",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"Hyogo",
      "name":"Warhammer - Kobe-Sannomiya",
      "countryCode":"JP"
    },
    "firstName":"Shinji",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWQ5YjU0ODItYmY3Yy00MzA2LTg0OWMtNjMyNzBhY2I0Y2UxIiwidm90ZXJJZCI6ImRmY2FlMmZjLTJhZmEtNGRlMi1iNTAyLTY0Y2JmMTgxZTM3YiIsImlhdCI6MTU2NTk1NTk1MCwiZXhwIjoxNTY1OTU2MjUwfQ.Rh9P8CoIzxtJHLGmn7TrPukVuHqs__ncyevLIJ5XOTg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c872d818-6030-4e03-bb8f-fcd4dd2f3a4e.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"KY",
      "name":"Games Workshop - Stony Brook East",
      "countryCode":"US"
    },
    "firstName":"Virginia",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWRjOWMyOTMtYzhiOC00ZDc1LWJhNTktNmE1NmM0YzdmNzZhIiwidm90ZXJJZCI6ImVhNjVhNjYzLTA3ZTYtNGY1Ny04NjU2LTM4YzM3NWIzNjMxYyIsImlhdCI6MTU2NTk1NTk2MSwiZXhwIjoxNTY1OTU2MjYxfQ.qKHZ_A-iUiy-TO2NOtJZmI2YY-cTiWi2W0ucIHfYFc4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/752cad9b-685a-4d33-aebd-5c86ee37c95e.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"OH",
      "name":"THE SOLDIERY",
      "countryCode":"US"
    },
    "firstName":"Robert",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjk5ZDE2NzctNzE1ZS00YjYxLWIzOTAtZDI3MTE3ZDhlMjgwIiwidm90ZXJJZCI6IjM3ZGExODlhLWUwM2ItNDE4Yi05N2E5LTVjNjE4NmQ1MzM1YyIsImlhdCI6MTU2NTk1NTk2OCwiZXhwIjoxNTY1OTU2MjY4fQ.XmxRgcLXaqy9-9gg--dZnQjlrp0zXkkuIwz9Z7O9qjc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9459c4c2-287a-4996-91a9-310ca2acc459.JPG",
    "rotation":0,
    "store":{
      "country":"Poland",
      "state":"",
      "name":"Model Mania - Gda\u0144sk",
      "countryCode":"PL"
    },
    "firstName":"Maciej",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjMzZmE2OWMtZWZjYS00MWRmLTk5MzEtMTU0ZDJiZmU2ZmI4Iiwidm90ZXJJZCI6ImIxMDk4ZTBlLWRjZjYtNGVkMC04MjNlLTFkZGRiMmUxYjUzYiIsImlhdCI6MTU2NTk1NTk3MiwiZXhwIjoxNTY1OTU2MjcyfQ.ocM0C9SV3SWPKOLi0ZsMNmQ4dRBTsYoa5E_BJInBjTY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f94aabd8-84a7-42eb-9d86-d553e7d0a659.jpeg",
    "rotation":0,
    "store":{
      "country":"Viet Nam",
      "state":"",
      "name":"Empire Capital Shop",
      "countryCode":"VN"
    },
    "firstName":"Chu Tu\u1ea5n",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODhkNzZlMzUtZGYwOC00ZjJiLWIzNDEtMDM2MTg0YTE3YjMxIiwidm90ZXJJZCI6IjY1MGMzYTY5LWNkNWUtNGFmYi1hODgwLWJiN2I4NTYwY2EyYyIsImlhdCI6MTU2NTk1NTk4MiwiZXhwIjoxNTY1OTU2MjgyfQ.0soEOpqmHGliElPun2zYu3O6OI0X3hIfzJ2ZEEDLaf0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bca1af1e-2ba3-4f26-9cc2-ebb3fa51a291.jpg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"APPRODO DEL RE",
      "countryCode":"IT"
    },
    "firstName":"Michele",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTAyMTM5YWUtZjVlYi00ZDM3LTljNDUtZTRkNzdkOTI1ZWMxIiwidm90ZXJJZCI6ImFjYmM4MTQxLTViNDItNDA3Yi04MzBmLWYxZTg3OGI4MDU0YiIsImlhdCI6MTU2NTk1NTk4NSwiZXhwIjoxNTY1OTU2Mjg1fQ.wvXOMQpuqKl_1G_hFwqeT3HDA-6o0LZXYxYUh_wUSK8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d2bd03c2-9c00-4a6a-8a15-4e718b05d8ae.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WA",
      "name":"Games Workshop - Kent Station",
      "countryCode":"US"
    },
    "firstName":"Amanda",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWE4MmU5NmUtMDFkZi00ODE4LTg5NjEtMTVlOTY2NTFlMGM5Iiwidm90ZXJJZCI6ImY0ODI1NzQwLTBlY2ItNDM0OS1iZGY5LTFlMmU4MTY2YzcyOCIsImlhdCI6MTU2NTk1NTk5MCwiZXhwIjoxNTY1OTU2MjkwfQ.ZRcYpVUti-cN0YqE-bQUIJNmP_1mYPNK_EdBqXuyGgQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e9c6e6f1-0a99-46ac-92b0-d10e1e673f88.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Warhammer - Webster",
      "countryCode":"US"
    },
    "firstName":"John",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmQ0ODQ2YzItMDAxMi00NzZlLWE1M2QtM2M4OWY3YmQyZWVlIiwidm90ZXJJZCI6ImIxNDgzMjhiLWU4MzItNDk0Mi04NDgwLTg4MjE1MjM5NTFiMCIsImlhdCI6MTU2NTk1NTk5MywiZXhwIjoxNTY1OTU2MjkzfQ.mNcWW_aSIXaLidHyA7aBNinvzQDtxIk3jwKKCdQJ47o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2aa75d4c-7668-4114-bed7-5fd6d4e2cd42.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Spandau",
      "countryCode":"DE"
    },
    "firstName":"Colin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjUwZmZkNzEtYTMwMS00NTgyLThjMmQtZmUwZjg5OGFkNjRlIiwidm90ZXJJZCI6IjQ1NjdmZjYxLTY0MWYtNDhlZC04ZWEwLWQ5ODI0ZDYyNjQzNyIsImlhdCI6MTU2NTk1NTk5NywiZXhwIjoxNTY1OTU2Mjk3fQ.gtQg2997O4FzhrKJ4OmRqTroyFxbuZHAINDstnN2Pgk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1e7ca1b7-d4fe-4705-9266-c60b75ae3b0d.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Uxbridge",
      "countryCode":"GB"
    },
    "firstName":"Martin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTNmMzFiOTMtMzA0Mi00Y2JkLTg1YWEtMDNkYzAyYzVjOTA3Iiwidm90ZXJJZCI6ImVmN2EyNjE2LWU5ZDgtNDkzNi05MGM1LTYyMzY1YWNhZTcxOCIsImlhdCI6MTU2NTk1NjAwNCwiZXhwIjoxNTY1OTU2MzA0fQ.mmSYorY6Cgdud9zbP3hAMkzMhrSQbz9gM6pcAzed4Nk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/316a707a-1f19-45a8-b275-8f2f9922b852.JPG",
    "rotation":0,
    "store":{
      "country":"Singapore",
      "state":"",
      "name":"Warhammer - Tiong Bahru",
      "countryCode":"SG"
    },
    "firstName":"Dexter Jr.",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDJiYzAxMTUtMjFjMS00MGE0LWI3ZTQtODkwOGQ2MmNkMDhlIiwidm90ZXJJZCI6ImY3YmI2Mjg5LTUwZTctNGE1ZC1hN2Q2LTA4OGViOTBlMWVlOSIsImlhdCI6MTU2NTk1NjAwOCwiZXhwIjoxNTY1OTU2MzA4fQ.0cKkcIiesIUsBWvWXAigRoM1jyVMIf-LqCZWJzIj9s8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6ba569e8-9eb5-4a6e-a8f2-e6b0f8e6f344.jpg",
    "rotation":0,
    "store":{
      "country":"Hungary",
      "state":"",
      "name":"HammerTime Caf\u00e9",
      "countryCode":"HU"
    },
    "firstName":"Dominik",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmQ4MzI3ZDItMTA4ZS00YmFiLTllMTYtMDViZTkxMDZkYjBkIiwidm90ZXJJZCI6IjI1Y2YwNjQ0LWNjNTUtNDA2Ni04ZmY2LWJmZjAxZGE4MDBhZCIsImlhdCI6MTU2NTk1NjAxMSwiZXhwIjoxNTY1OTU2MzExfQ.KM1y8R1FTg5ZI9A7_-KlixtjC82q2_fiOyf5oolag60"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ce61dfa8-0e20-483d-8b70-e037c0ab0719.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Hobby Games - Lipovaya Alleya - St Petersburg",
      "countryCode":"RU"
    },
    "firstName":"Nikolay",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2QzNWE4Y2ItNmI4OS00YzRlLThlZWEtYmEwM2NiYTg3N2Y0Iiwidm90ZXJJZCI6IjQyOTU1OGIyLWY2YmMtNDllMS05MzBkLTc5ZTVkNmE0YWZjOSIsImlhdCI6MTU2NTk1NjAxOCwiZXhwIjoxNTY1OTU2MzE4fQ.BDij0DWWajiwE-1VnfEP9W05ziGPjhr7AI4_If_lCv8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/125bb038-d93e-405c-b3d7-1a8cce63c1d7.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"BC",
      "name":"Games Workshop - Highgate Village",
      "countryCode":"CA"
    },
    "firstName":"Hudson",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODJkYThkOWQtOTA3ZS00ZWM2LWIzYWItMTQ2Njg4ZGYyYjhlIiwidm90ZXJJZCI6ImI4OWU2YzNlLWM1ZDktNDY0Ni1hM2E3LWU2YzJkNjhlZWJiNiIsImlhdCI6MTU2NTk1NjAyMiwiZXhwIjoxNTY1OTU2MzIyfQ.0QdaLUveJtSrdj2p23k94UtuqXHP_STieDQzAtjXzjU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2a168a0b-4e41-410c-9a21-d5d2e65084cc.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Gloucester",
      "countryCode":"GB"
    },
    "firstName":"Kirsty",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWY1Yjc5MTgtMjFkMS00YmFmLTkwYzktZjIxZTVhOTc5OTk0Iiwidm90ZXJJZCI6ImZmZTBhMGE3LTNlMjktNDQ1Ni05ZWYwLWFhZjA2YTJmYmMwMCIsImlhdCI6MTU2NTk1NjAyNSwiZXhwIjoxNTY1OTU2MzI1fQ.jDMjIT7drolwAv4bcMT9nqtb7EQVqioUNMjPtCs9yX0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ee8ce384-3f37-4c1f-907c-4c6d08e7baa2.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Cambridge",
      "countryCode":"GB"
    },
    "firstName":"Richard",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTRiNGRmZmUtNTNlMi00NDgzLWE0Y2QtZGFkNGI1NDk5MDYxIiwidm90ZXJJZCI6IjFkYWUxNzE3LTJkYzUtNDhmYS1iZmVkLWE0OWE0NGI2M2Y2MiIsImlhdCI6MTU2NTk1NjAyOSwiZXhwIjoxNTY1OTU2MzI5fQ.hIEnBtRxyg7NQdW3Bb8WpbNa4gyYu7-I0Qn2uNtGHpI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/104ad01b-9bd9-48c4-b84b-b0f9d1578ab8.JPG",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Avignon",
      "countryCode":"FR"
    },
    "firstName":"Francois",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTk0YWQxM2UtNGMxZC00Y2VmLTlmYzktOTc2MWMwNmI3ZmUzIiwidm90ZXJJZCI6Ijg1ODJjMGEwLTVkZTMtNDE5OS1iOGY0LTZmNDA1NjllMDI5MSIsImlhdCI6MTU2NTk1NjAzMiwiZXhwIjoxNTY1OTU2MzMyfQ.iqaIeFUy_CQ2nZAyCK8-BTtG-C_IJ7aaaZP4Qokjq3k"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/65463b0d-f539-4a25-bb76-9c27889ea124.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WA",
      "name":"OLYMPIC CARDS AND COMICS",
      "countryCode":"US"
    },
    "firstName":"Adam",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTdjYzkyMWQtNGQ2Zi00NGFjLWFiZmYtYWI0MGI5ODQzNWIxIiwidm90ZXJJZCI6IjJlMWZjMWZkLTQ5NTEtNGY3Ny05M2ZlLTM5NTU3NDQ3OGM4YiIsImlhdCI6MTU2NTk1NjAzNiwiZXhwIjoxNTY1OTU2MzM2fQ.NEGBzSqp0Ul9GNOL9IgOR7Ay9PnnOg882-IrwOyCvzU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/81cb42f7-b92b-4a25-a6f7-ae0e64c29267.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"HI",
      "name":"OTHER REALMS",
      "countryCode":"US"
    },
    "firstName":"Micah",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmNiZDNhZmYtM2I0Mi00ZGZmLWEyZDAtYTZlYTZlMjQxMDBhIiwidm90ZXJJZCI6IjE0NjcxZWMyLTdhY2EtNDNkYy05YjNlLTZkZWQzYzA3ZTJlMCIsImlhdCI6MTU2NTk1NjAzOSwiZXhwIjoxNTY1OTU2MzM5fQ.pzdeaYP0WVCh05Ui4eGHf8TC45ACD2hY0plMJIOYdRo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fb9cc052-23c2-4182-90a3-406effff7a66.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - M\u00f6nchengladbach",
      "countryCode":"DE"
    },
    "firstName":"Jeffrey",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNWRkMmNiNzItODhmMi00MGI3LThhMDAtZDhmMWEwYTNlNmNjIiwidm90ZXJJZCI6IjU0MjNmMTU5LTYzNzktNDk1YS1iODVkLTgxNDlhMjI5ZDU1NyIsImlhdCI6MTU2NTk1NjA0MywiZXhwIjoxNTY1OTU2MzQzfQ.zfLZzMVs6fPnYS16h_y3YbzKBc8mvgDIHEIdKA_aypI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/410475bd-a156-4256-8ac3-7398e2a19062.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Burton-upon-Trent",
      "countryCode":"GB"
    },
    "firstName":"Jay",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTljMzAyYTktNjdlMy00MDIxLTkyZDctMmYxZDhlZjBkNmFkIiwidm90ZXJJZCI6ImU0N2U3YmUyLTFlYTQtNGM2My1iODY4LWYxOTQ1NmU1YzM3MyIsImlhdCI6MTU2NTk1NjA0NiwiZXhwIjoxNTY1OTU2MzQ2fQ.JWtEZTIB3P7gusQbwiKZ3iC4s91Ed2SKqyLdPSvWzn0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bd3095d0-1f6d-42e4-b650-40bc3e91ae1a.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"IVORY GOAT GAMES",
      "countryCode":"GB"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTVhY2Q2ZTUtODNkNC00NWNjLTlkNTAtNGQ3YzgzMDA2Y2U5Iiwidm90ZXJJZCI6ImJiYzYxNTU5LWJkZGEtNGI4My05NjRjLWYwYTQ3NWYwYjk1YSIsImlhdCI6MTU2NTk1NjA1MywiZXhwIjoxNTY1OTU2MzUzfQ.7SL_nAkhUgwL1RiKk447o3GyXG9g3AD4uNkgSBIM3_I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2682676b-e472-406b-8525-d877e4f88da3.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"WA",
      "name":"Games Workshop Fremantle",
      "countryCode":"AU"
    },
    "firstName":"Jiunn",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTc1YTg2NjYtMmFkMi00OTRhLWIwMDMtNmIxMzNhNTg0ZjVkIiwidm90ZXJJZCI6IjdjYjU4N2JiLTlhZGEtNDM0Ni04YjAxLTQ5NWY3NDc5M2I0NyIsImlhdCI6MTU2NTk1NjA2MCwiZXhwIjoxNTY1OTU2MzYwfQ.Ncyi-tMfG_LUnf_etjNrTlG72cU1xkhdBsAL2W3Vcq8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4d2bb441-c67a-4030-93ce-c469e20ad899.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Aylesbury",
      "countryCode":"GB"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjRmZjYzYzMtYTY5NS00MGUxLThlMTctZjM5OGYzMzQ5MGJhIiwidm90ZXJJZCI6IjZlNGMzYmM3LTFjY2EtNDk5OC05OWU5LWY1YmNjZjlmM2RlMiIsImlhdCI6MTU2NTk1NjA2MywiZXhwIjoxNTY1OTU2MzYzfQ.qsXo8bWqJ3QSvyjwFzdb16jD5Zs0RfBd7TSSEmwOXsM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0335c519-943c-4bd4-bd0f-aad5a6d93fa0.jpg",
    "rotation":0,
    "store":{
      "country":"Austria",
      "state":"",
      "name":"Warhammer - Wien Bahnhof",
      "countryCode":"AT"
    },
    "firstName":"Christoph",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmFjN2M4ZjYtNTVkNS00YTMzLTkyMWMtNTZkMTk5YjFjZGY5Iiwidm90ZXJJZCI6IjBkNjUyNmQ1LTNlODUtNGY2Yi1hMjNlLTg3NzM3M2QxZjY4YSIsImlhdCI6MTU2NTk1NjA2NywiZXhwIjoxNTY1OTU2MzY3fQ.hnUMJaCgMndFCKzAqxlyQD7AxyRkqPUzkcbp57F1YGY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b2bd70cb-80b3-4f1c-adc5-2b69b3727a45.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"TRIPLE HELIX WARGAMES",
      "countryCode":"GB"
    },
    "firstName":"George",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzU0YzhjMWItMTVlYS00ZWZjLWFkZDQtNGZkYmJhN2UwZWU2Iiwidm90ZXJJZCI6IjAyZWU3MmVjLThmYzMtNDUxZC1hMmJhLWM4MjI5NDRlODY1ZSIsImlhdCI6MTU2NTk1NjA3MSwiZXhwIjoxNTY1OTU2MzcxfQ.wAC_CgJEymNBwaK2wCK75LawH6w9FdjxbjGVm2Kcl9c"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a40f9314-1b97-47d9-8a8c-6ab32144657e.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Warhammer - Paris Rue De La Cossonnerie",
      "countryCode":"FR"
    },
    "firstName":"Nicolas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGM0NzA2MjQtNzkyNi00MDE4LTk0MDktYzQ4MWRkOGI3MDVlIiwidm90ZXJJZCI6IjFjMjU3NzcxLTUwMzktNDcxMC05YzljLTFmZDBhNmVmMzM4NiIsImlhdCI6MTU2NTk1NjA3NCwiZXhwIjoxNTY1OTU2Mzc0fQ.D2ZzCfvIag1e0w4U72gk3KddIMKggKwAqvhzRc6_rkE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7e6a4995-fee2-4bc9-a6e4-d09b0d8b8183.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Magdeburg",
      "countryCode":"DE"
    },
    "firstName":"Edda",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjE1NzMwYWUtM2QxOS00ZmM0LWE0MGUtOTMxNDI5OTk5YzU4Iiwidm90ZXJJZCI6ImY4OGJjYzg2LWM1NmMtNDA2NC1hOTMwLWFhOGNkZThmYTdmNyIsImlhdCI6MTU2NTk1NjA3NywiZXhwIjoxNTY1OTU2Mzc3fQ.enHYK4z99kYKN7QsqEcaeyMRYILvM8pPhFN_mpHK3d0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8295ba30-e88d-450b-9eb6-cf9c76ba7e61.jpg",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"Tokyo",
      "name":"Warhammer - Ikebukuro",
      "countryCode":"JP"
    },
    "firstName":"INOUE",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGJhMDM1MjgtYzZkZi00YzA3LWJiN2EtNTY1YmQwZDMyNWY1Iiwidm90ZXJJZCI6IjY0N2ZlMTM5LTk0YmQtNGRhMC1iM2EwLWJlMGVhMzBiOTBhZSIsImlhdCI6MTU2NTk1NjA4MSwiZXhwIjoxNTY1OTU2MzgxfQ._dripg5lPn2LjxT_ZWP2OrmZ4o6Io9WNN1MRdK7PtAU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/caead682-0e0a-410e-bb36-d91a4b59770b.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Reading",
      "countryCode":"GB"
    },
    "firstName":"Richard",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWM0OTBjZTItN2M4OC00YmVhLTlmNTQtMDBhNTk2ZWNjZTQ5Iiwidm90ZXJJZCI6IjQ2MDlmYjdiLWQxMmEtNDUwNy04NmZiLTc0NTcwMjk5OTE2ZSIsImlhdCI6MTU2NTk1NjA4NCwiZXhwIjoxNTY1OTU2Mzg0fQ.KaK7bWiV5v8pcfFnArENbEjTeeZE99wNCdIQmqw_hyg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/af05f6f8-ee23-4d76-b910-da5e65b2165c.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Epsom",
      "countryCode":"GB"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGQ4Mjc5ODEtNTE4OS00YjEyLTkyNjEtNDA2ZDU1NWU1ZmI2Iiwidm90ZXJJZCI6IjgzZWE4MzljLTVjNDktNDZkOS04MDE5LThjYTk5ZGFmYjIwYyIsImlhdCI6MTU2NTk1NjA4OCwiZXhwIjoxNTY1OTU2Mzg4fQ._AnPr9oYjEHKyWVt06GstN5VXHqDKymSs5TAMQKFOpQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fa6c3a23-f982-44dc-95bb-50f0faf4d1ab.jpg",
    "rotation":0,
    "store":{
      "country":"Belgium",
      "state":"",
      "name":"Warhammer - Namur",
      "countryCode":"BE"
    },
    "firstName":"Fran\u00e7ois",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGIwMWJiOWQtNGU5NC00NzFlLWFmMGQtN2NjNTIzZDJhMjM0Iiwidm90ZXJJZCI6IjcyN2FjY2FlLTFlN2ItNDg1MC04YmI3LWU1MjY2ZWMxYzNlYSIsImlhdCI6MTU2NTk1NjEwMywiZXhwIjoxNTY1OTU2NDAzfQ.20aKapLmWXpFkOa7VX7a8b9rhjY0ZnVXR3bu6-QgkQI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1badadac-c9e7-4628-9664-cf267bca106f.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Sevilla",
      "countryCode":"ES"
    },
    "firstName":"Olmo",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjNjNDNhMTMtNDBlOS00NjYyLWFiOWEtZjA1NTgyZDA5MTIwIiwidm90ZXJJZCI6IjY1OGMzMzI0LTdiMGQtNDA2ZS05MGE4LTg5ZDg0NTk3ZWIyZSIsImlhdCI6MTU2NTk1NjEwNywiZXhwIjoxNTY1OTU2NDA3fQ.n1gRRX640jvKtCo7DtkLU1e7ZOkAqJdNLnevu6RJCos"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3cab364d-f241-4ea4-a478-fb8b8a4e7753.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CO",
      "name":"Warhammer - Boulder",
      "countryCode":"US"
    },
    "firstName":"Cecilia",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTcyZjYyZDctZWZkYS00MjUyLTg3MTktZmFlNTFiNTM4MGFlIiwidm90ZXJJZCI6IjliZWQzNzY3LTc3NzctNDkzOS1iNTM2LTcwOTZjN2VlMzNlYSIsImlhdCI6MTU2NTk1NjExMCwiZXhwIjoxNTY1OTU2NDEwfQ.OQYg663Ep4cEJBj1O0tqaJYk-QRozw37rjE5tIQLat4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0b40d5fb-3b3a-4639-b236-ff2861b3ca15.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IL",
      "name":"Games Workshop - Deer Grove",
      "countryCode":"US"
    },
    "firstName":"Justin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2Q3MjQyZmUtMjZiYS00ODI4LWI3OWQtYTc4NDQ2MjFjNGIwIiwidm90ZXJJZCI6ImViZWMwNjZlLTJhNmMtNDFmOS04NTIwLTAzMzdlMGZiOGMzYyIsImlhdCI6MTU2NTk1NjExNCwiZXhwIjoxNTY1OTU2NDE0fQ.sO7pD0yrhF2Iv-RKzI9j55xX0ocRJPmn6QNBN01eR94"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d3239f19-a818-42b3-9d34-0d6a5268fa9a.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"GAME KASTLE - SANTA CLARA",
      "countryCode":"US"
    },
    "firstName":"Garrett",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTUyOTIyZWQtMDJhMS00ZDU1LTk2ZGQtMDBmOTUxZmI0Yzc0Iiwidm90ZXJJZCI6IjJkY2MwY2EzLWFmODMtNGZkZi05NzgzLWI4ODkzZTM4NWY4OCIsImlhdCI6MTU2NTk1NjEyOCwiZXhwIjoxNTY1OTU2NDI4fQ.ux9zPp7UiuJHMQoltfIbIWL1Ro-D5MgA4hfkE5FPxfs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a415e5f5-a69d-45c3-a9c8-6869d4b3e7ba.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NC",
      "name":"Atomic Empire",
      "countryCode":"US"
    },
    "firstName":"Dave",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjM1MjczMzMtY2QwYy00NjBmLTg2NTQtY2M4M2Q1NTJmYzQwIiwidm90ZXJJZCI6IjEyOTQyOTI2LWUxODgtNDEwMS1iYmU3LTk5Y2U5OWQ4YTIzNSIsImlhdCI6MTU2NTk1NjEzMiwiZXhwIjoxNTY1OTU2NDMyfQ.6W1o3Jf5A60_DbE2H9lMl3WP2iQ5rF9M4OeQqAqjJ9o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0c632d06-3b63-4dfd-847e-768c5f0506f5.JPG",
    "rotation":0,
    "store":{
      "country":"Malaysia",
      "state":"",
      "name":"Gitz Hobbies Trading Co",
      "countryCode":"MY"
    },
    "firstName":"Zayyani",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWIyMTFlNDYtNTUwOC00NjVjLTg5OTMtMWJlODMzYTVjNjc4Iiwidm90ZXJJZCI6IjIyZDlkZDY0LWM4YjgtNGQ1NC05YmUyLTc0ZjNjM2FmZjhiNSIsImlhdCI6MTU2NTk1NjEzNSwiZXhwIjoxNTY1OTU2NDM1fQ.PmrgDnuyN4_Pnsg-o3BtOXO0RlfDzYMuNUMUWbx1U10"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2b5b2fb4-2fa0-43fa-811d-09cf057acb52.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Plymouth",
      "countryCode":"GB"
    },
    "firstName":"Cameron",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTAwNGVmMWMtNmFmOS00ZGQwLTgyNzctZjJhMDc4YjI1OWFhIiwidm90ZXJJZCI6IjczZjkzZDk5LWEzOWYtNDY5OS04ZWU3LTY1YzIwYWE1ZWU0MSIsImlhdCI6MTU2NTk1NjEzOSwiZXhwIjoxNTY1OTU2NDM5fQ.6Xqt6e1AdWzDY99NFNnkPwfNbGrMeF7MjxKvNDMBAwI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/618b3415-5789-484a-9dd6-a32c794d959f.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"BANDUA WARGAMES S.L.U.",
      "countryCode":"ES"
    },
    "firstName":"Diana",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWQ1MDZlYzQtMDI4MS00M2Q2LWJiM2UtMTE2MTVhOTFmM2JjIiwidm90ZXJJZCI6IjQzMjlmNjkyLWYxZTUtNGE5ZC1hNzlmLWQ2YTU1NjE3YjEyNiIsImlhdCI6MTU2NTk1NjE0MiwiZXhwIjoxNTY1OTU2NDQyfQ.xppi1cBnwB6RitL3_jb8GbWOzvwruV45oX2H0tfsuuo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cf33c2e8-ac7a-4622-9f72-0629c458ab52.JPG",
    "rotation":0,
    "store":{
      "country":"Malaysia",
      "state":"",
      "name":"Warhammer - Velocity",
      "countryCode":"MY"
    },
    "firstName":"Suk Keat (Michael)",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmFiZjRiYjctNDNiOC00ZTQ0LWJmZTItYmU0NmVhZjBkOTdlIiwidm90ZXJJZCI6ImE4ZDNmMzgxLWYyZjktNDU2Zi05MGEwLTIyMzVjM2NjZjcwYSIsImlhdCI6MTU2NTk1NjE0OSwiZXhwIjoxNTY1OTU2NDQ5fQ.U01VUfCfVJ--DG3YqNQUnIYwdbMUfPTC1vPKplSqV1s"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/604bc231-6f9f-4726-adac-cc1f8342cd1b.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"FL",
      "name":"Games Workshop - Garden Park Plaza",
      "countryCode":"US"
    },
    "firstName":"Steven",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzk1NjIyNGYtMDhiNi00ODNhLTgyNWEtY2Q1YTE5NzBmODU4Iiwidm90ZXJJZCI6IjRiMmExM2ExLWRjNzMtNGY2NC04YmExLTcyZjIyOTJiZjkzMyIsImlhdCI6MTU2NTk1NjE1MywiZXhwIjoxNTY1OTU2NDUzfQ.oRJxQh2jf8ta-VcJjCcTvUBysA6olcV2ijPIooAIQFk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9dd77057-0686-4094-a565-2682ad82dcf7.jpeg",
    "rotation":180,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - M\u00e1laga",
      "countryCode":"ES"
    },
    "firstName":"Pedro",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTBiNTkyODgtZDFlOC00NjY4LWJlNjgtYTRlNzlkMjYwOGY4Iiwidm90ZXJJZCI6IjUwZTRjZmIzLTEzNzctNDEzMC04ZDJkLTkwM2JkZDUwNWMyMCIsImlhdCI6MTU2NTk1NjE2MywiZXhwIjoxNTY1OTU2NDYzfQ.DFWgoaPJVQc72d0KJ-g-N2d4pItkLUAQdjznrTTZK_o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fa7404bf-a82c-43f2-9b9e-47ba7dc1bbe6.jpg",
    "rotation":0,
    "store":{
      "country":"Belgium",
      "state":"",
      "name":"Games Workshop - Antwerpen",
      "countryCode":"BE"
    },
    "firstName":"Jonathan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWRiN2E2ZTYtNDI1NC00NmVhLTg5MDItNTZlYmQ1MWRlMmY1Iiwidm90ZXJJZCI6ImVkZjc4YmUyLWUxYTctNGFmMC1hNmRmLTczMjgzNzAyNzc4MiIsImlhdCI6MTU2NTk1NjE3MCwiZXhwIjoxNTY1OTU2NDcwfQ.d0LVEn6_B3Ijh9KO4zACybljSBIl4l3ZT8Wwpls-l_E"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/42ad4097-5f0a-4120-b455-b50620bcea30.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WI",
      "name":"Games Workshop - Prairie Ridge",
      "countryCode":"US"
    },
    "firstName":"Andrew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzZiM2VkZTctMDA0NC00ZTBiLWE5MzgtNjhjMTlkNmRhY2U1Iiwidm90ZXJJZCI6ImUyY2MyMzc3LTY2NjMtNDcwMy1hN2ZiLTU3YjMwYWYxMjc4MyIsImlhdCI6MTU2NTk1NjE3NCwiZXhwIjoxNTY1OTU2NDc0fQ.ee-7eJN8kWp9oStLnYroiyim-9K-aq_zpdSfT404BKM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7deaaa5d-c9f5-4328-8933-58a48c5ef00e.jpg",
    "rotation":0,
    "store":{
      "country":"China",
      "state":"",
      "name":"Da Ren Gong She",
      "countryCode":"CN"
    },
    "firstName":"\u6ea6",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmJlZTMxOTAtNmQ0Yi00MTU5LTg3MzMtMWJhMzY4ZmIxODY0Iiwidm90ZXJJZCI6IjRjNzAxZWVmLTQwZjUtNDUyYi1hYjRhLWNiOTczZTU1MzkzNiIsImlhdCI6MTU2NTk1NjE4MCwiZXhwIjoxNTY1OTU2NDgwfQ.X2qbb8dIiRRCKpTagI2hLgj0v8dShmSecZL_RCnyEgg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/89ddc7e7-29fd-4fe7-9f23-09b9fbfa7b57.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Arg\u00fcelles",
      "countryCode":"ES"
    },
    "firstName":"Antonio Fulgencio",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWNlNzFlZGUtZWZmMi00ZDM4LThkNTgtM2IwZGNjNWQ3NGVlIiwidm90ZXJJZCI6IjExYmFhNWY4LTJjZmQtNDQwMy04ZTgzLWYzMjk5YmYwYjhkMCIsImlhdCI6MTU2NTk1NjE4NCwiZXhwIjoxNTY1OTU2NDg0fQ.uvway4cP8cW5UcuBbgZ2zhO9O0s5jDUaKMQdVPtXTNM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d7b4c3b7-9b18-4378-afdd-b7517d77bdd2.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"PA",
      "name":"Warhammer - Bridgeville",
      "countryCode":"US"
    },
    "firstName":"Brian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTM0ZjFiMjQtNDc1MC00NzViLTlmOTItMjhhNjcxNTU4ZjA1Iiwidm90ZXJJZCI6IjVlNjUzYzM0LTlkYjktNGNkYy1hZWRhLTEzMWJiZWJlMzNlNiIsImlhdCI6MTU2NTk1NjE4OCwiZXhwIjoxNTY1OTU2NDg4fQ.lzVXwUZJdfr6JFG-XIcpQfoT6uTpO3abm2lv_voqEAk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/01a4d04c-fd45-43aa-a16f-d56062210deb.jpeg",
    "rotation":0,
    "store":{
      "country":"Philippines",
      "state":"",
      "name":"Magus Games",
      "countryCode":"PH"
    },
    "firstName":"Christian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjI5ZjZkMDAtNjc2OS00NGYyLTg1NDctNjJmNDYwYjBlMjQ3Iiwidm90ZXJJZCI6ImZkZmY5YTk0LTNlZTAtNGM4OC04NGI2LTFiZTEzMDNlOTEwNCIsImlhdCI6MTU2NTk1NjE5NiwiZXhwIjoxNTY1OTU2NDk2fQ.W51H3PZ8RLiUrLMMeNrSHD5KE9r5j7Tyowk7AFNLICc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/11a78541-6a49-4423-9fb4-cf854d580321.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"ATT ENRIQUE LLANO",
      "countryCode":"ES"
    },
    "firstName":"Miguel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTZlMzJlZmQtNmMxYS00YmE2LTgwOTktYTE3MmE1M2E5MTI5Iiwidm90ZXJJZCI6Ijg5MGY5OGM5LTFiMTEtNGY2MS1iNWFiLTA1YTJhNDAwZjBhOSIsImlhdCI6MTU2NTk1NjE5OSwiZXhwIjoxNTY1OTU2NDk5fQ.p-qGliP_C6ga-Uf8uWBr2lIJ11ivrOoqYx53DzX0D7U"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b99067fc-c9f4-4565-b7fc-8845a15d744e.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"AL",
      "name":"Warhammer - Westbury Square",
      "countryCode":"US"
    },
    "firstName":"William",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGFhY2NkMjItYThmZC00MWM5LWJhNjEtNTRhNTNjY2ZiODNkIiwidm90ZXJJZCI6IjllYzI5ODQ2LTQ5YWYtNDQ4ZS05MDZiLTU5NmY1NGI0NzQ5MyIsImlhdCI6MTU2NTk1NjIwNywiZXhwIjoxNTY1OTU2NTA3fQ.k6iFJIyQEVR4dyY5ThSczdkZwTCmcPTD23PgoqJKtJw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/73ce7371-5c3f-4f57-9c37-c3d633df1bd2.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"SCI FI FACTORY",
      "countryCode":"US"
    },
    "firstName":"Robert",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTljMzRiZmYtYmE4Yy00Mjc3LWEyNjgtOTg0M2MyY2E1M2UzIiwidm90ZXJJZCI6IjkwMjI4ODAzLTI3NWItNDllNy1iOWVmLWU1MDNhNzYyNmVlYSIsImlhdCI6MTU2NTk1NjIxNCwiZXhwIjoxNTY1OTU2NTE0fQ.gbAnqL8izo8esOQ0tGegS8gFgWxrmXBVVgaDHwQaeMU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e034a13d-322d-47f3-a063-76900d2eeac2.jpg",
    "rotation":0,
    "store":{
      "country":"Malaysia",
      "state":"",
      "name":"Warhammer - Summerton",
      "countryCode":"MY"
    },
    "firstName":"Wei Jern",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGE2N2JhNDYtODgzOC00YWMxLWEwYmMtNDk1ZGFmMGY0ZjEzIiwidm90ZXJJZCI6IjQxZDJhZjE2LTQ1MjAtNDU3Yi05MThmLTdmZWQ0NTY4YTAxMCIsImlhdCI6MTU2NTk1NjIxOCwiZXhwIjoxNTY1OTU2NTE4fQ.iUb2_2VUdnrTkcRwfoS4qrgqggYjyEYl8p81RY71D2s"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/57632b49-1166-4205-9142-939ee34214b5.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Games Workshop Miranda",
      "countryCode":"AU"
    },
    "firstName":"Joshua",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWY1ZjdkNjItYWU1Zi00NWI4LWE4OTYtYzI1NjY2NmVkZGZmIiwidm90ZXJJZCI6ImMwNzdmNmUyLWExZTYtNDY4Ny1hNDljLTQ1M2YxZWI4NWQ2MSIsImlhdCI6MTU2NTk1NjIyMSwiZXhwIjoxNTY1OTU2NTIxfQ.lmy-rMwwCmdCUqyUg3VomYoxKZgWnsrsDV2TY9ZHgwY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f6c4db29-ef39-4dc2-90aa-c984bb6b31cb.JPG",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Ingolstadt",
      "countryCode":"DE"
    },
    "firstName":"Maximilian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTNlMGNmMTYtY2UwMC00ZDEyLTg3NmMtOTg4NzY2ZWI2NTlhIiwidm90ZXJJZCI6ImFhMjI3OTVlLWVlMGItNDg1OC05ODE0LWI4NGVlZmY2MzRkMSIsImlhdCI6MTU2NTk1NjIyNSwiZXhwIjoxNTY1OTU2NTI1fQ.cT3d14Z1uQzR7WkPTBna9Go6GKB2d8nyoLLkkWLdH-o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bd87bc1c-9332-4b9e-b751-23a756e2399c.jpg",
    "rotation":0,
    "store":{
      "country":"Malaysia",
      "state":"",
      "name":"Warhammer - Publika",
      "countryCode":"MY"
    },
    "firstName":"Joash",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDEzNTc5NmEtMjJiNC00MzE1LWJhM2UtZTMwMzQ2ZjJiMzY4Iiwidm90ZXJJZCI6IjAzN2FkNDdjLWQ3MzUtNDQ4OS1hODA0LTA0YjE1YmY1MThlZCIsImlhdCI6MTU2NTk1NjIzMiwiZXhwIjoxNTY1OTU2NTMyfQ.lI1RQP7MLIKMJqr6IYovHNur-1bEfGhT3oLtuXDpDQw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a612f480-1c49-4679-8bbe-353aa2cdb264.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Worthing",
      "countryCode":"GB"
    },
    "firstName":"Jake",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2EyYjRiZmYtYzZlMy00MzRkLWI5MDQtYzRmYjBlMjNiNjFmIiwidm90ZXJJZCI6IjFlNTZhYjE1LTBjNTAtNDk4ZC1iYTNmLTAyNDdiMWI3NGU2YiIsImlhdCI6MTU2NTk1NjIzNSwiZXhwIjoxNTY1OTU2NTM1fQ.XSfu4ucBeYaR_sx3gU2LWANv2ifyDOMdXMjYMS_kNIA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8d75c3c8-545e-4f5c-83ef-e4103aa6f1cc.jpg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Games Workshop - Padova",
      "countryCode":"IT"
    },
    "firstName":"Angelo",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWM0OWFiYjQtYTU2ZS00NDYzLTlmNDEtZDA3MmJkZmI5MjI5Iiwidm90ZXJJZCI6IjdlZDYyYTk1LTg5YWMtNDk0Yi05YzQwLTI0MjhjZmVmNjlmMSIsImlhdCI6MTU2NTk1NjI0OSwiZXhwIjoxNTY1OTU2NTQ5fQ.rqmYez72xXaXP_MS7zx1aw7wFIahz7646iZ0rcM1L60"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f24c7b6f-c1f7-4535-a7dc-2881dedbc1e4.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MD",
      "name":"Warhammer - Gaithersburg",
      "countryCode":"US"
    },
    "firstName":"Walter",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTQxNzIzNzgtMWQxNS00YzY5LTk4NDItNTliYmYwY2NhMmFkIiwidm90ZXJJZCI6ImYzZDk1OTE3LTI5ZTgtNDM1My1iZTk5LWM4YjM0M2Y2YWIyNyIsImlhdCI6MTU2NTk1NjI1MywiZXhwIjoxNTY1OTU2NTUzfQ.I9FeOLPvHqYEDYjrFF3ShRcD2-rW08qulbc0cFioejk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/71c86340-2405-4f23-8b9a-94656d83416b.jpg",
    "rotation":0,
    "store":{
      "country":"Philippines",
      "state":"",
      "name":"Neutral Grounds Centris Walk",
      "countryCode":"PH"
    },
    "firstName":"Rainier Albert ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjU2MjYwNTMtZmNiMi00ZDBjLTgwZmItYTgyMjAxMzlmNDg4Iiwidm90ZXJJZCI6IjZmZWZiNjFkLThjN2YtNDcyNC05ZDAyLWNmOTk2NDk4YWJkNiIsImlhdCI6MTU2NTk1NjI1NiwiZXhwIjoxNTY1OTU2NTU2fQ.wwu5JuCc-Xl5fSQCdJNXieAztOkB5bpsk9W8wWNQAJk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ff319a06-7f5b-45d6-8cbf-c9bf31c43edd.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"WA",
      "name":"Games Workshop Morley",
      "countryCode":"AU"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzM0Yjk1OTAtOGMzMC00N2MwLThiZmEtMjIyZjc2NTVmNWYxIiwidm90ZXJJZCI6IjY3ZDQ3Yzc0LTVkMGUtNDUyMi05OWZiLTg2NjBmOWIwMDE0YiIsImlhdCI6MTU2NTk1NjI2MCwiZXhwIjoxNTY1OTU2NTYwfQ.z66VqRBdu1E4ox-3gCETxB7yDndsT073GGmYGF1BZyo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b445b942-41e1-4c25-9f92-625ed7e9d9a0.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Amiens",
      "countryCode":"FR"
    },
    "firstName":"Nicolas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2JkOWY3MWQtMGVjZi00N2RhLThhOGEtODhjNTczOTRmZDI2Iiwidm90ZXJJZCI6ImVkNjg0Y2ZkLTcxOTUtNGVhNy05NGVmLTU5ODUzMjY0MWMyMiIsImlhdCI6MTU2NTk1NjI2MywiZXhwIjoxNTY1OTU2NTYzfQ.Al35UrFUdRaQeRDNJqIeKlI1ebE83h96JSA16TGkYf0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b095be7d-514e-4b10-a895-7a75419ae7f5.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Bremen",
      "countryCode":"DE"
    },
    "firstName":"Katharina",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjRkOWVmMTUtMmEzZi00NTRiLThjMmItNmVkYzhmOWI2OWFhIiwidm90ZXJJZCI6IjYzMjJkNmNmLWNkNTYtNGQyNC1iMWI2LWY4YzIzN2U2N2QxYiIsImlhdCI6MTU2NTk1NjI2NywiZXhwIjoxNTY1OTU2NTY3fQ.ocxE8b9dBbtP4NtZFU1Yqg2KP4BYcpYaRmi7ZkmjCrM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/76b4946a-9b3d-4ccd-bfce-8afb867bfb03.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IL",
      "name":"Games Workshop - Lakeview Plaza",
      "countryCode":"US"
    },
    "firstName":"Juan Carlos",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2ZkMzFkMjgtYTlmZi00N2EwLWFlYmEtZThiZTU1YzRhNjIzIiwidm90ZXJJZCI6IjBhN2EwMGY5LTc4NTAtNDliOC1iMjE2LTI2ODUzMDNjN2IxYSIsImlhdCI6MTU2NTk1NjI3MCwiZXhwIjoxNTY1OTU2NTcwfQ.QOx9nEOH7-jdkD7kBOgKAsg6fdLlPM90h5Ac68EZQhA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7e0212fb-8851-4970-8865-67fcba942524.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IL",
      "name":"Games Workshop - Acadia on the Green",
      "countryCode":"US"
    },
    "firstName":"Francis",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzNiZDNkNzktYzU2ZS00OGExLTk3NWYtYjNjY2YxZTcxMGFkIiwidm90ZXJJZCI6ImYyODM5ZjE5LTU4ZmItNGVmMy05ZjMyLTBiNWZlMzFmMDJkMyIsImlhdCI6MTU2NTk1NjI3MywiZXhwIjoxNTY1OTU2NTczfQ.q-UXZfQrw9Ii8bt0wHClGfNLRiIsNrkmNjF1htsLjAw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/536c4cb2-f23a-411d-b313-eb17178b9c50.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"ON",
      "name":"Games Workshop - Yonge and Lawrence",
      "countryCode":"CA"
    },
    "firstName":"Joshua",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmQwNmExOGQtYzMxMS00MzQ3LTg1NzEtYzJmNWI5NTBkMTNkIiwidm90ZXJJZCI6ImZjM2M5MzllLWM0OGYtNDk0NS04ZjFmLTk0ODcwODg0YmRmMyIsImlhdCI6MTU2NTk1NjI4NCwiZXhwIjoxNTY1OTU2NTg0fQ.qD_mH6w09ChwScGJKVAKzTKzm8TPiqKu9pVODT595FM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2e826bae-3e03-44db-8075-e3943a7850bf.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"GA",
      "name":"Win Condition Games",
      "countryCode":"US"
    },
    "firstName":"Hyon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTFhNTY5OTAtYjVlMS00MWQyLWI4NzktNGQ5NWE2YjZiY2YyIiwidm90ZXJJZCI6IjNiMjUwZDZjLTllOTEtNDA5Zi04NTBhLWY3YzhkYTEzNjEzZCIsImlhdCI6MTU2NTk1NjI4NywiZXhwIjoxNTY1OTU2NTg3fQ.M5W3N9Y5k8rOLLgMF3Mgp0GMaHfx1-Wh0QqfGq8qVB0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1e3668e0-f5eb-404a-981a-12292e630e45.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"LA ESCOTILLA",
      "countryCode":"ES"
    },
    "firstName":"Jos\u00e9 Ricardo ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2U1MzE3MDAtZTE0NS00MGRlLTk0OGItMzg4ZTYzMjJmZjk4Iiwidm90ZXJJZCI6ImFlY2VlYjUyLTk0Y2UtNGZlOS1hOTBkLWZlMjY5YmJmZGNkOCIsImlhdCI6MTU2NTk1NjI5MSwiZXhwIjoxNTY1OTU2NTkxfQ.xK4b0Ip1gZTlHPQv3gWbl4dz0qGRteXHktKYFdhC8sQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fd002317-7dbc-4d36-8233-b237a16f7f47.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Paris Avenue Daumesnil",
      "countryCode":"FR"
    },
    "firstName":"Alexis",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjkyZDkxOTctMjRjZi00MWQ0LTg1NGYtNWYxODEwMTczZTM0Iiwidm90ZXJJZCI6IjIyMWExNzQ5LWM4Y2YtNDJjYS04MDQzLWVhN2JkOTc1MTY4ZiIsImlhdCI6MTU2NTk1NjI5NCwiZXhwIjoxNTY1OTU2NTk0fQ.9FAngtmrb1CzG4aCPhcU3Z9YSeQ9CJ5sLLI67xYREts"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c5f10a49-1d24-4a46-b5c1-680c22819975.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WI",
      "name":"Warhammer - Fitchburg",
      "countryCode":"US"
    },
    "firstName":"Joshua",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmI0OTdmYWMtMGQyOS00MTg4LTliN2YtNjE0NDFhOTI5MzI3Iiwidm90ZXJJZCI6IjdhMDcxNDk2LTgwNDktNGVjOC1hNzFlLWMwOGQ5NDlhMzBkNyIsImlhdCI6MTU2NTk1NjMwOCwiZXhwIjoxNTY1OTU2NjA4fQ.yUQps5ovy4tL9smkWa6sVx5DFYegox_9xmcJ6O4lT_0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9496d656-8590-4e66-b48c-46f3407dcff7.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Warhammer Lyon II",
      "countryCode":"FR"
    },
    "firstName":"Mickael",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDBiYmMwMWMtY2IwNy00MjJmLWJlYjYtMWJjYzQwN2M5ZDIwIiwidm90ZXJJZCI6IjU5NTc1MzYwLWJlMGItNDQ5Yi1iMWExLTRiNzQ0N2RmNDk2NiIsImlhdCI6MTU2NTk1NjMxMiwiZXhwIjoxNTY1OTU2NjEyfQ.aOxovK1y6wZQDUEdjyKlErYHryBwCPc8c6BdS-FtrJg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3d6266a7-4620-40a0-a298-d1d158f8eb2f.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TN",
      "name":"Games Workshop - Cool Springs Village",
      "countryCode":"US"
    },
    "firstName":"Ben",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2UyY2M3NTAtM2Q4OC00NTdlLWFhMDUtNWE1ODk5NDhmOGRkIiwidm90ZXJJZCI6Ijc3NjA3YWI3LTQ3YTktNDkxOS04OGViLTQzNTMwZWFjNDAxNiIsImlhdCI6MTU2NTk1NjMxOSwiZXhwIjoxNTY1OTU2NjE5fQ.XB0oLGF5itO2iHdL3O34Cy0w5rO2BMaSZ1cpQNROP0Q"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8b186e0c-d91b-4645-9d10-c4b03404f9c4.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MI",
      "name":"RIDERS HOBBY SHOP",
      "countryCode":"US"
    },
    "firstName":"Frank",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDBmMzk3ZDktMzcxYi00ZTY4LWI0YWMtMWMzZjJiMTE3Nzg3Iiwidm90ZXJJZCI6IjZiMTc4MTYyLTU3MzktNGY4NC1iZDhlLWFhYzE1NzI3ZDQ0NyIsImlhdCI6MTU2NTk1NjMyNiwiZXhwIjoxNTY1OTU2NjI2fQ.7_SPKjRUHOtMAjGaZFSqL7V4JOaxTzNyLXVj0jIk4IA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b3c805d2-0478-4521-bbb1-260463be1f2c.jpg",
    "rotation":0,
    "store":{
      "country":"Greece",
      "state":"",
      "name":"Fantasy Shop Central",
      "countryCode":"GR"
    },
    "firstName":"Ilias",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjg0MWQyYTQtZTllYi00NGFlLTgwZGQtNWU0MTJlMTEzZjVhIiwidm90ZXJJZCI6ImIxMzhlOGJkLTI0YzctNDFkOS1hMWFkLTVkMTg0YmMyZDhiOSIsImlhdCI6MTU2NTk1NjMzMywiZXhwIjoxNTY1OTU2NjMzfQ.JAe7RaB-Rxl2fJ3P-wKCEWgGbh8mE1LQ0tylaSuyRdQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/56deded9-5a2d-4c5a-a84d-b531db505773.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NV",
      "name":"War Room Games",
      "countryCode":"US"
    },
    "firstName":"Taylor",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDEzZmMyMDYtMjVlOC00N2MwLWIwOGUtZDYwNzFlODBmNjY3Iiwidm90ZXJJZCI6IjYwNGI4MmZjLWViYmUtNDRmMS05MThmLTJiYjlmMGJjMTgzZiIsImlhdCI6MTU2NTk1NjMzNiwiZXhwIjoxNTY1OTU2NjM2fQ.pRfEKI5SuLGQxc63bieHM6Ju9E7SBn1JMX2Epaouc8c"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3dac846b-ad8c-4974-9fde-9d43bc30486d.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MI",
      "name":"GENIEHOBBIES",
      "countryCode":"US"
    },
    "firstName":"Theodore",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDE3NzdhYmYtMDJmMS00NWQ0LThjY2EtNTA0YTQ3ZjEzZjU4Iiwidm90ZXJJZCI6ImI3ZWU0ZjAwLTAyNGQtNDJlMi04Y2ZiLTA4Njc5YWZjYmVkZiIsImlhdCI6MTU2NTk1NjM0MywiZXhwIjoxNTY1OTU2NjQzfQ.SBro_kB5Ct8uqYQ9WQusy21ILPQQETwIPoQ6oK-nylA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bebd93ce-5d13-4af8-becf-3c12b9080412.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Windsor",
      "countryCode":"GB"
    },
    "firstName":"Paulie ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNWIwMmQ2ZTgtMjI3Ny00YzAzLWIyNmItMzFmNjE1MTIzYTQ1Iiwidm90ZXJJZCI6IjkxMDg0NjBmLWEwMWQtNDUxOC04ZDE4LTY5MzVhOWJkYzg1OSIsImlhdCI6MTU2NTk1NjM1MCwiZXhwIjoxNTY1OTU2NjUwfQ.sDO5WO8wylui_60QY1bdooANwGTmbAATSo22GRTSCVE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b1e8bbee-b1df-47ba-bfd2-dd661e44dfae.JPG",
    "rotation":0,
    "store":{
      "country":"Singapore",
      "state":"",
      "name":"Kolectiv",
      "countryCode":"SG"
    },
    "firstName":"Kwan Jin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzY3YzBkNTUtMGE1NS00N2NjLTg2YTYtZjgxMGY0ZGM4YjI0Iiwidm90ZXJJZCI6IjIzNzA1ZjM4LTk4MTAtNGIwYi05MTljLTdlZGUxMTUwMzI0MCIsImlhdCI6MTU2NTk1NjM1NCwiZXhwIjoxNTY1OTU2NjU0fQ.PD-gm5Cgs2mDeDt6HrqIRkyM0s69RXsXhml7jpwj-7c"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/49920528-b6bc-4413-a5ba-6acc1ea8db9f.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MT",
      "name":"Retrofix Games",
      "countryCode":"US"
    },
    "firstName":"Eric",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzYxYWUwOGItZWI5NS00ZjcxLTgxNzMtMjE5MDZjOWViMzgyIiwidm90ZXJJZCI6ImFkMTMxOTRhLWNiYTMtNGZmNS05YTY4LTRhMDc5Y2U1Y2Q0NyIsImlhdCI6MTU2NTk1NjM2MSwiZXhwIjoxNTY1OTU2NjYxfQ.MoeR0J0ykgrfDxDVYk3JPYoRWGC8EIsVEwFbEd6G23I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/75f74afe-65b4-440e-98a0-a4cc7f93919a.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Hobby Games Novosibirsk Sovetskaya st",
      "countryCode":"RU"
    },
    "firstName":"Natalia",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzQzN2JmOTYtMmI5Yi00ZjRhLWE3NDYtOWFjY2RhZGVkZTEzIiwidm90ZXJJZCI6Ijk3NDU0ZjgzLTY0ODYtNDQ0ZC04MjU5LTZkZWM4ODBhNjM3OCIsImlhdCI6MTU2NTk1NjM2OCwiZXhwIjoxNTY1OTU2NjY4fQ.hbDM-8W-2x1Jj7xY6TOM-tHw9KBOXySHVM_z7G8gaBY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3b25eeeb-c671-400e-babd-cb84bfaea7a6.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NJ",
      "name":"THE ONLY GAME IN TOWN",
      "countryCode":"US"
    },
    "firstName":"Sean",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGEzMjJhNmQtNzYwOS00ODVmLWI0MTQtYjEwZjA1MTUxYzMzIiwidm90ZXJJZCI6IjgwMDNjZGJmLTIxZWUtNDdkNS1hYTI5LTM1NGFhMTk2MmQyNCIsImlhdCI6MTU2NTk1NjM3MiwiZXhwIjoxNTY1OTU2NjcyfQ.Eh0VwtrJO4RgkiZwiNr4z0NhYKwvQqRexpc47MVuX4A"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e16ad159-b123-4737-8cef-7bfc39dfb452.png",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"MAGIC BAZAR ANNECY",
      "countryCode":"FR"
    },
    "firstName":"Lo\u00efc",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNWIxYmU1NTctYWMzYy00MzllLThhMjktMDE1MGZiZDJiNTM0Iiwidm90ZXJJZCI6ImIxYjJiOTY0LTQ2NDYtNDg0My1hNzgzLTFjMTY3ZTkzYTgwNyIsImlhdCI6MTU2NTk1NjM3NiwiZXhwIjoxNTY1OTU2Njc2fQ.4zBza9NAKEl8oV6G6MgaRy704A9p26N33F_BXR4xr9E"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6551c7fc-9a10-4092-8b8e-90d148b147df.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Games Workshop - Plaza Rancho Penasquitos",
      "countryCode":"US"
    },
    "firstName":"Howie",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGE4ZGZjNDAtZDM1My00NWNmLWI4MWEtOGJmMzg2ZDgwMDNiIiwidm90ZXJJZCI6IjhjMDdkNjk3LTRjMDItNDY4MS04ZWYxLWRhNmI0YTcxMDMwNSIsImlhdCI6MTU2NTk1NjM3OSwiZXhwIjoxNTY1OTU2Njc5fQ.5EdJN3UXKyTj_Ki8cHt4FOu6dgFvBMOo5hYFarnQgP4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/68679412-503e-440a-b886-040a69b55980.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Tottenham Court Road",
      "countryCode":"GB"
    },
    "firstName":"Alexander",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDdjNjA0ZmQtMjY1ZC00OGZjLWFhMDYtOGQ5NDYyNThjZjFlIiwidm90ZXJJZCI6IjE5MzgzN2M4LWE1MzQtNDU1MC05Yzc1LTE1MDk0MWMzNzRkZiIsImlhdCI6MTU2NTk1NjM4MiwiZXhwIjoxNTY1OTU2NjgyfQ.WGjZp5nKClZUnVRCpmMzzI66vva1zA3XQfSj2kwrKxE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/47d108ea-f61a-4c73-99bb-e51244dce8be.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Staines",
      "countryCode":"GB"
    },
    "firstName":"Nick",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWZjM2ViZjYtY2M3OS00OTlhLTkzYzUtOWJjZTlmODVmOTJkIiwidm90ZXJJZCI6ImY0NzMzZTYwLWJhYTItNDk5YS1hZmQ1LTI4MWQ1NjJkNjdkZiIsImlhdCI6MTU2NTk1NjM5MywiZXhwIjoxNTY1OTU2NjkzfQ.bYE3THrWZEBKX3p8JP4z7WVjNojDHLUwCV8SQ1H_URs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d605bded-9503-439f-b368-b646e8440769.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"FORGES DE LUMIERE",
      "countryCode":"FR"
    },
    "firstName":"Adrien",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWU2OGNiNWYtYTQ2Yi00NTdmLTgzODItOWZlMDdjZGE4ZGI4Iiwidm90ZXJJZCI6ImRhODY3NWNlLTY0YTYtNDQ2Zi04ODFlLWNhNzMzOWMwNTM3YyIsImlhdCI6MTU2NTk1NjQwMCwiZXhwIjoxNTY1OTU2NzAwfQ.7FgDK_HPIpC8EMWRQlGOZNkRpUEDNOJZmXre4MMO4zA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/49a22f27-78c6-4d1f-94be-f10f66a86679.png",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Warhammer - Lyon",
      "countryCode":"FR"
    },
    "firstName":"Thibaut",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjliMzIzYzEtNTVlZi00ODIwLWIyMGYtYjMxNGU3Yzc4NWQ1Iiwidm90ZXJJZCI6IjkyZDg2MWI0LWI1MzYtNDNjZS04OTA1LWM0NGIxNDU0OWI2MSIsImlhdCI6MTU2NTk1NjQwNCwiZXhwIjoxNTY1OTU2NzA0fQ.2BQAgZZDiimDjk2jbFIKgJ_lUYm6LLvpGDv5L8YXnsA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e0cfef21-edda-4424-aeae-d32ddd16876e.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Podzemelie & Drakon",
      "countryCode":"RU"
    },
    "firstName":"Igor",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODhkYjUxNDgtYzU2ZC00MGFlLTljYjMtZDM0ZjczMjM3YmZmIiwidm90ZXJJZCI6ImE1YjIwYTE5LWJhNGEtNDRiNC1hM2QyLTU3MjY4MTI1NzllMyIsImlhdCI6MTU2NTk1NjQwNywiZXhwIjoxNTY1OTU2NzA3fQ.GbKMPxZUz6G3tULMPDe2aOqIEXPQfrA45gmpXrbXa1I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/448c89c2-8298-4acf-b946-81aea51c78e2.jpeg",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"",
      "name":"HOBBY SHOP Arrows",
      "countryCode":"JP"
    },
    "firstName":"Kazuya",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGUyYzc1NjYtOGFiYy00MDk4LTg1OTMtNmMwMjNiOGQ5OTIyIiwidm90ZXJJZCI6IjYxYWM5YzUzLWRlMzgtNGQ0MS04NThlLTk4ODY3MTlkMjhkYSIsImlhdCI6MTU2NTk1NjQxMCwiZXhwIjoxNTY1OTU2NzEwfQ.pc9hhGvX2nfvP1keRewFAEfVU7sYQg85m6cOcgJ93Ss"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0b34c347-f3fb-49ac-bf87-d4523aaf0de9.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Basildon",
      "countryCode":"GB"
    },
    "firstName":"Pete",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmQ4OWNlYTgtOTIyNi00ZjYyLWJiYmQtODNiOWQ5MmNiZWVhIiwidm90ZXJJZCI6IjQzZDJlMjY2LWM1N2MtNDZlNC04NGQ1LWFlYmU2YjA0MmEzMyIsImlhdCI6MTU2NTk1NjQyNSwiZXhwIjoxNTY1OTU2NzI1fQ.-qNKc3MXgdyYydvFjjttmePP276R73U7xEYTNBn6rgs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a46962a9-ed04-4484-a5f3-7714cc336d0a.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Wurzburg",
      "countryCode":"DE"
    },
    "firstName":"Simon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjVmNTdlYzctMDYzYy00N2RmLWE4OTAtNTU5OTNjMmVkYjY4Iiwidm90ZXJJZCI6IjkyN2MxOTFmLTZhMjgtNGM4Yy05NDI3LWE2YWZjYTU4YjM0MCIsImlhdCI6MTU2NTk1NjQzOSwiZXhwIjoxNTY1OTU2NzM5fQ.iteNJ_Bm433QQoniZqmQXH3XrR-Ob1lqnUJvRm3SexY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8d19135b-93e0-4c97-a1a2-4ae0520b0785.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"",
      "name":"General Games Pty Ltd Malvern East",
      "countryCode":"AU"
    },
    "firstName":"Harrison",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDI0MGE3OGQtYmY5ZS00Nzc4LTg5NzUtODBjYTg5MDZmMjgzIiwidm90ZXJJZCI6IjZjYmYyNmVkLWFlYjctNGQ0Ny04OTE1LWY5NDhjNTgzZGJiZiIsImlhdCI6MTU2NTk1NjQ0NiwiZXhwIjoxNTY1OTU2NzQ2fQ.M6VVf4xA9DWkUdihvUmCwdbppDRe9kDcU_k_xBah7eQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0f535d69-d145-41d3-866d-3b2be7e5c230.JPG",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Chemnitz",
      "countryCode":"DE"
    },
    "firstName":"Alexander",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDRlODJmNzctNzYyMi00ZTRiLWI3YzAtZjVjMTczMzA1NzgzIiwidm90ZXJJZCI6IjU5MDIyMzJhLWI3OTQtNDUyNy1hNmRiLTE1YTFlZTE5YmZhZiIsImlhdCI6MTU2NTk1NjQ1MywiZXhwIjoxNTY1OTU2NzUzfQ.o0D8e6e5ezOQp6c12G5ABNUzciifX9YMUa0_dhiH-xI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ae8adb04-9e96-467b-9ed6-bb8cbe9db913.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TN",
      "name":"Warhammer - Turkey Creek",
      "countryCode":"US"
    },
    "firstName":"Victor",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDlmNzY1NzgtM2Y0Yi00MzFiLThjMGYtNzlkYWE0Mzc4MmMyIiwidm90ZXJJZCI6IjJiZmY0MjMxLThmMzQtNGYxNC04MWJkLTllODY2YjY5MzM3MyIsImlhdCI6MTU2NTk1NjQ1NywiZXhwIjoxNTY1OTU2NzU3fQ.FyXc2R2hZV6QQ7rvReuPL4dlImAXO2qEvR1H8DuCG8k"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/16d18ac8-ca8a-4f1f-8e01-8c26d9b12271.jpeg",
    "rotation":0,
    "store":{
      "country":"China",
      "state":"",
      "name":"Warhammer - Xinhua Road",
      "countryCode":"CN"
    },
    "firstName":"Qianwei",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGNiOWI5NGQtNjcyYS00ZDdlLWJlZjYtNTNlODhjYmVlY2I5Iiwidm90ZXJJZCI6IjdkZWE4NmIwLTFkYjQtNDk1Zi1iZmM3LTcwMzRhZmU5ZDU3MyIsImlhdCI6MTU2NTk1NjQ2MCwiZXhwIjoxNTY1OTU2NzYwfQ.L0LXyF__v2sT65Iop18fkKeVCgXPfzZWW7BZZpAO58g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4d0c1b17-63fd-45b5-9f48-b3b549f9aad3.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"ON",
      "name":"Games Workshop - Langstaff Square",
      "countryCode":"CA"
    },
    "firstName":"Murray",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2ZmYWIyMjEtOTIyNC00NDI4LTlmODEtOTU3YzI5MThjNjg4Iiwidm90ZXJJZCI6IjljMmU3OThhLTgwODItNDk0OC1hMGVlLWUzNzVlMzRlZjRhYSIsImlhdCI6MTU2NTk1NjQ2NCwiZXhwIjoxNTY1OTU2NzY0fQ.zMKjPRVzgP-0a0tkkqlu1XQfLoLv89CyVTFc0G0S3aU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/88547b2b-e5ab-442f-ae3c-75976f4530b5.jpeg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"GAME IT SPIELWAREN",
      "countryCode":"DE"
    },
    "firstName":"Mathias",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjYwMThkYTctNmQ3OS00MWU1LWE0NDEtMzYxMzY4YTJhYzVlIiwidm90ZXJJZCI6ImFlMTcxNDQ4LTM0NzEtNGM3MS04OWM2LTkwMWYzOGFhNWYyOCIsImlhdCI6MTU2NTk1NjQ2NywiZXhwIjoxNTY1OTU2NzY3fQ.quXdms8BBKFrNPM71ClmQ9T9obflQHmXegXi78cA2cM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/39582154-7b2a-47dd-bde2-bc9d160a51d5.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Grenoble",
      "countryCode":"FR"
    },
    "firstName":"Alessio",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjc5MTAwNDQtMGVkZS00YjQ4LWExMTQtYTY2ODdjMTIyNzc0Iiwidm90ZXJJZCI6Ijk3NzYyNGMyLWZkOGQtNDk3Ny05MmYzLTk5YjY2YjgxYTEzNSIsImlhdCI6MTU2NTk1NjQ3NCwiZXhwIjoxNTY1OTU2Nzc0fQ.m27THq8S7FMZDLQmi3HR-xNI9FkZI26nmS8jA2T0BRY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e103d940-1f88-40cc-b1bd-aeef5edc64e8.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Cwmbran",
      "countryCode":"GB"
    },
    "firstName":"Martyn",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGNmZjNiNDEtNDAzMS00OTVhLTlmODItYjcyZjg1NWQ2MDhkIiwidm90ZXJJZCI6ImQxOGUxMzQ2LWVlMTctNDk3Ni04YmUyLTY0Y2QzMzFlZTcyNCIsImlhdCI6MTU2NTk1NjQ3OCwiZXhwIjoxNTY1OTU2Nzc4fQ.RaI8hXHkgv0heWqFD1fpgIdXbsfID1az_GksiIPxZwM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/49c5f9f4-36f0-4c70-8867-810863640b9e.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"DICE SALOON",
      "countryCode":"GB"
    },
    "firstName":"PAUL",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjQ3NmE5Y2EtYjRkOC00YzU2LWJlODktNjE2ODAzMjQzMjdkIiwidm90ZXJJZCI6IjYxNzg5MmIzLWFkMzUtNGIxYi05YjhmLTI2MDFlZTcyZTRiNSIsImlhdCI6MTU2NTk1NjQ4MSwiZXhwIjoxNTY1OTU2NzgxfQ.YW0iIR0PpH9vLlcydvQroajKBm9dAQRl9FPCF3I3b6g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/11dd8ea5-9521-4ff2-8508-6e54944e5b07.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - High Wycombe",
      "countryCode":"GB"
    },
    "firstName":"Paul",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNWQ2ZjBkNjItOTE5OS00NDhiLWEyMWEtMDlhYWIzYTYyODJlIiwidm90ZXJJZCI6IjU0YTM4YzA5LTIzZWEtNDA5Zi1hNGEyLWMxMmFiNmMwMjZkMSIsImlhdCI6MTU2NTk1NjQ4NSwiZXhwIjoxNTY1OTU2Nzg1fQ.EgvvD0TUoTaCUstYmf427HwPTOQh8YM1JUH3UbUpAvo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ea155140-9d99-4d38-ac4b-64cf169ab903.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IL",
      "name":"Warhammer - Collinsville",
      "countryCode":"US"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDQzYWU5MjctY2EzYy00YzBmLWE2MzgtZWZmODUwMmZjYjc3Iiwidm90ZXJJZCI6IjZjYWNhZTcwLWY1MTEtNGQwZi1hNmJjLTg2YWQ5ODMwMjAyOSIsImlhdCI6MTU2NTk1NjQ4OCwiZXhwIjoxNTY1OTU2Nzg4fQ.0El5j7AxBBSjDuUGuZEetCGA63hHh3LPwDSQ1OvTSbM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0c7257fd-15b5-48e1-bbba-cd5a1eaacaf5.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Pforzheim",
      "countryCode":"DE"
    },
    "firstName":"Bjoern",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDZkMTBmZmQtY2I1Zi00OWIyLTg0MDQtY2EyN2ZkYzg1MWM0Iiwidm90ZXJJZCI6ImMzYzk5NzVlLTA3NTMtNGY4Zi05M2UzLTkzMmZhYTQ5M2Y0OSIsImlhdCI6MTU2NTk1NjQ5MiwiZXhwIjoxNTY1OTU2NzkyfQ.FIc_k1hsPmqIVlNG0RBflDNsixHvQsn3kgy-F8ZDG_g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fe9c2f27-04fb-4d2f-adb5-6592357ac173.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"CM GAMES INC-KNOX CEDAR BLUFF",
      "countryCode":"US"
    },
    "firstName":"Jen",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTA3MDQ0OWUtMmJlNy00NGFhLThkNWQtODExMGRkZGNkNWNjIiwidm90ZXJJZCI6ImNiM2Q5OWFhLWE4YzAtNDIxNy1hYTIyLWQyMGVmNDJlYzQxNiIsImlhdCI6MTU2NTk1NjQ5NSwiZXhwIjoxNTY1OTU2Nzk1fQ.t9L83nsnM0IV9l9NR6-RboW9IPc99crLODonJZKMvwY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d3d588b8-44d1-4634-ab42-081820c01700.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IL",
      "name":"CHICAGOLAND GAMES",
      "countryCode":"US"
    },
    "firstName":"Dustin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmIyZmZhNzQtM2NlNS00YTdlLTkxMzItMDc2ODgwZDFhMjlhIiwidm90ZXJJZCI6IjhhYTYxYTJjLTc3YjEtNGIzZC05NmFlLWMyYTQzYTk3MzQ2MyIsImlhdCI6MTU2NTk1NjQ5OSwiZXhwIjoxNTY1OTU2Nzk5fQ.zX8fLZznoU22vYyRcJRvU5ch0NRlCri_KkMfjF2Lts8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/da56a0dd-9b4a-4a5b-bb97-87a5cb5adc9c.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Peterborough",
      "countryCode":"GB"
    },
    "firstName":"Ben",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWZhN2UxNGUtODIzNC00YTQ2LWJhMWEtMzJjNjUyNzFlZDBmIiwidm90ZXJJZCI6IjA2MjBhZGQ5LWJiZWQtNGZkMy05ODQxLWZlNThhZDUxMTMzOCIsImlhdCI6MTU2NTk1NjUwMiwiZXhwIjoxNTY1OTU2ODAyfQ.upufJI2SNoUuDgZjIkj4iqUXvCExVK0RmTKvWXXe1Q0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d5d7920a-d75b-40ff-b1a9-eb31a8d30cae.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MA",
      "name":"Warhammer - Franklin",
      "countryCode":"US"
    },
    "firstName":"Travis",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDA2YmYyMWMtMzdkYi00OWM0LTk4YWYtZjcwYjEwZTRlZGNjIiwidm90ZXJJZCI6IjVjMTVkYjdjLThkOWEtNGVlMy1hOWJlLWZmODU4YzlmODIzOSIsImlhdCI6MTU2NTk1NjUwNiwiZXhwIjoxNTY1OTU2ODA2fQ.o3CwxrV3owwScZNCMztjZhSpl2-h_OU3ec9LG-P8rkA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e4be0b7e-f050-4d42-a988-d210b0fac166.jpg",
    "rotation":0,
    "store":{
      "country":"USA",
      "state":"",
      "name":" Games Workshop - Silas Creek Crossing",
      "countryCode":"US"
    },
    "firstName":"Ryan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWI0ZDY4OWMtNWQwMC00ZTNhLWI2YjUtZjIzOTU5NGQxZGJlIiwidm90ZXJJZCI6IjUwMzUyZjdmLWI3MGUtNDI0NS1hODgwLWM0ZmIxODA2NzBlMSIsImlhdCI6MTU2NTk1NjUxMCwiZXhwIjoxNTY1OTU2ODEwfQ.agEqk7MPEgMr5PxF3DkXyDNLmMNr7bqsJx8EaW3oon4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/dedcd2ec-a533-4c71-b5bf-859585ef92ea.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CO",
      "name":"Warhammer - Fort Collins",
      "countryCode":"US"
    },
    "firstName":"Jesse",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzdkODY5ODctZTMxYi00YWQ5LTk3YjYtZmU2MTNmZjI0NTdiIiwidm90ZXJJZCI6ImUwMWM1M2I0LTE0YzAtNDg2Yy1hNDIzLWIxZTdmNDU3OWQzMiIsImlhdCI6MTU2NTk1NjUxNCwiZXhwIjoxNTY1OTU2ODE0fQ.d8dyNamf_ixKl3Lg1SoZcCVPEBXk7jWMsXW-9KnbmsY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/68849060-96d8-4623-84bc-164e00e7f1b7.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"KS",
      "name":"Warhammer - Lawrence",
      "countryCode":"US"
    },
    "firstName":"Denny",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTJiNDEwNDItN2U2Mi00M2I5LTk5ZjItYmEyZjcwYWNhOGJlIiwidm90ZXJJZCI6ImE3YmE1OTYxLWZlYzQtNDAwNS1hM2VmLTZhM2QxODNjYmI4MSIsImlhdCI6MTU2NTk1NjUxOCwiZXhwIjoxNTY1OTU2ODE4fQ.4nxRL2gA19x11pob0HmVe9CWJcYxmqagfB97rtelP3I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/53cee312-b1e2-424b-a935-7bfe0332c65d.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Warhammer - Manhattan Beach",
      "countryCode":"US"
    },
    "firstName":"Ilya",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2E0OTQzODYtYzNjYS00NzUxLTlkMGEtZjUwMGY2MTQ3MDUxIiwidm90ZXJJZCI6ImExODdmYzJmLWYzZmUtNDhiOC05NjUyLWQxMmU1YzJiYWVlMyIsImlhdCI6MTU2NTk1NjUyMSwiZXhwIjoxNTY1OTU2ODIxfQ.dRz4HoozVvO6GSm3zXKNQj5jKdoQT3QZXWnsIDnYEBc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e1437052-339e-401f-9fb6-328a57aef695.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"GOBLINTRADER ZARAGOZA",
      "countryCode":"ES"
    },
    "firstName":"carlos ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNWVmNTZhNTgtMzA3Ny00MGZjLTgwYTgtYjkwZjU3MjBjZmVmIiwidm90ZXJJZCI6IjBlOTg3YTExLTUwOGEtNDVhZC05MTk4LTMxZGM5ODNlOGIyNiIsImlhdCI6MTU2NTk1NjUyNCwiZXhwIjoxNTY1OTU2ODI0fQ.RSEgb6RNVuPOivVCfrQ4B45zEQA1J9mojQhu1ozIegY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8621420b-3c37-452d-8c1d-01851e6f5f59.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Hobby Games Moscow Dmitriya Ulyanova st",
      "countryCode":"RU"
    },
    "firstName":"Egor",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWVhNjdjMjYtMTRkYy00NmJlLWJhOTQtMDUzZmI1MGUyYWFmIiwidm90ZXJJZCI6IjRjNzQ2ZjAwLWEwNDAtNDE0MC05MjFlLTg0NWJlMGEyZDFlYyIsImlhdCI6MTU2NTk1NjUyOCwiZXhwIjoxNTY1OTU2ODI4fQ.ZCZn42xkNcb2QmzxzHy7VlfIXdC8KUUyLOD9ezvbbrU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/68093c39-8aff-4020-84b2-81da5bed3a99.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IL",
      "name":"Warhammer - Glenview",
      "countryCode":"US"
    },
    "firstName":"Joe",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTY3MWU5ODEtZjg5MS00ZTAxLThmMmEtOGFjNDdkYzBkM2FmIiwidm90ZXJJZCI6IjMxNTk2ZDI4LWIyZDAtNDZmZi1hZGRlLThiOWQ3NDBlZTgzMSIsImlhdCI6MTU2NTk1NjUzMSwiZXhwIjoxNTY1OTU2ODMxfQ.jvMqN6pTRrA8KqSres9uqUe-sC5H4B4Pb5T4_s5G4EI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/399de385-bb96-4062-b1ed-c0edb0de6a2d.jpg",
    "rotation":0,
    "store":{
      "country":"Slovenia",
      "state":"",
      "name":"Trgovina Crna Luknja",
      "countryCode":"SI"
    },
    "firstName":"Andrej",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDJlMjMxZWYtYjk4YS00N2VhLWEzOWItYTcxZDA0NmU1ODM4Iiwidm90ZXJJZCI6Ijg5ZWU2MDYwLTcwNjgtNDU4ZS05ZWQwLWE4ZWVkMWI2NjlkZCIsImlhdCI6MTU2NTk1NjUzOCwiZXhwIjoxNTY1OTU2ODM4fQ.KOMlMbbVjX-BxNb5QPHbd16ggg2Z5AJ7ymDx5PrjSFw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7489be91-bfd1-4546-be34-4ca4c00634ef.jpeg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"TAS",
      "name":"Games Workshop Launceston",
      "countryCode":"AU"
    },
    "firstName":"Evan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGE1ZTMyMjEtZTNhYy00MDE2LThmOGQtMTI4NjhiOTdjOTBmIiwidm90ZXJJZCI6IjE5ZTZhMjlkLWQxM2ItNDk4OS1hNTI0LTAzNzcyMDc0OGQwOCIsImlhdCI6MTU2NTk1NjU0NiwiZXhwIjoxNTY1OTU2ODQ2fQ.EbF33QEMP9CCbQUzH1hq2Ubw3A6yZKsPPs8xf2y9v-k"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/37c58d77-2251-4b9d-a7b6-809d3e792e58.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Chesterfield",
      "countryCode":"GB"
    },
    "firstName":"Tomasz",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmYxNmM5ZGEtZGJiMS00NTQ1LWE1OGUtZmI4ZTE0YTYxZjA5Iiwidm90ZXJJZCI6IjQwYjE3YzMwLWU1OTgtNDU0Ny1hODhmLTIzNTY3N2EyNzRkYyIsImlhdCI6MTU2NTk1NjU1MywiZXhwIjoxNTY1OTU2ODUzfQ.26oXqgpJl5HYTmUIk_XiVSSEBGp2cHYFEJEBoFcemwM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7aae1830-954e-4623-9b04-e15a8dd24737.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Games Workshop - Kettering",
      "countryCode":"GB"
    },
    "firstName":"Michael",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDJkOTg0YjYtN2IxNC00NjVlLWE1YWEtZTQzMThkZTBhZTMwIiwidm90ZXJJZCI6IjhhYWNkMTU4LTQ3YTItNGYzYS04MDM1LWU2ZWIyNzkxZjA0ZSIsImlhdCI6MTU2NTk1NjU1NiwiZXhwIjoxNTY1OTU2ODU2fQ.vp7tEKUwWewn3Kdzrjhe-tSXqM3dFXUrvHQ1i09PZmI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a830bb04-9d20-4b45-8fee-6cca5420b865.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MA",
      "name":"Warhammer - Brookline",
      "countryCode":"US"
    },
    "firstName":"Ryan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMThkYTBmMDYtZDQ3OC00MzVjLThlY2YtOGJjOTJiNzJmYTMwIiwidm90ZXJJZCI6IjhhOTUxMTQyLTIwMTQtNGIzZC1hYmZiLWI4ZTVkZjk5NTY3YSIsImlhdCI6MTU2NTk1NjU2MCwiZXhwIjoxNTY1OTU2ODYwfQ.tr0PebIFYztZS9OjeWWufv0N5qkV2Pd8j15DNjCAgfs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8eba66f2-9481-47a8-9d3b-2f069762ae7b.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"ON",
      "name":"Games Workshop - Heartland Town Centre",
      "countryCode":"CA"
    },
    "firstName":"Michal",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDdkZTc1YmItMTBiZS00NmQ0LThhNGQtNjQ2ODhkMGRlMTdiIiwidm90ZXJJZCI6ImExMWYxMjUzLTRiMDgtNDQ4Ny1hZDc1LTg5ZDEzMDkxNTUzZCIsImlhdCI6MTU2NTk1NjU2NywiZXhwIjoxNTY1OTU2ODY3fQ.mdV43AAuU4mJcbLPyHialxkB7FxeIfyJQsthMUY31pk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/98070ee9-009e-4b45-9fa1-ddde4f627a15.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"WA",
      "name":"Games Workshop Perth",
      "countryCode":"AU"
    },
    "firstName":"Brett ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGRlN2M2ODgtNmQ0Ny00MzAxLTllMzEtZThiYzAxN2JlNzcxIiwidm90ZXJJZCI6IjQ3NjBjZjJjLWY3YWYtNDA4Ni1iMGVjLWZkOTc3MTJmNzMyYiIsImlhdCI6MTU2NTk1NjU3MCwiZXhwIjoxNTY1OTU2ODcwfQ.Mb6CNvQdPyrvFFy7KQuZsXv5BWZJBMeMZ44yU0-ZoxQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f008bda3-3563-4101-8542-2f16e77f64bc.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WA",
      "name":"Games Workshop - Freeway V",
      "countryCode":"US"
    },
    "firstName":"John",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTQ3NTVmNzUtNTZiOS00MzYxLWFmYjMtMjQxN2U0MzBjNDkwIiwidm90ZXJJZCI6ImNhNjg4YTA5LTdkYzQtNDY1YS04NjdjLTZmZmRmYTkxYjg4NSIsImlhdCI6MTU2NTk1NjU3NCwiZXhwIjoxNTY1OTU2ODc0fQ.7oNQhIluz6HUK92W61pm3UHRKX-VRz8S0PDTmhySQ-E"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/60d9aa2e-ca61-4420-85e7-fe8861142df8.jpeg",
    "rotation":0,
    "store":{
      "country":"Austria",
      "state":"",
      "name":"Warhammer - Wien Gasometer",
      "countryCode":"AT"
    },
    "firstName":"Franz",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTgxMTQwYTUtNzZjYy00MDkxLTk1YzQtZmM2NmVkZjYwNTdkIiwidm90ZXJJZCI6IjJmZjhiYTE3LTg1ZDctNGFlZC04N2I5LTBhNGUwZDFlYzVkZSIsImlhdCI6MTU2NTk1NjU3NywiZXhwIjoxNTY1OTU2ODc3fQ.-HZ0COMTbIh8XANE34XvstKqU2HeevHSXft542p8c08"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1ae5ac6e-8024-4cd2-90d1-061a2c429b63.jpg",
    "rotation":0,
    "store":{
      "country":"Switzerland",
      "state":"",
      "name":"L'ORNITHORYNQUE SARL",
      "countryCode":"CH"
    },
    "firstName":"David",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGU4NGM5MWQtODk2MS00NWQzLWJiOGYtYmY3NjkwOGFlNGVkIiwidm90ZXJJZCI6IjcxNjUzMDdjLTkxY2UtNDE2Ny04MGU3LWQ5ODdkZTFlMTljNyIsImlhdCI6MTU2NTk1NjU4MSwiZXhwIjoxNTY1OTU2ODgxfQ.nZkP9KnwsKz3dMg_ZJCT6jstN5Lx1kKHlawPOj_x2YE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cc9296a0-c2b6-4e64-8841-2be9801dad89.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"THE GAMERS EMPORIUM SWANSEA",
      "countryCode":"GB"
    },
    "firstName":"Greg",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2Y2OWRjNjUtN2ZlMC00NDQ4LTg0MGMtZGUwZTJjNGJkYjIxIiwidm90ZXJJZCI6IjM5YjM5YjUwLTA5ZjctNDkzMS04YTQ4LWJmNGNiOGZjOGNiOCIsImlhdCI6MTU2NTk1NjU5NSwiZXhwIjoxNTY1OTU2ODk1fQ.SB1Kf2xhfVP3tJCoL8owh2FlGY2l3Sb44UGKpHMSQbE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/482af40b-e056-4968-8f2c-98eafa31d99c.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Dudley",
      "countryCode":"GB"
    },
    "firstName":"Harley",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTQyYjcwNzAtOTVkMC00OWU3LWEyYTAtMWJiMzU2YzAzZmUyIiwidm90ZXJJZCI6IjM3NTUyY2E2LTE5MmUtNGQxYS04Y2ZjLTJhYWYxZDNlMzNmZiIsImlhdCI6MTU2NTk1NjU5OCwiZXhwIjoxNTY1OTU2ODk4fQ.lsslzrkm4lmEV35Ces1xFJ7ewiI_mdNnCZwYdqge2JU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/029c1c9d-bc6f-49c2-b611-6b0432c1879d.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Tres Aguas",
      "countryCode":"ES"
    },
    "firstName":"\u00c1lvaro",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTIxMDk5Y2EtMDZmMy00OTc5LWI2YzItOTVlYmY3ZDZhMGVhIiwidm90ZXJJZCI6IjdlOTlmYTFmLTYxNmQtNDY3Yy1iNmQxLTMyMWQ4OGNkMmIwMSIsImlhdCI6MTU2NTk1NjYwNiwiZXhwIjoxNTY1OTU2OTA2fQ.WtAsGChs5wMDBPgAlJxckZJLOwuZFM1fFzb-HZ0Wkuw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2ca87e81-80fe-4a7a-ade4-984c4ea5116e.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Edinburgh",
      "countryCode":"GB"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjgxNGQxMDItNzg4YS00N2Y4LWExN2UtMzc2ZmI4ZDBmODcyIiwidm90ZXJJZCI6ImY3MzA4YjgzLWVhNjUtNDQ4YS05ZTk0LWFkNTc3OGNiOWRmMyIsImlhdCI6MTU2NTk1NjYwOSwiZXhwIjoxNTY1OTU2OTA5fQ.vYYDMEfO-v0FrCer0c_RMrdHewWBstUv1ESr8t-RdSc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/247b327b-31c5-44ad-b5b8-4882f9f10324.jpeg",
    "rotation":0,
    "store":{
      "country":"Netherlands",
      "state":"",
      "name":"Games Workshop - Groningen",
      "countryCode":"NL"
    },
    "firstName":"Raven",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGU4OGUyYjItNGQ1ZC00Njg2LTk1MzYtMDg1YmNmODQ4NjI2Iiwidm90ZXJJZCI6Ijc3MGIwYzQ0LTUyN2QtNDZiMi1hYjAxLWYzZjEwYzU2ODM5MCIsImlhdCI6MTU2NTk1NjYxMiwiZXhwIjoxNTY1OTU2OTEyfQ.TfxaDNW6weXoNz0ioIzt2L8EFVsrvdcCv4KjPyRksmw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/30716b6e-0021-4cde-b4ef-f4382bcca1b2.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NE",
      "name":"THE GAME SHOPPE OMAHA",
      "countryCode":"US"
    },
    "firstName":"Matt",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDk0MWI2NjktYjJkMy00YTE2LThkNWEtYWJlNWQ5ZmZhNzM2Iiwidm90ZXJJZCI6IjYzN2JmZmRjLTQ3NDktNDA1Yy04ZTkwLWIyZGIzZTM0ODFkMyIsImlhdCI6MTU2NTk1NjYxOSwiZXhwIjoxNTY1OTU2OTE5fQ.uq0pzEF4Enpf60E_g1cUVqYnkZmogDoo11yBAuMj5ck"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/914ecc18-392e-403f-b28e-505b69e9e004.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IL",
      "name":"Warhammer - Elgin",
      "countryCode":"US"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGYzMzk4NTEtN2U4Ni00OTFhLWE5OWItZjdmYTYwM2E1NDIxIiwidm90ZXJJZCI6IjZkMjMzNDg0LWYwNzctNGQ0NS05MzM5LTMxM2NlYWIwOTk3YyIsImlhdCI6MTU2NTk1NjYyNywiZXhwIjoxNTY1OTU2OTI3fQ.dvL26ZkVCtJCu3Hks2SwqNtiNk71YLu7EvAbxCElW9o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7907c63b-3380-455f-a019-c3f903db008e.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"VA",
      "name":"Warhammer - Richmond",
      "countryCode":"US"
    },
    "firstName":"Chelsea",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGJhYjQ3ZDYtNjA0NC00MmRmLWFmMWEtNDRhZjllZTRiNzExIiwidm90ZXJJZCI6IjA1ODk2YWU5LTRjMTEtNGVlOS1iZGUwLWZhOWMzNDg3YmI2YiIsImlhdCI6MTU2NTk1NjY0MSwiZXhwIjoxNTY1OTU2OTQxfQ.ztKccoGhfL9vaoq9ohMxSc0X_nHgpXRjZtvm9y_AYt4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fa6e64b6-b766-472a-ba8e-02c281616f2b.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Worcester",
      "countryCode":"GB"
    },
    "firstName":"Ben",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTg2MTBmNzQtNzkyZi00YzY3LWJjMzctZjcyZDQwNzZhYzgyIiwidm90ZXJJZCI6IjZhNDNkYzMzLTNjMzUtNDYyMS1iYmQ4LWM5ZjMzZWY1OGI0OCIsImlhdCI6MTU2NTk1NjY0OSwiZXhwIjoxNTY1OTU2OTQ5fQ.DS-AZ4SCXyTUKfXhN7G16llhu4Z_Dn4ZIFHb-mo22gQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a587489f-6d91-40ed-96af-ff289ea55ec5.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Paderborn",
      "countryCode":"DE"
    },
    "firstName":"Lars",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYThlYjk5ZjMtMTFhZi00MWFiLWE0NTMtY2YyNDNhYTA4NmNmIiwidm90ZXJJZCI6ImViOWQzODZhLWZhOWMtNGQ5ZC1hMWI2LWQ1NGVlMTY3YzViOSIsImlhdCI6MTU2NTk1NjY1NiwiZXhwIjoxNTY1OTU2OTU2fQ.FeD99VuTzjUxGi4raSZ4FhX4Koi0S_PGcFj8tIGdoz4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d3fed89d-b326-48a8-93aa-3725dfee12e2.jpeg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"ON",
      "name":"Games Workshop - Durham Centre",
      "countryCode":"CA"
    },
    "firstName":"Justin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzMzYTc0NDktM2UwOS00ZjM3LWE4MjYtMTE2ODBlNmZkYTM5Iiwidm90ZXJJZCI6ImQ3MTIwNDQ3LTI1MDctNDgwZC05MDhiLTQ3N2Y4YWNlN2MzNyIsImlhdCI6MTU2NTk1NjY2MywiZXhwIjoxNTY1OTU2OTYzfQ.R-9z-tqLOV363yfQb4PSZqeamG2TAXgz7JIY9884iK8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a8b8298e-2c6e-4646-bc03-f5e7e502097b.jpeg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"",
      "name":"Red Claw Gaming",
      "countryCode":"CA"
    },
    "firstName":"PETER",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2I1Mjc1MzItZWQ3Zi00MGZiLWFmZmYtM2NlMjVlYTQxNTQ2Iiwidm90ZXJJZCI6IjkyZTc0YjZiLTJhZjMtNDE5Yy04ZTFhLTRjNzhlNDJhM2FmNCIsImlhdCI6MTU2NTk1NjY3MCwiZXhwIjoxNTY1OTU2OTcwfQ.eQmQvcyEBVLuGDrN2cEn7rjGd5DUX0qBJ3yVp35VLFM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f2e16203-f248-42fc-914e-3d582dc0b841.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Sheffield (Central)",
      "countryCode":"GB"
    },
    "firstName":"Bobby",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjBkOWU1MjQtMWFiYS00NjAyLTgyNzMtNmZhYTY4NjcyYWIyIiwidm90ZXJJZCI6IjljYjEzODM4LTYyNTItNGJjMS05OTQwLTZjNmIwMWIyMTYzNyIsImlhdCI6MTU2NTk1NjY3NCwiZXhwIjoxNTY1OTU2OTc0fQ.GptluerJeSYpVTWTaOBJnDsBxWtzKJ7ogocZMTFLO2Y"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9ef5f818-86fe-49f4-9bf3-b216852e82b6.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IL",
      "name":"FOX COMICS AND GAMES",
      "countryCode":"US"
    },
    "firstName":"Jason ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDFhZDQ0MTYtNWUzOS00NDRiLTljZTYtYTIzMzk4OWJjMzdhIiwidm90ZXJJZCI6ImY3MTNmNGU4LTZhMGUtNDUzYy1hY2ZkLWM5Yjk4MWNlMGIzZiIsImlhdCI6MTU2NTk1NjY3NywiZXhwIjoxNTY1OTU2OTc3fQ.bdC48Zh4lq_u8O5MxCDGcVLHEFDkp2xIr7ckWLcg0yk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1356c610-0993-4024-89bd-ac3afe9d793c.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Carpe Diem Comics",
      "countryCode":"US"
    },
    "firstName":"Will",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGQ3ODBjYzgtMWZjZC00ZDA4LTg3ZmYtZGFhNTY5MzhmYTg0Iiwidm90ZXJJZCI6IjU1YmU0ODg4LWE0NmYtNGJiZC1iZDYwLTUwMDYyYWI1NjM5NiIsImlhdCI6MTU2NTk1NjY4NCwiZXhwIjoxNTY1OTU2OTg0fQ.VzYgqTpfbnO-i_PvWyv8MTb4RcxwhMME1cQp49F7OEo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/db73e532-3d0e-48eb-b380-e7f6b827d7d2.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Games Workshop - Village Center",
      "countryCode":"US"
    },
    "firstName":"TJ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzg5NTVmNjEtMWNmYS00YzVkLThmMDUtMTM0ZjhhODY1ZDU4Iiwidm90ZXJJZCI6IjBlYTY0NDY1LWRiNjQtNDk2ZC05NTY2LTQ5MTE2ZjNhODcxMSIsImlhdCI6MTU2NTk1NjY5MSwiZXhwIjoxNTY1OTU2OTkxfQ.5rM5AWs-j7g-02QLH3RXyyy2MjrAt1O2zPSCLN8xg3g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f993cc47-7cd9-423e-8b93-9d5c87464c1d.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Warhammer - Wollongong",
      "countryCode":"AU"
    },
    "firstName":"Christopher",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWM4NTcwYTEtMzI5Yy00NDg1LWEwZDgtZDgyZTc5ZTQ2OWViIiwidm90ZXJJZCI6IjEyMWU2YjI0LWEyOWEtNDJjYy1hOThlLWQwOWE0NGQ4ODNjZCIsImlhdCI6MTU2NTk1NjY5OCwiZXhwIjoxNTY1OTU2OTk4fQ.TnwbUKMm7tkp5LF8P01WVBaOZhwqNFoKpXZ2TJchb7Y"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b3e2feaa-cbff-483f-bac7-e980a97abef4.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"VIC",
      "name":"Games Workshop Geelong",
      "countryCode":"AU"
    },
    "firstName":"Julian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzQ1YWNiODMtODA4OS00YjZiLWI1NDgtNDI5MWFiZGZkNDg5Iiwidm90ZXJJZCI6Ijc3ZDE2MzIxLTgxM2UtNDY0Yy1iZjE1LTdhMzg1OTQ5NDMxNyIsImlhdCI6MTU2NTk1NjcwMiwiZXhwIjoxNTY1OTU3MDAyfQ.jojAuDU6Hcev177x7uwXHHfaRWaINd1s76P-iyEr4Tg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1e271254-9705-49b7-a67f-7ec420fc47c6.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"ND",
      "name":"PARALLAX GAMES",
      "countryCode":"US"
    },
    "firstName":"Joshua",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmZhZmFiMzAtYjJiMS00MTllLWI0N2EtNGE4M2RjYjMyMTc5Iiwidm90ZXJJZCI6ImI2N2U5Y2MzLWMzMzYtNDA1Zi1iMjRmLTA3N2VhYWIyNDU3MCIsImlhdCI6MTU2NTk1NjcwNiwiZXhwIjoxNTY1OTU3MDA2fQ.M0unfQ1EL8A5cLZz69Sp7LpAhiMAYRvfyDiHlMX12Rs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/379000a6-e6e6-449b-bc2e-89ea06c2e674.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Warhammer - Modesto",
      "countryCode":"US"
    },
    "firstName":"Jon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmY1ZTI5MTItMDVkMC00M2EzLWJlZGEtZjQ2OWRhMDc4ZDEzIiwidm90ZXJJZCI6IjUwMmQzMzZlLTg3NGQtNDgzMS1iYTIwLTczNGI3ZjFhMWVkNyIsImlhdCI6MTU2NTk1NjcwOSwiZXhwIjoxNTY1OTU3MDA5fQ.zTW6CJDrW_p-LL1w-B97wCUVrF6jspal2j7vnpKcAHw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ad0e6435-079a-4f81-8367-389c5f4bb544.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Isle of Wight",
      "countryCode":"GB"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzU0NGRkMzYtMmIyYi00MTJkLTg4ZWItZTFiZGJkZGQ0NzQ3Iiwidm90ZXJJZCI6ImQzM2ZhYmRlLTJlZmYtNDc5ZS05YWVhLWVlNzEzZmExOGU3NyIsImlhdCI6MTU2NTk1NjcxNiwiZXhwIjoxNTY1OTU3MDE2fQ.8PjCsLPwSc8ySS6dqeCrgOckJt55skKbaodRoTGGGNQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5fa867a1-2543-47a7-ba3a-d34cb477f3bf.jpg",
    "rotation":0,
    "store":{
      "country":"Romania",
      "state":"",
      "name":"VIKING ART & HOBBY SHOP",
      "countryCode":"RO"
    },
    "firstName":"Alexandru",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzA5NDY2ZTUtMTQxZi00MWQ0LWE1YmEtNmE5YWRjM2Y4NTkwIiwidm90ZXJJZCI6IjEwNzdlOWZiLTFlOTQtNGQ0YS1hZDFlLWNmOWQ2ODkxYmFhZCIsImlhdCI6MTU2NTk1NjcxOSwiZXhwIjoxNTY1OTU3MDE5fQ.krAd65ynypXskesshTuJQVwPXopr708bOm7au5lkZRo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/938c5d61-a314-403f-bfda-a4ca45681462.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Game Kastle (Mountain View)",
      "countryCode":"US"
    },
    "firstName":"Israel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjcwY2ViZWUtOWIzYS00Y2NhLTlhYzctOGQzM2M1YWQ5ZjJlIiwidm90ZXJJZCI6IjE2MzUyZWNiLTM1NDMtNDAxNC04ZTFjLTlmNTFiODVlODBmNCIsImlhdCI6MTU2NTk1NjczMywiZXhwIjoxNTY1OTU3MDMzfQ.1euMrhujBc3XbJyfZE5wkROVt2UO7axN38nxgAm4MM8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/57ed8562-b730-403c-92f0-231c9b2b9344.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Swindon",
      "countryCode":"GB"
    },
    "firstName":"Samuel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDU5YmE0OTktZTRkZC00MzQ4LTg4MjQtYjE5MGJlZWQyN2UyIiwidm90ZXJJZCI6ImE5N2RjMzM1LWI1ZjItNGRkOS05NjI2LTMzOTYzYjQxYzY5ZiIsImlhdCI6MTU2NTk1NjczNywiZXhwIjoxNTY1OTU3MDM3fQ.CHsv_Y5I_otd8UWtJ2jgzYdsJEO4SBBrFg0cQRCCGUY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6e74e8b8-7ab2-4384-b769-f319e5c6cd8d.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Maidenhead",
      "countryCode":"GB"
    },
    "firstName":"Alexander",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGUyN2M3NGItOTVkMi00YzBiLThiODQtYTBjY2Y1MGUyNDlmIiwidm90ZXJJZCI6ImYxZmZhMzM0LTQzZmUtNGVmZi1iZjBmLTljN2E5MGIxYWYyZCIsImlhdCI6MTU2NTk1Njc0NywiZXhwIjoxNTY1OTU3MDQ3fQ.EkErVeF-QMIeqk6RYODEK2UQJGY2og3F20C9X4SPIaA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/09257abc-925a-463d-a8f0-b0a85ce6b96c.png",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Woking",
      "countryCode":"GB"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzA5MjFlNjgtMWYyMS00MWNmLWJiNGEtMGM4MGE2M2Y3ZmM5Iiwidm90ZXJJZCI6ImU2YWE2MDUyLWM4OWUtNDdiNi05ZjJlLTA1M2Q5NTcyZTQ0YiIsImlhdCI6MTU2NTk1Njc1NywiZXhwIjoxNTY1OTU3MDU3fQ.JM0TR8vVlCn8V4Lvb8ZcpVe9-pi0KqBnIBOPEKiJyEA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a8c0c327-01ac-45b0-96c9-dd54a59252f4.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Lost Planet Games",
      "countryCode":"US"
    },
    "firstName":"Blake",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTk5NGVhMjEtNjY3OC00NTRmLWJkNDMtMDc1NmM4NmM4ZGU4Iiwidm90ZXJJZCI6ImM1OTM2MTQ1LTg1YmUtNDdmNi1hNTE0LTczYjAxY2ViYWM3ZSIsImlhdCI6MTU2NTk1Njc2MSwiZXhwIjoxNTY1OTU3MDYxfQ.o9MISiNDopmwJKg96aV5S42MTJH9F7-eM091j3z5OMw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a46f00b1-a60b-42f6-a730-d86e10b52dd2.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Rennes",
      "countryCode":"FR"
    },
    "firstName":"Romain",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjFiYmNlZWEtNTE5OC00Njk0LWJhOGEtMjUwMzFlOGQ2NGJlIiwidm90ZXJJZCI6IjdhMzAzM2RkLTBkZGUtNDQxMy1iZGM4LWU5YWJhNGU1NjExMCIsImlhdCI6MTU2NTk1Njc2NSwiZXhwIjoxNTY1OTU3MDY1fQ.9B84Gr4Cdfe_7MFfSXVzICy_ZU_ubmtZZj05711hdtg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d52c4c3d-737d-41c6-bf8d-bb9ff4da6d1f.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Bernab\u00e9u",
      "countryCode":"ES"
    },
    "firstName":"JAIME",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmZkNWQ0MzEtMWIzZS00ZjBlLWE3ZTctZGEzNmNiOTFhZGE1Iiwidm90ZXJJZCI6ImJkMjAyNzc2LWIxMmEtNGYxOS04ZTE4LThkYTUxMTdmMDY2NCIsImlhdCI6MTU2NTk1Njc3MiwiZXhwIjoxNTY1OTU3MDcyfQ.9h80FYADha2U0hyXvVHbm73_2x4-f0BquKcczvh9dWU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5b7b8f99-194a-4b2b-9c7b-73b40ad186ef.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Shrewsbury",
      "countryCode":"GB"
    },
    "firstName":"Robert",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTY4MGEwMzAtZjJlZC00MTg3LTg5MTUtMjY2ZjRlOTFkZGY5Iiwidm90ZXJJZCI6ImJlMTBiNGJhLTliZjUtNDJkOC1iMzdlLTZhYjFjNzExYjEzZSIsImlhdCI6MTU2NTk1Njc4MywiZXhwIjoxNTY1OTU3MDgzfQ.7926oj3o2oiKTr6L8R0F2ZVceruqNU3fTwb-CNIOnB8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2810639c-1218-488f-988f-0d5cda8b4125.JPG",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - N\u00eemes",
      "countryCode":"FR"
    },
    "firstName":"Fabrice",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzQ2ZDExZDctODNiZC00N2I1LWFiMDktMTU3OGM0N2VjOGZjIiwidm90ZXJJZCI6IjA1OThkNzM3LTdjZWItNDc0Yy05ZmRjLWE0MGRlM2NjZWJjYiIsImlhdCI6MTU2NTk1Njc5MCwiZXhwIjoxNTY1OTU3MDkwfQ.QyMikE-uakvKf3zuQvIIbWYQQKhEdtX7kP5kw08ZmmM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0e5f8e46-9f7e-4c20-8b9e-ce6d5afda049.jpeg",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"",
      "name":"Hobby Shop Miniature Forest (\u30db\u30d3\u30fc\u30b7\u30e7\u30c3\u30d7\u30df\u30cb\u30c1\u30e5\u30a2\u30d5\u30a9\u30ec\u30b9\u30c8)",
      "countryCode":"JP"
    },
    "firstName":"\u5927\u8f14",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGJjYTMzNjItY2FiNC00N2VkLThjNTEtOTU5MzI3ODU2YzllIiwidm90ZXJJZCI6ImQzOTg2MzM4LTRhYTUtNDkwNy1iMmFhLWNkNzg2N2U2YWE2YSIsImlhdCI6MTU2NTk1NjgwMCwiZXhwIjoxNTY1OTU3MTAwfQ.zasHj7JTd8jUQU28tkA5REGVU3nBr8Ii8jO8mNrXDBg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/35416bb6-b80e-40b8-a24a-86e281a7cd8b.jpg",
    "rotation":0,
    "store":{
      "country":"Bolivia, Plurinational State of",
      "state":"",
      "name":"La Guarida del Goblin",
      "countryCode":"BO"
    },
    "firstName":"Carlos",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjA1ZmU0YzAtMmU2My00MjZjLWFlMGMtYjBjYWQ3MDMzMDBlIiwidm90ZXJJZCI6IjdmNmMwMTgzLTA3ODQtNDJkNy1iYzFjLWY0ODIwZDYwOGY0MyIsImlhdCI6MTU2NTk1NjgwNCwiZXhwIjoxNTY1OTU3MTA0fQ.zGgqIwiksPNFR_5AHxiZsiNCZrGtGLZAQe5EqgKjViY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4b75d810-c2b3-43f8-8a36-6526e6825814.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"VA",
      "name":"Games Workshop - Pender Village",
      "countryCode":"US"
    },
    "firstName":"Jeffrey",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjhkM2E5ZmEtMGFhMy00MTgwLTg1NmItNjJiMjcyNjEyNTEzIiwidm90ZXJJZCI6IjA2ZWQ5MDdkLWE3Y2QtNGMwOC04NGUwLWE4NzYzZjRmZmZjMSIsImlhdCI6MTU2NTk1NjgyMiwiZXhwIjoxNTY1OTU3MTIyfQ.avosj3AdoniTh-awwIfljiMbXQeKcvBXiwOv_a1btAo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9f433a8d-00cd-473b-991c-408d097a408f.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WV",
      "name":"YOUR HOBBY PLACE MARTINSBURG",
      "countryCode":"US"
    },
    "firstName":"Scott",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjlmOGMwZDktOWJiZi00ZjExLTk0MjItNGQwYzNjM2QxZmMzIiwidm90ZXJJZCI6IjUwZTU1NGNmLWMwYjAtNGUyOS05MGMwLTg4ODI3ODMzMjk5OCIsImlhdCI6MTU2NTk1NjgyNSwiZXhwIjoxNTY1OTU3MTI1fQ.ZgNIEt9NeS9sx-SdRC5BIxy-bgIzDf7WcUBlJNWJHeI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a052fed8-30c3-4cef-9344-663f4d8bc8e0.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"GAMESCAPE NORTH",
      "countryCode":"US"
    },
    "firstName":"Christophe",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzJkODlhZGMtMjA3OC00ZjRjLThlYmEtMGYzOWM3MzIwMzZiIiwidm90ZXJJZCI6IjgyNGE3MWZkLWYyMWMtNGJkYS05ZWMxLWUwMjA0NWYyN2I2MCIsImlhdCI6MTU2NTk1NjgyOSwiZXhwIjoxNTY1OTU3MTI5fQ.qSjc6t4dO7YQGvg_MUKxvQXXpPdJGH6LhEJHe5qCKL4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5f2553e5-f332-4be9-a38b-a0b806d81d2b.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Warhammer - Los Gatos",
      "countryCode":"US"
    },
    "firstName":"Markus",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjU5YTViMTctNmRiZi00ODZmLThjN2EtODgwNmQyNGM5OGFlIiwidm90ZXJJZCI6ImJkMmUzNjg5LTQxOTQtNDY5YS1iYmQwLTAxOTQ3N2NlNDliZiIsImlhdCI6MTU2NTk1NjgzMiwiZXhwIjoxNTY1OTU3MTMyfQ.B_taxKEXUVTrhqp7pXs_NByoYluzv7GA2fbfgvaasuA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c3d49d0c-7129-419c-99ca-25b3ca92bf58.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"VA",
      "name":"THE DRAGON'S HOARD",
      "countryCode":"US"
    },
    "firstName":"Jason",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjQxZGYwNTgtN2U5Ny00ODVjLWFmZjktNjFkMDMzMGEyMWQ3Iiwidm90ZXJJZCI6ImNiNzc1MGI4LWUzNjYtNGZjYi05YjZkLWY4YTRiMTY0MzI4MyIsImlhdCI6MTU2NTk1Njg1NywiZXhwIjoxNTY1OTU3MTU3fQ.HZF8gG4SNWXyYVQlegQUDJLIR25LQgPigUHKvyf_wgs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/47398d72-2a57-456e-b2f3-be03a7ccd0e8.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"",
      "name":"SWORD N STEELE",
      "countryCode":"CA"
    },
    "firstName":"Maria",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjU0YWNjZjItMzI0NS00YTE4LWFiZjktODVhYzIwOWFiYTYyIiwidm90ZXJJZCI6ImRkNzRkMzQ5LWM2NzYtNDBhYS1iYzI3LTZjNzRlMTY4YTRhZiIsImlhdCI6MTU2NTk1Njg2MCwiZXhwIjoxNTY1OTU3MTYwfQ.EWGpUzZ7E3DKo7pm1PwadWBqpSf335w4yYb8jf9CnkM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/81a11c12-969b-4be5-9006-1f7f016dd8e5.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"VIC",
      "name":"Games Workshop Melbourne",
      "countryCode":"AU"
    },
    "firstName":"Tomas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjQ4ZmUwNDUtZWUwMy00ZGE3LWE4YzgtOGRjOGEyM2EwYmU5Iiwidm90ZXJJZCI6Ijg0YzBiNjExLTJjNWMtNDRlMS05NjA0LWUwYmQ0N2VhMmMzMiIsImlhdCI6MTU2NTk1Njg2NCwiZXhwIjoxNTY1OTU3MTY0fQ.QHDvX6eoiqMD4ocVGxl7wMg6MEK0fyuVcoW7lMbRm04"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/368a0061-9b8a-4a92-ae4f-a9e21fd27092.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Belfast",
      "countryCode":"GB"
    },
    "firstName":"Tom",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzJmM2VlOTEtNDVlMy00OWY5LTg3OWItOGRhN2I3MTg4Y2RlIiwidm90ZXJJZCI6IjhiMTNkNTIxLTAzN2MtNGIxYy1iZmNmLTgwMjJkZjQxODJkZCIsImlhdCI6MTU2NTk1Njg3MSwiZXhwIjoxNTY1OTU3MTcxfQ.3a37STFQ8SoK0X6LRoDdxAc-7S91WTkugrvAQLn8usY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/793534e5-79a9-465b-86d6-755fc64f55d2.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Games Workshop - Brodie Oaks Shopping Ctr",
      "countryCode":"US"
    },
    "firstName":"Luke",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzFkMmE3YzMtMzg1Zi00N2I1LTg4YjktMmI1MzkxNDEwNWM3Iiwidm90ZXJJZCI6ImIzNDMyZWY1LTRmYmItNGE0Ni1iN2NlLWRkN2QzZmFjNTdlMiIsImlhdCI6MTU2NTk1Njg3NCwiZXhwIjoxNTY1OTU3MTc0fQ.fagpWbcrdrleY-bWkf5mItMwpTgLnG4eCqZR1jjEOoU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f622b2ea-a341-41b5-85c5-317193bd8818.JPG",
    "rotation":0,
    "store":{
      "country":"Poland",
      "state":"",
      "name":"VANAHEIM",
      "countryCode":"PL"
    },
    "firstName":"Rafal",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWQ5NjY5NzUtMjlhMy00NzRhLWE5MDYtYTVkZGQ2YzczZTNkIiwidm90ZXJJZCI6IjdlZjA1NTFhLWRhMTgtNGM5YS05NDBlLWY1MTRjOTFhMDRiZiIsImlhdCI6MTU2NTk1Njg4MSwiZXhwIjoxNTY1OTU3MTgxfQ.BkL37cbed8hlM0Dwun3vIjbg7eRrDNvsMrKZ0eZr-7M"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1f9ac8e2-f0fe-414d-9770-5d8e51a4da16.jpg",
    "rotation":0,
    "store":{
      "country":"Finland",
      "state":"",
      "name":"Warhammer - Helsinki",
      "countryCode":"FI"
    },
    "firstName":"Joonas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGVhMmFlZWQtYzRjMC00Y2I0LWFkMmQtMWRhYjEyNzgxNjExIiwidm90ZXJJZCI6IjE2NDg5NzNjLWFkM2YtNDY4YS05NTA2LTRlOWJlZTNhOTM2MCIsImlhdCI6MTU2NTk1Njg4NCwiZXhwIjoxNTY1OTU3MTg0fQ.iwfQTRiOBCAgzAMEbdJwqqzLhjRMs6ritqPT4KqMthg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0e80a4bf-2244-4a85-b394-055138fca494.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"PA",
      "name":"DrawBridge Games",
      "countryCode":"US"
    },
    "firstName":"David",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjg1NGEyNTYtYzFkNy00ZWFiLTkzMjgtNzVjYmI5MmM1YjBhIiwidm90ZXJJZCI6IjlmNjI3MzQyLWUwODYtNDY4NC04NWQ0LTM2YTQ3OGVkNjc3YyIsImlhdCI6MTU2NTk1Njg5MSwiZXhwIjoxNTY1OTU3MTkxfQ.Bm8RzK29cm9QhdYc2OZWZjeL9Ip-cDYv0E0jtGtE5Ao"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0c060907-75c5-43f9-8ed8-64a2588a4903.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Aachen",
      "countryCode":"DE"
    },
    "firstName":"Falco",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzliNGExNDYtN2M2Yi00NjUxLTgwZTEtNjZlNTc4NTVhYWZlIiwidm90ZXJJZCI6ImNmNTQwYWYxLTNlMWEtNDJjMS1iYjY1LTYxMGQ3YzJhY2E3MSIsImlhdCI6MTU2NTk1Njg5NSwiZXhwIjoxNTY1OTU3MTk1fQ.FxyOhwisR6TA5e79w6xj1evUNg5YDSrqb1uzBLuEHQU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7025f1ea-0434-4c6c-a179-b518e4c99e0f.png",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NY",
      "name":"ZOMBIE PLANET",
      "countryCode":"US"
    },
    "firstName":"Forrest",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGRlMWQ3ODktY2VkMC00ZDI4LThjYmQtNzY1Yjc4NGM5YzMzIiwidm90ZXJJZCI6ImEwZjNhNjU5LTg0N2UtNDA5My1hNzZmLTJiZDQyN2RlNDZhMyIsImlhdCI6MTU2NTk1Njg5OCwiZXhwIjoxNTY1OTU3MTk4fQ.HQ3WH_yYiNj1FsIdfCKFUkUJXLUiL5IO_SqEn43e_4I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cc8673a9-4105-4b9e-8557-e7e2f644fbe5.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Bolton",
      "countryCode":"GB"
    },
    "firstName":"Kiefer",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmRmZjdjYWEtYjdjYy00MjQyLWEwNzAtNjM3NDEyYjY1NzAwIiwidm90ZXJJZCI6IjY0N2U1ODUwLTUzN2QtNDllMi04YTJmLTVmYTg3Nzc2ZDExOCIsImlhdCI6MTU2NTk1NjkwNSwiZXhwIjoxNTY1OTU3MjA1fQ.ePF3yA55YW1-YLn3vwCiib6vUiNZ4XDdVirpTYHQgqo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6ecb8fc1-6ee1-4411-a94e-1c8eddf36694.jpeg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Games Workshop Albury",
      "countryCode":"AU"
    },
    "firstName":"Danny",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzBhNjg4NGMtODUxNy00ZGNhLWE4ZDktZDM4NGQwNzBkYjdjIiwidm90ZXJJZCI6IjlkMzg5ZWQwLTY4OWUtNDdmNi1hZTY1LThiNDdlMDMwOGZmYSIsImlhdCI6MTU2NTk1NjkwOSwiZXhwIjoxNTY1OTU3MjA5fQ.XFYYNGoLcP8wtWsN0Ev3t1aXfcnZ4mrzpAhacD9rpao"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4ad9bc78-998d-4e8c-bd72-f6b3099de965.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Khitriy Ezh",
      "countryCode":"RU"
    },
    "firstName":"\u0421\u0435\u0440\u0433\u0435\u0439",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDhjMTA4OWEtODAwNC00MjYzLTg4YTMtOGE5NGZhZTIyYzcwIiwidm90ZXJJZCI6IjdiYmE1ZWIyLTFmMzEtNGE5NC1hNWM2LTUzMDJkOTk5M2YwOCIsImlhdCI6MTU2NTk1NjkyMywiZXhwIjoxNTY1OTU3MjIzfQ.ScSiferiBUeX-KdV5Xct0a5AXnjTvcQKWRpLA7mnR8Q"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/18cc7054-3779-4157-ac6d-fd22df6bb055.jpg",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"Tokyo",
      "name":"Warhammer - Harajuku",
      "countryCode":"JP"
    },
    "firstName":"Kazuhiro",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWE5MGJmMTctOTY0Zi00MzZjLThiNTgtOGNiMDNmMjNmODMyIiwidm90ZXJJZCI6ImQxOTM3YzQ5LTVkYjktNDU4Ny05NDBlLWQyYTljYmQxZjBiMSIsImlhdCI6MTU2NTk1NjkzMCwiZXhwIjoxNTY1OTU3MjMwfQ.IoTAfzXAh96VHX3SGJ9RoRkwQGIT-zksqrAl02tzKPU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d08bb97c-21dd-4133-95de-eee51ea52cf4.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Erfurt",
      "countryCode":"DE"
    },
    "firstName":"Andreas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWNmNTk1ZGYtZmFiZi00ODA2LTg0Y2QtY2FlNGY3NDllNTJhIiwidm90ZXJJZCI6IjFmY2I3MTg4LWZiMWMtNDU1MS1hYWNkLTNmYTgxYmY5ZjQ5MiIsImlhdCI6MTU2NTk1NjkzNCwiZXhwIjoxNTY1OTU3MjM0fQ.5wzBHIn84hR_I_F7G-7jasTtXe7KfutAYCy224CevSQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bda4506a-d21a-420c-a45b-c1759e9ace21.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Newcastle",
      "countryCode":"GB"
    },
    "firstName":"Joe ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTIzZDM0MGItZDExNC00YzZiLTgzNzMtODljMzg4NWIxZTIwIiwidm90ZXJJZCI6Ijk1NTQ4Y2U2LWQ1OWMtNDhiNC1hNWEwLTYyNTk4NjUzMTAxZCIsImlhdCI6MTU2NTk1NjkzNywiZXhwIjoxNTY1OTU3MjM3fQ.WVWvgtBKKQb1JX3HLfY3I5cvJDIC51HazrKSYWHd9Yk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/baf892ac-ea03-4fca-923b-d1b71fc91545.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Harrogate",
      "countryCode":"GB"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTRkZTRhYjktMmFhZi00MmQ0LTk1NzQtNDAzOGZiYjIyNDJjIiwidm90ZXJJZCI6ImQ1M2VmMjRjLWRlZTQtNGQyZS05MWY3LTRhOTIzYTViMWQ3ZCIsImlhdCI6MTU2NTk1Njk0NCwiZXhwIjoxNTY1OTU3MjQ0fQ.CDQk7Xiiqw1XdXQp2DDDpqYPVARRXrFr8cXYSvITqKs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9f2cc054-ff12-4067-8da8-5926c03ac19c.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Carmarthen",
      "countryCode":"GB"
    },
    "firstName":"Ryan ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWU1MmJlODUtOTc2NS00MzBhLWExODAtYTNkNTNmNjc3OWJhIiwidm90ZXJJZCI6IjRkN2ZiMGFmLWRlMjMtNDU3Ni1iMDJiLTZjM2UwNzQyYjU1NCIsImlhdCI6MTU2NTk1Njk1MSwiZXhwIjoxNTY1OTU3MjUxfQ.dE_yxel-w0keliUp6qYPs4mupUohJWkbnDvX1YG53EM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1f94b281-0eff-4f22-bb0a-fe881f5eca69.jpg",
    "rotation":0,
    "store":{
      "country":"Singapore",
      "state":"",
      "name":"Games@PI",
      "countryCode":"SG"
    },
    "firstName":"John",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTdjM2I4ODctMzNlZC00ODlhLTg1ZjEtYzAwYmZlYzc1MzhkIiwidm90ZXJJZCI6ImExYTBiM2IyLWE3ZjctNDExMi05NWVmLTg1MjEwODA0M2ViZiIsImlhdCI6MTU2NTk1Njk1NSwiZXhwIjoxNTY1OTU3MjU1fQ.XZD7QCV-e4KfvMB0X0UAnaRGnnpe6i0gIe7eikpAh-g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0a94303a-4abf-4fe5-9c74-b12adced7cb9.jpg",
    "rotation":0,
    "store":{
      "country":"China",
      "state":"",
      "name":"Games Workshop - Pudong",
      "countryCode":"CN"
    },
    "firstName":"\u9ec4",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODZhYTc3OWMtNmEyYy00MGYyLTkxMjItMjIzNmVjMTYzZGUwIiwidm90ZXJJZCI6IjJmODZiMTI0LTE2NGMtNGY3MC1iNWFiLTJlNTg1OGM1NTg4YyIsImlhdCI6MTU2NTk1Njk2MSwiZXhwIjoxNTY1OTU3MjYxfQ.XOkEOkOkSvPnJnVFOwqgzle44uE-97Rpin-95GujF54"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/59008c70-f05c-472d-86e1-39a9d242afdd.jpeg",
    "rotation":0,
    "store":{
      "country":"Argentina",
      "state":"",
      "name":"Imaginario Games",
      "countryCode":"AR"
    },
    "firstName":"Vladimir ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzgzM2Q5N2YtNDk0NS00Mjk1LTkxYjUtYjVlOWM4ZjA2NzVlIiwidm90ZXJJZCI6IjIyOTVmYTU3LTdmNzAtNGIxZS04OWM1LTFkNDBhZjM2MDdlZCIsImlhdCI6MTU2NTk1Njk2NSwiZXhwIjoxNTY1OTU3MjY1fQ.U18hbuR7nhSblFp81s0wZaOqJjPUwwIgvdFtUSZFf9E"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/04cfd203-2a8c-4194-b3c1-70e6252eb9ef.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Warhammer - Erina",
      "countryCode":"AU"
    },
    "firstName":"Max",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDk3YmRkYTgtZjkxNC00YzZhLThmMzItMTVkNDMzZmU2NjcxIiwidm90ZXJJZCI6IjI0YjkxZDZlLTNmNDgtNDg4ZC1hY2UyLWY5YjNmYzZlYWM5ZSIsImlhdCI6MTU2NTk1Njk2OCwiZXhwIjoxNTY1OTU3MjY4fQ.jMhjRmHqSgXaJBZV7KmIEm0cawQYyXkKDxFr9cnnhig"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/22d9baac-accf-4872-94f0-f683daee4463.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Derby",
      "countryCode":"GB"
    },
    "firstName":"Bryan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDU2NmEwNDgtZjJjNC00MDE4LTkxNmMtYTMzMTZkZTYyMWQzIiwidm90ZXJJZCI6IjUyNzQ2NzY0LTQxMDctNGIzYS1hN2Q0LWQyNzc1NTJiOTM0NiIsImlhdCI6MTU2NTk1Njk3NSwiZXhwIjoxNTY1OTU3Mjc1fQ.5ZP29soBXAelX66vctSXsuwz7-HZGn6Lene83YQodGI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c362df7e-52a4-40ff-a186-7ac853c88ba3.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Warhammer - Le Havre",
      "countryCode":"FR"
    },
    "firstName":"Quentin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2QxZDhmNGEtZWVjNS00ZGQyLWIxZDctMjE2OTkwMGM1ZmY5Iiwidm90ZXJJZCI6IjQzMzFmMTNjLWRjZDMtNDRiMC05MDIxLTZkMDYxZWZlNDNmNCIsImlhdCI6MTU2NTk1Njk3OSwiZXhwIjoxNTY1OTU3Mjc5fQ.WSSnQFi9n_M2gYbjCiRcp33Me3EwpxPmkDn0xP5Gh_s"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7f9a0e7d-4f30-478b-ba90-211d7542eb0f.jpg",
    "rotation":0,
    "store":{
      "country":"Poland",
      "state":"",
      "name":"STAGRAF KRYSPIN SWITLICKI",
      "countryCode":"PL"
    },
    "firstName":"Piotr",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2FhYjUxYjYtYzk0Ni00ZmQxLTgzNDktZDljYjk0ODRhZmExIiwidm90ZXJJZCI6IjQyNmUwOWJlLWZmYjctNGRkNS05NTNkLTQyMzhjYWRmNDlhZCIsImlhdCI6MTU2NTk1Njk4MiwiZXhwIjoxNTY1OTU3MjgyfQ.NSfz9PDT67DnbbuM7uyNasAoFZCJYR0cPVE9WrHiSlY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fec37efa-00ef-4206-8b39-98abfe0ba8b5.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Strasbourg",
      "countryCode":"FR"
    },
    "firstName":"C\u00e9sar",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzA0ZmUzZTItMWNmMy00M2E2LWFlOWYtNDBiYjM5NzRjZmI2Iiwidm90ZXJJZCI6IjRkODM4ODk2LTU2NDYtNDA1Yy04Nzc4LThkNjliZTdhOTE5MiIsImlhdCI6MTU2NTk1Njk4NSwiZXhwIjoxNTY1OTU3Mjg1fQ.Arjoe7gMfsgJ3cYSoGgDVl9h-L-cMnF1H7ESpTGcPxY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/01b3a6a4-b8a2-4fe7-ae3f-6796ba8d25e0.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - St Albans",
      "countryCode":"GB"
    },
    "firstName":"Ryan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODVjNzJmMGItMjRjMi00ZTI0LTkwMmQtODhlY2Q1ZDlmOTk0Iiwidm90ZXJJZCI6ImQxNGJhNDQ5LWRlYTEtNDUwZi1hZDIxLTZhNzM0NThmMjNlMCIsImlhdCI6MTU2NTk1Njk5MiwiZXhwIjoxNTY1OTU3MjkyfQ.dw5-qSeweA2hWeU8QbxcbO7A0mq8fOxgo8EskPdqKIA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/75d6257e-bf9b-4cac-ae53-20299fbac5df.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Basingstoke",
      "countryCode":"GB"
    },
    "firstName":"Jamie",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWZhNWMyNjUtNjU0Zi00YmRlLTg4ZWUtNTY3Zjg0YjE0NDQxIiwidm90ZXJJZCI6IjBlOWQ4Mzc2LWQ0NTctNDRlNC04OWI1LWEzYWQzNjlmOTMzOCIsImlhdCI6MTU2NTk1Njk5NiwiZXhwIjoxNTY1OTU3Mjk2fQ.nSKuMXVeBx9QERV_S_oPXEkPCZPUc5m4D4rceqwjBUg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c57d3195-8875-4e97-ad27-b4987cec0f31.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Hobby Games Nizhniy Novgorod Bolshaya pokrovskaya st",
      "countryCode":"RU"
    },
    "firstName":"Alexander",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2MyZjljMjMtNjhkOC00ZWY4LWE1MDQtNTBjNzk1MTNjMTYyIiwidm90ZXJJZCI6IjFjMDMyMjZlLWY2MTktNGJiYi1hYWUyLTVjOWIyZWI2MThkNiIsImlhdCI6MTU2NTk1NzAxMywiZXhwIjoxNTY1OTU3MzEzfQ.W4x1DiKPbqr-YxPkXIZ9CggWyPK2_iTUkr_kuMaFIL4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0f1d176d-2a7d-4a10-aa3c-d80e3d5f759f.jpeg",
    "rotation":180,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Stoke",
      "countryCode":"GB"
    },
    "firstName":"Chloe",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzQwZGM0MDItNmM1MC00OTIxLWFiMzgtNWJlYTMxZmM4OTA3Iiwidm90ZXJJZCI6ImJjZTE1MWI2LTUxMmEtNDNiZC05MmYzLTRlZjE0NTNmNDc2YSIsImlhdCI6MTU2NTk1NzAyNCwiZXhwIjoxNTY1OTU3MzI0fQ.Z1vZch5acQlM3Zxiz-WIOzRCCoz3S69WrIcnEKjLZTE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2a9420b9-14c4-4a8c-a98b-9eac148ee97e.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Badalona",
      "countryCode":"ES"
    },
    "firstName":"Juli\u00e1n David",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjFlYzY1ZDgtNjJiZC00M2NiLWE1MDUtMmFkYTczOTQ4MTczIiwidm90ZXJJZCI6ImNjYTFlYjZhLWE4NDgtNGRjYS04NjUyLTYxODFiN2MyNDA5MSIsImlhdCI6MTU2NTk1NzAyNywiZXhwIjoxNTY1OTU3MzI3fQ.ZWMUm6bQ5Kb8leElA0iBy5y3Jo4Qk2FtEYDshEZ8Mgw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6a51787f-6bfa-4337-8a21-371d20c9066d.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"SA",
      "name":"Warhammer - Modbury",
      "countryCode":"AU"
    },
    "firstName":"shannon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjUwMjc4NzMtZWVmYy00OGI4LTgzNjAtMjM3ZjQ3YzdmN2E0Iiwidm90ZXJJZCI6IjdkMTdjYTg2LTAxZmUtNDQ2OC1iYjI5LTg5ODEwMTYzMjRiZSIsImlhdCI6MTU2NTk1NzA0MSwiZXhwIjoxNTY1OTU3MzQxfQ.Nwd5M9sdp8-oqEIpNO3_Si2v1Za_79ngJWt-a3csV-E"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/018df1b6-0e50-4a8d-886d-2cad217f5e90.JPG",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Duisburg",
      "countryCode":"DE"
    },
    "firstName":"Christian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGFlZjE3OTUtZWNlNS00MThkLTllOWUtMjY5NTE5ZTM5NzRiIiwidm90ZXJJZCI6ImNmMzNhY2YxLTZlZTMtNDBmNS1hMzk3LTNhMGIyMTI2NmQ3MiIsImlhdCI6MTU2NTk1NzA0NSwiZXhwIjoxNTY1OTU3MzQ1fQ.fQ02yp0xwYCQFsl5sVUfeMMngs_vktVyc8hW8KyRnZY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5d90bca5-d320-4d45-b412-ef13593d486c.jpg",
    "rotation":0,
    "store":{
      "country":"Malaysia",
      "state":"",
      "name":"RNG GAMES",
      "countryCode":"MY"
    },
    "firstName":"Ivan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmRlNjlkZGEtNDVlMi00ZmI0LTgxMTYtYWI4OTgyMTk0MGUwIiwidm90ZXJJZCI6Ijc3NmI3ZDU0LTkyN2ItNDUxNi04MTU2LTJmYzNlZGMyNjk4NCIsImlhdCI6MTU2NTk1NzA0OCwiZXhwIjoxNTY1OTU3MzQ4fQ.nNBlHFEVANuO9KyB6l_tc_M977DvwwfLF7YLZuXfmfg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9cff38ea-7589-40b2-8202-eff0be1768c7.JPG",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Mannheim",
      "countryCode":"DE"
    },
    "firstName":"Markus",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTdkMmVlZDAtYjhkZi00MmZhLTk2MTMtOGVjMmEyYThkZmFmIiwidm90ZXJJZCI6ImQ1YjQ0YTM1LTE4NzYtNDNmNi1iYWU4LWY1ODM0ZjNiMWQxOCIsImlhdCI6MTU2NTk1NzA1OSwiZXhwIjoxNTY1OTU3MzU5fQ.s_TVOd7HVBX3dujae2w5tr-Bm14UryGpfUdMQz1bUSk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c7830a26-5d9a-4cc5-b10e-2b9d4306a532.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"AR",
      "name":"Imagine Hobbies & Games",
      "countryCode":"US"
    },
    "firstName":"Justim",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjkxYWM3YjEtNTZkNS00OWNmLTg3YjgtYTJiZDJhOGM0YTkzIiwidm90ZXJJZCI6Ijg0MmRiNGYwLWYwYTktNDIwMy1hZmQ3LWEwMDJhMTAyN2YxNiIsImlhdCI6MTU2NTk1NzA3NCwiZXhwIjoxNTY1OTU3Mzc0fQ.9sEJNgY2aVEcYLl-FBkd8gIs87NfGWlNzVTHFiVIFng"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/caddadad-6dff-4721-97db-74eefce6a0a5.jpg",
    "rotation":0,
    "store":{
      "country":"China",
      "state":"",
      "name":"Mo dao Zhan Chui Dian",
      "countryCode":"CN"
    },
    "firstName":"liu",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2Y5YWYzYTctN2ZlZS00MDYxLThhM2UtYzFhNGZhMTA0NmRhIiwidm90ZXJJZCI6IjQxNTFmM2JiLTlkNmMtNGE1Ni1hM2VhLWZlNWM2Y2FiYjdhOSIsImlhdCI6MTU2NTk1NzA4MSwiZXhwIjoxNTY1OTU3MzgxfQ.vophNaqhxGLHCbQoFGkBcj46jVIg_S4V29QkUtTMAVE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6cf238d4-f0fe-404a-b1dd-41dc19648ec3.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Bury St Edmunds",
      "countryCode":"GB"
    },
    "firstName":"Jordan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTRlNzdiN2ItZTRlZi00ODhjLWE3M2UtZDU2OTEzYmVmMjIwIiwidm90ZXJJZCI6ImE1ZDNmNmFjLTU5ZjItNDEzMC1hMGE0LTE1OGRmNmM5ZTk2OSIsImlhdCI6MTU2NTk1NzA4NCwiZXhwIjoxNTY1OTU3Mzg0fQ.Yd2VPQx5YEsP_SFSxwvAzbnk0qcD4TT7CidbZvWat1A"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3b2c1ddc-fec6-4421-a0f9-2b56742a5cd6.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Poole",
      "countryCode":"GB"
    },
    "firstName":"Lewis",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTgxMmVkYTctYjA1Zi00ZWY5LWE3YWEtYzBmMzk0NjhlMWYzIiwidm90ZXJJZCI6ImFiNjU2YzNkLTE4MWItNDViNi04NzdlLTY4MDI3NWZjNjI5MCIsImlhdCI6MTU2NTk1NzA4OCwiZXhwIjoxNTY1OTU3Mzg4fQ.hoXuIgxYPEVw-gAxMJlZd7iXdIUlSV84Ze8h_dYjLrQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0786a86d-8679-4482-b803-b26c6702e4b8.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - N\u00fcrnberg",
      "countryCode":"DE"
    },
    "firstName":"Patrick",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjIyNzliYzItY2Q5OC00MjY2LWI4YWMtZDA1Y2JhNmJiZTZiIiwidm90ZXJJZCI6ImI0MjNjMzEwLTZmMTMtNDVmZS05NzY2LWI0ZWE4MjRjODMxMiIsImlhdCI6MTU2NTk1NzA5MSwiZXhwIjoxNTY1OTU3MzkxfQ.eMwIK8mWqm3c9-Tyf3A4XNkFUPOVXStvHXoHcONmb6c"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5fd61756-5c69-4bcb-9fc6-ab9368239d69.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"OTTO'S VIDEO GAMES & MORE",
      "countryCode":"US"
    },
    "firstName":"Nathan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2ZjNmEyMTItN2RlNS00OGZmLTliYWUtYjUyZWRkM2QzOTQyIiwidm90ZXJJZCI6ImY5Mjc0ZDE0LWRkNDUtNDI3My1iZThlLTJiZTIwZGZiODVlNSIsImlhdCI6MTU2NTk1NzA5NSwiZXhwIjoxNTY1OTU3Mzk1fQ.Yaplu0vDm7t9XmLZMqWKH1NfjhECVy2uUFHwCGkTtuQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f8f6799d-5fa0-46fb-aad2-6800442ce568.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Birmingham",
      "countryCode":"GB"
    },
    "firstName":"Simon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmFiZmNjMWUtMDM2Ni00NDE1LWExMDEtMTU0N2RiMjE3NDY0Iiwidm90ZXJJZCI6IjAxNmEwNDc2LTM2MGUtNGEyNS1iYzM0LTVkNTBlZDM3OTVkNyIsImlhdCI6MTU2NTk1NzEwMiwiZXhwIjoxNTY1OTU3NDAyfQ.ndryx-axt5yt4Sd0lC4AUy9KmJO3g2x21zktD_buna4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/95553550-6f20-4e86-a299-3ff58ddb66f4.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"HOBBY MINIATURES",
      "countryCode":"ES"
    },
    "firstName":"ADRIA",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTcyZTZkNTMtZmFlMy00MDQ4LTgwMjgtOTJjNDU1ZTMxZjJhIiwidm90ZXJJZCI6ImJkOThhNTc0LTkwMTMtNGU0OC1hZDBhLTM1OWU4N2JmZjBjOSIsImlhdCI6MTU2NTk1NzEwOSwiZXhwIjoxNTY1OTU3NDA5fQ.VZYzBxbW5akllgGgaDf0dYPncHbrKtSzDsW678mz6lY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/749be7ca-faf7-475a-ba5a-7eda7e2aaea1.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Reims",
      "countryCode":"FR"
    },
    "firstName":"Gautier",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjVmMjgzYjgtOTc3Mi00MGViLTk5OWEtZWVhMmNlZjllYTY4Iiwidm90ZXJJZCI6ImI0MGFhMDM1LTQ2NzEtNDEwMC04NzI2LWRhMGI2MDVhZTgwZSIsImlhdCI6MTU2NTk1NzEyMywiZXhwIjoxNTY1OTU3NDIzfQ.7ChnXtF-wfHCZUApchZOc8Pkrm3BXeUDzGzl9Xxq894"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2ac859be-81e8-46a8-aed5-6e53c1ca2da0.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NY",
      "name":"Warhammer - Plainview",
      "countryCode":"US"
    },
    "firstName":"Michelle",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTU3ZWViNjYtMzBlOC00NjczLThiYjgtY2JlYmFmY2RjZWJmIiwidm90ZXJJZCI6ImRhMTUzM2QxLTdjYjAtNDE1MC04MWUyLTA4MTBkOWM3NGQ4OCIsImlhdCI6MTU2NTk1NzEzMywiZXhwIjoxNTY1OTU3NDMzfQ.rIcXMPlYUwNzl6K1IBtLekxqJQGL7tvd_1TMEEwHtP8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5f3539e5-ee2d-4cfc-8c2b-109b1039c107.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"AZ",
      "name":"Warhammer - Prescott",
      "countryCode":"US"
    },
    "firstName":"Alexzandria",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzEyMGQyNDMtMDJmMC00YjM0LWE0OGQtMDYwMDk3Zjg3YzUzIiwidm90ZXJJZCI6Ijk5ZWY3NzdhLTdlNzEtNDY3ZS04YWEyLWVjZGU2NjJmZjRjYSIsImlhdCI6MTU2NTk1NzE0NCwiZXhwIjoxNTY1OTU3NDQ0fQ.h0KvPCO_h6DUrJAF6TCUsjl0TwGQT2x1BAiHbJxFAf0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fe717e23-25b8-44f3-a59f-35ac3a98dddb.jpg",
    "rotation":0,
    "store":{
      "country":"Sweden",
      "state":"",
      "name":"Warhammer - G\u00f6teborg",
      "countryCode":"SE"
    },
    "firstName":"Stefan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTY1OTNkOTYtYmViZC00YzkyLWI2YzQtZjk0MzQwMDM5M2Y4Iiwidm90ZXJJZCI6ImUxMzkxMDA0LTQyZjYtNDY0Yi04Y2M5LTdlZDJjNjJlODUwMSIsImlhdCI6MTU2NTk1NzE1MSwiZXhwIjoxNTY1OTU3NDUxfQ._TMTiRsNmtFA_4Fm62yRLNrHI0xjhuT-tRtPWM_hqyA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d0ae6db7-354d-416c-a9b1-1780d96f25a8.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Deu i Mata",
      "countryCode":"ES"
    },
    "firstName":"Joan Carles",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2RhZmM4OWItM2ZkMi00NDBmLTljZDgtNTNmNmUyNjFhZTkyIiwidm90ZXJJZCI6IjY5NWI4NjNkLTcyOTgtNDQ0Yy05NWM4LTQwYzg1MmU3YzAwZSIsImlhdCI6MTU2NTk1NzE1NSwiZXhwIjoxNTY1OTU3NDU1fQ.qbVzwpJgXfVXnJl_w0aTZnERCb8mcS89UWZKM_NlUW4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9aa319a9-057b-4ed9-ad2d-f7d5ec203dc5.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Hereford",
      "countryCode":"GB"
    },
    "firstName":"Lee",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjhmNjk1ZWMtNjYyMy00YTE0LThhOTUtNzk2YjY3MGY2MzBiIiwidm90ZXJJZCI6IjFlNjBjYzU0LTQ4MDAtNDU3Zi05NmY1LWZlZjZhMGEyMTZjNiIsImlhdCI6MTU2NTk1NzE2MiwiZXhwIjoxNTY1OTU3NDYyfQ.jvDeW1ygs5ve2J4C9KFkQiWO1TwCskgDWh94YOe3mX4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f161bd50-8378-4a01-9920-2b7f1752dde9.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Preston",
      "countryCode":"GB"
    },
    "firstName":"Paul",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTk3NWU3YzQtN2YyNy00OWMyLWIxYjQtNzAwZjk0NzQ0ZWI5Iiwidm90ZXJJZCI6IjRkOGQ3OWIwLWY0M2UtNGNkZi1hNjI2LThhMDgyMDRkMWM5YiIsImlhdCI6MTU2NTk1NzE2NSwiZXhwIjoxNTY1OTU3NDY1fQ.XeUMJVZ_OvehHwjvDlXrxAQHv5wzDSxheYfkVIp2nyw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/044364f8-3d21-4a47-b303-c7eb386729f8.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Bedford",
      "countryCode":"GB"
    },
    "firstName":"Ben",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzk2N2UwYWYtOWMwNy00ZjhiLWFlYTgtODMzMGFiZmY4YTIwIiwidm90ZXJJZCI6ImY5MzRjOTAzLWI2NzgtNDNjNi05ZGZmLTgzNGQzZWE1ZDA1OSIsImlhdCI6MTU2NTk1NzE4MywiZXhwIjoxNTY1OTU3NDgzfQ.TOUAK3sis3I5Ni0tIU-WlDx4yrKAEu-5isNCnaMH1rs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a8c1eaf0-eabb-4301-852b-da34288e0335.jpeg",
    "rotation":0,
    "store":{
      "country":"New Zealand",
      "state":"",
      "name":"Games Workshop Mt Albert",
      "countryCode":"NZ"
    },
    "firstName":"Clive",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWNlMjA3MWMtYmZmYS00MDc1LTgwZjAtMjRkZWYwZGNkM2M1Iiwidm90ZXJJZCI6ImZhMDMzNGQyLTFmNDAtNDU0OS1iYmJhLTAzNmJlMzYzYjJlMyIsImlhdCI6MTU2NTk1NzE5NywiZXhwIjoxNTY1OTU3NDk3fQ.vDvobH089XSxLNWdePzvidH4ifk7jT46BUtKMEoKXGY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7d234722-3dbd-4d5e-bc31-ca8a2d7458f5.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NY",
      "name":"JUPITER GAMES",
      "countryCode":"US"
    },
    "firstName":"Paul",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmMyZTEyNTEtMmU4OS00YTdjLWJiNjktMDQ1YmE4NDM5YzAyIiwidm90ZXJJZCI6IjBiNDc4NGY0LTJhMWMtNDk4My05MjFhLTcxNzNmNTMxY2ExMiIsImlhdCI6MTU2NTk1NzIwNSwiZXhwIjoxNTY1OTU3NTA1fQ.yAE8uPLvtVw7dcJDAEaBCSy46Rcw1-aFA27dx3w7d0Y"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/90ed20a0-efc4-45fe-aa48-6a84c34d7240.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MN",
      "name":"Games Workshop - Fountain Place",
      "countryCode":"US"
    },
    "firstName":"Robert",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjI5ZWExMjUtODMzZS00NzJiLWI0YzUtYWQzM2NmNTUwODM5Iiwidm90ZXJJZCI6ImJhMDlkZGFkLWQ2ODMtNGM4Zi05Y2JjLTAxMDYxZTgzMzUyNSIsImlhdCI6MTU2NTk1NzIwOCwiZXhwIjoxNTY1OTU3NTA4fQ.OlDFha4ZXCNmyWSZP4bEJI_wJJFO6Mp8Dzt1yTme7S8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/214f615a-e641-48b7-81b9-4a3e0444912f.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Gaud\u00ed",
      "countryCode":"ES"
    },
    "firstName":"Carla",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjBhNzUzYzctY2YwOC00ZDgzLWI5NzctNTk1NGUzMmNjODA0Iiwidm90ZXJJZCI6IjI1OGFlOGQ3LTA3ODQtNGI5OC04MzYwLWI5YzA4ZDA4MjMyMyIsImlhdCI6MTU2NTk1NzIxMiwiZXhwIjoxNTY1OTU3NTEyfQ.TBL_SIFtBNF0lYXn__Nr5HLuFqFvpsWLjnI4yEtgN9M"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7225936b-9602-4077-9d5d-01f5915911a7.JPG",
    "rotation":0,
    "store":{
      "country":"Mexico",
      "state":"",
      "name":"Dragon's Hohle",
      "countryCode":"MX"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDNhMThiMjQtYmEwZS00MTYxLWJmN2MtNDgwNjJjODZmYjRmIiwidm90ZXJJZCI6ImYzZjc5YjY3LTI1MzAtNGQxNC1hYzBjLTRlMGU5NDFlMWJhZiIsImlhdCI6MTU2NTk1NzIyNywiZXhwIjoxNTY1OTU3NTI3fQ.kytyuVgMVto1RYKM_wTk-zBrn5BxNrnk_aaf-WPjaeM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cc0e49f7-36ec-41eb-bb0d-fc67ff8ead24.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"",
      "name":"Jeux A-Z Games",
      "countryCode":"CA"
    },
    "firstName":"Arthur",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2Q1NWZhZTAtZjJhOC00YjFjLTgxZDMtMmE3ODRiYTI3MGVjIiwidm90ZXJJZCI6IjdjMmIyNzdmLWE5ZWQtNDFiOS1iMThlLThkMzcwYmE0YjlkNyIsImlhdCI6MTU2NTk1NzIzNCwiZXhwIjoxNTY1OTU3NTM0fQ.wISblhPQXduXtMnMn7ruFJ_KFKclI1_JxpHz0lJszmU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4d5f4a43-e23a-41c9-9f8e-72997ab5f258.JPG",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Games Workshop Blacktown",
      "countryCode":"AU"
    },
    "firstName":"Jonathan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTdkMzA0MWYtN2FhNC00ZmYwLTkwOWEtM2QyMjk2NjAwNGMzIiwidm90ZXJJZCI6IjA4YzcyZWZkLTY5YzYtNDlkMi05N2UyLTljNTYxNjBkOWVlYiIsImlhdCI6MTU2NTk1NzIzNywiZXhwIjoxNTY1OTU3NTM3fQ.XFG1FeQJio-znGah6RDqr3Y1XR18I09aOSty2Kxg4A8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b0506070-a076-42f1-88cb-9df4cc2e775c.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Warhammer - Perpignan",
      "countryCode":"FR"
    },
    "firstName":"Aur\u00e9lien",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2E4YTE0NmQtYWEyZS00MGRkLWFhYmUtMmUyYTdmODk4OTQ2Iiwidm90ZXJJZCI6IjBlZDU0YmU5LTgwMWItNGVmYy1hMDcxLWIyZWJhYTA4YjA4MCIsImlhdCI6MTU2NTk1NzI0NCwiZXhwIjoxNTY1OTU3NTQ0fQ.RV0au5RqUkpPnwPW1OMKuAPGx5wQluA8ey2S2L61nmQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a4b45382-3ada-46ae-b133-0fd7dbb6045b.jpg",
    "rotation":0,
    "store":{
      "country":"Finland",
      "state":"",
      "name":"FANTASIAPELIT JYVASKESKUS",
      "countryCode":"FI"
    },
    "firstName":"Erkka",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGQxMGRhOWMtOTUyMS00MjEyLTljZGQtYjEyNmI3ODZlYjhhIiwidm90ZXJJZCI6IjJjNGFhNzQ1LTk3MzktNDc2MC1hZDVmLWQ4MDkzN2ZhODQ1ZiIsImlhdCI6MTU2NTk1NzI1OCwiZXhwIjoxNTY1OTU3NTU4fQ.6ApGKLv6TxCm1KyywtNbObzY3pL8CbLOp5beOJPLMGk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b0232d65-f60e-4a63-90c6-33c4f3f6cd9e.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Canterbury",
      "countryCode":"GB"
    },
    "firstName":"Darren",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWYwZWZiNjgtMTVjZi00MTg0LWEyMWItNWM2YzFmYTEzOGQxIiwidm90ZXJJZCI6IjAwMzc4OWVmLTEwMGYtNDg3NC05M2FjLTBlZTAxMTQzMmEwMSIsImlhdCI6MTU2NTk1NzI2MiwiZXhwIjoxNTY1OTU3NTYyfQ.9qdg8rHMltUuQaz5dGZwGBIETPgShVDcWEE0Xl71KdQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c69dcc37-4f20-4653-bb8f-458eaa58bb80.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"GROUND ZERO COMICS",
      "countryCode":"US"
    },
    "firstName":"Tony",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTUxZDY2YmQtZWQ0My00NGEwLTk2MDItMjhhNTgxNDI2NzFjIiwidm90ZXJJZCI6IjA4Njg0MTgzLWE5ZTctNDM4My1hNzlhLTQ4M2VhOWU2YzdjNiIsImlhdCI6MTU2NTk1NzI3MywiZXhwIjoxNTY1OTU3NTczfQ.EJa7790wSeBKYKOQzCn4GIO-kz9EsFP6RP5tKLyAuXA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/11080293-1f74-45d7-8b38-42332f345ca4.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - K\u00f6ln",
      "countryCode":"DE"
    },
    "firstName":"Tobias",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmZhNGEyMjItNDk5Yy00MzFlLTg4MTgtMTFjNWQ5ZDE5ZjQ0Iiwidm90ZXJJZCI6IjhmOTA4ODY3LWUyMzAtNDVjOS1hYjc3LTIxOWUxMTkzZGYyMiIsImlhdCI6MTU2NTk1NzI3NiwiZXhwIjoxNTY1OTU3NTc2fQ.W8olhuowUJyB4hUrsuFSd69naHIc7J_KTjcz3Dl1R0A"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d2ead4dc-b0ea-4e14-ad5f-e94082c263fe.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"ON",
      "name":"Games Workshop Coliseum Shopping Center",
      "countryCode":"CA"
    },
    "firstName":"Alain",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDY4MzlmMTMtMjJiZC00MDA0LTgzYTItMjNlZjA2ZDU3MzMwIiwidm90ZXJJZCI6IjQ5ODRiOTUzLTQ5NTgtNDVmYS05ZjRmLTk3NmNmOTAyMjBiZSIsImlhdCI6MTU2NTk1NzI4NywiZXhwIjoxNTY1OTU3NTg3fQ.0HVB87u3vrhJY6F91oQZ4pcKf9-K-vskl7Rh3KBiH4w"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3d393ac8-9d57-4793-b714-d875249a65f2.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"FL",
      "name":"Warhammer - Winter Park",
      "countryCode":"US"
    },
    "firstName":"Robert",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDhmZDQ2N2MtM2YxNy00NzM5LTliMzItMWNiYjhhMjVlZjI5Iiwidm90ZXJJZCI6ImIzOGFjYWMyLTVlNmUtNGVhZS05MzdhLTIyMTQwM2UxMmVjNCIsImlhdCI6MTU2NTk1NzI5MCwiZXhwIjoxNTY1OTU3NTkwfQ.7y_7gDpessgf90KpnaYANLbA3YsAhxC1vanGHg9o9Pw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8f74c3be-51cd-4c16-b038-d114857be622.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Lincoln",
      "countryCode":"GB"
    },
    "firstName":"Adam",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjZmNzQ3MzctYmIxOC00Mzg4LThlOGQtMjYyNTBmNjY2Y2U3Iiwidm90ZXJJZCI6IjY5MTc5NzBiLTI4OTItNDQwMi05NTdhLWZlOTg3MTA4ZjQ5MyIsImlhdCI6MTU2NTk1NzI5NywiZXhwIjoxNTY1OTU3NTk3fQ.PsG1QnQYTMi7d6mjKHtIG2ZSM31de07wbPDNmeB_cRM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c611e2e8-fd3b-4877-986f-72826b3a2eb2.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"GA",
      "name":"Level Up Games",
      "countryCode":"US"
    },
    "firstName":"Ryan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTFjZTE5YTctZTkzMi00YmFlLTk1MDEtNzYxODhmZGIyMDg3Iiwidm90ZXJJZCI6IjMwMDIwYmNjLThjNjktNGJlMS04MjE4LTEzNjk2ZTVjYjAzOSIsImlhdCI6MTU2NTk1NzMwNCwiZXhwIjoxNTY1OTU3NjA0fQ.Rtl8PDlBmSoTTGmsrJPbpYViDLsGopdlZNbaqPTM3Uw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b19c1485-cc80-467e-b703-9850a70d1813.jpg",
    "rotation":0,
    "store":{
      "country":"Hungary",
      "state":"",
      "name":"Tuan Kiad\u00f3",
      "countryCode":"HU"
    },
    "firstName":"Bence",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWFiY2I2ZGQtZTE0Ni00MGI3LTg0YTYtYmRkMzgzNDYzZGNjIiwidm90ZXJJZCI6IjFlMWJjZGNjLTEzZWUtNDY1MC1iYzM2LWNhNjViMGI4YzFlMCIsImlhdCI6MTU2NTk1NzMxMSwiZXhwIjoxNTY1OTU3NjExfQ.ZbVTrLzNUVRcMnHR-KmV8xCf9C7Du1PsSWshFIeA8Ws"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8c86cdda-8f3e-4954-ad4a-3285b6987718.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Games Workshop - Manchester (Central)",
      "countryCode":"GB"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjMwNDM5OWItMzdlMC00OGQ0LWE2NDgtZTAyOGQyMmU2MDhhIiwidm90ZXJJZCI6ImExYTU2ZGMyLTAzNDMtNGVhYy04NGRmLTVjNGE1YzE0ZmQzMSIsImlhdCI6MTU2NTk1NzMxNSwiZXhwIjoxNTY1OTU3NjE1fQ.k8D0XxvAvcB0y-Tf9pfd3wQ9G12RLvi7iq637L4cY5Q"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d900da67-ca3c-4f90-9d2f-36bbbf4eff8e.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"SK",
      "name":"Warhammer - Regina",
      "countryCode":"CA"
    },
    "firstName":"Eric",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODNiM2NlZjUtN2Q4Ni00ZTQyLTgyOWItNGFlNjQzNmQyZmE1Iiwidm90ZXJJZCI6IjYxOGQ0OTAwLTNmNjctNDQwOS1iYzY1LTIwZTA0ZmE4NjQxYSIsImlhdCI6MTU2NTk1NzMyNSwiZXhwIjoxNTY1OTU3NjI1fQ.vc_i7xgMcqqis0luBDxWCN1NESM3nLlZRjatqufScpo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/dbfe7f0e-827a-4e39-8a32-c80c89d6d908.jpeg",
    "rotation":0,
    "store":{
      "country":"Belgium",
      "state":"",
      "name":"Games Workshop - Li\u00e8ge",
      "countryCode":"BE"
    },
    "firstName":"Guillaume",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDRhNzEzNjMtOTQzMS00NzNlLWFlYWMtOGJmMTcwMWU5MmJjIiwidm90ZXJJZCI6ImIzNDg2NTIxLTQzOWYtNGUxYy05ZGNjLTVkOWNlZmM5NDc3MyIsImlhdCI6MTU2NTk1NzMyOSwiZXhwIjoxNTY1OTU3NjI5fQ.BCykXoT2Yo6-Ke9mhosH8YUydCh8e2cYN13TccAjrQs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/99862b6b-5f00-400f-aa81-954b3e3f5109.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Portsmouth",
      "countryCode":"GB"
    },
    "firstName":"Gary",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2RlNDY0OGYtNmU4Zi00MmNlLTlhOTQtNTg1YThiNWFhZmM0Iiwidm90ZXJJZCI6IjdkNGJiZDgwLTVlYjItNDdlOS1iYWMzLWY1MDRiZGRjZjg2ZCIsImlhdCI6MTU2NTk1NzM1MSwiZXhwIjoxNTY1OTU3NjUxfQ.Uoj_OnQWc2QApipUlnpRNRHOBM4CSSrt_PNPICpciZg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/eee7d876-ee10-4645-b3cc-07f8fe2a08f7.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"QLD",
      "name":"Warhammer - Cairns",
      "countryCode":"AU"
    },
    "firstName":"Liam",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGM0NmFhODMtODI3NS00ZjA0LThiZjYtNmRjNTg3ZGJkYjkxIiwidm90ZXJJZCI6Ijk5ZTA0MjVkLTVhYWItNGI4My1hMzYzLWFjMjkyYmFkZjEwMSIsImlhdCI6MTU2NTk1NzM1NywiZXhwIjoxNTY1OTU3NjU3fQ.lBM3kEM3zCeZFX8X29iAApB12RjYWgnKogSc6Z7cO4k"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/46f8f9af-c9b3-486f-9114-7e374ab8eb5b.jpeg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Games Workshop Newcastle",
      "countryCode":"AU"
    },
    "firstName":"Derek",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODlmZDUzYWYtY2RmNS00NzFiLWFjZWQtNTExYmU5NGI1NzI5Iiwidm90ZXJJZCI6ImMxODNmMjk3LTY0OGYtNDFlOS05OTg2LTY1Y2FhM2NiMDgzYSIsImlhdCI6MTU2NTk1NzM2OCwiZXhwIjoxNTY1OTU3NjY4fQ.pFNs-_eUsTevcDjGu6tStKAK8hszyhi77Nuusd1b-c0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c7e17405-0f26-4452-9104-f1e4633e0291.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Stevenage",
      "countryCode":"GB"
    },
    "firstName":"Andrew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjIwNjFkMTQtNDMzOS00YjE5LWEzMDEtZTFmZmJhYmFlOTA1Iiwidm90ZXJJZCI6IjJiNzA4MDdkLWE3MTctNGEwMi1hZDk1LTdjMTE3YzJjMzFjMSIsImlhdCI6MTU2NTk1NzM3NiwiZXhwIjoxNTY1OTU3Njc2fQ.qsstZypBrtr6joDTw59EKK6r16BQut_-fPfjHSlrd8Q"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b759f7c0-60de-4d71-a790-9ebf13ff6a69.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Games Workshop - Westminster Center",
      "countryCode":"US"
    },
    "firstName":"Ralph",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2Q3MWFkYjMtNmNkOC00ZDdjLThhNmItMDU1M2RhYTVlMDI0Iiwidm90ZXJJZCI6ImQxZjEwMTk1LThjNWMtNDUyYy1hMjYxLWI3YmE1NmU4YmZiNyIsImlhdCI6MTU2NTk1NzM4MCwiZXhwIjoxNTY1OTU3NjgwfQ.bjXKZl-kZxz-BWP-hNyc0rEL-Rkt_fH72Mv8zfGxF20"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/497b4cad-6a8d-40be-ae37-3c3e2223bd81.jpg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Warhammer - Roma Via Nemorense",
      "countryCode":"IT"
    },
    "firstName":"Adriano",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjU1NmFkNGQtNTg0My00ZDdmLTk4ODEtMjg0MzMxODZkYzRlIiwidm90ZXJJZCI6IjE2MmMxMzViLTViODctNGZhMy1hMzhkLTQ3NjIyNmViMmQxOSIsImlhdCI6MTU2NTk1NzM4NywiZXhwIjoxNTY1OTU3Njg3fQ.F1ffLxbnS4s8V3FKQjpWcURV2ZkUImXTL2tgQc0EczA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a58de625-b8af-41f3-8f12-a19ef4007919.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Berlin Alexanderplatz",
      "countryCode":"DE"
    },
    "firstName":"Christoph ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGU2M2Q1OTQtMTYxOC00ZTVhLTk4OTItYmU3MWUzYzk4N2MyIiwidm90ZXJJZCI6IjRjZTRiZDNkLTg3Y2EtNDA0YS05NDllLWExZTVjM2UyZTJjNCIsImlhdCI6MTU2NTk1NzM5MCwiZXhwIjoxNTY1OTU3NjkwfQ.ny4jmzODSYo0eyOViPd3DSvBirH2a9r_oG-IIBtj05Y"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1a8f3327-60c4-4400-b395-bc51a2ae6c4b.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"VIC",
      "name":"Warhammer - Bendigo",
      "countryCode":"AU"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjk1NWZkNmYtMjI3My00YWI1LTk1NWYtYWM0MTYwYWVmYTU5Iiwidm90ZXJJZCI6ImUwMTZhNDFhLTIxZDUtNGI1OS1hY2E1LTIyYjg2MWRhNmQzMSIsImlhdCI6MTU2NTk1NzM5MywiZXhwIjoxNTY1OTU3NjkzfQ.1Lk4liAL2O1kTr3WqCGpfe9JkuRZDa7rekNwJJw9Aao"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5ecb4f95-12c7-4dc1-8449-5c6a1356f958.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MI",
      "name":"Warhammer - Ann Arbor",
      "countryCode":"US"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjJmYWFiZmMtMjNkZC00ODczLWE5ZTEtM2Q1YmIxOGVlMjcxIiwidm90ZXJJZCI6IjI2Nzg4M2U2LTEzODMtNDI1Ny05OGI2LTA0ZTljYzM3YWRkYiIsImlhdCI6MTU2NTk1NzQxNSwiZXhwIjoxNTY1OTU3NzE1fQ.jugs4C7W6fyjZC5VJWAuwZ_zJlEqB2gtSnnoc3fffZI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f95599f6-d99a-4c4a-bff7-23b74a8fd10e.jpg",
    "rotation":0,
    "store":{
      "country":"Korea, Republic of",
      "state":"",
      "name":"ORCTOWN",
      "countryCode":"KR"
    },
    "firstName":"Junsan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2I5YTRmMTctYjE3Ny00NTZlLTkzNGItYjhkNDMwMmY5OGFkIiwidm90ZXJJZCI6IjQzNzZhZGRiLWQzOWEtNDEyYS05NjdjLTY3NmZkMTUzNDM2ZSIsImlhdCI6MTU2NTk1NzQyNiwiZXhwIjoxNTY1OTU3NzI2fQ.cI_aD65VkMtyMsRctMM4Pq96AMEDuBfNjUK6QTwgHmU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/54ac2049-c0da-4074-967d-d6c9c4bf7192.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"ENTOYMENT",
      "countryCode":"GB"
    },
    "firstName":"Lee",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDAzY2ZmZjQtYjYwYy00ZTNiLWI1YjQtZWU5NjZiOWM3NTdkIiwidm90ZXJJZCI6IjM1NGNlY2M5LTI3Y2EtNDg4ZC05NDEyLTkxYmNmZGNlMThjMCIsImlhdCI6MTU2NTk1NzQ0MCwiZXhwIjoxNTY1OTU3NzQwfQ.jbq4vC5OVpyv5nb0mBczX9XpLLohLslJvM8VzpxNjdE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6bac1b19-3b67-466e-8bed-7bea8b16c89b.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"DRAGONS LAIR - AUSTIN",
      "countryCode":"US"
    },
    "firstName":"Seth",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGQ3NWFmYWItNzllZC00ZjNjLTg2MmMtYmM2YWRjMDQ4ZGYzIiwidm90ZXJJZCI6IjZjNWRlMGE5LWYwMTYtNDQyNi1hZmE2LWQzMmY1NGRkNzcxYSIsImlhdCI6MTU2NTk1NzQ1MSwiZXhwIjoxNTY1OTU3NzUxfQ.Pj7rqRmn5OxBM47rk1jRBxpaGLpR7Tovhzm8lKaE_JQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6ba37105-779d-443e-b190-08d804ab2375.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Warhammer - Baulkham Hills",
      "countryCode":"AU"
    },
    "firstName":"Patrick",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDc2MGRhOGEtNzhhYy00MDkwLWFjMDItOTkzYzE5YmI0NWFiIiwidm90ZXJJZCI6IjU2ZTFhZThlLTNmN2MtNDE5OC1hOTc2LTg1YTljYjdjNDgyMCIsImlhdCI6MTU2NTk1NzQ1NCwiZXhwIjoxNTY1OTU3NzU0fQ._ObCbsL4V3nhaiN7jZxFlvkzCjbsw3UL_WJp4XP4wmQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c99ced6d-f023-4d79-909f-63145137fb11.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Nantes",
      "countryCode":"FR"
    },
    "firstName":"Yohan ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTg5MTNkOGQtZTRiZi00MWYyLWI1NTEtYjY3ZDI0ZTUyOGI4Iiwidm90ZXJJZCI6IjJkNzFjYzYwLWNiYzctNGQ5Ny04MzhmLTZjYzU4OGQ0ZWUzZSIsImlhdCI6MTU2NTk1NzQ1OCwiZXhwIjoxNTY1OTU3NzU4fQ.BcwfqTcJMSiVxYsZ4QkPR_AnBkDlylq-mFpc8OeSsaw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6fe95d64-326e-460c-bba6-33b698776dd2.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"JOUECLUB THONON",
      "countryCode":"FR"
    },
    "firstName":"Jason",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWViNGQzYzMtN2Y3OS00OTEwLWJkNTUtNGU2MzRkOWUxOWU0Iiwidm90ZXJJZCI6ImFlMDdjYmY4LTVmZWItNGQxMy1iNjIzLWU2NDM0ZDllNmQxMiIsImlhdCI6MTU2NTk1NzQ2NSwiZXhwIjoxNTY1OTU3NzY1fQ.i-rmA2XUX3kp_cbNugTd96pJ9SJhSYFuLipGf00IsGU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f004e82b-fa13-46af-8901-1007394b64ed.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"OH",
      "name":"Games Workshop - Hyde Park Plaza",
      "countryCode":"US"
    },
    "firstName":"Rachel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjI3NGI5MDctMzZlOS00YmE2LWE0MDktZWMwYTZiNWY5NjVhIiwidm90ZXJJZCI6IjIwNjZkMjc4LWI1ZWYtNDM5NS04ZTVlLTMwYzEzYTM4MGYxNiIsImlhdCI6MTU2NTk1NzQ3MiwiZXhwIjoxNTY1OTU3NzcyfQ.KaKe64X_Yno6xuIBSZB9MBuRtpu5cFP_-SDtHtwv_Ck"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b6072fcb-a81b-4053-b8ee-95a65142cd05.jpg",
    "rotation":0,
    "store":{
      "country":"Ireland",
      "state":"",
      "name":"Games Workshop - Dublin",
      "countryCode":"IE"
    },
    "firstName":"Marco",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmEzYmIxYWEtZWQzMC00MjJiLTg4NWItMjE2ZjU3MTVmNmFjIiwidm90ZXJJZCI6IjI1Y2Y2OTRjLWM1N2MtNDBhZS1hM2U0LTVmODgwZTllODJjZCIsImlhdCI6MTU2NTk1NzQ4NywiZXhwIjoxNTY1OTU3Nzg3fQ.c3oCTlIhow6npyX-0PNTEenHMthTc4uhkgPyRvD6q4k"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/074c08ed-808b-4a6f-a95d-b7234ebd4f44.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MO",
      "name":"C AND K CARDS GAMES & MORE",
      "countryCode":"US"
    },
    "firstName":"Brett",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzI0MDdkZWMtZDgwNy00YTAwLTk1MDUtN2Q1OGZiNTFlMTNmIiwidm90ZXJJZCI6ImYzMTJjMDQ2LTEwZmEtNDhmNS1iMjBiLTU3ZDhiNTM5YjE1NyIsImlhdCI6MTU2NTk1NzUwNSwiZXhwIjoxNTY1OTU3ODA1fQ.6GieUfWd06bFZVP8jUCEqJQ9ZQVl8QePAxnVrxVX4Yk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/326dbd8a-4288-4c91-8f28-c2739118ddea.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"SC",
      "name":"Games Workshop - Shoppes at Woodruff",
      "countryCode":"US"
    },
    "firstName":"Dylan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2VjMTZjYWYtYTU1Yi00MmQ2LWE3ODgtNjQxOGIxMmM1ZDA0Iiwidm90ZXJJZCI6ImU0OGE0ODcyLTM3MjYtNGI2Yi05MzZiLTM1YjJmOTVlNjcyYSIsImlhdCI6MTU2NTk1NzUxMywiZXhwIjoxNTY1OTU3ODEzfQ.bs5JWnJ-PUCZBuu2g5XPQQSUcgrjtZLeziiO6Kuxe5U"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cb28a41f-b20f-4ff9-9bc9-554cbe4b7597.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NE",
      "name":"Games Workshop - Westwood Plaza",
      "countryCode":"US"
    },
    "firstName":"Shawn",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGNjZmZmNDUtYTFkNS00YTA2LWE4MDAtMjYwNTJjNTgwZDI4Iiwidm90ZXJJZCI6IjEwZThmZmE0LWRhMWUtNDlhOS1iY2M0LTY3ZDhkNThiMWE0MSIsImlhdCI6MTU2NTk1NzUxNiwiZXhwIjoxNTY1OTU3ODE2fQ.RdzIgX_WYtkJPRwNru1-IE_PCaayRGuxUBzNwPcy810"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cdf83ad9-e950-4c53-af3b-fde9987747d6.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"",
      "name":"General Games Pty Ltd Frankston",
      "countryCode":"AU"
    },
    "firstName":"Corey",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGE5MmMyY2QtM2NkMi00MTQzLWI5NmEtYTZkOTg3NmNiMzY3Iiwidm90ZXJJZCI6Ijg4NzVkM2MxLTA1ZWYtNDI0Yi1iM2IwLWQ2NTZmNDAxYmFhZSIsImlhdCI6MTU2NTk1NzUzOCwiZXhwIjoxNTY1OTU3ODM4fQ.Xt4gny1z-jC9zIb8JdaPUXdI0yM-xM8MUA1Ne_ipa_4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/67fc05f3-85ab-4346-9214-a44affe53cec.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"QLD",
      "name":"Warhammer - Maroochydore",
      "countryCode":"AU"
    },
    "firstName":"Peter",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODc0MDg1ZDktMGI1Yy00ZjNkLTg5MTktZmJiZWVlNGFjYjdmIiwidm90ZXJJZCI6IjYwNDM2NTZhLTVkZTYtNDg1OC05ZDUzLWMxYjlhZTAzOWIwMyIsImlhdCI6MTU2NTk1NzU2MiwiZXhwIjoxNTY1OTU3ODYyfQ.mW1yymNYbCkCsA8Bno-mNKamFenvN3tbQhAdeu56Rco"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5d8da7e5-6d28-4c55-a2d5-981e9cb2bb1e.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Skay store",
      "countryCode":"RU"
    },
    "firstName":"Anastasiia",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2FjNDRmOTctZjk3Yi00MTM3LWI1ODUtYjM5YjMyMWRjOGQ0Iiwidm90ZXJJZCI6IjdkNDFmOTE4LWMyNDUtNDQzZi1iODFkLTY3NTMxYWFjNzc1OSIsImlhdCI6MTU2NTk1NzU3NiwiZXhwIjoxNTY1OTU3ODc2fQ.MRMamDAaetBuGNEnxAoyTRvo0qNdoryYlkJfbfxbzS8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4e799cd0-3181-4441-a2ec-2996a4558b00.JPG",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Bielefeld",
      "countryCode":"DE"
    },
    "firstName":"Kira-Marie",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTE4NGRiMjctZmFiMy00NDQ0LWI5NWMtZDE5NjQyYWY0YjNmIiwidm90ZXJJZCI6IjQ0MThhMjIyLTNhOTctNDM2MC04ZGZlLWVhNWRhZjM3NmFiZCIsImlhdCI6MTU2NTk1NzU5MSwiZXhwIjoxNTY1OTU3ODkxfQ.CN_eoj8zyiTjuMmJy5KQLN7a8cSNjeNzIDDWDpI-JRY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/250f71de-586f-4a49-a49f-7cf2b205529f.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Linebreakers",
      "countryCode":"US"
    },
    "firstName":"AJ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTBhN2YyOTktMjVlMS00ZjFiLWIxN2UtMzNmYmIwMDE5ZWU1Iiwidm90ZXJJZCI6IjRmMjJjNGFkLWMzZTYtNGEwNy05ZDY2LTE2MmNkNThmM2E0NyIsImlhdCI6MTU2NTk1NzU5OCwiZXhwIjoxNTY1OTU3ODk4fQ.pubvAEapI09Rc7VeC-8fulUpd0JG0KjXkht_TAB8XgA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e024c1f2-4f2f-430c-9f13-0a9fa06afcec.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Warhammer - Lille",
      "countryCode":"FR"
    },
    "firstName":"Pierre",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDAzODQ2NDAtM2JiMC00YmFmLWJhMWYtYWEwMGYyYmExNDgxIiwidm90ZXJJZCI6IjQ2ZjNjODk4LTJhZjgtNGUxMS04M2FhLWJhZjM3YTc3ZjdhNyIsImlhdCI6MTU2NTk1NzYwNSwiZXhwIjoxNTY1OTU3OTA1fQ.VaflUMFMlWnI73M9jj6RP4v2cm4k-_pT1XFXg9pZpbI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8afd3702-6650-4f91-905e-0dc989cb018d.jpg",
    "rotation":0,
    "store":{
      "country":"Denmark",
      "state":"",
      "name":"AALBORG SPILFORSYNING",
      "countryCode":"DK"
    },
    "firstName":"Anders Juul",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDZlMTlkMDUtNWZkMS00ZDU2LTkyOWQtMTFiYjY1MjUyYjk3Iiwidm90ZXJJZCI6ImFmNGIyMTU2LWNmMmYtNDcyNi05MzE2LWUyYTNhYTg0YTIxMiIsImlhdCI6MTU2NTk1NzYyNiwiZXhwIjoxNTY1OTU3OTI2fQ.w6QyYyMLKvtr5gnnqdp1HVUvrKbLrykuFQL1mZLnDMU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a8f4e67b-14e4-4b84-9a45-e9ba7711f576.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Games Workshop - Southend",
      "countryCode":"GB"
    },
    "firstName":"Peter",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWY1MTc4YzYtODczOS00NzQwLTlmYTQtM2EzYzFjNzVlMTZkIiwidm90ZXJJZCI6IjdlYzM2MjcxLWU2NzEtNDk0OC04NDY3LWE3MWI5OTQ1YzdjMCIsImlhdCI6MTU2NTk1NzY0MCwiZXhwIjoxNTY1OTU3OTQwfQ.d2a5lI1_CknbWThLc6YULZkKPtlGgNh8Nz_5iocP5UU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9685e962-26cd-45fe-991f-7fa793f53c81.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"VA",
      "name":"Games Workshop - Tower Center",
      "countryCode":"US"
    },
    "firstName":"Brock",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjg5N2M1OTYtOGY3MS00OGZiLTk3YTktNmNmNDRkNDNiZmNkIiwidm90ZXJJZCI6IjcwYzQyZDNlLWFiNjYtNDVlMC1hZWQxLTQ3YmJlYmQ5ZTM1MyIsImlhdCI6MTU2NTk1NzY1NCwiZXhwIjoxNTY1OTU3OTU0fQ.rBeahCLEEUcRyeD4-FKoON02GLVmJPTtrLKAYJHBakc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0781953f-2e42-47f2-b288-5ab2301ce84d.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"",
      "name":"BLACK KNIGHT GAMES",
      "countryCode":"CA"
    },
    "firstName":"Aj",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjEyZjRmNTItNGVmOS00MjQ5LWI1MzgtN2RmYzI2Nzg2NzBiIiwidm90ZXJJZCI6ImQ1YWI1ZWYxLWE0ZmYtNDZmNy1hZjFkLWJjNTczNGViNTVhMSIsImlhdCI6MTU2NTk1NzY1NywiZXhwIjoxNTY1OTU3OTU3fQ.-dgwY6INEHc4VTDvYtAlSvqLb5S8zYSzbp5SA-BQF0g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5184576a-4ed2-4045-b33a-fbb8ab437c83.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Croydon",
      "countryCode":"GB"
    },
    "firstName":"Matt",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzc3MjRkNmItOTZmZC00OTE2LTlmZTYtNWYzYjY4MDVlN2YwIiwidm90ZXJJZCI6IjFiNmZlMDUyLTlmYjMtNGU4MS1iM2M1LWYxNDFkZWY4NjdmYiIsImlhdCI6MTU2NTk1NzY5MiwiZXhwIjoxNTY1OTU3OTkyfQ.ojK6_NMhrTRAS4YAFolXedlB6Nzh8ioLV6v1XK7_44o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cb411051-3569-447d-afc7-93bd168487a9.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"SD",
      "name":"Warhammer - Sioux Falls",
      "countryCode":"US"
    },
    "firstName":"Genevieve",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmNiYzY5ZTctNTU3ZC00MGNhLTk1ZDMtZGQxNDQwNTZkMGUxIiwidm90ZXJJZCI6ImNlMmY0ZDZiLWY1MGYtNDZhMS04MTU3LTJhMmNmMmI1N2NjNyIsImlhdCI6MTU2NTk1NzcxNywiZXhwIjoxNTY1OTU4MDE3fQ.i5fvgyRb21bHtEYjad5kkRroE5FFaRi9RqhrnOVvdck"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/dae340c2-b29d-4c5d-a72a-f9323bb19aa9.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Bochum",
      "countryCode":"DE"
    },
    "firstName":"Julian Konstantin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjkyNGIwNTYtNzM3My00ZGVhLWIwZGQtYTYxNzZiMWFjZjYyIiwidm90ZXJJZCI6ImM4MDAxYjI2LTYzYzgtNGYyMi1hYTFjLWU3NTczYmQ4ZmVjZSIsImlhdCI6MTU2NTk1NzcyNywiZXhwIjoxNTY1OTU4MDI3fQ.61UCsfMi_PcDS6NSReIW3bvqE0XHA7IZBDum1800Idc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e99400bd-892f-4e18-a4b1-605505f4510e.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Wiesbaden",
      "countryCode":"DE"
    },
    "firstName":"Yasmin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDRhNTgyZDItM2JjOC00ZGExLWJkN2UtNGE4NTgwNjgyNGYzIiwidm90ZXJJZCI6ImQyOTU2M2M3LTRkODgtNDYxMS04OWNjLTVmZDNiYjE3ZGMyMiIsImlhdCI6MTU2NTk1NzczOCwiZXhwIjoxNTY1OTU4MDM4fQ.nGuN3cxhyW7qW_1dJHUhKQDHIr_W6FXjaDmpr30fk-o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/85e4bff0-8694-499f-9469-3fe809aefe8f.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Games Workshop Liverpool",
      "countryCode":"AU"
    },
    "firstName":"Matthew ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDc2NzBlYTctZDU1OS00YTE1LTg2OTQtY2UzYmFjMzIyNWYyIiwidm90ZXJJZCI6ImIyYWY3NGZiLWY2MzUtNGI4MC04NGRhLTJiZjg5ODcwMmViNyIsImlhdCI6MTU2NTk1Nzc1NSwiZXhwIjoxNTY1OTU4MDU1fQ.KAifba66caHTnhyNQgypy9XCx6sSny4OvJQ7RmOCdfc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a690f9d2-f270-45d5-81bc-109be29f27eb.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Games Workshop - Lake Crest Village",
      "countryCode":"US"
    },
    "firstName":"Thomas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmNiZTM1YTgtMmMyOS00ZjlmLWI2YzEtMjg3MmE1YjU4OTBmIiwidm90ZXJJZCI6IjBmM2ZkMjgzLWZiMTMtNDI5Ni04ZTgxLTYzYzNkOTcxYWM2NiIsImlhdCI6MTU2NTk1Nzc2OSwiZXhwIjoxNTY1OTU4MDY5fQ.xQJ1EREd2DpcrjgSha9pcssMN_rHRoinDhBpXXxiJMQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/92002260-edb5-43ad-a0d4-ae8dbabb9249.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"UT",
      "name":"GAJO GAMES",
      "countryCode":"US"
    },
    "firstName":"AJ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTQ0ZDA3MjItMTc2Ny00Mjg0LWIxYjMtM2ZlMjUyNGVjZjJkIiwidm90ZXJJZCI6IjNjZGNjYWM0LTljNjEtNDljZi1iNGEwLTAzN2EwYmEwZDFiYSIsImlhdCI6MTU2NTk1Nzc4MywiZXhwIjoxNTY1OTU4MDgzfQ.M1f1690jBnRcYvFmE7HJCsq_1NEcSIlGucGgHw9fecQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/211d71aa-ac55-4b6b-a44a-b5e70367a85f.jpeg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"VIC",
      "name":"Games Workshop Cheltenham",
      "countryCode":"AU"
    },
    "firstName":"Jacob",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGE2MjhjOTItOTg2Yy00NjYwLTgyOTctOTNmNWZjNWM1YTcyIiwidm90ZXJJZCI6IjcxNTBjNTg0LWMwMGEtNGU0Zi05YmRhLTdiMTZjZjkwZmY5MSIsImlhdCI6MTU2NTk1NzgyMSwiZXhwIjoxNTY1OTU4MTIxfQ.6Plbwba2SfR4wU-1c2DRytSl3BUVllrlLlNZu_pxOqo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2928f13c-e2c8-494e-8ab5-f16e5adfa0fd.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Halle",
      "countryCode":"DE"
    },
    "firstName":"Jakob",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTliNTEwN2EtZDkzYy00YjAxLWJkNzEtZmI1YTJjM2MzZDliIiwidm90ZXJJZCI6Ijg4NTNkYmUyLTQ4ODYtNGI0OC05ZDE1LTFhMmQyNGRlMzQ3YSIsImlhdCI6MTU2NTk1NzgzMiwiZXhwIjoxNTY1OTU4MTMyfQ.5aXx2NCywczOmK3PkpMVbxpgAl1iEjn0-jPqHGYrNUI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d4fc6831-8182-41d4-b6f1-fe1c29cb715e.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"WARGAMESTORE",
      "countryCode":"GB"
    },
    "firstName":"Gerrad",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWFjOTYyOWYtNDJiYS00NzBlLWI2ODktY2YzMzkzYzUyYmY3Iiwidm90ZXJJZCI6ImMxZDgzMDA1LThlMzEtNDAzNy05MzFlLWQxODdlNDRiNWYwYiIsImlhdCI6MTU2NTk1Nzg0MywiZXhwIjoxNTY1OTU4MTQzfQ.x169cIOQjZfzbvHT6xcBCpuP0zBvpmcrP4aoxOfF9mA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a3db4f3e-926a-4e39-9de7-48e14a734d08.png",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"TROLLUNE",
      "countryCode":"FR"
    },
    "firstName":"Thibaut",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTI4YzRlMTAtYTA2My00ODI1LWExYzgtZTc1ZjM1MWI3MDE0Iiwidm90ZXJJZCI6ImNiMTgwYzljLWZmNzMtNDY3Ny1hODFhLTQ0YWE0NzlmM2U4ZSIsImlhdCI6MTU2NTk1Nzg0NiwiZXhwIjoxNTY1OTU4MTQ2fQ.hOjnKRRxq8HRETCrE-dwDwW1IKbzyqPYEhetCBGxah4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/822fc31d-51d3-4209-9e21-296df382a9a6.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Truro",
      "countryCode":"GB"
    },
    "firstName":"William",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGMxNDljODUtYWY3Yy00YzUzLTgzYTEtZjg1ZTlhODM1ZGY5Iiwidm90ZXJJZCI6ImMzODE3NTMyLTNjZDgtNGYyMy1iZTZhLWE1MGNlMDcxYjExMyIsImlhdCI6MTU2NTk1Nzg1MCwiZXhwIjoxNTY1OTU4MTUwfQ.bPDIQDlFosLAVG6CC1IFDJuRc7fW4oke-3D15JdW0VA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/786aca0b-f154-445b-9844-e308c2eb9046.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Trier",
      "countryCode":"DE"
    },
    "firstName":"Alexander",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2EzNjZhNzMtMTZkMC00ZmM1LThhNDMtN2YxNWZiZjVkNDE4Iiwidm90ZXJJZCI6IjkxOTc5NzU5LWFlNTQtNDhjNS04MDExLTkzMTVlNjdhZjU5YSIsImlhdCI6MTU2NTk1Nzg1MywiZXhwIjoxNTY1OTU4MTUzfQ.lowXb2_HSZG940cVit2SLWAwzr-9nhanwsEARhIFNhc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1a9bfc59-120f-4155-b6fd-ae6545e994f4.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - York",
      "countryCode":"GB"
    },
    "firstName":"John",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTk1MWViY2ItMDE3NC00NmQ5LWEyMTgtOTgzNDFhMjU4ZTE1Iiwidm90ZXJJZCI6ImY4NzlhMGY0LTYxMGYtNGYyYi1iMjQ0LWY0MGM2OGZjMThlNiIsImlhdCI6MTU2NTk1Nzg2MCwiZXhwIjoxNTY1OTU4MTYwfQ.FSQj1wqXCoHqpjEptec290hBLHfJijiYj22RdC_s2CE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/aea876ba-87e4-469a-951e-7ff1f8595745.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Montpellier",
      "countryCode":"FR"
    },
    "firstName":"J\u00e9r\u00e9my",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGVmYWMxZDAtMzk0My00ZWFjLWFjMDgtMGNkYmQ2OTZiZTk2Iiwidm90ZXJJZCI6IjhiMjkzNDBiLWNkMDgtNDdlMS1iOTg1LWFkYTA0NWRhY2ZmMiIsImlhdCI6MTU2NTk1Nzg3NCwiZXhwIjoxNTY1OTU4MTc0fQ.H9frvqBF3vJQEOeAb-kK_65LvbbpNbwe4fMPYUoeLS0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/79d6424b-e961-4b82-b4ef-d75675814738.jpg",
    "rotation":0,
    "store":{
      "country":"Philippines",
      "state":"",
      "name":"The Dragonforge",
      "countryCode":"PH"
    },
    "firstName":"Milan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWE5NDllZDQtZTU0NC00ZjcwLThhN2UtMDZmYzk3YWU2ZGJmIiwidm90ZXJJZCI6IjJlMzRhMTA4LWE4ZmItNDJhMS04MDdjLWY4OTMxMjczMDNhMyIsImlhdCI6MTU2NTk1Nzg4MSwiZXhwIjoxNTY1OTU4MTgxfQ.MXm110jQVRJghy9eILvgQ3k65sG2b5V2kZFi8jtMYqE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1bc678bf-fe4b-4671-80cf-c59eccdecce9.jpg",
    "rotation":0,
    "store":{
      "country":"Poland",
      "state":"",
      "name":"FGB.CLUB",
      "countryCode":"PL"
    },
    "firstName":"Tomasz",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjg4NGI2MTQtMDM0NS00ZmE4LThjMjQtYWZmZDBkZWQyNDliIiwidm90ZXJJZCI6ImZmYjljNWJiLTIwYjUtNGQ0Ny1hMGQ3LWIwZGE0YTVmYzI1ZiIsImlhdCI6MTU2NTk1Nzg5MiwiZXhwIjoxNTY1OTU4MTkyfQ.NKUPVKCyZaQLU4wMFL4n4-BAll0UHGQ3rMuEzrWo0I4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/74352dd1-932f-4d10-af6d-d4ce11e0783c.jpg",
    "rotation":0,
    "store":{
      "country":"Poland",
      "state":"",
      "name":"TRZY TROLLE MICHAL OFMANSKI",
      "countryCode":"PL"
    },
    "firstName":"Piotr",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWQ3ZThhZjAtM2EyYi00Njg4LWExY2QtNTk5MmE4NjJlMDdhIiwidm90ZXJJZCI6ImUzNGRlNTRhLTZmY2EtNGMyMC1iMzFlLTZjNjVjYWVkMzM4NCIsImlhdCI6MTU2NTk1NzkyMCwiZXhwIjoxNTY1OTU4MjIwfQ.Cpgs12jX2uSc6fjwHEjx2MLleWLa62W689XKZYyWVqg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2dcddbee-c561-460b-88cc-27df1f5f10ad.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Chiswick",
      "countryCode":"GB"
    },
    "firstName":"Michael",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjkyMDNhMzYtMDY2ZS00OWNkLWFjOGUtZWZlZGNiNTgzNTdmIiwidm90ZXJJZCI6IjM5ZDYyNTIxLTIwY2ItNDFlMC05ZWE3LThlZWVjNDc1ZjBiNiIsImlhdCI6MTU2NTk1NzkzMCwiZXhwIjoxNTY1OTU4MjMwfQ.aD-WiBaZEDIw8WSBouK7ArVSXyQvJITNWo4DbQ932P0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8e9ded5a-9499-4a3b-894c-aa880f4fefb0.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Berlin Tauentzienstr.",
      "countryCode":"DE"
    },
    "firstName":"Christoph",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWFkNGRhNTQtZmY3NS00YmFhLWIwMTUtN2FmZjg2MTQyMzdhIiwidm90ZXJJZCI6Ijg3NjY1MjEwLWQ4ZTItNGNkZC05NzYwLTI2NmI3ZTc3NGRiMyIsImlhdCI6MTU2NTk1NzkzNywiZXhwIjoxNTY1OTU4MjM3fQ.TRHFRNFJTTxNgwZIWGG69vHdN1lqBQfA5eM4n9XK6Ik"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d2be5727-21aa-4fa7-bf43-08f3cb2942da.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"KY",
      "name":"Games Workshop - Man O War",
      "countryCode":"US"
    },
    "firstName":"Jason",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWEzMDRhN2UtMTFmOS00Y2ViLWJhMDEtZTU5ODlhZGI1NjdiIiwidm90ZXJJZCI6IjFlMTcyNjczLWMzYWMtNDE5Zi1hZGU3LWI5OGNmYmViZDkxMCIsImlhdCI6MTU2NTk1Nzk0NCwiZXhwIjoxNTY1OTU4MjQ0fQ.0sHlII7pHTN4P3hi5dkX-lLIkvF7L5m3yVK0XwRiFzM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9889f4e1-fb6d-45ba-810f-c1ba02c6cc0c.jpg",
    "rotation":0,
    "store":{
      "country":"Sweden",
      "state":"",
      "name":"LEGEND SPELBUTIK AB",
      "countryCode":"SE"
    },
    "firstName":"Klas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGI3YTZkNmMtNzkxNC00ZGM0LTlkMTAtMjRlMTcxM2FiYzY5Iiwidm90ZXJJZCI6ImU0OWM0ZTQ0LWRlNDctNDBmOC05MWUyLTc1NTQwOTQwOWYxOSIsImlhdCI6MTU2NTk1Nzk1OCwiZXhwIjoxNTY1OTU4MjU4fQ.w64hkSW_bDrdlQYR2FsK82i4z5IqCQ9MRvHk0Q0M_kM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2aab4813-ff93-4074-8d84-7648a999f33b.JPG",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Kiel",
      "countryCode":"DE"
    },
    "firstName":"Mark",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDlhOWY0NmEtNzE1Zi00NTdmLTg3ZWItYTJhM2RjN2M1ZWI3Iiwidm90ZXJJZCI6ImUzODRhMWNjLTY2MWItNDg3NC1hOTExLWNiYTJjYTA4ODk4YyIsImlhdCI6MTU2NTk1Nzk5MCwiZXhwIjoxNTY1OTU4MjkwfQ.bAtU0WcO0mXj4MxVGVNMMAicDe0BqC5BHw5LMUvpCVs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0ea0aafd-8157-40e9-996b-062dccfd50d8.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Nuneaton",
      "countryCode":"GB"
    },
    "firstName":"Rachel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTMyYzBmYjUtOTg2MC00ZTYwLWIyMzgtMTA2Y2RmZTQ2ODc2Iiwidm90ZXJJZCI6IjBmNmI2N2Y5LTNiODktNGU2YS1hZGU0LTYwMjhjMjA3OGU5MiIsImlhdCI6MTU2NTk1Nzk5MywiZXhwIjoxNTY1OTU4MjkzfQ.FXo8IrpKahEZPwKMCa92QnX18drlPswG7D7DK5-gTz4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fa07b1c4-d140-42d5-abcf-fcf12a8c57f1.png",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Stuttgart",
      "countryCode":"DE"
    },
    "firstName":"Spyros",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzhmZDE2MWYtOGFkMi00OWZkLTgxZmQtZWMwODdmOTE2ZjRkIiwidm90ZXJJZCI6IjNjZmU1NjAzLTlmMzAtNDI0Ny1hMGExLWVhOTQ5ZDdiOWE0OSIsImlhdCI6MTU2NTk1Nzk5NywiZXhwIjoxNTY1OTU4Mjk3fQ.AgGmEQb4c-Iz0C8io5ZY2vc2un_Ml7DKTGByVEXgbcs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3167d65b-41b2-46e3-a29f-6a67a5c2b14d.jpg",
    "rotation":0,
    "store":{
      "country":"Croatia",
      "state":"",
      "name":"Carta Magica",
      "countryCode":"HR"
    },
    "firstName":"Miljenko",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmFmYTIyYTctMmVjNi00ODNmLTllN2YtNGIxMTZjOWM5OTFkIiwidm90ZXJJZCI6ImVhNWVjNDMzLWEwZTUtNGUzYS05MjdlLTE4ZGRmOTJkMDNlNiIsImlhdCI6MTU2NTk1ODAwNCwiZXhwIjoxNTY1OTU4MzA0fQ.QPiVXTUx0pPh_MpLwiLn7_IWbU9JW1xaPEM9deGk9AA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/725d2667-2d29-416d-8889-fae27c1eaa30.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Games Workshop - Morningside Plaza",
      "countryCode":"US"
    },
    "firstName":"Brandon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTU5ODliMDItZDg2OS00MGRmLWFkZjUtMmNhNzgzZjM3OGQ2Iiwidm90ZXJJZCI6IjIzNWY1OTNjLTRhOTQtNDBjNi1iNDA4LWFmNTZmZGE2N2UzMyIsImlhdCI6MTU2NTk1ODAxNCwiZXhwIjoxNTY1OTU4MzE0fQ.LRzNpD6w7MTbd6_agfYjfHCvOGnuq0xPEjPJ5t9KS2Q"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f61af909-6b7d-4c1b-b1c5-7b1e0cd66dc8.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"",
      "name":"Game on Cards and Games",
      "countryCode":"AU"
    },
    "firstName":"Damian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmNkOWJmZTYtNGI4Zi00Y2RmLTk5ZjAtOTgxZTBiM2JjZGFlIiwidm90ZXJJZCI6IjU1MTUwMjIwLTg5OWQtNDM1Yi1hYTA3LWRmMDFhMTlmOTE3NCIsImlhdCI6MTU2NTk1ODA0MiwiZXhwIjoxNTY1OTU4MzQyfQ.0V6XEs1r-3rBkwc4rjDrO6j5HyGkCU4xhOQNoIPVY9A"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/aff96df4-362d-4cf7-a1f5-380d3c79e864.jpeg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"GUNSHIP GAMES",
      "countryCode":"DE"
    },
    "firstName":"Sven",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmFjN2Q1MmEtMDlhYy00YWE3LWI0ZmQtZDY4ODMwZGQzM2YzIiwidm90ZXJJZCI6IjI3ZWZiZDBmLWU1OGEtNDdiNy1iZDRhLTA3MTcxOTYzYjhjMyIsImlhdCI6MTU2NTk1ODA2MiwiZXhwIjoxNTY1OTU4MzYyfQ.UdTEz_VgrzuOx1n2lGVLceuDokyVXkulRYtamwXVcvY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/088430a6-c3dc-46e6-a6c3-393a42df7cb9.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Durham",
      "countryCode":"GB"
    },
    "firstName":"Gerald",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODM1ZTg2YTgtZjNiYy00N2MzLWI0MzgtNjM2MjEyMTY5NmVhIiwidm90ZXJJZCI6ImFkN2ZkOGQ0LThjMmQtNDQ0MS1hOTMyLTg1NmNlNGFmMTc0MiIsImlhdCI6MTU2NTk1ODA2OSwiZXhwIjoxNTY1OTU4MzY5fQ.xCe-u6jsLMb6Tf8Tysgp6KUnO2fXYPRPqhdeSA4jzQ0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/aae2cc5f-d68d-46d0-bc31-131e74c87c06.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IA",
      "name":"FIRST TURN GAMES",
      "countryCode":"US"
    },
    "firstName":"Jason",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjRkZTAyYTMtMGJmZC00YzFiLTk5ZGQtN2RjMGNhNmUyNmFkIiwidm90ZXJJZCI6ImRiMTY3ZjgwLThhMDMtNGQ3OS05ZmY0LTVkN2RjNzNiMzAyYyIsImlhdCI6MTU2NTk1ODEwNSwiZXhwIjoxNTY1OTU4NDA1fQ.syWghp8j_7iLNxfRNQQLQypL06ewDcgxThQYzrnANsQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f1c5a9af-f929-4851-b09c-882427259454.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MN",
      "name":"DREAMERS VAULT (ST LOUIS PARK)",
      "countryCode":"US"
    },
    "firstName":"Jack",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTMxMGVlNWUtMmQ4OS00NzZlLWJkOTktNTQ5ZjAwODlkM2FlIiwidm90ZXJJZCI6IjhiZWY5NTY1LTM5ZDMtNDlhYS1iYmZjLWRkMWY1Y2FkZGY2MiIsImlhdCI6MTU2NTk1ODExMiwiZXhwIjoxNTY1OTU4NDEyfQ.2TJTJeLE1jevzVNC6Wc-Ld2QNaCaQ15oW0a8eO9l27M"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a41b068b-9bcd-4375-b95f-453f796a5ab3.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Bonn",
      "countryCode":"DE"
    },
    "firstName":"Jonas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWJjOWY1NjItMDhlNS00YWYyLWFjOWYtZGNjMTNjYjgzNzM1Iiwidm90ZXJJZCI6ImI3MDAyM2ZjLTllZDItNGNiNC1hZGJhLWYwNzRlMzNmNDZkMCIsImlhdCI6MTU2NTk1ODEyMywiZXhwIjoxNTY1OTU4NDIzfQ.VG2BuUCAWU6SlzKTvI6I0-6FjCMQ_o49TvVOxIkcVp8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3c4a32e3-195a-4ae0-a849-781549b82e59.jpg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Games Workshop - Monza",
      "countryCode":"IT"
    },
    "firstName":"Giulio",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzJmODE1ZGUtY2EyZS00MGJhLWE5YTEtYTZkNThjNTNlNjUyIiwidm90ZXJJZCI6IjhhNzdkMTM4LWRiNDgtNDlhYi04NGZkLTJmNGQ2NDU4ZjZkNCIsImlhdCI6MTU2NTk1ODEyNywiZXhwIjoxNTY1OTU4NDI3fQ.eeZtrJc8uGyixULtP8f2iQOD_jj8N4smdABg9jaaDmM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e7c1873e-2d26-48ca-a3f2-df7eac0b95c9.jpg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Bai-Ji Store di Sorrentino G.",
      "countryCode":"IT"
    },
    "firstName":"Achille",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzgzMGJmN2MtODA4Yy00MjU3LThjYjItMmQ3NDYxYTc3NzZkIiwidm90ZXJJZCI6IjZiY2Q1MGZjLWExNTItNDIwMi05NmY5LTJlZjg0MGQwNWJmNCIsImlhdCI6MTU2NTk1ODEzMCwiZXhwIjoxNTY1OTU4NDMwfQ.RDGj2w44tZrRBh-k8InQKs5vgXtA3tTWCeE8uD4GORU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3410a7da-abbc-4675-bc6c-e9210d3704d1.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Warhammer - Murrieta",
      "countryCode":"US"
    },
    "firstName":"Glenn",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzQ0ZDIyZWEtNTJjOS00OTJlLTg3MDUtNjRmMWI4OGFmMzU1Iiwidm90ZXJJZCI6IjM5YzUyZmE0LTI4ZGItNDU5Zi05ZmY3LWMyM2I5Y2FiMzViMiIsImlhdCI6MTU2NTk1ODEzNywiZXhwIjoxNTY1OTU4NDM3fQ.n6VU6ZBTKXjJPE6AgWtxqsUiyg7zKk_53RbrQ24De2Q"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ce557416-8b75-409c-a679-77ccd7a5b829.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Leeds (Central)",
      "countryCode":"GB"
    },
    "firstName":"Tom",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWUxNzYyN2ItY2VkZS00ZmMzLTk1NjMtYWZlYWRlODk3YzQ0Iiwidm90ZXJJZCI6IjBlMzkzYTM0LTRmY2YtNGU4Ni05MjYyLTQ5YTFmZWRiN2NmNyIsImlhdCI6MTU2NTk1ODE0NCwiZXhwIjoxNTY1OTU4NDQ0fQ.lOWT3uJMXxxlwsMN31q-8uNISwOvrRfG49DibrmOHgU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8b2fe43e-511e-4c34-8fbd-dcba053639c2.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Kendal",
      "countryCode":"GB"
    },
    "firstName":"Kati",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2I1MDU3ZTgtMTc5Ny00MzlhLWI4M2UtMjFkM2JkY2E3ZWU2Iiwidm90ZXJJZCI6IjZhZGE1ZmFiLTZmZWYtNDFhMS1iODAyLTdiYTJmOWIyN2E3OSIsImlhdCI6MTU2NTk1ODE2OSwiZXhwIjoxNTY1OTU4NDY5fQ.ykZSUmaXak_Ggw7uBZarb4i5iynoVVaFz5pAWoDfxS8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/60452ce0-0b12-4887-b361-8085cee76056.JPG",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Mulhouse",
      "countryCode":"FR"
    },
    "firstName":"Virginie ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDk3Y2U1NTItMWEzOC00NWRhLWI4ZTMtZGFjY2Q0YmQ1M2ZjIiwidm90ZXJJZCI6IjEzOGYxN2YxLWMxMzEtNDFjMS1iNzdmLTJlYmNiZjgzN2ZjYSIsImlhdCI6MTU2NTk1ODE3MiwiZXhwIjoxNTY1OTU4NDcyfQ.tYX17v6cZckNfVSwA1F-_nkNlBsAcPRsFYEFUM1jc_o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8ff0f76c-f47a-4072-9ffc-738d6b11c676.JPG",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"Tokyo",
      "name":"Warhammer - Jinbocho",
      "countryCode":"JP"
    },
    "firstName":"JUN-ICHI",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGE3N2VjNTgtMDIzMC00Mzg5LTljMGMtYTM1NDQ1MTQwMzRkIiwidm90ZXJJZCI6Ijg3ZWU2ZTViLWI4NDktNGQ2Mi05NjM3LTI4ZWNkNGIwZWMwMyIsImlhdCI6MTU2NTk1ODIxMiwiZXhwIjoxNTY1OTU4NTEyfQ.OI6HwsYWtSFRjkrXFgmAj9SagMK_0cJR24u4EFMLTpI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/09de1671-3d8f-47d8-ab70-e2a7989a78d4.jpg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Warhammer - Torino",
      "countryCode":"IT"
    },
    "firstName":"Riccardo",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmFiZTE3N2EtY2FhZS00Mjk5LThkZmMtMWFjZmQwMDhmMTYxIiwidm90ZXJJZCI6IjQwYmQxZmRlLTY2YzEtNGM4Yy1hNGFiLTRkMjg2YzAwMTQ1OSIsImlhdCI6MTU2NTk1ODIxNSwiZXhwIjoxNTY1OTU4NTE1fQ.jp2y0NqHC3flnq5REEQtMpRueITb7mJOo4lB87sIhh0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cfc77a4d-037a-4ca6-ad44-e492bff7d4cb.jpeg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Warhammer - Sydney",
      "countryCode":"AU"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTZkNmY1ZDctNDc0MC00MGFiLWI1OWEtYmJiZGViZmIxYTdlIiwidm90ZXJJZCI6IjIxMzcwNTgyLTE1MDQtNDg4Ni1hYWIyLTQ2ZWIwN2U1ZGEwMCIsImlhdCI6MTU2NTk1ODIzMCwiZXhwIjoxNTY1OTU4NTMwfQ.2-HY494TA4RfDN3wf6vJGqn9lq3hIBUvbS5e7fv0sXw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2cbca5c6-0832-4867-bcea-d0ef9a369f53.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Warhammer - Apple Valley",
      "countryCode":"US"
    },
    "firstName":"Michael",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjBhMTM2NjUtNGZjMy00ZTc0LWI5YjgtNzM1ZDA4MTY4NGI3Iiwidm90ZXJJZCI6IjU3NWFjOTlhLTNiOGUtNGVmZi1hN2UyLWIzZjU3MTIyZDFhZCIsImlhdCI6MTU2NTk1ODIzMywiZXhwIjoxNTY1OTU4NTMzfQ.Oux1MVm5cRw_CXMO85UeXCU2o3U9Nzmx5Aicz9srBl4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0d215052-145d-48c2-bed8-b7177726c43f.JPG",
    "rotation":0,
    "store":{
      "country":"Denmark",
      "state":"",
      "name":"Warhammer - K\u00f8benhavn",
      "countryCode":"DK"
    },
    "firstName":"Nikolas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjk0NGQyMjMtMGI2Ni00YTdlLWExMjctMmMyM2FlMGIwMDQxIiwidm90ZXJJZCI6IjMxNzhhN2U1LTY5MGUtNDMwMS05OTU5LTU2NmIyZjdhZTk0ZiIsImlhdCI6MTU2NTk1ODI2MSwiZXhwIjoxNTY1OTU4NTYxfQ.gkkayS6lYWvFryPDg12Rvw4vWzjp1vcv4j1Am5wzqIk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/07be5f58-dff8-4b52-9326-fcf6c29f383f.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MA",
      "name":"THE BOLTER HOLE",
      "countryCode":"US"
    },
    "firstName":"Isaiah",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzgzNWFjNmQtOGYxOS00MDU4LTg0ZTUtZTZjZTE2NTQwNmMzIiwidm90ZXJJZCI6IjllZTAwMmVlLWZhY2EtNDI1Ny04NjVhLWZlZWJhOWQ4ODk5MCIsImlhdCI6MTU2NTk1ODI4MiwiZXhwIjoxNTY1OTU4NTgyfQ.eWAGVMdIuV2TWtm5Gol4BURecEVvwJrYBXH6I_Zamkw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9708396e-225d-444c-9f6c-9c48c7871120.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"NOC'S BOX",
      "countryCode":"GB"
    },
    "firstName":"Rebecca",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjlkNmVhMTEtZWQ2NC00NGRhLTliYmMtMThmZWViMmQwMDA0Iiwidm90ZXJJZCI6IjQyMTQzZmFkLTI3MzUtNDE4Mi04MzhiLWQxMDNjYWIyYzE0ZCIsImlhdCI6MTU2NTk1ODI4OSwiZXhwIjoxNTY1OTU4NTg5fQ.Rs6PtJICDK8O3Ie7DnKkU4ryBxj6LFJ0bdUCxQt3cQc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0ef1e2f5-e335-44e7-ae3d-defa1ef0706f.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"FL",
      "name":"Warhammer - Naples",
      "countryCode":"US"
    },
    "firstName":"Hayden",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjczYjNlNjgtMjJkZC00MTU3LThhZGEtODE2MWIwYzZkYjU1Iiwidm90ZXJJZCI6ImNhMmFjZmRjLTk4NDktNDRjMC1hMDhiLTI4Y2IwZjU4YTgzOSIsImlhdCI6MTU2NTk1ODMyOCwiZXhwIjoxNTY1OTU4NjI4fQ.f2IFpmNODCrnMEqO9EvPBb24b8s6KYWjoMCRG09623c"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7d5d8125-9eec-4583-97bb-d349dc82d7a8.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Dundee",
      "countryCode":"GB"
    },
    "firstName":"Callum",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTkxMzAwZjYtMDYwMC00ZjY1LThiMzUtMWU2YTZkYmNjODVmIiwidm90ZXJJZCI6IjQ3YTEyOTNmLTJlNWItNGExZi04M2IyLTNkM2M4MmNhYzgzNCIsImlhdCI6MTU2NTk1ODMzOCwiZXhwIjoxNTY1OTU4NjM4fQ.nxPxoOCAP3DGwAuuL-4uz46r2d8dKcgR0PAEgRsOLHk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/49bc8b81-57c2-49e1-8f0e-3d3cb79c0121.jpeg",
    "rotation":0,
    "store":{
      "country":"South Africa",
      "state":"",
      "name":"The Unseen Shoppe",
      "countryCode":"ZA"
    },
    "firstName":"Andrea ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2Q1OWQ2ODQtOWYzOS00MDU5LThmNzctZjFhNTM3MjQyMmNkIiwidm90ZXJJZCI6ImM2OTk4OTUwLTM5NGItNDNmZS1iYzc2LTIxNjA3YTAxZGNkMiIsImlhdCI6MTU2NTk1ODM1MiwiZXhwIjoxNTY1OTU4NjUyfQ.ziveqHWIojaFY4UTZemx9sqd9Ymq8TnG_D9-HpmLO8I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9a6a0650-4ca2-43a6-9bf3-c7ad7c14d68d.JPG",
    "rotation":0,
    "store":{
      "country":"Chile",
      "state":"",
      "name":"Hangar 019",
      "countryCode":"CL"
    },
    "firstName":"Manuel ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzhkN2RlYWUtZmI5Mi00MGJhLWI3Y2YtZmU1NDllYmRjYjAwIiwidm90ZXJJZCI6ImNkOTFjMDMwLThhMzktNDhjZS04ZmU3LWQzYjUzNDhhNTAzMCIsImlhdCI6MTU2NTk1ODM1OSwiZXhwIjoxNTY1OTU4NjU5fQ.uoo1FVE_2B8xBL77kERLl_wiOFgiewAYaYYn7tb9vRU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/db6c2402-f439-43b9-893c-18875c24b526.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Scarborough",
      "countryCode":"GB"
    },
    "firstName":"Andrew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDU1OTYwMDEtYWYwMy00NzQ4LWIyYTctZTkzZDYxM2IxNzRiIiwidm90ZXJJZCI6IjcyOTJiM2ZhLTFjOTAtNGYyOC04NmUyLTdmMzBhOGZmODM3NSIsImlhdCI6MTU2NTk1ODM5OCwiZXhwIjoxNTY1OTU4Njk4fQ.FBlGHnyQlSBqaPxYvI8N-IVxpHJ6_9huO98fnta33Bw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/597478de-b238-4d7e-9f7b-19aa60d2e145.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NJ",
      "name":"Warhammer - Holmdel",
      "countryCode":"US"
    },
    "firstName":"Kyle",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTNlMWRlYTktNWM4OC00ZTk4LWI3YzUtMDdhNGJlZThjODRjIiwidm90ZXJJZCI6IjQ2ZWZkZWU3LWE4Y2ItNGI3Yy05NTNjLTQ4ZmQzMzM2ZDk4MiIsImlhdCI6MTU2NTk1ODQzMCwiZXhwIjoxNTY1OTU4NzMwfQ.BOFXAXNJX0HU2rPqBUNQ538xQSC_5620WecxIN4UJEk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2b2f9d6d-80ed-4e98-ae5e-321efef5ce89.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Hobby Games Ekaterinburg Kuybisheva st",
      "countryCode":"RU"
    },
    "firstName":"Vitalie",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmYzMzA3NDYtZTFkYy00MzAyLWEyZTMtYTlkZTlkMTgyMDg4Iiwidm90ZXJJZCI6Ijc2YTFmYTBmLWI5NDktNGI2Ni1iZmE0LTNjOTkwNjM0ZWIzNSIsImlhdCI6MTU2NTk1ODQ0NCwiZXhwIjoxNTY1OTU4NzQ0fQ.N4ioKXPDLakK_KSP3j_XhYUMCcMM8-d6XwZLkIuxV6I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/46847683-058d-49c1-8229-f16658ab3772.jpg",
    "rotation":0,
    "store":{
      "country":"Chile",
      "state":"",
      "name":"Wargaming.cl",
      "countryCode":"CL"
    },
    "firstName":"Gustavo",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDFjNTk5MzctZWYzMS00NTk0LThmZDctOGZiZDFmMGQwMjNiIiwidm90ZXJJZCI6ImVjZWUyMmVmLWQ4MjAtNGU4MS1iYWU4LTE4YzNhOWUwNWNhMyIsImlhdCI6MTU2NTk1ODQ1MSwiZXhwIjoxNTY1OTU4NzUxfQ.4l7OsHQJ6GLzkxYuM5_e57p7bPQANacF8Iss_2dcSe0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/64c9b097-a186-4a54-ae19-f53b15d4f431.jpeg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"QLD",
      "name":"Warhammer - Capalaba",
      "countryCode":"AU"
    },
    "firstName":"Anthony",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDNhNDQ4NDctM2QyYi00OGNmLTgxOTctNmNmNzIwMDczNWRkIiwidm90ZXJJZCI6Ijc4ZjVkMmU0LTM3YjItNDZmOS05NGUxLTU3ZGZhMzYyMTM5ZCIsImlhdCI6MTU2NTk1ODQ1NCwiZXhwIjoxNTY1OTU4NzU0fQ.JxpwRfywWnpSBdUNYPQog5SiNt-Qhecyxpaey8dtW-E"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/037fbb9f-8f35-44c9-92bd-9c76b48e3067.JPG",
    "rotation":0,
    "store":{
      "country":"Austria",
      "state":"",
      "name":"S-GAMES",
      "countryCode":"AT"
    },
    "firstName":"Stefan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTZiODk5MWMtMzk5ZS00MzY5LWEwZmQtOTA4MjBlYjdjZWJkIiwidm90ZXJJZCI6IjFhYmRhMjhmLThmNjctNGM5OC04YjcxLTVmMTlhZDA3MWFlZiIsImlhdCI6MTU2NTk1ODQ1OCwiZXhwIjoxNTY1OTU4NzU4fQ.5w9RC_xOVlHPpv5Fm7_WfKD9Mj2VdPteYMeTOfMsl7w"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a5d745d4-388e-4b8f-89ea-d4752c6645c0.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"GOLDMINE GAMES",
      "countryCode":"US"
    },
    "firstName":"John",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzdiNWVjMGEtYWMwZi00MDFlLTk5ODktMGNhNzI0ZGFhNzI5Iiwidm90ZXJJZCI6Ijc0MmY5OGYxLTIxNzMtNGQ2OS1iYzU0LTAxZjkxMzQwZDZkNiIsImlhdCI6MTU2NTk1ODQ2MSwiZXhwIjoxNTY1OTU4NzYxfQ.Hhfvg01AWu-UD0j1-YsqrwytNOSU24JXCymrZkwuKYo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/39cb47d2-7841-4bdb-9e62-8678d185303c.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"The Warhammer Citadel - Store and Cafe",
      "countryCode":"US"
    },
    "firstName":"Derek",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2Y1YWZlNjktNDAyNy00NDA1LTg0NDAtYTA3MzJkMjU1NTJkIiwidm90ZXJJZCI6IjI5Mzk2ZTA0LTlhYTAtNDNmZi05NTU0LWZmYjdhOWQ0YmE1ZiIsImlhdCI6MTU2NTk1ODQ3NSwiZXhwIjoxNTY1OTU4Nzc1fQ.Mgn6eIL0l5yqx1DL6Eq2l7wp7UMgZBv9c9oOROMdJoc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cfa7c414-7829-4152-9779-ebda756a6861.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CT",
      "name":"Sarge's Comics and Games",
      "countryCode":"US"
    },
    "firstName":"Craig",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWM3NjY2YTMtOWM2OC00MjAyLTlkNjYtODA3MjI4MzYxYzhkIiwidm90ZXJJZCI6IjM2MDgyYTRmLWRlNDEtNDRiYS04YzQwLWVmZTkzMDIwYjM5MCIsImlhdCI6MTU2NTk1ODQ4NSwiZXhwIjoxNTY1OTU4Nzg1fQ.UKcmUXIjGvj_SGYuaWc3ori5l-lCSLTqGPhzCfLXeRE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5eed6680-96ef-47ff-9c7b-69aa05d35405.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MD",
      "name":"Warhammer - Mountain Marketplace",
      "countryCode":"US"
    },
    "firstName":"Thomas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2MxNjQ4MGItZWJhZS00MWQ5LTk2YjItNGViNjA5ZTY5YWE0Iiwidm90ZXJJZCI6IjE3NTIxZWYxLTVmNjctNDQwNC04ODg5LTcwZGYwZWMzNGIxNyIsImlhdCI6MTU2NTk1ODQ5NywiZXhwIjoxNTY1OTU4Nzk3fQ.Xf1AwTAEfEDur0iy4hnEfQmiDgfmFcjqsGGO55S9x3g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2a8114ed-1f2c-4e6c-8fdb-7553347e01f0.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Hannover",
      "countryCode":"DE"
    },
    "firstName":"Stefan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGNjODBjMzEtYjcyZC00MTI1LTlhMzctYzk1ZGYwOTZkNDE1Iiwidm90ZXJJZCI6ImEyMTM4MWRhLTYwNDktNGVkMS1hMWM4LWZlZjY4NTg0NTFjNCIsImlhdCI6MTU2NTk1ODUyMSwiZXhwIjoxNTY1OTU4ODIxfQ.ZJaIgnZumldvBCs2SDjJcIRI9Pukj1vcjcsp5XQRfqI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c420729f-efb5-4c5a-bd18-24c41eb1ec4e.jpeg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Games Workshop - Verona",
      "countryCode":"IT"
    },
    "firstName":"Domenico",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGEzZGM1ZjYtNWY0My00NDI1LWI1MjktNDgxOTUyN2E0N2JjIiwidm90ZXJJZCI6IjY5MzUwODY2LWU3MWMtNDZkMi05NGE3LTk5ZGU4MWFkYTMzNiIsImlhdCI6MTU2NTk1ODUzNiwiZXhwIjoxNTY1OTU4ODM2fQ.kr3YYPhMdPttSpx6Pxe1GB2VHWePljRY30SDq8jSzsE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f1050efa-1068-441b-bcc8-9066b66d73ac.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"IQ GAMES CENTRE",
      "countryCode":"GB"
    },
    "firstName":"neil",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjFlZTM5NjMtYWZhZi00MDY3LThjMzEtZmUyMzBjMThhNGIxIiwidm90ZXJJZCI6ImUwN2FiMzAyLTZlMDItNGU5Ny05NzY0LTczMmEwMjVjODA1NyIsImlhdCI6MTU2NTk1ODU1NCwiZXhwIjoxNTY1OTU4ODU0fQ.OAtlRFvFulh84BsACANlJu9406DQci6hxKULfQlTMHs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/71f9dcea-eb32-4db9-b4e5-a3b5a6768a24.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"OH",
      "name":"Dragon's Roost",
      "countryCode":"US"
    },
    "firstName":"Steven",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWQzZjViY2MtZDJkMi00YWQ4LTk5ZjAtMWM4ZjU3NGM4ZWVkIiwidm90ZXJJZCI6IjA5YmY4MmVhLWMwNzUtNDhlMC04NTAwLTBkMjBjYmNhZjg2ZCIsImlhdCI6MTU2NTk1ODU1NywiZXhwIjoxNTY1OTU4ODU3fQ.1l1W860-hYW6YUnsOUGIxf3uVgLhtvy7c8NpfQIBy6s"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/dba4d676-21d3-4f73-b40c-172f3f796175.jpeg",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"",
      "name":"Warhammer - Tachikawa",
      "countryCode":"JP"
    },
    "firstName":"Takatoshi",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTRkYjdkNWUtNGJhOS00ODAzLTljYTktODEwZDgyYTA0MjhjIiwidm90ZXJJZCI6IjM5ZTc1NmU3LTc1OTUtNDM3Zi1iYzkwLWY2YjY0OWZiMTViMCIsImlhdCI6MTU2NTk1ODU2NCwiZXhwIjoxNTY1OTU4ODY0fQ.UaDGiUX2oz91ydMbnBqDGZzOAbyJuPjW1o5PVqtcxS4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f469cfff-1126-42ce-9dad-f778b77d92c7.jpg",
    "rotation":0,
    "store":{
      "country":"Poland",
      "state":"",
      "name":"PLANSZOWECZKA PL",
      "countryCode":"PL"
    },
    "firstName":"Natalia",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2ZlZDEwMjYtNDFmMy00ZmU1LThjMDAtODU0YTczM2I2NjRkIiwidm90ZXJJZCI6IjJhNmNmZDc1LTU4Y2QtNDVjMS04NzE2LTU0MGY3MjBhNWJmMyIsImlhdCI6MTU2NTk1ODU2OCwiZXhwIjoxNTY1OTU4ODY4fQ.fkJZUVP7t8taIpPlh8XSbo27Nj81_1kM3FISMgQUcfQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7d54b2e5-670c-4100-bb97-b85c2541de38.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MD",
      "name":"Games Workshop - Columbia Palace",
      "countryCode":"US"
    },
    "firstName":"Patrick",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNWM0M2ViOWMtNmE3My00NTY2LThhNGMtYTk0ZDdjMmQ5NjVmIiwidm90ZXJJZCI6ImZlY2I4NDcwLTIyM2YtNDE4OS1hMDFlLTFhMTRjNjAwMzIwMyIsImlhdCI6MTU2NTk1ODU5MCwiZXhwIjoxNTY1OTU4ODkwfQ.cepb1SoLr6vIWHl-5EGGztlm0S-c7om9ET267ujgK-o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d25cc84e-57bd-42d1-9fd3-7a72fe5f16f4.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"NS",
      "name":"Games Workshop - Halifax",
      "countryCode":"CA"
    },
    "firstName":"Michael",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmFlNWY1MjgtNjBhYy00YmRlLWFkN2QtMGUyNGY5NDlhZGQ0Iiwidm90ZXJJZCI6ImJjZGZhYmMzLTZiNWMtNDMxOS1hZjdhLTE4N2E3M2QzMTY5NCIsImlhdCI6MTU2NTk1ODYwNywiZXhwIjoxNTY1OTU4OTA3fQ.w9P24Jygq8JIRC_GOOgET_Oj54Xg-SZjxdqagTxD-mU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2f971ad7-6ea1-4904-bec4-a0ebfd002fd8.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"NSW",
      "name":"Warhammer - Parramatta",
      "countryCode":"AU"
    },
    "firstName":"Steven",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWIxNzFlMmQtMmY5Yy00M2VhLTk3ZTktZjgzZWJlY2JhMDAwIiwidm90ZXJJZCI6IjdkZTYyZDAzLTFhMGMtNGJiNi1iODc2LTk5NWUzN2Q3OTJjNSIsImlhdCI6MTU2NTk1ODYxNCwiZXhwIjoxNTY1OTU4OTE0fQ.0GaGK6s0vCAMFIfbFnDCyY2YlZ8f7BcYMNdhr5KP4t0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8f48268a-02db-4485-a8a3-433c3af2a8fe.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"VIC",
      "name":"Games Workshop Ascot Vale",
      "countryCode":"AU"
    },
    "firstName":"Mark",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmRhMDUxMjYtMGQ1Zi00Zjk0LTg4YjYtZmExZDIyYjlmZjlhIiwidm90ZXJJZCI6ImYyYzIzZDFjLTI5OWQtNGQ5My1iMzc0LTgxOTk4NjE1ZDIwZSIsImlhdCI6MTU2NTk1ODYzOCwiZXhwIjoxNTY1OTU4OTM4fQ.WNiRE2sTVsjfscXEojG4Lqjq3xQnzcZBxM4lSRSkPuU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/95a00f8c-870f-4509-8618-e4b1dc3315df.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Pamplona",
      "countryCode":"ES"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2IxNjQ4MGYtZjg1Yi00ZDJkLTkyNGUtMmZhYTNlMDgyOGZjIiwidm90ZXJJZCI6ImY1MWViMzFiLTk2NjYtNGRjMS05NTk3LWNlNWI0YzM1MjUzNSIsImlhdCI6MTU2NTk1ODcyNiwiZXhwIjoxNTY1OTU5MDI2fQ.sF7kVxRbMW2JG4xauHr2EmfTGHPGQvAF16KJDmJxcWw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8e1cdfc1-93ff-4c39-addd-e8ffe5ea8752.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NM",
      "name":"Warhammer - Rio Rancho",
      "countryCode":"US"
    },
    "firstName":"Kimberly",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDY1ODNiM2UtZjE5OC00MjRkLTgxODktYzkwOGQyMDEwNTc0Iiwidm90ZXJJZCI6ImI5OTA3MjUyLTNkNTgtNDJmYy04ZWJjLTAyNmE2ZWMwNmRiYyIsImlhdCI6MTU2NTk1ODc1NSwiZXhwIjoxNTY1OTU5MDU1fQ.iAu8lyiPNUwPL1hpdF77BL23qI1Y6tU3kwiIAUxQGcg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5cc3ace0-c71b-487a-8a89-54b96ed65e92.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CO",
      "name":"THE NERD STORE",
      "countryCode":"US"
    },
    "firstName":"Dawson",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzFjMjYxMzAtNTM2OS00ZGM2LWIyZWMtMjhkMmVkOTUyYWQ4Iiwidm90ZXJJZCI6Ijg2NjdiYTlhLTM5OGItNGIyYS1iY2I2LWNmNGUzOTExZTEzMSIsImlhdCI6MTU2NTk1ODc2MiwiZXhwIjoxNTY1OTU5MDYyfQ.7bl00NDOzs6Ik37KnnsSofXXBPe_UoRZMUSFfeVI4uk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b436e0b3-213c-41f4-ba1a-3ae83fb45a04.JPG",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"SA",
      "name":"Warhammer - Adelaide",
      "countryCode":"AU"
    },
    "firstName":"Jessica",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTI4MzBhYmUtZjMzNy00ZDhlLWFhOTUtNzRhMWRiZDZhMzE2Iiwidm90ZXJJZCI6IjZkYjc4MjhmLTYxOWUtNGM2Zi1iNjNhLTkwZThiNTZlYjZkMiIsImlhdCI6MTU2NTk1ODc3MiwiZXhwIjoxNTY1OTU5MDcyfQ.2tq0iaZRZ20eJp1mijTVF1S9ANJ3EYLggcui81Bqqi8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4d07d3ca-ed12-483a-a24b-a5a0ba63a614.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"AZ",
      "name":"GAME ON",
      "countryCode":"US"
    },
    "firstName":"Amber",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2FjNGJmMzUtMmE3NC00YmIxLWFlMjgtNTQzMzZiZjc5ODI5Iiwidm90ZXJJZCI6IjM0MmIyMGY3LTZkOGUtNGIwOC05YzUwLThiYjE3MWUxNWUzOCIsImlhdCI6MTU2NTk1ODc4MCwiZXhwIjoxNTY1OTU5MDgwfQ.bkn265SILYpDBKEt-u6JVsx627YQ6q8Qt-4PBo1xHIc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/66e5973e-7bc4-42ab-aed6-1a0cae59aa66.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Rotherham",
      "countryCode":"GB"
    },
    "firstName":"Johny d",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTMyZDI0ZjAtZGQwNy00YTJhLTljZDQtNDlhYzg1OTdjZTgzIiwidm90ZXJJZCI6IjA5NWFmNjU4LTE5MWUtNDg4YS1hZTBmLWUxNTE2ZTFiOGFiMyIsImlhdCI6MTU2NTk1ODc5MiwiZXhwIjoxNTY1OTU5MDkyfQ.YrD0akDhj_Mt54gOo16DOtmR9rSTYUoRWycFhGAAF9I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4cbd84e8-e88f-45f3-b1e4-32539b4827ce.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Leipzig",
      "countryCode":"DE"
    },
    "firstName":"Felix",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODQxZTgxNWYtMjQ3OC00NmVlLWFkNGQtZTE1ZTI5MGQ5ZWU5Iiwidm90ZXJJZCI6IjFiZTkzOWE3LTk0NTctNGM5YS1hOWEzLTAxNTZhOGUzYTQwNCIsImlhdCI6MTU2NTk1ODgwMiwiZXhwIjoxNTY1OTU5MTAyfQ._S2blGsgRiYU_086rJW3lJ-GDJLGs5slgv-mEkgkpT0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8c796597-503b-4861-83e2-87614da56ae0.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"FL",
      "name":"Games Workshop - Deerwood Village",
      "countryCode":"US"
    },
    "firstName":"Luis",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzhhNGE4YTMtOTY1OS00YTljLTgxOTYtYWY3YTJkMTc0YjRhIiwidm90ZXJJZCI6IjNhNzQ4YmNlLTA1YzQtNDU3OS05ZTUyLWQ1YTFiY2E0N2QyOCIsImlhdCI6MTU2NTk1ODgyMCwiZXhwIjoxNTY1OTU5MTIwfQ._AmXWRcKY0JFu3efi0DkUGedZBNcEbJGTM1REeed0MM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/65560bf9-bf41-46b9-87a2-36f28d4be17b.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WI",
      "name":"Warhammer - West Bend",
      "countryCode":"US"
    },
    "firstName":"Jesse",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODYyNTMxODktODA4Yi00NDY0LWE4NjEtMDI2N2YxODYwYmIwIiwidm90ZXJJZCI6ImMyY2I4N2M0LTUzMWYtNDZiNS1iNmE3LTJjNjUwYmM4ZDNjNyIsImlhdCI6MTU2NTk1ODgzMSwiZXhwIjoxNTY1OTU5MTMxfQ._EBfv2IVmyON_cvuGcXYPHyq7C2BOKUpVQHbEXmyFDM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/26f97e40-9e48-4699-8112-0e498535d529.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Bristol",
      "countryCode":"GB"
    },
    "firstName":"Felix",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzgxMDZlMWItY2FmMC00ODc1LTlhYmEtYWQ3YzM3ODQ0MTQxIiwidm90ZXJJZCI6IjI0OTVhZDA4LTdlMDctNGY0ZC04OTY5LWVhZmU2MmEyMWJlMSIsImlhdCI6MTU2NTk1ODg0MiwiZXhwIjoxNTY1OTU5MTQyfQ.p-b03TOAyW6z5zv8zb6lCmE9la43w5VblSvUWlqOa5s"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5b168384-778a-44a3-9eee-43b6e722948a.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Warhammer - Riverside",
      "countryCode":"US"
    },
    "firstName":"Darrell",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTU4NzBiYmUtNDZmYS00YjgwLTk0ZDQtOTFlNWNlOGRiZTczIiwidm90ZXJJZCI6Ijc5OTc2YmZkLWMzNDgtNGZkZC1iMDUzLTliNmMyNWE2NjhkNiIsImlhdCI6MTU2NTk1ODg1MywiZXhwIjoxNTY1OTU5MTUzfQ.T-Gm70mkZj6dqsn856yCGj-OigspKRD3zqkGE925Kgs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d6f5dbc0-fe36-4669-b816-c7c31707b6d5.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Karlsruhe",
      "countryCode":"DE"
    },
    "firstName":"Mattis",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODY0NDg0NTktMzA4MS00MGQzLWE4YWUtMzNiODVmZDNiMDVjIiwidm90ZXJJZCI6IjJlZmVlZDUyLTc3NGItNDc3NC1hNWE2LTExYzIxMDE0OTBjYyIsImlhdCI6MTU2NTk1ODg2NywiZXhwIjoxNTY1OTU5MTY3fQ.51axsRZz0ioKFMQXxLYtaTkEpXWZGKLVOdwclePqkE0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d8c66b97-4282-4ff3-a3fa-055617964808.JPG",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Edinorog - Krasnodar",
      "countryCode":"RU"
    },
    "firstName":"Vladimir",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWI1ZjlmMTUtNzE5OC00YWY0LWI2OGYtMjA3YWI1YTdmYWRiIiwidm90ZXJJZCI6IjMzOGU2OTVkLTM0ZjctNGYyNC1hN2YxLTQ5MTI5NmUzOTg1OSIsImlhdCI6MTU2NTk1ODg3OCwiZXhwIjoxNTY1OTU5MTc4fQ.6WFPpSSjl-eKjq93mWOlTL5upym052LU6mguyQgboaE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7b5ee7f2-06f8-45a9-9686-49d0469e9542.jpeg",
    "rotation":0,
    "store":{
      "country":"Hong Kong",
      "state":"",
      "name":"Warhammer - Morrison Hill",
      "countryCode":"HK"
    },
    "firstName":"Johnny K S ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWFjOGJhZmUtNDNlMC00YjUzLTk5YmMtMTNmMzgzZDNlMTc0Iiwidm90ZXJJZCI6ImNmOGJjZjM5LTdlYjItNGRlMi1iMDllLWViZWUwNjQ3MDM0NiIsImlhdCI6MTU2NTk1ODkwNiwiZXhwIjoxNTY1OTU5MjA2fQ.P0tyNdB023L6ikgk_daBv8gPx2_EXXJ9uxxbYlHo9wg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5035cd8f-ae6b-427e-8592-3e57645da8ff.JPG",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Koblenz",
      "countryCode":"DE"
    },
    "firstName":"Sven ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTEyYzIyZDMtZDRmZC00MzgzLTk3OGMtZmMyZmRkZGM3NDY4Iiwidm90ZXJJZCI6Ijk2MThmNDM4LTg0ZmMtNGY0ZC1hMTBhLTA4MGJiOTc5NDU4YiIsImlhdCI6MTU2NTk1ODkxNiwiZXhwIjoxNTY1OTU5MjE2fQ.aRVlNz95mDkFIwkdoXZ8KWrzJ4zKDSLdSxgL7ecvvA0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7baa5a8d-7895-4f86-b403-9fd42ecdbb1d.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"AZ",
      "name":"Games Workshop - Scottsdale Towne Center",
      "countryCode":"US"
    },
    "firstName":"Ellison",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTAzMDcwODktYzgwZC00ZTEwLThmNjUtZWYxY2Y2MjE3NDhjIiwidm90ZXJJZCI6ImM4MGQ2MDZlLTNiYjgtNDkxMC1iODIxLTkyM2U1MDA4NDQzYyIsImlhdCI6MTU2NTk1ODk0MiwiZXhwIjoxNTY1OTU5MjQyfQ.PTEa7MAegBJH9kaLF24J2tBbPWhtKDkwAbETat4FJE0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/95d61834-57c2-4322-88a2-a8139e1142f8.jpeg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Oldenburg",
      "countryCode":"DE"
    },
    "firstName":"Thilo ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGJkZWI2MDgtMjBlYS00N2Q4LTlhOWMtOGFjMjMwZmUxNWQwIiwidm90ZXJJZCI6IjA3YjM2YTlhLWNmM2UtNDUyYS04ZTIxLWMyOGIyNDM2ZmRhMSIsImlhdCI6MTU2NTk1ODk0NSwiZXhwIjoxNTY1OTU5MjQ1fQ.aN8rdxEF38xg0Po79xQsdH2-xNOeO2jMyScfs6-7MsI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c07c9049-b4c3-4285-ad5d-8f752eb96254.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"ND",
      "name":"Warhammer - Fargo",
      "countryCode":"US"
    },
    "firstName":"Chris",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzFkNzQ3ZjItMDgxMi00NDRmLWFjYzgtOWVjNWIwZTlmNDE5Iiwidm90ZXJJZCI6IjM5MjhkYzhhLTcwMTktNGVjZi05NzM0LTQ5YmM1MTEzZjkzYyIsImlhdCI6MTU2NTk1ODk1OSwiZXhwIjoxNTY1OTU5MjU5fQ.jP-Hk8ApMusnHv4OsIHbab5Hm66PWeiC47aDdFO55bc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/795c2004-8e7c-4368-9864-edaa1432030c.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MO",
      "name":"META GAMES UNLIMITED",
      "countryCode":"US"
    },
    "firstName":"Catherine",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDJlOTNlY2ItN2IyOS00OTNhLTkxNTItM2Q2OTUzYzg3NTEyIiwidm90ZXJJZCI6IjkwZjU4N2I2LWMzMzctNDlmNC05ODc1LTdmODcwY2M1ZGZmYiIsImlhdCI6MTU2NTk1ODk3OCwiZXhwIjoxNTY1OTU5Mjc4fQ.QvvhKn6d6BBrO7I4IclSuIukgKc6E8OZ2fSiTBS6f6k"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2030dde7-1ea0-4dfc-a0b9-86d893218525.JPG",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Games Workshop - Bologna",
      "countryCode":"IT"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDAwN2M5NGEtNTMxYi00MTIxLWI0N2EtOTQxYzIzNDAxNGYwIiwidm90ZXJJZCI6IjNlNGUwY2RiLTUyOTYtNGIzYS04ZTBhLTcwMzAyZmRlZTZmYSIsImlhdCI6MTU2NTk1ODk5MiwiZXhwIjoxNTY1OTU5MjkyfQ.tAhXfGFZ4tUikrzJt1ZZd7cJSZqg1RuK1hqGbGbqOK4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/accdf8cf-9a86-4ae6-8f78-857fa7e142ae.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CO",
      "name":"Games Workshop - Square One Denver",
      "countryCode":"US"
    },
    "firstName":"Michael",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDc3MGRiMTUtNjFkYS00ZWI4LWI3ODktOTNjMGYwZDZjMGNhIiwidm90ZXJJZCI6IjhmMGNmYTVmLWFjNzQtNGQ3Yi1iM2M4LWJhODdmYzZmMjdkMSIsImlhdCI6MTU2NTk1ODk5NSwiZXhwIjoxNTY1OTU5Mjk1fQ.T7gptDUKtNknVSVOtAZ4UTggY2x1NUuV9GBHnsoKkAk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/20650e53-74fd-4ace-89fd-757920bc044d.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Guildford",
      "countryCode":"GB"
    },
    "firstName":"Janine (Jai)",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTg1Mjg3MzMtZWUzZC00Y2RlLWE4NjItNzZmNjlmMGU5MjgyIiwidm90ZXJJZCI6ImFlMTIwNzAwLTQwMTYtNDhkMS1iNmYxLWJkYmRmYjJjM2YxMCIsImlhdCI6MTU2NTk1ODk5OSwiZXhwIjoxNTY1OTU5Mjk5fQ.nja1hjF9Opwz6SbPxtzshwdvm17T85WQV-7Z3Zmuytg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7f5b26d8-5e6e-4613-9e94-19173419498a.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Metro Centre",
      "countryCode":"GB"
    },
    "firstName":"Graham",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjY0YzgwYWItYTk4YS00YWFhLWE4ZTUtZDhjNTMzOTQ4M2JhIiwidm90ZXJJZCI6Ijg1NWZjZjI1LTU1MDYtNDM1MC04ZjkxLWRiYTY2OTNmZDMwZSIsImlhdCI6MTU2NTk1OTAwNiwiZXhwIjoxNTY1OTU5MzA2fQ.Ll3-iSHbuaQqxpJn44KPvWu9PMp3wGS9KDwJzM1K9Og"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/976a3aa0-348b-4d65-bbf7-8562bee45a27.jpg",
    "rotation":0,
    "store":{
      "country":"Switzerland",
      "state":"",
      "name":"TACTICA GAMES",
      "countryCode":"CH"
    },
    "firstName":"Thomas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjgxNzc2NWItMGRhYS00NTIyLTk4N2UtOTA0NmNhMWQ2M2ZmIiwidm90ZXJJZCI6IjgxNTQ4MDljLWMyY2QtNDNhNi1hNjcwLWRkYzFmMmJiMDFmYiIsImlhdCI6MTU2NTk1OTA3MCwiZXhwIjoxNTY1OTU5MzcwfQ.ib7uGGQJkoRvZqlYnf_CSoRwb924O1SjwMl1URLUNZQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/fb1203ca-098a-43e1-8f90-808468249c7e.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"WA",
      "name":"Games Workshop Joondalup",
      "countryCode":"AU"
    },
    "firstName":"Mark",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTZhYzZiMDctM2NiZS00YmI1LWJmMTgtNDNkZTVkOGFjODA4Iiwidm90ZXJJZCI6IjhkM2Y5ODk5LTk1ODItNDJkZS1hYmY1LWQ2YTY4NTYxM2E4ZiIsImlhdCI6MTU2NTk1OTExNCwiZXhwIjoxNTY1OTU5NDE0fQ.sBjaKwFgaOvAHvM9uqifqT8poB-wAE839-FfoEUnHq8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/06da26fa-f7fc-4fdc-86a4-9c72c2d6f040.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Warhammer - Preston Ridge",
      "countryCode":"US"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2E2NDdmNDctOTc4MC00ZWUyLTgwNDItMGU5NjEyNmE5ZDg2Iiwidm90ZXJJZCI6ImVkOTgxZjc5LThiMWQtNDdmMi1iODA5LWNjOWFkNjNkZDBhNCIsImlhdCI6MTU2NTk1OTEyOCwiZXhwIjoxNTY1OTU5NDI4fQ.YGeGxWFwqWhPK9L8ILZNJI9K3Y35uQT3Gy-cRmGfXQ4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/893c57d3-dc7d-4ec7-9be3-882bd842b998.JPG",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - Braunschweig",
      "countryCode":"DE"
    },
    "firstName":"Patrick",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmE2YWZiN2ItYmRlMS00OGMzLThkNGMtMTg1N2VkMjgwZjFmIiwidm90ZXJJZCI6ImU0NTc2YWQyLTQxMDMtNDYxYi1hZTgyLWE0OWUwYzg1YTcyOCIsImlhdCI6MTU2NTk1OTEzNSwiZXhwIjoxNTY1OTU5NDM1fQ.meVUmhvr2KR8PaCSa6LV-Md6VKhNnAQiV0bD6dd-86g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f5ed54f8-3642-4721-ab29-5aae7f83cb92.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"THE GAMES TABLE LTD",
      "countryCode":"GB"
    },
    "firstName":"Sophie",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTZmMzkyZjMtMGYzNC00MzdjLTg4ZDQtZDgwY2M1NWVkNzUxIiwidm90ZXJJZCI6ImI3ZWU1ZWIyLWQwOTItNGNiYi1hNDQxLTZhOGQ5NTk2NTM0OSIsImlhdCI6MTU2NTk1OTEzOCwiZXhwIjoxNTY1OTU5NDM4fQ.83wu0soJO6gcJM7nA1B1MZvij0YFGz7j7TxIlhzjaYQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b34888ed-6b14-480c-bd9b-255918b189cd.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"VIC",
      "name":"Games Workshop Preston",
      "countryCode":"AU"
    },
    "firstName":"Nicholas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNWYwZTk1ZDQtNzk5Yy00YTk2LWE1Y2QtMzQ5ZTljMTZhZTRmIiwidm90ZXJJZCI6IjI2ZTc3ZjYwLTgxYzAtNDkxZC05YjdmLTUyMjhlY2RhMWM3OSIsImlhdCI6MTU2NTk1OTE0MiwiZXhwIjoxNTY1OTU5NDQyfQ.KMIG2kCxPN2Xd4o6ibLMFciJpsfZP5q3_dwIpVRuHGs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/1c4451d2-022f-4860-a67e-fec055bbf602.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"PA",
      "name":"Six Feet Under",
      "countryCode":"US"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjI4MTAwYTQtZDE5NC00NTJmLWFmZmUtNDE3ODM3ZDAyYWFmIiwidm90ZXJJZCI6ImQwOTFiMTNkLWFlNGYtNGE1YS1hZjllLWE5MWIwMTVjNzc1MCIsImlhdCI6MTU2NTk1OTE0OSwiZXhwIjoxNTY1OTU5NDQ5fQ.Lf7JOFe0xo5YtghssPAAwMYJSSa1sxoKRXxBwQqg2p8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2ede4d78-46a8-4b5f-9d1c-95e192545ee8.jpg",
    "rotation":0,
    "store":{
      "country":"Belgium",
      "state":"",
      "name":"DEMO SPEL BVBA DD",
      "countryCode":"BE"
    },
    "firstName":"Sergey",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjY5NDczNzctNWY0YS00NzEzLTllOTYtNDQ5M2VmNTk5OTNhIiwidm90ZXJJZCI6IjVhNjAxNGFhLWUxNzMtNGJhZC05NTEzLTc5OWJlZDZhZjgxOCIsImlhdCI6MTU2NTk1OTE2MywiZXhwIjoxNTY1OTU5NDYzfQ.59iebOOca17fhJb9LXRhufaMSXxQP87IJ3CaM2YLWWo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/859f6166-c2f8-4d7d-afc2-a52c7b1cc5cc.jpg",
    "rotation":0,
    "store":{
      "country":"Netherlands",
      "state":"",
      "name":"Warhammer - Zwolle",
      "countryCode":"NL"
    },
    "firstName":"Allard",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWNiNjA1MTEtNmZjMi00YmM5LTgzMzYtZjJjNTE0MTc3MjBiIiwidm90ZXJJZCI6ImQzYWUyZGI5LTJkNWUtNDNlNi1hOWZlLTBlZTUyOWU3N2U1ZCIsImlhdCI6MTU2NTk1OTE5MSwiZXhwIjoxNTY1OTU5NDkxfQ.SaJ8Rss6GcummhpRM1-_7-O-AhATjWBTsRjawF1KKPo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8ba988f0-3f4e-4a52-941a-30193f0bde57.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Wakefield",
      "countryCode":"GB"
    },
    "firstName":"Allen",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTljOTFjOWEtNzRjNy00YTM3LTljYjAtZjU5NjU4OTU3MjVkIiwidm90ZXJJZCI6IjY1YjljMWM3LTRjMjItNGQwMi04MjdhLWQzMGExOWNkODRhMCIsImlhdCI6MTU2NTk1OTIwMiwiZXhwIjoxNTY1OTU5NTAyfQ._LRB7-IGQw-rTlXBSTwwAAIvDbzH-LWHP_QCZojX_-I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/86745f59-ba39-43a6-b455-b352d2e4f933.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Cribbs Causeway",
      "countryCode":"GB"
    },
    "firstName":"ryan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2ZhZGNjMmMtMjBjNC00NTljLTkxMDAtYTU4MTE2NzBjZmE2Iiwidm90ZXJJZCI6Ijg3OGU0MmVlLWMwN2UtNDYyYS1iMmQwLWFhMzY0YTNlODM3YyIsImlhdCI6MTU2NTk1OTIyMywiZXhwIjoxNTY1OTU5NTIzfQ.U_lkbMSo-faWGQBYAWh8iVu6Kswh0n_5Hkju6rMXSU4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4d8e2fe4-5d0a-431b-80b3-140764a5de6d.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Banbury",
      "countryCode":"GB"
    },
    "firstName":"Darrell",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDFiNGI3NzUtNzE4Ni00MzEwLThlNDMtZmYxZjM4MTY0YTI2Iiwidm90ZXJJZCI6IjkzMGE1NjY1LWNkYTMtNGZjYS04NGQ0LWFiYzMwMzU2MWQ0YSIsImlhdCI6MTU2NTk1OTI0NCwiZXhwIjoxNTY1OTU5NTQ0fQ.Nuvsayx-hsvKRz-HbBBv7zCUSZivfIgekzBCDtEoGlM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/233211b0-e6b3-46eb-8dc7-f302e933dac0.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"ID",
      "name":"Warhammer - Boise",
      "countryCode":"US"
    },
    "firstName":"Colin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTg5MjgwNDYtNzhmNS00MTI1LWE4MjAtNDcxZWU5N2IxMDE0Iiwidm90ZXJJZCI6IjIzMGE0MjhjLTZiMmMtNGFlYS04YWYzLTcyN2Q4NjU4MTFkNyIsImlhdCI6MTU2NTk1OTMwMSwiZXhwIjoxNTY1OTU5NjAxfQ.3XcGH0mBuSaioeq2O-uzbGkmuxYxJcp0wMP650ERJGU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/60316b7f-7b5a-4d55-aa73-2468f1fece54.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"SC",
      "name":"Games Workshop - Forest Park",
      "countryCode":"US"
    },
    "firstName":"Brian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGQ5YzZjMTEtZTU5Zi00ZDliLWExZjMtMDQ3MTRhYjg4ZGNjIiwidm90ZXJJZCI6IjA5YTNjYTg1LWMwNWUtNDdiYy05NzJkLTU2NGI4MWYwZDM1YiIsImlhdCI6MTU2NTk1OTMwOCwiZXhwIjoxNTY1OTU5NjA4fQ.CuEYOcnwmqc1JfOqsFDIxY_OVQlTDsX53S_vZEksMjo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/80d8ffe4-0419-4954-98b3-721a930c32e2.jpg",
    "rotation":0,
    "store":{
      "country":"Iceland",
      "state":"",
      "name":"NEXUS",
      "countryCode":"IS"
    },
    "firstName":"J\u00f3n Gestur",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2MwZjg2ZWQtMzA4Ny00MmQxLWE2OGMtNGUwOTY4Nzk5ZmYxIiwidm90ZXJJZCI6ImFhYjFlM2JkLTdkNTktNDJhYS1iZjM3LWZlZTI1YTA1ZjBlNyIsImlhdCI6MTU2NTk1OTM3MiwiZXhwIjoxNTY1OTU5NjcyfQ.eNi0vux7dIZ5F6Zcc7lncswthfBIFmHVBg8m2RBs8YY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/015feeb0-531b-4271-8118-5252983bc9d6.jpeg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Hamburg Mohnhof",
      "countryCode":"DE"
    },
    "firstName":"Alexander ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWIxYjY0MDAtNzJiMS00OWI0LTlkYjgtYTMyZDU1ZDcyZWVlIiwidm90ZXJJZCI6Ijg3YzQ0ZTY2LWJlMjAtNDI2MS1hMzg2LTQxZjFlZmQxNTVkMSIsImlhdCI6MTU2NTk1OTM5NiwiZXhwIjoxNTY1OTU5Njk2fQ.cvay_dtf_uMDtWMX_n4SdgMM-4mUlVX58q-GP0dpODQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8840d8b6-3fbb-4e03-90ea-d1c3acc6a66a.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Palma",
      "countryCode":"ES"
    },
    "firstName":"Cristian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjY4YTQwNTItOTM2MC00OTE3LWI2M2UtYjM0OGY4NjQ0MmFhIiwidm90ZXJJZCI6ImEwYzYxNzZlLTAxMjctNGViZC04ZjA5LWI3MzE3ZjYyMTQ3YyIsImlhdCI6MTU2NTk1OTM5OSwiZXhwIjoxNTY1OTU5Njk5fQ.kSVryowofrbimcUbEQON2Jcl2DJLUP0AuiN4Y9CbOAA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cff5c78a-df1a-4514-96d5-6d7a42d5096b.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"VA",
      "name":"Warhammer - Fredericksburg",
      "countryCode":"US"
    },
    "firstName":"Teresa",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGQ1ZTEyZTItNDlhNS00MTQxLTllZDItZDgyNTBhZWNjYjk3Iiwidm90ZXJJZCI6IjE2YWRhOWU5LTI4NzUtNDNhYi1hYTkyLWVkODY3M2U1NjE1YSIsImlhdCI6MTU2NTk1OTQ1NiwiZXhwIjoxNTY1OTU5NzU2fQ.2sbKdV2bpWFgSjv_h8D9KDLuE987kGOaunYEKqRJe_Q"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/291ca402-96a4-45b5-b5b5-4799aed56386.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - M\u00fcnchen",
      "countryCode":"DE"
    },
    "firstName":"Raphael",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDliYmRmMGEtNTgwZi00MDYyLWI4NWEtNzhhMmJjZjlkZjc2Iiwidm90ZXJJZCI6IjEwZGJjODNiLTViODUtNDFiNy05M2VhLTdiOGVkMjY2NWY3MCIsImlhdCI6MTU2NTk1OTQ2MywiZXhwIjoxNTY1OTU5NzYzfQ.u6hiRy3M9pk9Xlzj6L-G-rjkp0-GZw0QI_03khTKgTY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/503f4f6c-84a3-4695-8bb1-e1d6ccc3e8fb.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MD",
      "name":"BEL AIR GAMES LLC",
      "countryCode":"US"
    },
    "firstName":"Adam",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2E1NGJmYWQtMGQ1Zi00ZDMyLWIzYzQtNzhiYjc5MDI5OWYyIiwidm90ZXJJZCI6ImE2NjVhMWRkLTYwZjQtNDZjOS1hMDMzLTUzMGUxNGQ2NmFiMCIsImlhdCI6MTU2NTk1OTQ2NywiZXhwIjoxNTY1OTU5NzY3fQ.s2wOpTTYgmpH38BA481paLgBX1gRKE1IeuLassTxAW0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/58e05bcf-a967-4f80-8d86-6101152e0b68.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"GA",
      "name":"AUGUSTA BOOK EXCHANGE",
      "countryCode":"US"
    },
    "firstName":"Jacob",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2IxMWZjOGItNTFjZi00YmMyLThkZmUtMTAzNTAzYzFiZDc4Iiwidm90ZXJJZCI6Ijk1MDk4NjE5LTg4OTMtNGVhMS1iYTZlLWE5YzA0ODdjY2JkMiIsImlhdCI6MTU2NTk1OTQ3NCwiZXhwIjoxNTY1OTU5Nzc0fQ.aIKzg1dQjOAK3D5FW0Pn_kTn6p20lRsF2BU8bSGYTSc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/22b5dc20-b43f-468b-a664-ebebb31fc365.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"ON",
      "name":"Warhammer - Fairway Road",
      "countryCode":"CA"
    },
    "firstName":"Jacob",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTQ2ODk2NGUtMjczZi00YmE0LWI2OTQtMmUzYjNjYzFjMmZkIiwidm90ZXJJZCI6IjIzMmJhMjc2LTk2ZTUtNDRlMS1iMjg2LTQ2NjNjOGYyYmZjMiIsImlhdCI6MTU2NTk1OTUwNSwiZXhwIjoxNTY1OTU5ODA1fQ.Dtaym3H30Ou5naEHYdWJmzLttXU_6KbX5OeCX4cYc6s"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3c8b0a50-df8a-4199-93e9-c1a392cc390c.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Grimsby",
      "countryCode":"GB"
    },
    "firstName":"Mark",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDJmOTBjZWEtZjZjMS00NDRkLWE4YWYtMzUwZjYzNmFmNmM5Iiwidm90ZXJJZCI6IjJmNGFlMTA3LTg0YWItNDc4My1iNDU5LTdiMzc1ZWJmMzk4NyIsImlhdCI6MTU2NTk1OTUxMCwiZXhwIjoxNTY1OTU5ODEwfQ.azNz4Pq0HT3tSY1IjcmB5JE07faDrEOGn7iy3m3gqew"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c234db02-2ecf-462d-9c40-d88df8f74868.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Essen",
      "countryCode":"DE"
    },
    "firstName":"Alexander",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2QwYWMwMDktZTlmMy00MzQyLWJjZDQtNDA2Y2MxNjI1ZjE1Iiwidm90ZXJJZCI6IjRmNTNmOGFkLTdlOGItNDI3Yy1hZTFhLTZjZThhOTAwZTVhNyIsImlhdCI6MTU2NTk1OTUyNywiZXhwIjoxNTY1OTU5ODI3fQ.Pwj5rKsCrraY7PkkN3JtaraOieR8073aK5--AOHMju8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9a26d614-99b9-4043-9a48-955cec5c906e.jpeg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Alegris",
      "countryCode":"RU"
    },
    "firstName":"Andrew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTM5Njk5ZDktNjc1My00MDFiLTg2NjYtOWZmNDM3MDM2YzgzIiwidm90ZXJJZCI6IjhhOTcyZTA4LTAyMTMtNGM3Ni1iMjI4LTI4OTZkZmVlZWNkNyIsImlhdCI6MTU2NTk1OTUzNSwiZXhwIjoxNTY1OTU5ODM1fQ.P9VBAEDiDWcm2Q-R0RtaxVJkkGfNgMqGy4Q1z_9AWZw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/94efe12a-e78c-454d-a3f6-2d8a1007573e.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NC",
      "name":"Games Workshop - Pinecrest Pointe",
      "countryCode":"US"
    },
    "firstName":"Dale",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDNiM2ZiYWItY2I4YS00MzJiLThjOGQtODVkZWVjYThkMmI4Iiwidm90ZXJJZCI6IjViOTM4YTczLTA2ZWYtNGIxOC04MGUzLTBmOTMxMGNhZjc2MCIsImlhdCI6MTU2NTk1OTUzOCwiZXhwIjoxNTY1OTU5ODM4fQ.24o2vPp7iKoggZD-FmYLffAuvYYgqeZVFunAhlpxqfk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/489c1ed9-f301-4dcd-a66b-080bdfff20bd.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Versailles",
      "countryCode":"FR"
    },
    "firstName":"Guillaume",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWFmOTMxMWYtNjZhNS00MmYxLThlZmItNjFhOGU1MDE1NmVjIiwidm90ZXJJZCI6ImNhNTlhY2NiLTJhYjYtNDNlZC1hNTU3LTBkMzUyMzUwNTM0MCIsImlhdCI6MTU2NTk1OTU0MiwiZXhwIjoxNTY1OTU5ODQyfQ.Mh8m5gJ-rPG0zaX605pp1Ld3ba2uwugY8qr3AuCAFwE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9fef684a-2cba-4da1-a4cb-c714166b6bb3.jpg",
    "rotation":0,
    "store":{
      "country":"Thailand",
      "state":"",
      "name":"Realmgate",
      "countryCode":"TH"
    },
    "firstName":"Fasai",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjE1NGEwNWUtNzI2MC00MWMyLWJlYTItZGFlYTg4NTYzYmU0Iiwidm90ZXJJZCI6ImJiZTBhMjYyLWM3Y2QtNDU3OC1hZmQ1LTkzNGI4YmVkYzkxOCIsImlhdCI6MTU2NTk1OTU2OCwiZXhwIjoxNTY1OTU5ODY4fQ.Jee0p8aPlf1uwKCXfUunz_7MWUfNiUq1eiWxMgIfNSE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b6bb62a4-62e0-4ba9-b252-ac892e2a9c1b.png",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Bradford",
      "countryCode":"GB"
    },
    "firstName":"Dane",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNWQ1NjY5MDgtYmI5Mi00OTQ5LWI0YjUtOGM1YzliYjMzODU2Iiwidm90ZXJJZCI6ImZhYThjMDQ4LTBjNjgtNDA1Ni1iNDk4LTZjZTgzMzU4M2ZmMSIsImlhdCI6MTU2NTk1OTYyMSwiZXhwIjoxNTY1OTU5OTIxfQ.m4x1neX4cj6vM-5CGGVfgpmnN3xlOFs_vgqB_wIM8fM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6f568ebb-d1b5-42d9-b4a6-83d5654c29fa.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WA",
      "name":"Games Workshop - Tacoma Place",
      "countryCode":"US"
    },
    "firstName":"Mindy",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTEzNDE2NjItNWNiMy00NzZkLTg1NjUtYWVlYTM5NThmZjU2Iiwidm90ZXJJZCI6IjgxY2Q0YWQwLWMxMDctNGQ0ZS1iNTQ5LTk4Y2I2ZTVmYWU4MiIsImlhdCI6MTU2NTk1OTYyOCwiZXhwIjoxNTY1OTU5OTI4fQ.dSbt0XSo3_n8lfHWArXYakFh9m1p-A2UbYpz56OAJ9U"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f17b3740-bade-4dcd-a52f-f5493596af3e.jpg",
    "rotation":0,
    "store":{
      "country":"Portugal",
      "state":"",
      "name":"LOJAS ARENA",
      "countryCode":"PT"
    },
    "firstName":"Jo\u00e3o",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjhmYTUxMWYtZWI0Ny00YjQxLWIyODQtOGJjMmRhZDk5ODVjIiwidm90ZXJJZCI6IjdkZDBkZjcwLThlODAtNDlhNy1iMjRhLTY1YzdlZWQ3MTI2YyIsImlhdCI6MTU2NTk1OTY3MSwiZXhwIjoxNTY1OTU5OTcxfQ.BJWYUsnPkd8Pt2d5h9gVkx_Gg8clrOtt_Kqe6k3eozI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d42b6eb3-36ca-4b77-b354-5783d203b644.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Goya",
      "countryCode":"ES"
    },
    "firstName":"Volomir",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZThmMjY0M2EtZmEzMC00NGM4LTg1YTMtMGM2YTlmNzRlNDBmIiwidm90ZXJJZCI6Ijk3Yjk2MDViLTI4ZDQtNDVjMC1hNGIyLTgyNzViZjdmM2U4YSIsImlhdCI6MTU2NTk1OTY4NiwiZXhwIjoxNTY1OTU5OTg2fQ.DQX2kL9-cMNiswUz6ZtJ_RhcinRJKjxFVR3vOYJJW5Q"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/82ffe9f9-4820-49df-bc17-5e1073dcf938.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Chatham",
      "countryCode":"GB"
    },
    "firstName":"Lawrence",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjkxMDM2Y2QtOTI0MS00ODgzLThjNmMtMTAwYWFjNTk5OWIxIiwidm90ZXJJZCI6IjM0N2UwZGM3LWIzNjItNGNhMS04MjJlLTQ5NmQyNzg5M2I5NSIsImlhdCI6MTU2NTk1OTcwNCwiZXhwIjoxNTY1OTYwMDA0fQ.ebmaOP28l2rpYY4QFmpNYdI2eMijYCY-98oI7uqEFL4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a465d010-04e0-49a7-9ad3-f1611f2c2db9.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Lavka Orka Rostov-na-Donu Krasnoarmeyskaya st.",
      "countryCode":"RU"
    },
    "firstName":"Ruslan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWE2YWYxNTItNjNmNS00MDkwLTk0ZjQtYjYxZTdmODdhZTU1Iiwidm90ZXJJZCI6IjVmODNhZDFhLWFhOWMtNDkyNy1hZGY4LTk0MGJlZGZiZWE5ZSIsImlhdCI6MTU2NTk1OTc1OCwiZXhwIjoxNTY1OTYwMDU4fQ.WJoGzOrmg2WdZpkov060PUXselZ8V_rZkH__QpBlm-U"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e4c51c72-494a-4608-93f8-3deb912375cf.jpeg",
    "rotation":0,
    "store":{
      "country":"South Africa",
      "state":"",
      "name":"Pandemonium Games",
      "countryCode":"ZA"
    },
    "firstName":"Richard",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjlkNWM0MTQtOTU1My00OTJhLTg2NzAtYzhjZjZkMmRkZmQ5Iiwidm90ZXJJZCI6ImJmNGRlYzdkLWY1OWYtNGNmMy05ZDg4LTYwOWY0MzE5MmVhYyIsImlhdCI6MTU2NTk1OTc2NSwiZXhwIjoxNTY1OTYwMDY1fQ.8QvYAF7XfD_m8y9SZhKO5oYrO9Z3j_pqUtj4smrQzWg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/71fdf359-31ca-4662-b219-ea0f63ada993.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Games Workshop - Hill Country Plaza",
      "countryCode":"US"
    },
    "firstName":"Gavin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYmRlNDczYWItYzM2Ny00ZWU5LWI5OTUtYmVmYzUxYjMxNDdjIiwidm90ZXJJZCI6ImM1ZDE4ZDIzLTk1ZDQtNDk2Mi1hZWRmLTY3ZDIzZDAwZWJkNyIsImlhdCI6MTU2NTk1OTc3MywiZXhwIjoxNTY1OTYwMDczfQ._nwUivnXdDRaxib0zf6c5CmtZxPXrhXnTbyGpBlW7c0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/70919834-d7cd-400b-9cb0-c98ed6338192.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Leamington Spa",
      "countryCode":"GB"
    },
    "firstName":"Matt",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDIxOTFmYjYtMjU4YS00YTI1LWJjMDgtNTUwMWZlMDA3YjBhIiwidm90ZXJJZCI6IjZhNTQxZTczLTZjYjgtNGFkYS1iZjI0LTE3YTdhOWVlYTliYSIsImlhdCI6MTU2NTk1OTc4MywiZXhwIjoxNTY1OTYwMDgzfQ.EVyTmM99evix_DNV0zolLKwPbcFSpIxUiDT3z-7MXI0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8ba14634-11f0-4110-9cd6-6f0c820bafc8.jpg",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"Tokyo",
      "name":"Warhammer - Takadanobaba",
      "countryCode":"JP"
    },
    "firstName":"\u548c\u8f1d",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzcyOTYwNjYtNzE0MS00ZGQxLTkzMTItYTBjMDM0MDkxNGFiIiwidm90ZXJJZCI6IjNkMzYwMTM5LWYyNzAtNDQ1Ny1hNTVhLTk2MDZhNDc3NjZiNCIsImlhdCI6MTU2NTk1OTc4NiwiZXhwIjoxNTY1OTYwMDg2fQ.fa_4oqo_lA0D6FNS4l77bzuf77f5btP1RJ8EC9-Akco"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/56ac02a6-31bf-40a8-890a-f11090c7a4d1.jpeg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"Games Workshop - Paris Rue Hautefeuille",
      "countryCode":"FR"
    },
    "firstName":"Olivier",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTMyNTcxMDgtZjYyNS00MmJhLWJmODEtNDM5OGVhYjRmY2JjIiwidm90ZXJJZCI6IjAwZmFlNGVhLTFhMGYtNDJkNi1hYjI5LTEwNzZlMWI3MTM0OSIsImlhdCI6MTU2NTk1OTgwNCwiZXhwIjoxNTY1OTYwMTA0fQ.qnPoD6-E9ih8PSPXIflNy5Sea88VV82xa3Poiro1dmo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2120e270-3615-49ec-a6f6-48b69eb9efa9.jpg",
    "rotation":0,
    "store":{
      "country":"Finland",
      "state":"",
      "name":"FANTASIALINNA SEINAJOKI",
      "countryCode":"FI"
    },
    "firstName":"Iiro ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzA4Nzg3ODAtOTQ4YS00ZGMzLTgyOGItY2U3OTRlOWZhYjQzIiwidm90ZXJJZCI6IjFjNzg0MzAyLTk3NjgtNDE0Ni1iZTliLTc0YmU3NzdkMzlmYSIsImlhdCI6MTU2NTk1OTgyMiwiZXhwIjoxNTY1OTYwMTIyfQ.uZMdrEUv-bMtGYn8yakUomMTq7l5023cuOz6rO3WN94"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f1a8478e-7d89-4bc6-97e8-30cfb3f875dc.jpg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"Games Workshop - Genova",
      "countryCode":"IT"
    },
    "firstName":"Andrea",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2ExMDAyMmMtYzQxNC00MzQzLTkxMDYtZDBhZjJjMzBhMTA0Iiwidm90ZXJJZCI6ImY5YWVlODhmLTdkOTQtNDIxYS05Y2NkLWZjYWJmYTgzY2E3NCIsImlhdCI6MTU2NTk1OTg1OCwiZXhwIjoxNTY1OTYwMTU4fQ.fm1pm9mARYZitIFl6hwO8zqX2yY5reLsKULlbLYht5w"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/152f77af-e54b-49a4-b424-ef0195073bad.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"OH",
      "name":"Warhammer - Powell",
      "countryCode":"US"
    },
    "firstName":"Christopher",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTE2MDFlZmQtZjM5My00NjRiLTkwZjEtOGQzZjk2MjA0ODg5Iiwidm90ZXJJZCI6IjVkMWM4YWRiLTA2MDMtNGIwNC05NGNjLTMwYTI1YTVlZmViNSIsImlhdCI6MTU2NTk1OTg5NywiZXhwIjoxNTY1OTYwMTk3fQ.rXSMybEE-N0lHSRF0q4vd3xeLrg8v-Tgz2VGU01Mx4w"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c64a671c-c4ea-48b1-8e42-27ce581c84c5.jpeg",
    "rotation":0,
    "store":{
      "country":"China",
      "state":"",
      "name":"\u53f2\u534e\u90fd\u6218\u9524",
      "countryCode":"CN"
    },
    "firstName":"George",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZTc5MWE4ZjktODA1NC00ZjI2LTg4MTktMTc5MDE0NjM1NzkyIiwidm90ZXJJZCI6ImRkNjFiM2IwLTNlNjMtNDIwOS04ZGQzLWJjZDlmNzhjYzg4NCIsImlhdCI6MTU2NTk2MDAwOSwiZXhwIjoxNTY1OTYwMzA5fQ.3Qo5LldzRqAVSn-UpwAeE5jb9Xt5Z-4ldFkLeyA8C4Y"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0e444369-3767-4ea6-bfe5-f36d5a58a69f.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Enfield",
      "countryCode":"GB"
    },
    "firstName":"Andrew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTdlYmM4MjMtMDU5OS00NGMxLTg1MjEtZmZiZmRiNjVhMDc3Iiwidm90ZXJJZCI6IjJhOTA4M2NjLTJhMjYtNGMyMy1iZWZkLWMxMmI0NjE4MTAzNyIsImlhdCI6MTU2NTk2MDAyNywiZXhwIjoxNTY1OTYwMzI3fQ.Xu2Dtk0hofOkoDtFV19MtfRSzhV7zwIZimqjafABXYE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d8178d31-d22c-4a80-bb0e-07a5e35d515b.jpg",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"",
      "name":"Battlestation Toowoomba",
      "countryCode":"AU"
    },
    "firstName":"Clayton",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTEyMzMxMTUtYzFmYy00YTI0LWI4NjAtODNjZDg0MDUxZDg0Iiwidm90ZXJJZCI6Ijg4YjIyNmRiLWI3MWQtNGEwYi1iNDZjLWI4MDk0ZTEyNTAwNiIsImlhdCI6MTU2NTk2MDA1OSwiZXhwIjoxNTY1OTYwMzU5fQ.uwOnA5knE46SC4SJB-pdlI6jP_xF-t7mCXPMptbZkBo"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5f7df96d-6908-48db-91c8-45b054d9b6a4.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MI",
      "name":"Games Workshop - Hampton Village",
      "countryCode":"US"
    },
    "firstName":"Derek",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTE2M2QxMDctYjVjOC00MzRmLTg2NzctMzQ3YTMzMDY0ZWYwIiwidm90ZXJJZCI6ImM4YTAwZTZiLWVhNWEtNDE2NS1hOGZlLTQzNTc4Y2ZmYWMwMiIsImlhdCI6MTU2NTk2MDExMiwiZXhwIjoxNTY1OTYwNDEyfQ.ofBOI7I63mXGmhEY_wbsjb_TlAm40ocbRzoK2QB7pmM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e36b6831-b300-476a-ad4e-f8864fa649ab.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"OH",
      "name":"GAME TABLE ADVENTURES",
      "countryCode":"US"
    },
    "firstName":"Damon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2Q5MDgzZWMtMWUzNi00NzgwLWE1OWEtNTUzNDcwNTkzMGMwIiwidm90ZXJJZCI6IjliODQyYTg3LWJjNjAtNGI4OC1hNWY0LWI3ZGVlYzFjY2JjNiIsImlhdCI6MTU2NTk2MDEyMywiZXhwIjoxNTY1OTYwNDIzfQ.PJTGRTozu75NujQbhi8lZ1w2uSe1cCmEV-O1DROZw8M"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/03036a52-12f7-4655-917d-2bc2d9ea0345.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"AB",
      "name":"Games Workshop - Kingsway",
      "countryCode":"CA"
    },
    "firstName":"John",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjNjNTk5MWEtYjIwZS00ZjlhLTgzYzEtY2Q5MjI5NmE0MTk4Iiwidm90ZXJJZCI6IjBjNTY1ODNlLWNiYTgtNDIzZS04OTg4LWIzZTE4NTQxYTNhNyIsImlhdCI6MTU2NTk2MDE3NSwiZXhwIjoxNTY1OTYwNDc1fQ.XYMTMRy03D3cQMdZDWGRWk2Jxt3DauFsgfwLREfnxps"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3a732c56-6818-4aa9-bc91-8ed5f72c9937.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Lavka Orka Stavropol Lenina st",
      "countryCode":"RU"
    },
    "firstName":"Alexey",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDM3YTVhYTgtZjFmNS00ZDU3LTk0MmUtNGRmOGRjY2VmMmVmIiwidm90ZXJJZCI6IjU3ZWI5ODc3LTRiNGQtNGNkZC04Nzk0LWU1MTYzNGQ4NDczNyIsImlhdCI6MTU2NTk2MDIxMCwiZXhwIjoxNTY1OTYwNTEwfQ.IM8sPx18V_XDx37e5jx1pEMJNItSnog-dLcexJttlhw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0d532567-1aec-4caf-bde7-6f03ef96c4df.jpg",
    "rotation":0,
    "store":{
      "country":"Thailand",
      "state":"",
      "name":"Golden Goblin Games",
      "countryCode":"TH"
    },
    "firstName":"Rittipong",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjUzZTk0NTMtYzBkZC00YjU4LTkxYjgtODQxOGU5MmU5ZmIwIiwidm90ZXJJZCI6IjU3NjFlMzZlLTYyY2QtNDlhOS1hYTYxLWE2OGUwN2Y5ZTkyZCIsImlhdCI6MTU2NTk2MDIyNCwiZXhwIjoxNTY1OTYwNTI0fQ.HQ9d_seZgAqCh116GaxsktqEpDDp1EDWdkdS5nLVxB8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/198c599c-f66b-4061-99ed-897221674e6e.jpg",
    "rotation":0,
    "store":{
      "country":"Italy",
      "state":"",
      "name":"GAMES ACADEMY LEGNANO",
      "countryCode":"IT"
    },
    "firstName":"Alessandro",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMWFmM2ExNjAtYzc0YS00ZWM0LWExNDgtZDkxZGVkMjVhMjQ1Iiwidm90ZXJJZCI6Ijg5ZGIyYzMyLWUzM2UtNDc4Yi05Zjc2LTRkOWYwOTM0ZmZiMSIsImlhdCI6MTU2NTk2MDI0OSwiZXhwIjoxNTY1OTYwNTQ5fQ.BL0bK_w_eT9pCjZepLeRw7yN7NH0N0-8izikVFiXTy4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d1968e17-d44d-4c5d-90db-da758d63b120.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Glasgow",
      "countryCode":"GB"
    },
    "firstName":"Chris",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGNlMDM3YTctOTc4Zi00NWRlLWFjYmItMzc5OTc0ZWMxNzc2Iiwidm90ZXJJZCI6IjQ5MzIwYWUwLTIyZTItNDQzYy05OWI5LWNhMDU2NjA3NTc2ZSIsImlhdCI6MTU2NTk2MDI1MywiZXhwIjoxNTY1OTYwNTUzfQ.--Sv5SLIXpFZ0j6QKCsrTrs2X5iAht2Lsc7qdzxCdkw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3c31dfb7-7e10-4888-bbe6-102ad6cad483.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Games Workshop - Barnstaple",
      "countryCode":"GB"
    },
    "firstName":"Adrian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDU3N2QyNjctZmJhNy00ODljLTk3NDAtMTcwYzg3OTZmY2RjIiwidm90ZXJJZCI6IjE1ZjFkMzRkLWUzYzgtNDJkMS1hOGEzLTM1MjQxNzNlNTU3NyIsImlhdCI6MTU2NTk2MDI2NiwiZXhwIjoxNTY1OTYwNTY2fQ.pe1jAY8dBOygBHqREPrYVf0AhXY2ui6odQzmkltlthE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/231f6442-44b3-4648-af43-da877494834c.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NC",
      "name":"THE GAMER'S ARMOURY",
      "countryCode":"US"
    },
    "firstName":"Brandon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2U1OGZlODItNWFmYS00YmEwLTljZDYtMjFmZTJkMzJhYjFhIiwidm90ZXJJZCI6ImIyMGJhYWYzLWUwOTctNGFlYy04NWM2LWViMThmN2UzYjcyMCIsImlhdCI6MTU2NTk2MDI3MCwiZXhwIjoxNTY1OTYwNTcwfQ.A5p4_uD3__laSR4a9RSUuiWL3REev0Guk5N3Ys-OsFY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5ffd37bb-988e-42ad-80b5-1c3ad381da07.jpg",
    "rotation":0,
    "store":{
      "country":"Ireland",
      "state":"",
      "name":"Warhammer - Cork",
      "countryCode":"IE"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjAyYjAzOTgtNWYzNS00MDhiLTljYWQtZGUxMTU0MzYxZTk3Iiwidm90ZXJJZCI6IjY0MTA2ODgzLWFhMGUtNDg5MS04NmY5LTBhMzA0NDI5Y2RhNiIsImlhdCI6MTU2NTk2MDI4NCwiZXhwIjoxNTY1OTYwNTg0fQ.XjgsRqPx2a810Ti2f6O41AlGVoqdBFhS3_kdw14bpV4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f26bef23-1f23-4fbe-8423-b2db79d77b61.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TN",
      "name":"Games Workshop - Memphis",
      "countryCode":"US"
    },
    "firstName":"Erik",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMjM1YTc4MjQtNWI5OS00M2IzLWE5ZDItNmU0NzE2ZmE4ZDUxIiwidm90ZXJJZCI6Ijk1YTMzNGJiLWVjNDYtNGY4Yi04NmNkLWI2ZWM5YzU0YWJkMyIsImlhdCI6MTU2NTk2MDI5MSwiZXhwIjoxNTY1OTYwNTkxfQ.78wL3TZhP5eqT2GMSKuYmNSQDnQuwYJY4bpCWPzwuec"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ae5db9c5-2ae3-4123-acb4-fa7c7d6cdc2d.jpg",
    "rotation":0,
    "store":{
      "country":"Austria",
      "state":"",
      "name":"Warhammer - Graz",
      "countryCode":"AT"
    },
    "firstName":"Daniel",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDEwMGI0NzEtOWNiYS00YWMyLWEwMWUtNDlkM2E5ZWI2Mjg5Iiwidm90ZXJJZCI6IjU1NjJhZjM0LTc1YTAtNDVhMS04YmQzLTE3NmNhMDAzMzZhMiIsImlhdCI6MTU2NTk2MDI5OCwiZXhwIjoxNTY1OTYwNTk4fQ.DvW8tI1CN0GJ75o2m30j3efHkFa2l1UZZeFqiVzeGqA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f7da3749-3a8b-4811-80af-c15f93d5263c.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Falkirk",
      "countryCode":"GB"
    },
    "firstName":"Colin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDQ0ZGY5MDMtYTk0Ni00OTY3LWE2NDItM2Q3ODBlZDM1ZWQ5Iiwidm90ZXJJZCI6ImFhNDZhOTdlLTEzZTItNDM3OS04NWQwLWMxOWMxYWFiNWY5MCIsImlhdCI6MTU2NTk2MDM2NiwiZXhwIjoxNTY1OTYwNjY2fQ.3-ryimeJdRFyw6FIiZdIx-TZ4OpsJnKE9m1F1R91qts"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/85233211-1aaf-4d4c-b465-f5d46cfd2ed5.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"CA",
      "name":"Games Workshop - Central Avenue",
      "countryCode":"US"
    },
    "firstName":"Thor",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmVmZmZlZGQtMTc3Ni00ZjYwLWJlM2ItYTFjMDllNjBmMDRlIiwidm90ZXJJZCI6IjIxYjE0MzZjLWFkNTItNGU3OS1hNjNiLTU3OTI0NWQ5ZDdjMyIsImlhdCI6MTU2NTk2MDM2OSwiZXhwIjoxNTY1OTYwNjY5fQ.7ni2wUe53eu6HCC4Lu1xlbalFlqycNhtGk_v6zdSJfg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/21ea7e11-4c1c-4e26-8b6a-2ff2a2613031.JPG",
    "rotation":0,
    "store":{
      "country":"Australia",
      "state":"QLD",
      "name":"Warhammer - Chermside",
      "countryCode":"AU"
    },
    "firstName":"Trent",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZmI5NjZhOWItMDc5Mi00ODNkLWFlOTgtMDg0MzBmZWVjODUxIiwidm90ZXJJZCI6IjY4NTlhNWRkLTljMTktNDFmMy04YzVlLWQ4M2JjZjZjOGIzYiIsImlhdCI6MTU2NTk2MDQxNiwiZXhwIjoxNTY1OTYwNzE2fQ.7ltaGEoJ0Yf7DSaE4rQr5lhqYVCcCnXtMXe-kvEDBGM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d41f2dea-4e6c-46fb-9de4-d97dfc7194f5.jpeg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Regensburg",
      "countryCode":"DE"
    },
    "firstName":"Markus ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDk0MWMyYWQtOGM4NS00YzU5LWFhMGUtZTQxMWFiOGQxNDA3Iiwidm90ZXJJZCI6ImM4ZTI1YzUxLTk3N2MtNGQ0Yy1iZmU2LTU1ZjEwNjdhYmI5NCIsImlhdCI6MTU2NTk2MDQ0MSwiZXhwIjoxNTY1OTYwNzQxfQ.bJj9tVHSVeeOFgzGv2jZdyRIAmN6QIHDVVzvjCLIMPI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2564927a-c360-48e9-80b6-916c741a384e.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MO",
      "name":"Warhammer - Independence",
      "countryCode":"US"
    },
    "firstName":"Sterling",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYWY0NTBkNmMtZWQyMy00ODFiLTk5NzYtMmVmZDUzYWEwOTBlIiwidm90ZXJJZCI6ImUwNTlkZmYxLWEwNWMtNDY5NC1hZmVmLTEzY2FlZTIwOThhZiIsImlhdCI6MTU2NTk2MDQ0OCwiZXhwIjoxNTY1OTYwNzQ4fQ.j9_3IQInMpJOI33CleSzCLNaOW7OzT0US4Uhm2jQ8ko"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0e4b6ebc-f8cc-46bc-a94d-6eacb0488302.JPG",
    "rotation":0,
    "store":{
      "country":"Japan",
      "state":"",
      "name":"Warhammer - Yokohama",
      "countryCode":"JP"
    },
    "firstName":"TAKEHISA",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTEwZjIwNjQtN2Y4Ni00OGI5LWE2MDctMDAxOWQyNzEzZWUzIiwidm90ZXJJZCI6IjczMjg0NWZiLTEzNmYtNDE5NC05NzQxLTE5OTAxNDFiMTExOSIsImlhdCI6MTU2NTk2MDQ4NywiZXhwIjoxNTY1OTYwNzg3fQ.Ob0ZkDisr5Jt31KCSHUSqVF-hqUMVWtrl9UlkfH2UNI"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0697f311-22ad-4fe5-bb01-1b873a30ccb7.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WA",
      "name":"Warhammer - Bellingham",
      "countryCode":"US"
    },
    "firstName":"Austin",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODNlOTQ3YTQtZTM0NC00OGJmLWI3YTYtZmI0NTJlZmU5ZTA1Iiwidm90ZXJJZCI6ImQ2MWZkNzlkLTk0MTYtNGQxZS04YjEwLTJjYTZjMGFiNTE5NiIsImlhdCI6MTU2NTk2MDQ5MCwiZXhwIjoxNTY1OTYwNzkwfQ.Yx3kd2MtJV-yxKggRGX9pwFKxUXbLzfi6uSyMZ5-4zk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/039f050d-5cca-4568-9ccc-0c132d146de0.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"ID",
      "name":"ABU GAMES",
      "countryCode":"US"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGE5ZjkyZDAtMjUyMS00YjczLTk1M2MtZTViMjg0ZDMxOTU5Iiwidm90ZXJJZCI6IjcxMDQyZDQxLWMwZTYtNDRjNy05YWE3LWQ0NTViYTZjZDEzYiIsImlhdCI6MTU2NTk2MDUxMSwiZXhwIjoxNTY1OTYwODExfQ.r97HrGuy8fcGXivtV10SoM4xI_aq64B3wI7XS6yusAY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/538f457b-b7d8-4a90-b97e-9e549954e51d.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Hobby Games Ryazan Pervomayskiy prospekt",
      "countryCode":"RU"
    },
    "firstName":"Artem",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYjBiY2U5MjctNDUyNy00YmQwLThmOTktMzZiNDExNTdmYzlmIiwidm90ZXJJZCI6ImI1NzQzY2EyLTY0NjgtNDQwOC1iMzM1LTZlNWY0MWJmZTUxOSIsImlhdCI6MTU2NTk2MDUyMiwiZXhwIjoxNTY1OTYwODIyfQ.KxyD5wh9Dot111dMh0iVXHLkjOFroVPowuX0T5uuRmg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/2ebc4337-dc0d-4969-92c3-c4837b22eb9a.jpg",
    "rotation":0,
    "store":{
      "country":"Austria",
      "state":"",
      "name":"Warhammer - Linz",
      "countryCode":"AT"
    },
    "firstName":"Julia",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2I1YmM2OTEtYzNhZS00ZjM1LTkwMzYtZjM1ZjQ3ZGUwZmMzIiwidm90ZXJJZCI6Ijk2ODM0MDBjLTA3NmQtNGEyOS1iNDJhLWM3NGY5MTk3MDk4ZSIsImlhdCI6MTU2NTk2MDU0MywiZXhwIjoxNTY1OTYwODQzfQ.ut9X4ZyeZ8rLftfFdFQYTf9ae1EYZuAgY8cmtZIfJOA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/89912dae-5ce3-46b1-84cc-0ca7f0e661b3.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WA",
      "name":"Games Workshop - Sunrise Village",
      "countryCode":"US"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGJjNmE1YTQtYTVhYy00YmM5LThiNjQtOTJkZTg4NzcyYzNhIiwidm90ZXJJZCI6IjkxMTJjOGViLWFmZWMtNGVmZS05YWVmLWNiMDRmNTc0YTE5MyIsImlhdCI6MTU2NTk2MDU1NywiZXhwIjoxNTY1OTYwODU3fQ.5cerQ4deHAvPFcc4b29KQT_H7OK-LuLU4QEyZbNxj_o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/28058c03-ff82-483e-b114-bb89ed0c2e95.jpg",
    "rotation":0,
    "store":{
      "country":"Hong Kong",
      "state":"",
      "name":"Warhammer - Amoy Plaza",
      "countryCode":"HK"
    },
    "firstName":"Leo",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTg5NWY1NzMtZjU4OC00MGNjLTgzOWEtYTE5NWYzNWMzYzkxIiwidm90ZXJJZCI6Ijg4NDkzNTk0LTY2N2UtNGU5MC1iYTcwLTBjMDU5ZmZjYjJlYyIsImlhdCI6MTU2NTk2MDY0OSwiZXhwIjoxNTY1OTYwOTQ5fQ.c7JHfzEh9HQCCLHw2tplcGBTLwhINLFFKpS4KxVsW1g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6605a579-1643-4510-b522-cde904f887d1.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"AZ",
      "name":"Games U",
      "countryCode":"US"
    },
    "firstName":"John",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiN2MwMGY0YzYtNmYyNy00MDMwLThlMjAtOTMyZjMxYTBhMDY2Iiwidm90ZXJJZCI6IjFkNWYwMTExLTJjZDUtNDcyNS05OTc1LWEwZGQyODVlZTU0YiIsImlhdCI6MTU2NTk2MDY1NiwiZXhwIjoxNTY1OTYwOTU2fQ.us9h8EgbjwOTijhf3RLGcFGLV_TP3J75B8_wBudShII"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e848afc4-ffc1-492c-8fef-6717607194e4.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Aberdeen",
      "countryCode":"GB"
    },
    "firstName":"Andrew",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODBjMTgyNjMtNGVkZi00OTE1LThjZTItMTdkNzE4YWNjODQyIiwidm90ZXJJZCI6IjAzNjMyM2FiLThmZjItNDU5YS1iOTRlLWNiNGY5Y2E0YzZkZCIsImlhdCI6MTU2NTk2MDc0NywiZXhwIjoxNTY1OTYxMDQ3fQ.U9HlepM-q-vfwjkAr6NeSMc8pMR4vTEyhqwU5nbHNtc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/5061516c-3e38-4830-874e-aadda679f4ab.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"VA",
      "name":"Warhammer - Winchester",
      "countryCode":"US"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNjhlZDU0NGYtOTE3ZC00M2RlLWI5MTYtYzQxNGEyNTg3OGU4Iiwidm90ZXJJZCI6IjAzZmI1YjYzLWI4MmMtNDJkYS04YzZhLTJhNjZiYjM5MmIwMSIsImlhdCI6MTU2NTk2MDc2NCwiZXhwIjoxNTY1OTYxMDY0fQ.QZvLDVEvFsr3Ue7UIw-dahwzbnJ98pCzvQs6pceZgd4"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/c15e5b4e-c82d-4b3a-b9e8-f72068a44c0f.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IL",
      "name":"Lindsay's Gamer Garrison",
      "countryCode":"US"
    },
    "firstName":"Jason",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTQzZTllZWUtYjhiNy00OWUxLThiYTYtYzZmYzdlOTE0YjdiIiwidm90ZXJJZCI6ImQyNjFkZDk2LTcxNmMtNDRmZS1iYjlhLWMzZjAzZjhlNDExOSIsImlhdCI6MTU2NTk2MDc2OCwiZXhwIjoxNTY1OTYxMDY4fQ.iz3YrZ0zRKqVTRJPgmnX2-A_8kdj2M5gbJBWDQIS51g"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8b877de4-5479-4b1b-ad71-8882d1d93a72.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"",
      "name":"STRATEGIES GAME & HOBBIES INC",
      "countryCode":"CA"
    },
    "firstName":"Dillon",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiY2Q0NDRhNjUtMGE4Yy00YTlkLTkyOTEtN2IyNDNjYjE5MDBkIiwidm90ZXJJZCI6ImJhOGQxNGNlLTY3OTEtNGRkMC1hZmU5LTY3ZDkwMjM5NDc4NiIsImlhdCI6MTU2NTk2MDkwNiwiZXhwIjoxNTY1OTYxMjA2fQ.qGVScSPZONIjUWmsaVyziZNEZBZzB6sHfSarKmCGUB0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/8e915c6e-59b3-42ac-8802-e2698a362e1d.jpeg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Wuppertal",
      "countryCode":"DE"
    },
    "firstName":"J\u00f6rg",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzY1OTY5OTMtYjFlYS00NjAyLWE2ZGEtYjYxMDI1YjY1ZjMzIiwidm90ZXJJZCI6IjM4M2M5Y2Q2LWRmODAtNGZkMS05ZDMxLWYwYjBiZDJkODgyZSIsImlhdCI6MTU2NTk2MDk3NiwiZXhwIjoxNTY1OTYxMjc2fQ.JKjuw5-EaVrQGiMS6ikSn4Li3CWjtVTvXnuBtl6_oDg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/7379635d-a4e7-4ff9-a053-d89f5f9def83.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"ME",
      "name":"Warhammer - Cornerbrook Plaza",
      "countryCode":"US"
    },
    "firstName":"Erik",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOGM2M2NiYTktMDljOC00Zjc0LWIyNjgtNDEzMTQ5Yzk1NzAwIiwidm90ZXJJZCI6IjA1YzQwZDZmLWY4YjctNGU1MS1iNTRkLWFmMmMyNzlkYTQzNiIsImlhdCI6MTU2NTk2MTA4OCwiZXhwIjoxNTY1OTYxMzg4fQ.bxriNlCN-HFNZJARuhrDUl0zywoLCirnoE7ssoAud2o"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/cfd8123b-eb4e-409a-b57c-186477ac9b55.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Brighton",
      "countryCode":"GB"
    },
    "firstName":"Laurie",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDM3NjNkODYtZWEyZC00MjBkLWJhY2UtZDM4NTM1NzVlN2U3Iiwidm90ZXJJZCI6IjQ0NjMwNjY5LWI5OWYtNDQxOC04MTY5LWM2YmZhNmU4OGE1NCIsImlhdCI6MTU2NTk2MTA5NSwiZXhwIjoxNTY1OTYxMzk1fQ._zd5VplMwdBYTnKyRAECNPwriI2Dv2hAyAuBcKHUr0I"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/04d89b2f-11c4-454f-b892-c4b47d34c4ad.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Games Workshop - L\u00fcbeck",
      "countryCode":"DE"
    },
    "firstName":"Valentin ",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGI5ODQxYWYtOTBiOC00NjVkLWIzOWItNjU2NTE5ZTMzNjA4Iiwidm90ZXJJZCI6IjlkMzIwYmEyLTZlMjQtNDI0MC1hZDFkLWY5YTk3NzBlNTkxOCIsImlhdCI6MTU2NTk2MTM5OSwiZXhwIjoxNTY1OTYxNjk5fQ.8uQduIhu3hnNAUlyOC0DF93jfwwAoZo9LgTZ2yDqIJM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/46bfc7c3-ff39-41fa-939b-ddbfc1cb1306.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"MS",
      "name":"TOP TIER BOARD GAMES",
      "countryCode":"US"
    },
    "firstName":"Randy",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGNmOTcwYmYtMGE1Yi00MjZiLWIyMWUtMWQ0NTZjMmQ1OWRiIiwidm90ZXJJZCI6IjgwMjQ1ODgwLTNiNjYtNDdkNi1iM2YwLWI3ZDFmYmIyYTliZiIsImlhdCI6MTU2NTk2MTQ3NCwiZXhwIjoxNTY1OTYxNzc0fQ.XAUB0uHspRtgPnzNXNFra1r2aNnlNdvfvJJiWlS4Keg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0af6b800-0be6-44c6-a14b-b3dbc6ebfd21.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Darlington",
      "countryCode":"GB"
    },
    "firstName":"Theo",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGFmNTVhNmMtZjE4MC00NzkyLTg5MGMtNDBmMDYzMDNkMzVmIiwidm90ZXJJZCI6ImJhYTg1NTQ4LTVjYjUtNDk5Ni1iYjA5LTljYTI0NDE5ZTQ4YiIsImlhdCI6MTU2NTk2MTUyNywiZXhwIjoxNTY1OTYxODI3fQ.Jvfi_gALPiBSdmlBW53CJqqf44FgEkt9iW1SePZqzRc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3b16aabb-39a7-40c0-81b3-0746d787feac.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Blackpool",
      "countryCode":"GB"
    },
    "firstName":"Dermot",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTdkZTVmMmYtY2UyNy00MmU0LTgwZmMtZWJiZTg5ZjVlOGZhIiwidm90ZXJJZCI6IjY4MGEzNzc0LTIxZjgtNDY3Ny1iMjNjLTBkNzhiMzYxMDIwZiIsImlhdCI6MTU2NTk2MTUzMCwiZXhwIjoxNTY1OTYxODMwfQ.NopEgugYeWWhpGmWpFhkQBs7pnxcT2PUptsIau8gyHA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ca8c4d90-f0ae-4b85-b198-0e8a8bdab2b2.jpeg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Tunbridge Wells",
      "countryCode":"GB"
    },
    "firstName":"Kitt",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjBiZTM1OTctYTQxZi00ZTdmLWE5NTMtNWU1Y2QyYTY0Y2YzIiwidm90ZXJJZCI6ImUyYmEwMzQxLTRhZGQtNGM3ZC05ODFhLWQ4NGFjMjFmMWM0NyIsImlhdCI6MTU2NTk2MTU3MSwiZXhwIjoxNTY1OTYxODcxfQ.Bs7rk3I1tcrbzDdPFGV2xF1z7lzjbX5FZafM5t6Xdqw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b11bcab5-531d-4170-b3bb-21962f15edd0.JPG",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Edinorog Novoslobodskaya",
      "countryCode":"RU"
    },
    "firstName":"\u041d\u0438\u043a\u043e\u043b\u0430\u0439",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMGRlNDE3NjItMTRiNS00YTdkLTgwNWUtYjE2ZTY5NTk4ODU4Iiwidm90ZXJJZCI6IjY0MTI2MWIxLTEzYmMtNDI4ZC05YmJhLTFlNGIwYWMwNmJmYSIsImlhdCI6MTU2NTk2MTY2NSwiZXhwIjoxNTY1OTYxOTY1fQ.d_erwpYTTpCTFCO829SSZ3yUKCC_1MSqxOP87I5u7aQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/29446a22-1205-4c07-b605-1093c65bfd2a.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"UT",
      "name":"Warhammer - The District",
      "countryCode":"US"
    },
    "firstName":"Richard",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNmM1YWNlMGUtZTQwMi00MjlkLWEyNDItZmIwYThlNGE4NjI1Iiwidm90ZXJJZCI6IjNmNmRjNzJmLTJiODEtNGFjNS04MTA1LWRlZmUwOTRiNjEwYyIsImlhdCI6MTU2NTk2MTc5MSwiZXhwIjoxNTY1OTYyMDkxfQ.WQyORGDWRVZe802ThIuFHKJmXWzl1PbNamGUG2K1n2Y"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/f89dbb8a-ed92-416e-b21d-9144bf6a7cd5.jpeg",
    "rotation":0,
    "store":{
      "country":"Switzerland",
      "state":"",
      "name":"FANTASTIC EMPIRE",
      "countryCode":"CH"
    },
    "firstName":"Sascha",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYTRlODg4YjQtMzQ1Yy00NjJmLWI5OTAtZjg0MmQwZWQ5ZTc5Iiwidm90ZXJJZCI6IjA1ZTAxMmMwLTliMjYtNDAyYy1iYjg4LWY2ZTIzNDQ4YjBkOSIsImlhdCI6MTU2NTk2MTg0NCwiZXhwIjoxNTY1OTYyMTQ0fQ._Vub7fk1UugQbWn9O4qwbvhaUGSVZCRlYioIdyBI214"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/83a3205d-e879-4071-9ebc-0c4ea512c084.JPG",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"IA",
      "name":"MAYHEM COLLECTIBLES UNIVERSITY AVENUE",
      "countryCode":"US"
    },
    "firstName":"Brian",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTA3ZWM0YmUtM2I2Ny00OGU0LWE1ZjktNzA3MzhiMWEwOWUwIiwidm90ZXJJZCI6ImU1NzQ0MmEwLTMxZjEtNDc1Yi1iNGRmLWNlNDE2MjIwOWZjNCIsImlhdCI6MTU2NTk2MTg2OSwiZXhwIjoxNTY1OTYyMTY5fQ.1aFKimvTbQ4CP-tGuIqmrnS5AKTj218FsOkis7KqvK8"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/68cbf907-e5be-4ab1-9814-cde9ba1cc4f2.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Ulm",
      "countryCode":"DE"
    },
    "firstName":"Toni",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDAyZDlhM2MtNTkwMS00NGM1LWIzMWYtNDFmMDhkNjYxZGE4Iiwidm90ZXJJZCI6IjJhNjM0NzA4LTEyNmMtNGZlMi05NmY2LTM2YzBmNzEyMDk5ZiIsImlhdCI6MTU2NTk2MTg5NywiZXhwIjoxNTY1OTYyMTk3fQ.Sks7Tzv7wzpyB1GACRHPGR61FOfMNq7Aio5aoRKK1aE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0c714919-139c-48d1-b0a6-58ea1d6f4bbe.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Liverpool",
      "countryCode":"GB"
    },
    "firstName":"Steven",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGY0YzViYjYtYzM4YS00YTBiLThhNmUtNTY5YWYyY2EzOGU5Iiwidm90ZXJJZCI6IjEyZDg5NGJjLTcyNjQtNDA2ZC05MWUxLTcxNmQwYTU4NzBlNiIsImlhdCI6MTU2NTk2MTk5NCwiZXhwIjoxNTY1OTYyMjk0fQ.-6mBvpliiScGmCFS-6Rp818oZveE8SCQSmCTugbEw_c"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bb711efc-dc7a-4311-9964-126adb7fa836.jpg",
    "rotation":0,
    "store":{
      "country":"Poland",
      "state":"",
      "name":"Games Workshop - Warsaw",
      "countryCode":"PL"
    },
    "firstName":"Krzysztof",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiYzQ1ZWM2ODItMTM3MC00ODdiLWE1YjUtNGE5ZGYwMDc1YWRlIiwidm90ZXJJZCI6IjJkMDkzMDk5LWQ0OTUtNDE4OS1iYzM1LWNmYjY4YTRiYjBjOSIsImlhdCI6MTU2NTk2MjAxNiwiZXhwIjoxNTY1OTYyMzE2fQ.dtCiiEb-qksyiPe3Ve59tfg2RtLe6jd7qHWnOVQeoUU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/264386b1-13e6-408f-9e54-a23ea052e977.jpg",
    "rotation":0,
    "store":{
      "country":"France",
      "state":"",
      "name":"LUDIK ADDICT",
      "countryCode":"FR"
    },
    "firstName":"Yael",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOTE1Y2FjNzEtOWU5OC00OGQzLWJkMGEtNTQ2MGMzMDUwZTFiIiwidm90ZXJJZCI6IjE2YTMwNjAwLTcxZDQtNDI4Ny1iZTI4LWZhY2QxYTFkNTRiNSIsImlhdCI6MTU2NTk2MjA5NywiZXhwIjoxNTY1OTYyMzk3fQ.M2aCJQ3FqlvSCknGUURKUstzyzoQapOi_W0slEb9Zdk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/4bc79355-21e6-48e4-88d8-9cd66b081c14.jpg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"BC",
      "name":"Warhammer - Surrey",
      "countryCode":"CA"
    },
    "firstName":"Ryan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGZkOGE2NzItZDQ1Mi00NDU4LTk0ZDAtYjBjYjIwNTYxMWRlIiwidm90ZXJJZCI6IjcwZGNjMjRhLThjZmEtNDMzZS05ZGU3LTQwY2U3MjdjOGVhNCIsImlhdCI6MTU2NTk2MjExMSwiZXhwIjoxNTY1OTYyNDExfQ.Hjbsgw1N2c-8W3n9nMQiz98FC8OY9_e9pPsruNDXxCQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/80a8c42a-9bca-4504-8a2e-3ab59b491da2.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"FL",
      "name":"Warhammer - Northwoods Center",
      "countryCode":"US"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZGM4MzNhODktZjhmNy00OTUxLWJkZmQtZmVmMmY5NWU3YTMxIiwidm90ZXJJZCI6ImM3NzA1MjFmLTZiNTgtNDE2Zi04MGZlLTdhMGE4MGMwNWI3MiIsImlhdCI6MTU2NTk2MjQwMywiZXhwIjoxNTY1OTYyNzAzfQ.tm6-QP5NONio3_XQ7edPDNiTfJaZ1uT2uhJy_nTeZIg"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3ef8a976-9403-4e4a-b9f5-df2ce269c6a4.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NH",
      "name":"Midgard Comics and Games",
      "countryCode":"US"
    },
    "firstName":"Robert",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjU1MjVjOGYtNjI5Zi00MTc3LWE0ZjQtOTVhMmZiYjhlZjBmIiwidm90ZXJJZCI6ImU1YjUzNGRkLTIyZmYtNDNiNi05OTk0LTY2NmMwMzViMDdiYyIsImlhdCI6MTU2NTk2MjQ0MSwiZXhwIjoxNTY1OTYyNzQxfQ.txXlRWu9F5R68Pc2bZjWeseDZnRjwpM9xeCgeAoqQBE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/3845920d-72e3-4471-a7b7-780ea146224d.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"WA",
      "name":"Warhammer - Olympia",
      "countryCode":"US"
    },
    "firstName":"Timothy",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTk4MzZkZmMtN2Q2My00MjU5LWIwNDEtNGY2ZjBlZDNmOTdmIiwidm90ZXJJZCI6IjIxNGIwNWViLTNmYWEtNGVjZC04OTY2LTlhYmQ2ODFiMTk3NyIsImlhdCI6MTU2NTk2MjU2NywiZXhwIjoxNTY1OTYyODY3fQ.Kagnv-P70A5Bz-iBh9NvXswdqWUx65t3kykob10lo28"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b1337915-7485-4eaf-9e47-a5b0f05e3bbc.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Bromley",
      "countryCode":"GB"
    },
    "firstName":"John",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZDQ2Yjc2MmItOWI3ZS00OWY5LThkOGEtMjJhMDdhMThiYzllIiwidm90ZXJJZCI6Ijg1YjI3NzQ2LThhODQtNDMyZS04NGZhLTg1NTAzMTRlNWUxMCIsImlhdCI6MTU2NTk2MjcyOCwiZXhwIjoxNTY1OTYzMDI4fQ.JGWsaZsXfcJFB4AdPJMxCm16hDBv5Hd213ug0-DIOzQ"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/501f92f2-ee95-48af-a453-6a51536f71b2.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - East Kilbride",
      "countryCode":"GB"
    },
    "firstName":"Stephen",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjVhMDFjZDAtOTk5YS00YTJmLTlmNmUtM2VhYmMyMTYxNThkIiwidm90ZXJJZCI6IjRiNmQzZjZjLTVlMjEtNDNmNS04OTkwLTg3Yjk1YTMzZWQ2YiIsImlhdCI6MTU2NTk2Mjg0NywiZXhwIjoxNTY1OTYzMTQ3fQ.eDDICGFQamrwRQPefcjY0GxM94Az_fSewQE_ZPE55xM"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/75162e14-9a92-46a0-8440-bac04c0de60d.jpeg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"AZ",
      "name":"Warhammer - Chandler",
      "countryCode":"US"
    },
    "firstName":"Christopher",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjg0YTcwZDktNmVmMS00MzBhLWE5MDUtNTdmZmY0NmMyM2M2Iiwidm90ZXJJZCI6Ijk0YjA0ZjA3LTBiMjctNDQ4NS1hODYxLWM5NjUxNGRjOTNlOCIsImlhdCI6MTU2NTk2Mjg5MywiZXhwIjoxNTY1OTYzMTkzfQ.YA-JsuriR-3DyumEAWqVV8ZqnFEfjFIPIWqE7vcCiwc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/b6913e3d-8980-4f93-8b12-c5ac29c0cf1a.everchosen orruk megaboss",
    "rotation":0,
    "store":{
      "country":"Sweden",
      "state":"",
      "name":"FANTASIA",
      "countryCode":"SE"
    },
    "firstName":"Emil",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTE2OWE4NTAtOTE0My00ZWQ1LTk4ZTItYTI2YmVlOThmOWE5Iiwidm90ZXJJZCI6ImQ2OGRhZWQ3LWNmNDctNGU3Zi1hMzcxLWNmNWIyZTgzNGI4MyIsImlhdCI6MTU2NTk2Mjk2MCwiZXhwIjoxNTY1OTYzMjYwfQ.6NKpXTT3F-_0wOvfa2i4VwM2gmFynWPtdB8ubd5DFVw"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9df9bd5f-7bfc-4a0b-891a-c25ac0781b23.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"HUDDERSFIELD GAMES",
      "countryCode":"GB"
    },
    "firstName":"Alex",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNGVmZmI4NmYtMmM5NC00YmVkLWJmYzQtMDZjMzhjM2EzZmJiIiwidm90ZXJJZCI6ImZmMTU3YTRhLWI5MGEtNGZmMi1iYWYwLTZjM2RkMjZjMmU2YiIsImlhdCI6MTU2NTk2MzA5NiwiZXhwIjoxNTY1OTYzMzk2fQ.GWfdNUY04ZzHATQbMgyW_0COUFveyrIhK9LkCXavuOs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/0264b8c9-6449-4b79-b3b4-cc81ba73a593.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Warhammer - Fort Worth",
      "countryCode":"US"
    },
    "firstName":"Dwight",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZWNhZWYyYjAtMzNkZi00NTU1LWIwZTktOWFlZmY0MWJiMmE3Iiwidm90ZXJJZCI6IjI3OGY2Yjg3LWQ5N2YtNGY3ZC1iYTIzLTZmZWM4ZDQ0NGQzMCIsImlhdCI6MTU2NTk2MzEwNywiZXhwIjoxNTY1OTYzNDA3fQ.zGM-lG1aiGI76H9VDi6XEupd-6jg-l_KLkgWaYx4hZs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/9ff4d0a2-fcda-4eec-b18d-969321389149.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Warhammer - Spring",
      "countryCode":"US"
    },
    "firstName":"Jesse",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMTBmNWNhZTgtMDg2NC00MmQwLTg5ZTAtNTJkYjJkZmEyMDgyIiwidm90ZXJJZCI6IjMyN2U3YWU5LWMwNDktNDJmMi1hMmNhLWNkMGNjYTZkYTNmZSIsImlhdCI6MTU2NTk2MzE2NywiZXhwIjoxNTY1OTYzNDY3fQ.IU-3ntM3E6vdT_uEQPCPG4RcfDDl-7zyRcmK40-EYcs"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/519f7ea7-416d-459d-aefb-dcc9f58a3309.jpeg",
    "rotation":0,
    "store":{
      "country":"Canada",
      "state":"AB",
      "name":"Games Workshop - West Edmonton",
      "countryCode":"CA"
    },
    "firstName":"Jared",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNzM4MTNmYWYtNDhlYS00MTkxLTkyYjAtYjNjOGQ2ZmU5MjhmIiwidm90ZXJJZCI6ImIwZmNlNjQ4LWU0Y2QtNDdhMy05ZDU0LWFlMTdmNzQ3MzY0MyIsImlhdCI6MTU2NTk2MzI2MiwiZXhwIjoxNTY1OTYzNTYyfQ.uJDGuLTybOcwVt3PxQd6kmB62-ia0F7wKsCKRFidVzY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a439e9a8-a484-49dc-822a-b9b7ba1a3d24.jpg",
    "rotation":0,
    "store":{
      "country":"Germany",
      "state":"",
      "name":"Warhammer - Osnabr\u00fcck",
      "countryCode":"DE"
    },
    "firstName":"Anne",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiM2MzZGJiNGItZTUyOS00NGMzLWJlN2UtM2U2ZmZmOGEwZDFjIiwidm90ZXJJZCI6ImFmMTMzNzVjLTM3OTktNGYyYy1iOGE4LWMzZjM5NmU1MDA2OSIsImlhdCI6MTU2NTk2MzU3NCwiZXhwIjoxNTY1OTYzODc0fQ.U-qFTSypCsS0OAXtit_WimRz_paErL3MB1WevNUEWvY"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/75ba969b-d9a2-4e64-a689-b70652ef987d.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"NV",
      "name":"Games Workshop - Blue Diamond Crossing",
      "countryCode":"US"
    },
    "firstName":"Jake",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNWY3YTEzMWQtMGUxYS00NTAyLThhN2EtZGQwMWY0MmE4MTViIiwidm90ZXJJZCI6ImY1OTQxOGIyLThkODYtNDgzNy1iMzY5LTRiZDU5ODk3YzNmYiIsImlhdCI6MTU2NTk2MzU4NSwiZXhwIjoxNTY1OTYzODg1fQ.qi88HRYP23OKdJZq2ZVRZzj57PzQIhUCzC2YyvbOPgU"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d96bda4d-f90f-4523-81bd-ffea87a7d4ce.jpeg",
    "rotation":0,
    "store":{
      "country":"Norway",
      "state":"",
      "name":"Games Workshop - Oslo",
      "countryCode":"NO"
    },
    "firstName":"Thomas",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMDAwZDg1NWUtMDRjMy00MDQ1LThiZDktODNhMDE1NGIyMzBkIiwidm90ZXJJZCI6IjhlMTVlNDJjLTI2OGEtNGQwZS1hMDUyLWVlMzUyZjM3MTkwNiIsImlhdCI6MTU2NTk2MzYwMiwiZXhwIjoxNTY1OTYzOTAyfQ.ZoyKhWPUSOr7UXcwhXApdgBQ03JNCxHEM6HqVYqRuT0"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a8bbdfe9-6daa-443b-a577-81acf7b6b34a.JPG",
    "rotation":0,
    "store":{
      "country":"Sweden",
      "state":"",
      "name":"Games Workshop - Stockholm",
      "countryCode":"SE"
    },
    "firstName":"Magnus",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiODhjZDI1YTctYTM2MS00YmM2LThmYjQtMjQwMTI4N2NhYmU4Iiwidm90ZXJJZCI6ImNiZWNiMDM2LTRmYjgtNGI4MC04OTQzLTI2ZGNjMDhiZWNiYiIsImlhdCI6MTU2NTk2MzczNSwiZXhwIjoxNTY1OTY0MDM1fQ.kYYjguUzv2Aboj88eXfj646fCxQqUddv9KAttuhZDTA"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/6440a831-1afb-4c46-b72e-92a3e59d2abb.jpg",
    "rotation":0,
    "store":{
      "country":"New Zealand",
      "state":"",
      "name":"Game Centre",
      "countryCode":"NZ"
    },
    "firstName":"Patricio Andres",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMmE3ZDJlZTctZDE3Ni00Zjk5LThlNDYtMGRmMmMzMTg0NjFhIiwidm90ZXJJZCI6ImYyYjJjYjg5LWI2MGEtNDMzMC1hNDk2LWUxOWZiMzgxODBhNiIsImlhdCI6MTU2NTk2Mzc4NiwiZXhwIjoxNTY1OTY0MDg2fQ.Ul1FJqVzPr2SvCLR5FmzBIb2imMUC-iZUF2F_sUWn8Y"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/962086cf-289c-4601-bac2-09926521c557.JPG",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Games Workshop - Exeter",
      "countryCode":"GB"
    },
    "firstName":"Peter",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiOWQ0ZDVlMzAtOWRkMi00NjVlLTg3OTYtZjVhNTZjNWYwNjU1Iiwidm90ZXJJZCI6ImE3MzNjNTY4LWEzMjUtNDNmYy05NzJmLTJkMjE0MTY3Y2YwZCIsImlhdCI6MTU2NTk2Mzg5MiwiZXhwIjoxNTY1OTY0MTkyfQ.rRZjqvZ9ET25w-KjChAlYTO24782AW1jhPGZPC2_Tbc"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/bd599228-ce09-4b60-90ab-e27278742b17.jpg",
    "rotation":0,
    "store":{
      "country":"New Zealand",
      "state":"",
      "name":"Warhammer - Dunedin",
      "countryCode":"NZ"
    },
    "firstName":"Nathan",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTcwZGQ4YjYtMjQwYS00YWI3LWEyMjUtNzQyMGNiNTM2ODA1Iiwidm90ZXJJZCI6IjJkZTdkYmVhLTUzM2QtNGQ3Mi1hODdlLWM1MDRkMmJlNzY2ZCIsImlhdCI6MTU2NTk2MzkyNCwiZXhwIjoxNTY1OTY0MjI0fQ.mhAz52EOYGTQlDwShRPt8JelUs-4fCrcf_Tb1266ygk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/56154a45-9971-4b10-a9d1-96580b267419.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Games Workshop - Roger de Lluria",
      "countryCode":"ES"
    },
    "firstName":"Vasilii",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiZjgyZjY4MTAtMjkwNS00MWZjLTkwY2MtNjg1ZWY5ODk1ZDE2Iiwidm90ZXJJZCI6IjhkMDU5MmMwLTU4MTYtNDNiOC05MmQ5LTUwZTliZGU5MDNiZCIsImlhdCI6MTU2NTk2NDAwMCwiZXhwIjoxNTY1OTY0MzAwfQ.Mt8b51Sg915Ak_FRJcKqt8htE6oFQUGha04pNvOayho"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/a38417f4-aac9-43f8-8b14-9731f9bad31d.jpg",
    "rotation":0,
    "store":{
      "country":"Russian Federation",
      "state":"",
      "name":"Hobby Games Saint Petersburg Sadovaya st",
      "countryCode":"RU"
    },
    "firstName":"Roman",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTVmYjgxYjgtN2Q4Ni00MzIyLTk1M2QtMjYxYmE3NTU2ZTU0Iiwidm90ZXJJZCI6ImY4YTFiNTY5LWU4YWQtNDkyZi04NTM5LTcwM2FlNTQ0NDNlNCIsImlhdCI6MTU2NTk2NDQxNiwiZXhwIjoxNTY1OTY0NzE2fQ.__MAGI14-dl_Zjwkk_rSNEF31t1hPtdhZ95RlVvsvHE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/ee2307eb-9776-479a-b8c5-e47cf92bdd17.jpg",
    "rotation":0,
    "store":{
      "country":"United Kingdom",
      "state":"",
      "name":"Warhammer - Inverness",
      "countryCode":"GB"
    },
    "firstName":"James",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiMzZmMjY5YWItYTU5Ny00YmE1LWE2MTEtNjA5NDY3MDM4MDNkIiwidm90ZXJJZCI6ImYwYjkwN2Q4LWM4YjUtNGQ2MC05YmNlLTBjM2E4YWQ2ZDdhYSIsImlhdCI6MTU2NTk2NDk4NiwiZXhwIjoxNTY1OTY1Mjg2fQ.8Amg2swG3FRPFImUyC0sttqUukH9YMReIXMx4fCqFQE"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/e2894259-4f50-4451-a026-026cd26320bc.jpg",
    "rotation":0,
    "store":{
      "country":"United States",
      "state":"TX",
      "name":"Warhammer - University Commons",
      "countryCode":"US"
    },
    "firstName":"Alyssandra",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNTMwNzNlMmUtMDZjYy00OTI3LWFlNTktNDlkMzBjNjcxNWY5Iiwidm90ZXJJZCI6IjkxZDZiNmQ0LWJiMDktNDRjMS05MGU3LTNjNzU3NGE3NWFmNiIsImlhdCI6MTU2NTk2NTA3NCwiZXhwIjoxNTY1OTY1Mzc0fQ.EqBYWPKuu4fiXLJU_UUK-FH1V49FSiixjFuceh58tLk"
  },
  {
    "miniature":"https://everchosen-images.warhammer-community.com/d0736dda-7726-449a-bbe3-fd051b38de18.jpg",
    "rotation":0,
    "store":{
      "country":"Spain",
      "state":"",
      "name":"Warhammer - Zaragoza",
      "countryCode":"ES"
    },
    "firstName":"Javier",
    "votingToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRyeUlkIjoiNDAyZGY5MGItN2NiYy00NzczLThmZTEtNzViZWI5ZDdhNjg3Iiwidm90ZXJJZCI6IjJhN2UxNGNiLTVkM2UtNDI1Mi05YWRkLWZhNmY3YTE3NzRhMyIsImlhdCI6MTU2NTk2NTQ0MSwiZXhwIjoxNTY1OTY1NzQxfQ.krH_smdj2-Zl3XsnP5Qm-3gW8yLyjcukO4V-8dZCzzQ"
  }
]
