import styled from 'styled-components'

export const AppHeader = styled.header`
  margin: 80px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AppLogoWrapper = styled.div`
  max-width: 400px;
`

export const AppLogo = styled.img`
  width: 100%;
`
