import { find, uniqBy } from 'lodash'
import React, { Component } from 'react'

import { Route, BrowserRouter as Router } from 'react-router-dom'

import { GlobalStyle, Root } from './style.js'
import gw_data from 'format_gw_data.js'

import Header from './Header'
import Filters from './Filters'
import List from './List'
import PortalList from './PortalList'

import { updateEntries } from 'localEntries'

const countries = uniqBy(
  [
    {
      Id: 0,
      Title: 'All countries',
    },
    ...gw_data.Ids.map((Id) => {
      const Record = gw_data.byId[Id]
      return { Id: Record.CountryCode, Title: Record.Country }
    }),
  ],
  'Id'
)

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      countryId: 0,
      stars: 0,
      byId: gw_data.byId,
    }

    this.handleVoteUpdate = this.handleVoteUpdate.bind(this)
  }

  handleVoteUpdate(Id, n) {
    updateEntries(Id, n)
    this.setState({
      byId: {
        ...this.state.byId,
        [Id]: {
          ...this.state.byId[Id],
          _stars: n,
        },
      },
    })
  }

  render() {
    const { countryId, byId, stars } = this.state

    const selectedCountry = find(countries, { Id: countryId })

    const list = gw_data.Ids.filter((Id) => {
      const Record = byId[Id]
      const countryFilter = countryId === 0 || Record.CountryCode === countryId
      const starFilter = stars === 0 || Record._stars === stars
      return countryFilter && starFilter
    })

    return (
      <Router>
        <Root>
          <GlobalStyle />
          <Header />
          <Filters
            options={countries}
            selectedCountry={selectedCountry}
            selectedStars={stars}
            onUpdate={(key, value) => this.setState({ [key]: value })}
          />
          <List byId={byId} list={list} />
          <PortalList
            list={gw_data.Ids}
            byId={byId}
            onUpdate={this.handleVoteUpdate}
          />
        </Root>
      </Router>
    )
  }
}

export default App
