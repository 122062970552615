import { isEqual, find } from 'lodash'

import React, { Component } from 'react'
import { Portal } from 'react-portal'
import PropTypes from 'prop-types'

import { scrollContext } from '_contexts'

import { removeBodyScroll, restoreBodyScroll } from 'bodyScroll'

import checkDomTargetPath from 'checkDomTargetPath'

import PopupPosition from './PopupPosition'
import DefaultSelector from './Selector'
import Control from './Control'
import SearchField from './SearchField'
import List from './List'

import A11yText from './accessibility/A11yText'

import {
  optionFocusAriaMessage,
  resultsAriaMessage,
  valueEventAriaMessage,
} from './accessibility/index'

import { scrollIntoView } from './utils'

import { Container, Dropdown as DefaultDropdown } from './style'

class Select extends Component {
  state = {
    scrollingInSelf: false,
    ariaLiveSelection: '',
    searchValue: this.props.defaultValue[this.props.titleKey],
    selectedOption: this.props.defaultValue,
    focusedOption: this.props.defaultValue,
    options: this.props.options,
    isSelectorFocused: false,
    isListOpen: false,
    isSearching: false,
    isShowAllOptionsFocused: false,
    isShowAllOptionsOpen: false,
  }

  containerRef = null
  getContainerRef = (ref) => {
    this.containerRef = ref
  }

  externalRef = null
  getExternalRef = (ref) => {
    this.externalRef = ref
  }

  controlRef = null
  getControlRef = (ref) => {
    this.controlRef = ref
  }

  popupRef = null
  getPopupRef = (ref) => {
    this.popupRef = ref
  }

  selectorRef = null
  getSelectorRef = (ref) => {
    this.selectorRef = ref
  }

  searchFieldRef = null
  getSearchFieldRef = (ref) => {
    this.searchFieldRef = ref
  }

  clearButtonRef = null
  getClearButtonRef = (ref) => {
    this.clearButtonRef = ref
  }

  listRef = null
  getListRef = (ref) => {
    this.listRef = ref
  }

  focusedOptionRef = null
  getFocusedOptionRef = (ref) => {
    this.focusedOptionRef = ref
  }

  showAllOptionsRef = null
  getShowAllOptionsRef = (ref) => {
    this.showAllOptionsRef = ref
  }

  shouldScrollToFocusedOptionOnUpdate = true

  componentWillUnmount() {
    if (this.state.isListOpen) restoreBodyScroll(this.context.scrollNode)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      isShowAllOptionsFocused,
      isSelectorFocused,
      isListOpen,
    } = this.state

    if (
      this.listRef &&
      this.focusedOptionRef &&
      !isShowAllOptionsFocused &&
      this.shouldScrollToFocusedOptionOnUpdate
    ) {
      scrollIntoView(this.listRef, this.focusedOptionRef)
    }

    this.shouldScrollToFocusedOptionOnUpdate = false

    if (isSelectorFocused && !isListOpen) {
      this.controlRef.focus()
    }

    if (prevState.isListOpen && !isListOpen) {
      restoreBodyScroll(this.context.scrollNode)
    }

    if (!isEqual(this.props.defaultValue, prevProps.defaultValue)) {
      this.clearList()
    }
  }

  getOptionsWithFocusedId = (offset = 0) =>
    this.props.options.map((option, index) => ({
      ...option,
      focusedId: index + offset,
    }))

  clearList = () => {
    const { defaultValue } = this.props
    const options = this.getOptionsWithFocusedId()

    this.setState((prevState) => {
      return {
        searchValue: '',
        selectedOption: defaultValue,
        focusedOption: options[0],
        options,
        isSearching: false,
        isShowAllOptionsFocused: false,
        isShowAllOptionsOpen: false,
      }
    })
  }

  resetList = () => {
    const { defaultValue } = this.props
    const options = this.getOptionsWithFocusedId()

    this.setState((prevState) => {
      const isSelectedOptionExist = !!prevState.selectedOption.Id

      return {
        searchValue: '',
        selectedOption: isSelectedOptionExist
          ? prevState.selectedOption
          : defaultValue,
        focusedOption: isSelectedOptionExist
          ? find(options, { Id: prevState.selectedOption.Id })
          : options[0],
        options,
        isSearching: false,
        isShowAllOptionsFocused: false,
        isShowAllOptionsOpen: false,
      }
    })
  }

  handleScrollEnter = () => {
    if (this.state.isListOpen) {
      this.setState(
        {
          scrollingInSelf: true,
        },
        () => removeBodyScroll(this.context.scrollNode)
      )
    }
  }

  handleScrollLeave = () => {
    this.setState(
      {
        scrollingInSelf: false,
      },
      () => restoreBodyScroll(this.context.scrollNode)
    )
  }

  handleGeneralEvent = (evt) => {
    if (this.containerRef === null) {
      document.removeEventListener('click', this.handleGeneralEvent)
      window.removeEventListener('scroll', this.handleGeneralEvent)
      return
    }

    if (evt.type === 'scroll' && this.state.scrollingInSelf) {
      return
    }

    const inContainer = checkDomTargetPath(evt, this.containerRef)
    const inPopup = checkDomTargetPath(evt, this.popupRef)
    const externalRef = checkDomTargetPath(evt, this.externalRef)

    if (inContainer || inPopup || externalRef) return

    this.toggleList()
  }

  toggleList = () => {
    const node = this.context.scrollNode || window

    this.resetList()

    this.shouldScrollToFocusedOptionOnUpdate = !this.state.isListOpen

    this.setState(
      (prevState) => ({
        isSelectorFocused: !prevState.isListOpen,
        isListOpen: !prevState.isListOpen,
      }),
      () => {
        if (this.state.isListOpen) {
          document.addEventListener('mousedown', this.handleGeneralEvent)
          node.addEventListener('scroll', this.handleGeneralEvent)
        } else {
          document.removeEventListener('mousedown', this.handleGeneralEvent)
          node.removeEventListener('scroll', this.handleGeneralEvent)
        }
      }
    )
  }

  handleClickSelector = () => {
    if (!this.props.disabled) {
      this.toggleList()
    }
  }

  handleFocusSelector = (isSelectorFocused) => {
    if (!this.props.disabled) {
      this.setState({ isSelectorFocused })
    }
  }

  handleSelectOption = (selectedOption) => {
    const { options, titleKey } = this.props

    this.announceAriaLiveSelection({
      event: 'deselect-option',
      context: { value: this.state.selectedOption[titleKey] },
    })

    const index = options.findIndex((option) => option.Id === selectedOption.Id)
    const option = options[index]

    this.announceAriaLiveSelection({
      event: 'select-option',
      context: { value: option[titleKey] },
    })

    const optionWithFocusedId = {
      ...option,
      focusedId: index,
    }

    this.setState(
      {
        focusedOption: optionWithFocusedId,
        selectedOption: option,
        isSearching: false,
      },
      () => {
        this.props.onSelect(option)
        this.toggleList()
      }
    )
  }

  handleSearchChange = ({ value, results }) => {
    const isResultsNull = results === null
    const options = isResultsNull
      ? this.getOptionsWithFocusedId()
      : results.map((result, index) => ({
          ...result,
          focusedId: index,
        }))

    this.setState({
      searchValue: value,
      focusedOption: options[0],
      options,
      isSearching: !isResultsNull,
      isShowAllOptionsOpen: false,
    })
  }

  focusOption(direction) {
    const {
      focusedOption,
      options: _options,
      isShowAllOptionsFocused,
      isShowAllOptionsOpen,
    } = this.state

    const options = isShowAllOptionsOpen
      ? [..._options, ...this.getOptionsWithFocusedId(_options.length)]
      : _options

    if (!options.length) return

    let nextFocus = 0
    const focusedIndex = options.findIndex(
      (option) => option.focusedId === focusedOption.focusedId
    )

    switch (direction) {
      case 'up':
        nextFocus = focusedIndex > 0 ? focusedIndex - 1 : options.length - 1
        break
      case 'down':
        nextFocus = (focusedIndex + 1) % options.length
        break
      default:
        break
    }

    if (isShowAllOptionsFocused) {
      this.searchFieldRef.focus()
    }

    this.shouldScrollToFocusedOptionOnUpdate = true

    this.setState({
      focusedOption: options[nextFocus],
      isShowAllOptionsFocused: false,
    })
  }

  handleKeyPressed = (e) => {
    const {
      isListOpen,
      isSearching,
      isShowAllOptionsFocused,
      focusedOption,
    } = this.state

    switch (e.key) {
      case 'Enter':
        if (isListOpen) {
          if (isShowAllOptionsFocused) {
            this.showAllOptions()
            return
          }
          if (!focusedOption) return

          this.handleSelectOption(focusedOption)
        }
        break
      case 'Escape':
        if (!isSearching && isListOpen) {
          this.toggleList()
          this.handleFocusSelector(true)
        }
        break
      case 'ArrowUp':
        if (isListOpen) {
          this.focusOption('up')
        }
        break
      case 'ArrowDown':
        if (isListOpen) {
          this.focusOption('down')
        }
        break
      case 'Tab':
        if (isShowAllOptionsFocused || (isListOpen && !this.clearButtonRef)) {
          this.searchFieldRef.focus()
          break
        }

        return
      case ' ':
        if (!isListOpen) {
          this.toggleList()
          break
        }
        return
      default:
        return
    }

    e.preventDefault()
  }

  handleHoverOption = (option) => {
    this.setState({
      focusedOption: option,
    })
  }

  showAllOptions = () => {
    this.setState((prevState) => ({
      focusedOption:
        prevState.focusedOption ||
        this.getOptionsWithFocusedId(prevState.options.length)[0],
      isShowAllOptionsOpen: true,
    }))

    this.searchFieldRef.focus()
  }

  handleClearValue = () => {
    this.resetList()
    this.searchFieldRef.focus()
  }

  handleShowAllOptionsFocused = (isShowAllOptionsFocused) => {
    if (isShowAllOptionsFocused) {
      scrollIntoView(this.listRef, this.showAllOptionsRef)
    }

    this.setState({
      isShowAllOptionsFocused,
    })
  }

  announceAriaLiveSelection = ({ event, context }) => {
    this.setState({
      ariaLiveSelection: valueEventAriaMessage(event, context),
    })
  }

  constructAriaLiveMessage() {
    const {
      options,
      focusedOption,
      isListOpen,
      isShowAllOptionsOpen,
      searchValue,
    } = this.state
    const { screenReaderStatus, titleKey } = this.props

    // An aria live message representing the currently focused option in the select.
    const focusedOptionMsg =
      focusedOption && isListOpen
        ? optionFocusAriaMessage({
            isShowAllOptionsOpen,
            focusedOption,
            titleKey,
            options: isShowAllOptionsOpen
              ? [...options, ...this.getOptionsWithFocusedId(options.length)]
              : options,
          })
        : ''
    // An aria live message representing the set of focusable results and current searchterm/inputvalue.
    const resultsMsg = resultsAriaMessage({
      searchValue,
      screenReaderMessage: screenReaderStatus({ count: options.length }),
    })

    const ariaLiveContext =
      'Type to refine list, use Up and Down to choose options, press Escape to exit the menu, press Enter to select the option and exit the menu.'

    return `${focusedOptionMsg} ${resultsMsg} ${ariaLiveContext}`
  }

  renderLiveRegion = () => (
    <A11yText aria-live="assertive">
      <p id="aria-selection-event">&nbsp;{this.state.ariaLiveSelection}</p>
      <p id="aria-context">&nbsp;{this.constructAriaLiveMessage()}</p>
    </A11yText>
  )

  renderSelector = () => {
    const { selectedOption, isSelectorFocused, isListOpen } = this.state
    const { titleKey, placeholder, disabled } = this.props

    const selectorProps = {
      selectedOption,
      value: selectedOption[titleKey],
      placeholder,
      onClick: this.handleClickSelector,
      isFocused: isSelectorFocused,
      isOpen: isListOpen,
      disabled,
      selectorRef: this.getSelectorRef,
    }

    const Selector = this.props.Selector || DefaultSelector

    return <Selector {...selectorProps} />
  }

  render() {
    const {
      searchValue,
      selectedOption,
      focusedOption,
      options,
      isListOpen,
      isSearching,
      isShowAllOptionsOpen,
    } = this.state

    const Dropdown = this.props.Dropdown || DefaultDropdown

    return (
      <Container
        ref={this.getContainerRef}
        onFocus={() => this.handleFocusSelector(true)}
        onBlur={() => this.handleFocusSelector(false)}>
        {this.renderLiveRegion()}

        <Control
          controlRef={this.getControlRef}
          onKeyDown={this.handleKeyPressed}>
          {this.renderSelector()}

          {isListOpen && (
            <Portal>
              <PopupPosition
                elem={this.selectorRef}
                popupRef={this.getPopupRef}
                onMouseEnter={this.handleScrollEnter}
                onMouseLeave={this.handleScrollLeave}>
                <Dropdown
                  externalRef={this.getExternalRef}
                  isSearching={isSearching}>
                  {this.props.search && (
                    <SearchField
                      SearchInput={this.props.SearchInput}
                      searchFieldRef={this.getSearchFieldRef}
                      clearButtonRef={this.getClearButtonRef}
                      value={searchValue}
                      targets={this.props.options}
                      searchKey={this.props.searchKey}
                      onChange={this.handleSearchChange}
                      onClearValue={this.handleClearValue}
                    />
                  )}

                  <List
                    listRef={this.getListRef}
                    ListContainer={this.props.ListContainer}
                    maxHeight={this.props.maxHeight}
                    options={options}
                    Option={this.props.Option}
                    titleKey={this.props.titleKey}
                    originalOptions={this.getOptionsWithFocusedId(
                      options.length
                    )}
                    isSearching={isSearching}
                    selectedOption={selectedOption}
                    focusedOption={focusedOption}
                    focusedOptionRef={this.getFocusedOptionRef}
                    onSelectOption={this.handleSelectOption}
                    onHoverOption={this.handleHoverOption}
                    showAllOptionsRef={this.getShowAllOptionsRef}
                    isShowAllOptionsOpen={isShowAllOptionsOpen}
                    handleShowAllOptionsFocused={
                      this.handleShowAllOptionsFocused
                    }
                    showAllOptions={this.showAllOptions}
                  />
                </Dropdown>
              </PopupPosition>
            </Portal>
          )}
        </Control>
      </Container>
    )
  }
}

Select.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  screenReaderStatus: PropTypes.func,
  titleKey: PropTypes.string,
  searchKey: PropTypes.string.isRequired,
  search: PropTypes.bool,
  maxHeight: PropTypes.number,
  Dropdown: PropTypes.any,
  SearchInput: PropTypes.any,
  Selector: PropTypes.any,
  ListContainer: PropTypes.any,
  Option: PropTypes.any,
}

Select.defaultProps = {
  disabled: false,
  placeholder: 'Select...',
  defaultValue: {},
  options: [],
  onSelect: () => {},
  screenReaderStatus: ({ count }) =>
    `${count} result${count !== 1 ? 's' : ''} available`,
  titleKey: 'Title',
  search: true,
  maxHeight: 250,
  Dropdown: null,
  SearchInput: null,
  Selector: null,
  ListContainer: null,
  Option: null,
}

Select.contextType = scrollContext

export default Select
