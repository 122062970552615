import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Container } from './style'

class Control extends Component {
  render() {
    const { controlRef, onKeyDown, children } = this.props
    return (
      <Container ref={controlRef} onKeyDown={onKeyDown} tabIndex="0">
        {children}
      </Container>
    )
  }
}

Control.propTypes = {
  controlRef: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default Control
