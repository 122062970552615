import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import fuzzysort from 'fuzzysort'

import { Input as DefaultInput, ClearButton, ClearIcon } from './style'

class SearchField extends Component {
  searchTargets = (searchValue) => {
    if (searchValue === '') {
      return null
    }

    const results = fuzzysort
      .go(searchValue, this.props.targets, { key: this.props.searchKey })
      .map((result) => result.obj)

    return results === undefined ? [] : results
  }

  handleOnChange = (e) => {
    const value = e.target.value

    this.props.onChange({ value, results: this.searchTargets(value.trim()) })
  }

  render() {
    const {
      SearchInput,
      searchFieldRef,
      clearButtonRef,
      value,
      onClearValue,
      className,
    } = this.props

    const Input = this.props.SearchInput || DefaultInput

    return (
      <Fragment>
        <Input
          type="search"
          ref={searchFieldRef}
          autoFocus
          tabIndex="1"
          className={className}
          value={value}
          onChange={this.handleOnChange}
        />
        {value !== '' && (
          <ClearButton ref={clearButtonRef} tabIndex="2" onClick={onClearValue}>
            <ClearIcon id="close" />
          </ClearButton>
        )}
      </Fragment>
    )
  }
}

SearchField.propTypes = {
  searchFieldRef: PropTypes.func,
  clearButtonRef: PropTypes.func,
  targets: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string.isRequired,
  searchKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClearValue: PropTypes.func,
  className: PropTypes.string,
}

SearchField.defaultProps = {
  searchFieldRef: () => {},
  clearButtonRef: () => {},
  targets: [],
  onChange: () => {},
  onClearValue: () => {},
  className: '',
}

export default SearchField
